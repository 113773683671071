import { ApolloError, gql, PureQueryOptions, useMutation, } from "@apollo/client";

const updateOptionOrdersMutation = gql`mutation ($restaurantId: String!, $optionIds: [String!]!) {
  updateOptionOrders(restaurantId: $restaurantId, optionIds: $optionIds)
}`


export type UpdateOptionOrdersMutationInput = {
  optionIds: string[];
  restaurantId: string;
}

export type UpdateOptionOrdersResponse = {
  updateOptionOrders: boolean
}

export function useUpdateOptionOrders(config: {
  refetchQueries: (string | PureQueryOptions)[],
  onCompleted?: (data: UpdateOptionOrdersResponse) => void,
  onError: (error: ApolloError) => void,
}) {

  return useMutation<UpdateOptionOrdersResponse, UpdateOptionOrdersMutationInput>(updateOptionOrdersMutation, {
    refetchQueries: config.refetchQueries,
    onCompleted: config.onCompleted,
    onError: config.onError,
  })

}

