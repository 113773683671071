import React from 'react'
import { useTranslation } from 'react-i18next'
import { Container } from 'reactstrap'
import Header from '../../components/Headers/Header'
import { getConfiguration } from '../../apollo/server'
import EmailConfiguration from './Email'
import StripeConfiguration from './Stripe'
import CurrencyConfiguration from './Currency'
import Mailer from './Mailer'
import { useQuery, gql } from "@apollo/client"
import SpecialFeatureTitle from './SpecialFeatureTitle'
import StoreClosed from './StoreClosed'


const Configuration = () => {
  const { t } = useTranslation()
  const { loading, error, data } = useQuery(gql`${getConfiguration}`)

  if (error) {
    return (
      <p> {`${t('Error')}! ${error.message}`} </p>
    )
  }
  if (loading) {
    return (
      <p> {t('Loading')} </p>
    )
  }
  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <SpecialFeatureTitle
          title={data && data.configuration ? data.configuration.specialFeatureTitle : ""}
        />

        <StoreClosed
          storeClosed={data && data.configuration ? data.configuration.storeClosed : false}
        />

        <EmailConfiguration
          email={data && data.configuration ? data.configuration.email : ""}
          password={data && data.configuration ? data.configuration.password : ""}
          enabled={data && data.configuration ? data.configuration.enableEmail : true}
        />
        <StripeConfiguration
          publishableKey={data.configuration.publishableKey}
          secretKey={data.configuration.secretKey}
        />
        <CurrencyConfiguration
          currencyCode={data && data.configuration ? data.configuration.currency : ""}
          currencySymbol={data && data.configuration ? data.configuration.currencySymbol : ""}
        />

        <Mailer />
      </Container>
    </>
  )
}

export default Configuration
