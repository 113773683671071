import React, { useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { 
  Container, 
  Row, 
  Card, 
  Modal,
  CardBody,
  FormGroup,
  Input,
  Col,
} from "reactstrap"
import OrderComponent from "../../components/Order/Order"
import OrdersData from "../../components/Order/OrdersData"
import Header from "../../components/Headers/Header"
import { calcBusinessEndTime, calcBusinessStartTime, getBusinessDate } from "../../utils/calcBusinessTime"
import { useOrdersByStoreIdQuery } from "../../domain/graphql/query/useOrdersByStoreIdQuery"
import { AppContext } from "../../app"


const Orders: React.FC = () => {
  const { t } = useTranslation()
  const appContext = useContext(AppContext)
  const userType = appContext.userType
  const restaurantId = appContext.restaurantId
  const [detailsModal, setDetailModal] = useState(false)
  const [order, setOrder] = useState(null)
  const [page, setPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const intializeStartDate = date => {
    var d = new Date(date.getTime())
    d.setDate(date.getDate() - 7)
    return d
  }
  const initialDate = getBusinessDate(new Date())

  const [startDate, setStartDate] = useState(intializeStartDate(initialDate))
  const [endDate, setEndDate] = useState(new Date())


  const toggleModal = order => {
    if (order) setOrder(order)
    setDetailModal(!detailsModal)
  }

  const { loading, error, data: orders, subscribeToMore } = useOrdersByStoreIdQuery(restaurantId, calcBusinessStartTime(startDate).getTime(), calcBusinessEndTime(endDate).getTime(), userType)

  if (error) {
    console.log(error)
    return (
      <div >
        <Header />
        <p style={{ marginTop: 30, textAlign: "center" }}>{`${t("Error")}! ${error.message}`}</p>
      </div>
    )
  }

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row className="mb-lg-5 mb-sm-3">
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="shadow">
              <CardBody>
                <Row>
                  <Col xl="4">
                    <FormGroup>
                      <label className="form-control-label">
                        {t("Starting Date")}
                      </label>
                      <Input
                        className="form-control-alternative"
                        type="date"
                        max={new Date().toISOString().substr(0, 10)}
                        min={"2021-04-01"}
                        onChange={event => setStartDate(new Date(event.target.value))}
                        value={startDate.toISOString().substr(0, 10)}
                      />
                    </FormGroup>
                  </Col>
                  <Col xl="4">
                    <FormGroup>
                      <label className="form-control-label">
                        {t("Ending Date")}
                      </label>
                      <Input
                        className="form-control-alternative"
                        type="date"
                        min={"2021-04-01"}
                        onChange={event => setEndDate(new Date(event.target.value))}
                        value={endDate.toISOString().substr(0, 10)}
                      />
                    </FormGroup>
                  </Col>
                  {/* <Col xl="1">
                    <FormGroup>
                      <label className="form-control-label" > CSV </label>
                      <br />
                      <Button color="primary" onClick={() => download(orders)} >DL</Button>
                    </FormGroup>
                  </Col> */}
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <div className="col">
            <Card className="shadow">
              <OrdersData
                orders={orders}
                toggleModal={toggleModal}
                subscribeToMore={subscribeToMore}
                loading={loading}
                selected={order}
                updateSelected={setOrder}
                page={page}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
              />
            </Card>
          </div>
        </Row>
        <Modal
          className="modal-dialog-centered"
          size="lg"
          isOpen={detailsModal}
          toggle={() => toggleModal(null)}>
          <OrderComponent order={order} />
        </Modal>
      </Container>
    </>
  )
}

export default Orders
