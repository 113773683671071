/**
 *
 * mutation
 *
 */
export const createVendor = `mutation CreateVendor($vendorInput:VendorInput) {
  createVendor(vendorInput:$vendorInput) {
    _id
    email
    userType
    restaurants {
      _id
      orderId
      orderPrefix
      name
      image
      address
      location {
        coordinates
      }
      commission
      deliveryCharges
      zone {
        _id
        title
      }
      storeCategory {
        _id
        title
      }
      storeSubCategory {
        _id
        title
      }
    }
  }
}`

export const editVendor = `mutation EditVendor($vendorInput:VendorInput) {
  editVendor(vendorInput:$vendorInput) {
    _id
    email
    restaurants {
      _id
      orderId
      orderPrefix
      name
      image
      address
      location {
        coordinates
      }
      commission
      deliveryCharges
      chargeLimit
      zone {
        _id
        title
      }
      storeCategory {
        _id
        title
      }
      storeSubCategory {
        _id
        title
      }
    }
  }
}`

export const toggleVendorCapability = `mutation ($id:String!, $userType: String!) {
  toggleVendorCapability(id:$id, userType: $userType)
}`

export const vendorResetPassword = `mutation VendorResetPassword($password:String!) {
  vendorResetPassword(password:$password) {
    result
  }
}`

export const ownerLogin = `mutation ownerLogin($email:String!,$password:String!) {
  ownerLogin(email:$email,password:$password) {
    userId
    token
    email
    userType
    restaurants {
      _id
      orderId
      name
      image
      address
    }
  }
}`

export const deleteUser = `mutation deleteUser($email:String!, $password:String!) {
  deleteUser(email:$email, password:$password)
}`


export const editRestaurant = `mutation EditRestaurant($restaurantInput:RestaurantProfileInput!) {
  editRestaurant(restaurant:$restaurantInput) {
    _id
    orderId
    orderPrefix
    name
    phone
    image
    address
    location {
      coordinates
    }
    deliveryCharges
    chargeLimit
    username
    password
    deliveryTime
    minimumOrder
    promotionText

    openTime
    closeTime
    openTime2
    closeTime2

    mondayOpenTime
    mondayCloseTime
    mondayOpenTime2
    mondayCloseTime2

    tuesdayOpenTime
    tuesdayCloseTime
    tuesdayOpenTime2
    tuesdayCloseTime2

    wednesdayOpenTime
    wednesdayCloseTime
    wednesdayOpenTime2
    wednesdayCloseTime2

    thursdayOpenTime
    thursdayCloseTime
    thursdayOpenTime2
    thursdayCloseTime2

    fridayOpenTime
    fridayCloseTime
    fridayOpenTime2
    fridayCloseTime2

    saturdayOpenTime
    saturdayCloseTime
    saturdayOpenTime2
    saturdayCloseTime2

    sundayOpenTime
    sundayCloseTime
    sundayOpenTime2
    sundayCloseTime2

    holidayOpenTime
    holidayCloseTime
    holidayOpenTime2
    holidayCloseTime2

    beforeHolidayOpenTime
    beforeHolidayCloseTime
    beforeHolidayOpenTime2
    beforeHolidayCloseTime2

    regularHolidays
    tempHolidays

    zone {
      _id
      title
    }
  }
}`

export const deleteRestaurant = `mutation DeltetRestaurant($id:String!) {
  deleteRestaurant(id:$id) {
    _id
    isActive
    isDeleted
  }
}`

export const deactivateRestaurant = `mutation DeactivateRestaurant($id:String!) {
  deactivateRestaurant(id:$id) {
    _id
    isActive
  }
}`

export const toggleFeatured = ` mutation ToggleFeatured($restaurantId:String!) {
  toggleFeatured(restaurantId:$restaurantId) {
    _id
    featured
  }
}`

export const createStoreCategory = `mutation CreateStoreCategory($title: String!) {
  createStoreCategory(title:$title) {
    _id
    title
  }
}`

export const editStoreCategory = `mutation EditStoreCategory($id: String!, $title: String!) {
  editStoreCategory(id: $id, title:$title) {
    _id
    title
  }
}`

export const deleteStoreCategory = `mutation DeleteStoreCategory($id: String!) {
  deleteStoreCategory(id:$id)
}`

export const resetPassword = `mutation ResetPassword($password:String!,$token:String!) {
  resetPassword(password:$password,token:$token) {
    result
  }
}`

export const createRider = `mutation CreateRider($riderInput:RiderInput!) {
  createRider(riderInput:$riderInput) {
    _id
    name
    username
    password
    phone
    displayPhone
    email
    available
    zone {
      _id
    }
  }
}`

export const editRider = `mutation EditRider($riderInput:RiderInput!) {
  editRider(riderInput:$riderInput) {
    _id
    name
    username
    phone
    displayPhone
    email
    zone {
      _id
    }
  }
}`
export const deleteRider = `mutation DeleteRider($id:String!) {
  deleteRider(id:$id) {
    _id
  }
}`

export const assignRider = `mutation AssignRider($id:String!,$riderId:String!) {
  assignRider(id:$id,riderId:$riderId) {
    _id
    rider {
      _id
      name
    }
  }
}`

export const createCategory = `mutation CreateCategory($category:CategoryInput) {
  createCategory(category:$category) {
    _id
    categories {
      _id
      title
      foods {
        _id
        title
        description
        variations {
          _id
          title
          price
          taxInPrice
          storeProfit
          discounted
          janCode
          addons
        }
        image
        isActive
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
}`

export const editCategory = `mutation ($category:CategoryInput) {
  editCategory(category:$category) {
    _id
    categories {
      _id
      title
      foods{
        _id
        title
        description
        variations {
          _id
          title
          price
          taxInPrice
          storeProfit
          discounted
          janCode
          addons
        }
        image
        isActive
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
}`

export const updateCategoryOrders = `mutation ($restaurantId: String!, $categoryIds: [String!]!) {
  updateCategoryOrders(restaurantId: $restaurantId, categoryIds: $categoryIds) {
    _id
    categories {
      _id
      title
      foods{
        _id
        title
        description
        variations {
          _id
          title
          price
          taxInPrice
          storeProfit
          discounted
          janCode
          addons
        }
        image
        isActive
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
}`

export const deleteCategory = `mutation DeleteCategory($id:String!,$restaurant:String!) {
  deleteCategory(id:$id,restaurant:$restaurant) {
    _id
    categories {
      _id
      title
      foods {
        _id
        title
        description
        variations {
          _id
          title
          price
          taxInPrice
          storeProfit
          discounted
          janCode
          addons
        }
        image
        isActive
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
}`

export const createMastersFromCSV = `mutation($ownerId: String!, $isReplaceAll: Boolean!, $masterInputs: [MastersFromCSVInput]!) {
  createMastersFromCSV(ownerId: $ownerId, isReplaceAll: $isReplaceAll, masterInputs: $masterInputs) {
    _id
    title
    foods {
      _id
      title
      description
      image
      isActive
      variations {
        _id
        title
        storeProfit
        janCode
      }
    }
  }
}`

export const createCategoryMaster = `mutation ($category:CategoryMasterInput) {
  createCategoryMaster(category:$category) {
    _id
    title
    foods {
      _id
      title
      description
      image
      isActive
      variations {
        _id
        title
        storeProfit
        janCode
      }
    }
  }
}`

export const editCategoryMaster = `mutation ($category:CategoryMasterInput) {
  editCategoryMaster(category:$category) {
    _id
    title
    foods {
      _id
      title
      description
      image
      isActive
      variations {
        _id
        title
        storeProfit
        janCode
      }
    }
  }
}`

export const updateCategoryMasterOrders = `mutation ($ownerId: String!, $categoryIds: [String!]!) {
  updateCategoryMasterOrders(ownerId: $ownerId, categoryIds: $categoryIds) {
    _id
    title
    foods {
      _id
      title
      description
      image
      isActive
      variations {
        _id
        title
        storeProfit
        janCode
      }
    }
  }
}`

export const deleteCategoryMaster = `mutation ($ownerId:String!, $categoryId:String!) {
  deleteCategoryMaster(ownerId: $ownerId, categoryId: $categoryId) {
    _id
    title
    foods {
      _id
      title
      description
      image
      isActive
      variations {
        _id
        title
        storeProfit
        janCode
      }
    }
  }
}`


export const createFood = `mutation CreateFood($foodInput:FoodInput!) {
  createFood(foodInput:$foodInput) {
    _id
    categories {
      _id
      title
      createdAt
      updatedAt
      foods{
        _id
        title
        description
        image
        isActive
        isAvailable
        variations {
          _id
          title
          price
          taxInPrice
          storeProfit
          discounted
          janCode
          addons
        }
      }
    }
  }
}`

export const editFood = `mutation EditFood($foodInput:FoodInput!, $oldCategoryId: String!) {
  editFood(
    foodInput:$foodInput,
    oldCategoryId:$oldCategoryId
  ){
    _id
    categories {
      _id
      title
      createdAt
      updatedAt
      foods{
        _id
        title
        description
        image
        isActive
        isAvailable
        variations {
          _id
          title
          price
          taxInPrice
          storeProfit
          discounted
          janCode
          addons
        }
      }
    }
  }
}`

export const toggleFoodAvailability = `mutation ($foodId: String!, $restaurantId: String!, $categoryId: String!) {
  toggleFoodAvailability(foodId: $foodId, restaurantId: $restaurantId, categoryId: $categoryId)
}`

export const updateFoodOrders = `mutation ($restaurantId: String!, $foodIds: [String!]!) {
  updateFoodOrders( restaurantId: $restaurantId, foodIds: $foodIds){
    _id
    categories {
      _id
      title
      foods {
        _id
        title
        description
        variations {
          _id
          title
          price
          taxInPrice
          storeProfit
          discounted
          janCode
          addons
        }
        image
        isActive
      }
      createdAt
      updatedAt
    }
  }
}`

export const deleteFood = `mutation ($id:String!,$restaurant:String!,$categoryId:String!) {
  deleteFood(id:$id,restaurant:$restaurant,categoryId:$categoryId) {
    _id
    categories {
      _id
      title
      foods{
        _id
        title
        description
        variations {
          _id
          title
          price
          taxInPrice
          storeProfit
          discounted
          janCode
          addons
        }
        image
        isActive
      }
      createdAt
      updatedAt
    }
  }
}`

export const createFoodMaster = `mutation ($foodInput:FoodMasterInput!) {
  createFoodMaster(foodInput:$foodInput) {
    _id
    title
    foods {
      _id
      title
      description
      image
      isActive
      variations {
        _id
        title
        storeProfit
        janCode
      }
    }
  }
}`

export const editFoodMaster = `mutation ($foodInput:FoodMasterInput!, $oldCategoryId: String!) {
  editFoodMaster(foodInput:$foodInput, oldCategoryId:$oldCategoryId) {
    _id
    title
    foods {
      _id
      title
      description
      image
      isActive
      variations {
        _id
        title
        storeProfit
        janCode
      }
    }
  }
}`

export const updateFoodMasterOrders = `mutation ($ownerId: String!, $foodIds: [String!]!) {
  updateFoodMasterOrders(ownerId: $ownerId, foodIds: $foodIds){
    _id
    title
    foods {
      _id
      title
      description
      image
      isActive
      variations {
        _id
        title
        storeProfit
        janCode
      }
    }
  }
}`

export const deleteFoodMaster = `mutation ($ownerId: String!, $categoryId: String!, $foodId: String!) {
  deleteFoodMaster(ownerId: $ownerId, categoryId: $categoryId, foodId: $foodId) {
    _id
    title
    foods {
      _id
      title
      description
      image
      isActive
      variations {
        _id
        title
        storeProfit
        janCode
      }
    }
  }
}`



export const createAddons = `mutation CreateAddons($addonInput:AddonInput) {
  createAddons(addonInput:$addonInput) {
    _id
    addons {
      _id
      options
      title
      description
      quantityMinimum
      quantityMaximum
      memo
    }
  }
}`
export const editAddon = `mutation editAddon($addonInput:editAddonInput) {
  editAddon(addonInput:$addonInput) {
    _id
    addons {
      _id
      options
      title
      description
      quantityMinimum
      quantityMaximum
      memo
    }
  }
}`

export const updateAddonOrders = `mutation ($restaurantId: String!, $addonIds: [String!]!) {
  updateAddonOrders(restaurantId: $restaurantId, addonIds: $addonIds) {
    _id
    addons {
      _id
      options
      title
      description
      quantityMinimum
      quantityMaximum
      memo
    }
  }
}`

export const deleteAddon = `mutation DeleteAddon($id:String!,$restaurant:String!) {
  deleteAddon(id:$id,restaurant:$restaurant) {
    _id
    addons {
      _id
      options
      title
      description
      quantityMinimum
      quantityMaximum
    }
  }
}`

export const createZone = `mutation CreateZone($zone:ZoneInput!) {
  createZone(zone:$zone) {
    _id
    title
    description
    location {
      coordinates
    }
    isActive
  }
}`

export const editZone = `mutation EditZone($zone:ZoneInput!) {
  editZone(zone:$zone) {
    _id
    title
    description
    location {
      coordinates
    }
    isActive
  }
}`

export const deleteZone = `mutation DeleteZone($id:String!) {
  deleteZone(id:$id) {
    _id
    title
    description
    location {
      coordinates
    }
    isActive
  }
}`

export const saveEmailConfiguration = `mutation SaveEmailConfiguration($configurationInput:EmailConfigurationInput!) {
  saveEmailConfiguration(configurationInput:$configurationInput) {
    _id
    email
    password
    enableEmail
  }
}`
export const saveSpecialFeatureTitleConfiguration = `mutation ($title: String!) {
  saveSpecialFeatureTitleConfiguration(title: $title) {
    _id
    specialFeatureTitle
  }
}`
export const saveStoreClosedConfiguration = `mutation ($storeClosed: Boolean!) {
  saveStoreClosedConfiguration(storeClosed: $storeClosed) {
    _id
    storeClosed
  }
}`



// オーダー詳細画面から利用
export const updateOrderStatus = `mutation UpdateOrderStatus($id:String!,$status:String!,$reason:String) {
  updateOrderStatus(id:$id,status:$status,reason:$reason) {
    _id
    orderStatus
  }
}
`

export const saveCurrencyConfiguration = `mutation SaveCurrencyConfiguration($configurationInput:CurrencyConfigurationInput!) {
  saveCurrencyConfiguration(configurationInput:$configurationInput) {
    _id
    currency
    currencySymbol
  }
}`

export const sendTestMail = `mutation SendTestMail($email:String!,$title:String!,$text:String!) {
  sendTestMail(email:$email,title:$title,text:$text)
}`

export const sendMail = `mutation SendMail($title:String!,$text:String!) {
  sendMail(title:$title,text:$text)
}`

export const uploadToken = `mutation UploadToken($id:String!,$pushToken:String!) {
  uploadToken(id:$id,pushToken:$pushToken) {
    _id
    pushToken
  }
}`

export const toggleAvailablity = `mutation ToggleRider($id:String) {
  toggleAvailablity(id:$id) {
    _id
  }
}`

export const updatePaymentStatus = `mutation UpdatePaymentStatus($id:String!,$status:String!) {
  updatePaymentStatus(id:$id,status:$status) {
    _id
    paymentStatus
    paidAmount
  }
}
`

export const createOffer = `mutation CreateOffer($offer:OfferInput!) {
  createOffer(offer:$offer) {
    _id
    name
    tag
    restaurants {
      _id
      name
      address
    }
  }
}`

export const editOffer = `mutation EditOffer($offer:OfferInput!) {
  editOffer(offer:$offer) {
    _id
    name
    tag
    restaurants {
      _id
      name
      address
    }
  }
}`

export const deleteOffer = `mutation DeleteOffer($id:String!) {
  deleteOffer(id:$id)
}`

export const createCoupon = `mutation CreateCoupon($couponInput:CouponInput!, $restaurantId: String!) {
  createCoupon(couponInput:$couponInput, restaurantId:$restaurantId) {
    _id
    coupons {
      _id
      title
      discount
      enabled
    }
  }
}`

export const editCoupon = `mutation editCoupon($couponInput:CouponInput!, $restaurantId: String!) {
  editCoupon(couponInput:$couponInput, restaurantId:$restaurantId) {
    _id
    coupons {
      _id
      title
      discount
      enabled
    }
  }
}`

export const deleteCoupon = `mutation DeleteCoupon($id:String!, $restaurantId: String!) {
  deleteCoupon(id:$id, restaurantId:$restaurantId) {
    _id
    coupons {
      _id
      title
      discount
      enabled
    }
  }
}`

export const savePaypalConfiguration = `mutation SavePaypalConfiguration($configurationInput:PaypalConfigurationInput!) {
  savePaypalConfiguration(configurationInput:$configurationInput) {
    _id
    clientId
    clientSecret
    sandbox
  }
}`

export const saveStripeConfiguration = `mutation SaveStripeConfiguration($configurationInput:StripeConfigurationInput!) {
  saveStripeConfiguration(configurationInput:$configurationInput) {
    _id
    publishableKey
    secretKey
  }
}`

export const createInfoCarousel = `mutation CreateInfoCarousel($title: String!, $titleColor: String!, $description: String!, $descriptionColor: String!, $link: String!, $image: Upload, $isAvailable: Boolean!) {
  createInfoCarousel(title:$title, titleColor:$titleColor, description:$description, descriptionColor:$descriptionColor, link:$link, image:$image, isAvailable:$isAvailable)
}`

export const editInfoCarousel = `mutation EditInfoCarousel($id: String!, $title: String!, $titleColor: String!, $description: String!, $descriptionColor: String!, $link: String!, $imageUrl: String, $image: Upload, $isAvailable: Boolean!) {
  editInfoCarousel(id: $id, title: $title, titleColor: $titleColor, description: $description, descriptionColor: $descriptionColor, link: $link, imageUrl: $imageUrl, image: $image, isAvailable: $isAvailable)
}`

export const toggleInfoCarouselAvailability = `mutation ($id: String!) {
  toggleInfoCarouselAvailability(id: $id)
}`

export const updateInfoCarouselOrders = `mutation ($ids: [String!]!) {
  updateInfoCarouselOrders(ids: $ids)
}`

export const deleteInfoCarousel = `mutation DeleteInfoCarousel($id:String!) {
  deleteInfoCarousel(id:$id)
}`

/**
 *
 * query
 *
 */
export const getStoreDashboardData = `query ($startingDate: Float, $endingDate: Float, $restaurant:String!) {
  getStoreDashboardData(starting_date: $startingDate, ending_date: $endingDate,restaurant:$restaurant) {
    _id
    favoriteCount
    totalOrders
    totalSales
    orderData {
      date
      sales
      count
    }
  }
}`

export const getVendors = `query Vendors {
  vendors {
    _id
    email
    userType
    restaurants {
      _id
      orderId
      orderPrefix
      name
      image
      address
      location {
        coordinates
      }
      commission
      deliveryCharges
      chargeLimit
      zone {
        _id
        title
      }
      storeCategory {
        _id
        title
      }
      storeSubCategory {
        _id
        title
      }
    }
  }
}`

export const getVendor = `query GetVendor($id:String!) {
  getVendor(id:$id) {
    _id
    email
    userType
    restaurants {
      _id
      orderId
      orderPrefix
      name
      image
      address
      location {
        coordinates
      }
      deliveryCharges
      chargeLimit
      commission
    }
  }
}`

export const restaurantByOwner = `query RestaurantByOwner($id:String) {
  restaurantByOwner(id:$id) {
    _id
    email
    userType
    restaurants {
      _id
      orderId
      name
      phone
      image
      address
      chargeLimit
      deliveryTime
      minimumOrder
      commission
      zone {
        _id
        title
      }
    }
  }
}`

export const restaurantList = `query RestaurantList {
  restaurantList {
    _id
    name
    address
  }
}`


export const getRestaurant = `query Restaurant($id:String) {
  restaurant(id:$id) {
    _id
    orderId
    orderPrefix
    name
    phone
    image
    address
    location {
      coordinates
    }
    deliveryCharges
    chargeLimit
    username
    password
    deliveryTime
    minimumOrder
    promotionText
    link
    videoId

    openTime
    closeTime
    openTime2
    closeTime2

    mondayOpenTime
    mondayCloseTime
    mondayOpenTime2
    mondayCloseTime2

    tuesdayOpenTime
    tuesdayCloseTime
    tuesdayOpenTime2
    tuesdayCloseTime2

    wednesdayOpenTime
    wednesdayCloseTime
    wednesdayOpenTime2
    wednesdayCloseTime2

    thursdayOpenTime
    thursdayCloseTime
    thursdayOpenTime2
    thursdayCloseTime2

    fridayOpenTime
    fridayCloseTime
    fridayOpenTime2
    fridayCloseTime2

    saturdayOpenTime
    saturdayCloseTime
    saturdayOpenTime2
    saturdayCloseTime2

    sundayOpenTime
    sundayCloseTime
    sundayOpenTime2
    sundayCloseTime2

    holidayOpenTime
    holidayCloseTime
    holidayOpenTime2
    holidayCloseTime2

    beforeHolidayOpenTime
    beforeHolidayCloseTime
    beforeHolidayOpenTime2
    beforeHolidayCloseTime2

    regularHolidays
    tempHolidays

    commission
    zone {
      _id
      title
    }
  }
}`

export const orderCount = `
query OrderCount($restaurant:String!) {
  orderCount(restaurant:$restaurant)
}`

export const getActiveOrders = `query GetActiveOrders {
  getActiveOrders {
    _id
    orderId
    paidAmount
    orderAmount
    taxInSubtotal
    customerCharge
    chargeLimit
    storeProfit
    storeCommission
    storeCommissionRate
    orderStatus
    status
    paymentStatus
    reason
    isActive
    deliveryCharges
    chargeLimit
    noChargeLimit
    memo
    updatedAt
    createdAt
    zone {
      _id
    }
    deliveryAddress {
      location {
        coordinates
      }
      deliveryAddress
      details
      label
      phone
    }
    restaurant {
      _id
      name
      chargeLimit
      commission
    }
    user {
      _id
      name
      phone
      email
    }
    rider {
      _id
      name
    }
    items {
      _id
      title
      quantity
      variation {
        _id
        title
        price
        taxInPrice
        storeProfit
        discounted
        janCode
      }
      addons {
        _id
        options {
          _id
          title
          price
          taxInPrice
          storeProfit
        }
        title
      }
      createdAt
    }
  }
}`

export const getUsers = `query {
  users {
    _id
    name
    email
    phone
    addresses {
      location {
        coordinates
      }
      deliveryAddress
    }
  }
}`

export const getRiders = `query {
  riders {
    _id
    name
    username
    password
    phone
    displayPhone
    email
    available
    zone {
      _id
      title
    }
  }
}`

export const getAvailableRiders = `query {
  availableRiders {
    _id
    name
    username
    phone
    email
    available
    zone {
      _id
    }
  }
}`

export const getRidersByZone = `query RidersByZone($id:String!) {
  ridersByZone(id:$id) {
    _id
    name
    username
    password
    phone
    available
    zone {
      _id
      title
    }
  }
}`

export const getStoreCategories = `query GetStoreCategories {
  storeCategories {
    _id
    title
  }
}`

export const getStoreCategory = `query GetStoreCategory($id: String!) {
  storeCategory(id:$id) {
    _id
    title
  }
}`

export const getAddons = `query Addons {
  addons {
    _id
    title
    description
    options {
      _id
      title
      description
      price
      taxInPrice
      storeProfit
    }
    quantityMinimum
    quantityMaximum
  }
}`

export const getOptions = `query Options {
  options {
    _id
    title
    description
    price
    taxInPrice
    storeProfit
  }
}`

export const getZones = `query Zones {
  zones {
    _id
    title
    description
    location {
      coordinates
    }
    isActive
  }
}`

export const getConfiguration = `query GetConfiguration {
  configuration {
    _id
    email
    password
    enableEmail
    clientId
    clientSecret
    sandbox
    publishableKey
    secretKey
    currency
    currencySymbol
    specialFeatureTitle
    storeClosed
  }
}`

export const reviews = `query Reviews($restaurant:String!) {
  reviews(restaurant:$restaurant) {
    _id
    order {
      _id
      orderId
      items{
        title
      }
      user {
        _id
        name
        email
      }
    }
    restaurant {
      _id
      name
      image
    }
    rating
    description
    createdAt
  }
}`

export const getCoupons = `query Coupons ($restaurantId: String!) {
  restaurant(id: $restaurantId) {
    coupons {
      _id
      title
      discount
      enabled
    }
  }
}`

export const getOffers = `query Offers {
  offers {
    _id
    name
    tag
    restaurants {
      _id
      name
    }
  }
}`

export const pageCount = `query PageCount($restaurant:String!) {
  pageCount(restaurant:$restaurant)
}
`

export const getPaymentStatuses = `query {
  getPaymentStatuses
}`

export const getInfoCarousels = `query {
  infoCarousels {
    _id
    title
    titleColor
    description
    descriptionColor
    link
    image
    isAvailable
  }
}`

export const getInfoCarousel = `query ($id: String!) {
  infoCarousel(id:$id) {
    _id
    title
    titleColor
    description
    descriptionColor
    link
    image
    isAvailable
  }
}`

export const isCommingSoonRestaurantWEB = `mutation ($id: String!) {
  isCommingSoonRestaurantWEB(id: $id)
}`
export const isNotCommingSoonRestaurantWEB = `mutation ($id: String!) {
  isNotCommingSoonRestaurantWEB(id: $id)
}`

/**
 *
 * subscription
 *
 */
export const subscribePlaceOrder = `subscription SubscribePaceOrder($restaurant:String!) {
  subscribePlaceOrder(restaurant:$restaurant) {
    userId
    origin
    order {
      _id
      orderId
      paymentMethod
      paidAmount
      orderAmount
      orderStatus
      status
      paymentStatus
      reason
      isActive
      storeCommissionRate
      deliveryCharges
      chargeLimit
      noChargeLimit
      createdAt
      restaurant {
        _id
        name
        image
        address
        chargeLimit
        location {
          coordinates
        }
      }
      deliveryAddress {
        location {
          coordinates
        }
        deliveryAddress
        details
        label
        phone
      }
      items {
        _id
        title
        description
        image
        quantity
        variation {
          _id
          title
          price
          taxInPrice
          storeProfit
          discounted
          janCode
        }
        addons {
          _id
          options {
            _id
            title
            description
            price
            taxInPrice
            storeProfit
          }
          description
          title
          quantityMinimum
          quantityMaximum
        }
        isActive
        createdAt
        updatedAt
      }
      user {
        _id
        name
        phone
        email
      }
      rider {
        _id
        name
        username
        available
      }
    }
  }
}`

export const subscriptionOrder = `subscription SubscriptionOrder($id:String!) {
  subscriptionOrder(id:$id) {
    _id
    orderStatus
    rider {
        _id
    }
  }
}`
