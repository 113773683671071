import { useQuery } from "@apollo/client"
import gql from "graphql-tag"

const newUsersCountQuery = gql`query ($start: Float, $end: Float) {
  newUsersCount(start: $start, end: $end)
}`


type Variables = {
  start: number
  end: number
}
type Response = {
  newUsersCount?: number
}

export function useNewUsersCountQuery(start: number, end: number) {
  return useQuery<Response, Variables>(newUsersCountQuery, { variables: { start, end } })
}