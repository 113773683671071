import { useQuery } from "@apollo/client"
import gql from "graphql-tag"

const queryResponse = `
  _id
  title
  count
  point
`
const zoneStatisticsQuery = gql`query ($start: Float!, $end: Float!, $mode: String!) {
  zoneStatistics(start:$start, end:$end, mode:$mode) { ${queryResponse} }
}`
const zoneStatisticsForStoreQuery = gql`query ($start: Float!, $end: Float!, $mode: String!, $ownerId: String, $restaurantId: String) {
  zoneStatisticsForStore(start: $start, end: $end, mode: $mode, ownerId: $ownerId restaurantId: $restaurantId) { ${queryResponse} }
}`

type Variables = {
  start: number
  end: number
  mode: "store" | "order"
}
type StoreVariables = {
  start: number
  end: number
  mode: "vendor" | "store" 
  ownerId: string
  restaurantId: string
}
export type ZoneStatisticsQueryResponse = {
  _id: string
  title: string
  count: number
  point: number[]
}

type AdminResponse = {
  zoneStatistics?: ZoneStatisticsQueryResponse[]
}
type StoreResponse = {
  zoneStatisticsForStore?: ZoneStatisticsQueryResponse[]
}

export function useZoneStatistics(args: { start: number, end: number, mode: "store" | "order"}, active: boolean) {
  return useQuery<AdminResponse, Variables>(zoneStatisticsQuery, {
    variables: {
      start: args.start,
      end: args.end,
      mode: args.mode,
    },
    skip: !active,
  })
}
export function useZoneStatisticsForStore(args: { start: number, end: number, mode: "vendor" | "store", ownerId: string, restaurantId: string }, active: boolean) {
  return useQuery<StoreResponse, StoreVariables>(zoneStatisticsForStoreQuery, {
    variables: {
      start: args.start,
      end: args.end,
      mode: args.mode,
      ownerId: args.ownerId,
      restaurantId: args.restaurantId,
    },
    skip: !active
  })
}