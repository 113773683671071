import React, { useState, useRef } from 'react'
import { validateFunc } from '../../constraints/constraints'
import { withTranslation } from 'react-i18next'

// reactstrap components
import {
  Alert,
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Row,
  Col
} from 'reactstrap'
import { GoogleMap, Polygon } from '@react-google-maps/api'

// core components
import { createZone, editZone, getZones } from '../../apollo/server'
import { useMutation, gql, } from "@apollo/client"

const CREATE_ZONE = gql`
  ${createZone}
`
const EDIT_ZONE = gql`
  ${editZone}
`
const GET_ZONE = gql`
  ${getZones}
`

const Zone = props => {
  const { t } = props
  const [mutation] = useState(props.zone ? EDIT_ZONE : CREATE_ZONE)

  const [title, setTitle] = useState(props.zone && props.zone.title ? props.zone.title : "")
  const [description, setDescription] = useState(props.zone && props.zone.description ? props.zone.description : "")
  const [polygon, setPolygon] = useState(props.zone && props.zone.location && props.zone.location.coordinates && props.zone.location.coordinates[0] ? transformPolygon(props.zone.location.coordinates[0]) : [])

  const [errors, setErrors] = useState('')
  const [succes, setSuccess] = useState('')
  const [titleError, setTitleError] = useState(null)
  const [descriptionError, setDescriptionError] = useState(null)
  const [center] = useState(
    props.zone && props.zone.location && props.zone.location.coordinates && props.zone.location.coordinates[0]
      ? setCenter(props.zone.location.coordinates[0])
      : { lat: 41.7687, lng: 140.7291 }
  )
  const polygonRef = useRef()

  const onClick = e => {
    setPolygon([...polygon, { lat: e.latLng.lat(), lng: e.latLng.lng() }])
    console.log(e.latLng.lat(), e.latLng.lng())
  }

  const [beforePath, setBeforePath] = useState(undefined)
  const onMouseDown = e => {
    setBeforePath({ lat: e.latLng.lat(), lng: e.latLng.lng() })
  }

  const onMouseUp = e => {
    const afterPath = { lat: e.latLng.lat(), lng: e.latLng.lng() }

    if (beforePath.lat === afterPath.lat && beforePath.lng === afterPath.lng) {
      return
    }

    setPolygon(
      polygon.map(p => {
        if (p.lat === beforePath.lat && p.lng === beforePath.lng) {
          return afterPath
        }
        return p
      })
    )

    console.log(e.latLng.lat(), e.latLng.lng())
  }

  function setCenter(coordinates) {
    return { lat: coordinates[0][1], lng: coordinates[0][0] }
  }

  // const onBlur = field => {
  // this.setState({ [field + 'Error']: !validateFunc({ [field]: this.state[field] }, field) })
  // }

  function transformPolygon(coordinate) {
    return coordinate.slice(0, coordinate.length - 1).map(item => {
      return { lat: item[1], lng: item[0] }
    })
  }

  const onSave = () => {
    if (polygon === 0) return
    let polygonBounds
    // const polygonBounds = polygon
    if (polygonRef.current) {
      Object.keys(polygonRef.current.state.polygon.getPaths()).forEach(p => {
        const result = polygonRef.current.state.polygon.getPaths()[p]
        if (result instanceof Array) {
          const inner = result[0]
          if (inner === undefined) return
          Object.keys(inner).forEach(q => {
            if (inner[q] instanceof Array) {
              polygonBounds = inner[q]
            }
          })
        }
      })
    }
    var bounds = []
    for (var i = 0; i < polygonBounds.length; i++) {
      var point = [polygonBounds[i].lng(), polygonBounds[i].lat()]
      bounds.push(point)
    }
    bounds.push(bounds[0])
    console.log('polygon array', bounds)
    return [bounds]
  }

  const onSubmitValidaiton = () => {
    const titleErrors = !validateFunc({ title: title.trim() }, 'title')
    const descriptionErrors = !validateFunc({ description: description.trim() }, 'description')
    let zoneErrors = true
    if (polygon.length === 0) {
      zoneErrors = false
      setErrors(t('Set Zone on Map'))
      return false
    }
    console.log('paths length', polygon.length)

    if (polygon.length < 3) {
      zoneErrors = false
      setErrors(t('Please set 3 point to make zone'))
    }

    setTitleError(titleErrors)
    setDescriptionError(descriptionErrors)
    return titleErrors && descriptionErrors && zoneErrors
  }
  const clearFields = () => {
    setTitle('')
    setDescription('')
    setTitleError(null)
    setDescriptionError(null)
    setPolygon([])
  }
  const hideAlert = () => {
    setErrors('')
    setSuccess('')
  }

  const SubmitButton = () => {
    const onCompleted = data => {
      if (!props.zone) clearFields()
      const message = props.zone
        ? t('UpdateSuccessMessage')
        : t('AddSuccessMessage')
      setErrors('')
      setSuccess(message)
      setTimeout(hideAlert, 5000)
    }

    const onError = e => {
      setErrors(e)
      setSuccess('')
      setTimeout(hideAlert, 5000)
    }

    const [mutate, { loading }] = useMutation(mutation, {
      onCompleted,
      onError,
      refetchQueries: [{ query: GET_ZONE }]
    })

    return <Button
      disabled={loading}
      color="primary"
      href="#pablo"
      onClick={async e => {
        e.preventDefault()
        if (onSubmitValidaiton()) {
          const coordinates = onSave()
          const t = transformPolygon(coordinates[0])
          setPolygon(t)
          // console.log(coordinates)
          mutate({
            variables: {
              zone: {
                _id: props.zone ? props.zone._id : '',
                title: title.trim(),
                description: description.trim(),
                coordinates
              }
            }
          })
        }
      }}
      size="md">
      {props.zone ? 'Update' : t('Save')}
    </Button>
  }

  return (
    <Row>
      <Col className="order-xl-1">
        <Card className="bg-secondary shadow">
          <CardHeader className="bg-white border-0">
            <Row className="align-items-center">
              <Col xs="8">
                <h3 className="mb-0">
                  {props.zone ? t('Edit Zone') : t('Add Zone')}
                </h3>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Form>
              <div className="pl-lg-4">
                <Row>
                  <Col lg="6">
                    <label
                      className="form-control-label"
                      htmlFor="input-title">
                      {t('Title')}
                    </label>
                    <br />
                    <small>{t('Character limit of max length 30')}</small>
                    <FormGroup
                      className={
                        titleError === null
                          ? ''
                          : titleError
                            ? 'has-success'
                            : 'has-danger'
                      }>
                      <Input
                        autoCapitalize="none"
                        className="form-control-alternative"
                        id="input-title"
                        placeholder={t('ZonePH')}
                        maxLength="30"
                        type="title"
                        value={title}
                        onChange={event => {
                          setTitle(event.target.value)
                        }}
                        // onBlur={event => {
                        //   onBlur('title')
                        // }}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <label
                      className="form-control-label"
                      htmlFor="input-description">
                      {t('Description')}
                    </label>
                    <br />
                    <small>{t('Character limit of max length 20')}</small>
                    <FormGroup
                      className={
                        descriptionError === null
                          ? ''
                          : descriptionError
                            ? 'has-success'
                            : 'has-danger'
                      }>
                      <Input
                        autoCapitalize="none"
                        className="form-control-alternative"
                        id="input-description"
                        placeholder={t('ZoneDescriptionPH')}
                        maxLength="20"
                        type="text"
                        value={description}
                        onChange={event => {
                          setDescription(event.target.value)
                        }}
                        // onBlur={event => {
                        //   onBlur('description')
                        // }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col lg="6">
                    <h3 className="mb-0">{t('Coordinates')}</h3>
                    <br />
                  </Col>
                </Row>

                <Row>
                  <GoogleMap
                    mapContainerStyle={{
                      height: '500px',
                      width: '100%'
                    }}
                    id="example-map"
                    zoom={14}
                    center={center}
                    onClick={onClick}>
                    <Polygon
                      ref={polygonRef}
                      // draggable
                      editable
                      paths={polygon}
                      onMouseDown={onMouseDown}
                      onMouseUp={onMouseUp}
                    />
                  </GoogleMap>
                </Row>

                <Row className="pt-5">
                  <Col className="text-right" lg="6">
                    <SubmitButton />
                  </Col>
                </Row>

                <Row>
                  <Col lg="6">
                    <Alert
                      color="success"
                      isOpen={!!succes}
                      toggle={hideAlert}>
                      <span className="alert-inner--icon">
                        <i className="ni ni-like-2" />
                      </span>{' '}
                      <span className="alert-inner--text">
                        <strong>{t('Success')}!</strong> {succes}
                      </span>
                    </Alert>
                    <Alert
                      color="danger"
                      isOpen={!!errors}
                      toggle={hideAlert}>
                      <span className="alert-inner--icon">
                        <i className="ni ni-like-2" />
                      </span>{' '}
                      <span className="alert-inner--text">
                        <strong>{t('Danger')}!</strong> {errors}
                      </span>
                    </Alert>
                  </Col>
                </Row>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default withTranslation()(Zone)
