import React, { useRef, useEffect } from 'react'
import { Route, Switch } from 'react-router-dom'
// reactstrap components
import { Container } from 'reactstrap'
// core components
import AdminNavbar from '../components/Navbars/AdminNavbar'
import AdminFooter from '../components/Footers/AdminFooter'
import Sidebar from '../components/Sidebar/Sidebar'

import routes from "../routes"
import CategoryMasterComponent from '../views/CategoryMaster'
import FoodMasterComponent from '../views/FoodMaster'
import Vendors from '../views/Vendors'
import StoreSalesBoard from '../views/StoreSalesBoard'
import ZoneStatistics from '../views/ZoneStatistics'
const sidebarRoutes = [
  {
    path: '/list',
    name: 'Restaurants',
    icon: 'fas fa-star text-yellow',
    component: Restaurant,
    layout: '/vendor_admin',
    appearInSidebar: true,
    admin: false
  },
  {
    path: '/categoryMaster',
    name: 'CategoryMaster',
    icon: 'ni ni-chart-pie-35 text-red',
    component: CategoryMasterComponent,
    layout: '/vendor_admin',
    appearInSidebar: true,
    admin: false
  },
  {
    path: '/foodMaster',
    name: 'FoodMaster',
    icon: 'ni ni-tie-bow text-green',
    component: FoodMasterComponent,
    layout: '/vendor_admin',
    appearInSidebar: true,
    admin: false
  },
  {
    path: '/storeSalesBoard',
    name: 'SalesBoard',
    icon: 'ni ni-tv-2 text-primary',
    component: StoreSalesBoard,
    layout: '/vendor_admin',
    appearInSidebar: true,
    admin: false
  },
  {
    path: '/zoneStatistics',
    name: 'ZoneStatistics',
    icon: 'ni ni-square-pin text-red',
    component: ZoneStatistics,
    layout: '/vendor_admin',
    appearInSidebar: true,
    admin: false
  },
  {
    path: '/vendors',
    name: 'Back to Admin',
    icon: 'ni ni-curved-next text-black',
    component: Vendors,
    layout: '/super_admin',
    appearInSidebar: true,
    admin: false
  },
]

function Restaurant(props) {
  var divRef = useRef(null)
  useEffect(() => {
    document.documentElement.scrollTop = 0
    document.scrollingElement.scrollTop = 0
    divRef.current.scrollTop = 0
  }, [])

  const getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.layout === '/vendor_admin') {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        )
      } else {
        return null
      }
    })
  }
  const getBrandText = path => {
    for (let i = 0; i < routes.length; i++) {
      if (props.location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
        return routes[i].name
      }
    }
    return 'Brand'
  }
  return (
    <>
      <Sidebar
        {...props}
        routes={sidebarRoutes}
        logo={{
          innerLink: '/vendor_admin/list',
          imgSrc: require('../assets/img/brand/logo.png'),
          imgAlt: '...'
        }}
      />
      <div className="main-content" ref={divRef}>
        <AdminNavbar {...props} brandText={getBrandText(props.location.pathname)} />
        <Switch>{getRoutes(routes)}</Switch>
        <Container fluid>
          <AdminFooter />
        </Container>
      </div>
    </>
  )
}

export default Restaurant
