import React, { useContext, useEffect, useState } from "react"
import { Badge, Container, Row, Card, Modal } from "reactstrap"
import Header from "../../components/Headers/Header"
import OptionModal from "./OptionModal"
import CustomLoader from "../../components/Loader/CustomLoader"
import { useTranslation } from "react-i18next"
import Loader from "react-loader-spinner"
import { getIsDisabled } from "../../utils/getIsDisabled"
import { SortableTable } from "../../components/SortableTable"
import { arrayMove } from "../../utils/arrayMove"
import styles from "../styles"
import { getRestaurantQuery, RestaurantQueryResponseOption, useRestaurantQuery } from "../../domain/graphql/query/useRestaurantQuery"
import { useDeleteOption } from "../../domain/graphql/mutation/useDeleteOptionMutation"
import { useUpdateOptionOrders } from "../../domain/graphql/mutation/useUpdateOptionOrdersMutation"
import { PHONE_BOUNDARY } from "../constants"
import { AppContext } from "../../app"

const Options = () => {
  const { t } = useTranslation()
  const appContext = useContext(AppContext)
  const userType = appContext.userType
  const restaurantId = appContext.restaurantId
  const [editModal, setEditModal] = useState(false)
  const [option, setOption] = useState<any>(null)
  const [items, setItems] = useState<RestaurantQueryResponseOption[]>([])
  const [modalLoading, setModalLoading] = useState(false)

  const toggleModal = (option: RestaurantQueryResponseOption | null) => {
    setEditModal(prev => !prev)
    setOption(option)
  }

  const ActionButtons = ({ row }: { row: RestaurantQueryResponseOption }) => {
    const [deleteMutate, { loading }] = useDeleteOption({
      variables: {
        id: row._id,
        restaurantId: restaurantId ? restaurantId : "",
      },
      refetchQueries: [
        { query: getRestaurantQuery, variables: { id: restaurantId } },
      ],
      onError: console.log
    })


    function onPressEdit(e: any) {
      e.preventDefault()
      toggleModal(row)
    }
    function onPressDelete(e: any) {
      e.preventDefault()
      if (getIsDisabled(userType)) return
      if (window.confirm("削除してもよろしいですか？")) {
        deleteMutate()
      }
    }

    if (loading) {
      return (
        <Loader
          type="ThreeDots"
          color="#BB2124"
          height={20}
          width={40}
          visible={loading}
        />
      )
    }
    return (
      <>
        <Badge
          color="primary"
          style={styles.badgeStyle}
          onClick={onPressEdit}
        >
          {t("Edit")}
        </Badge>
        <Badge
          color="danger"
          style={styles.badgeStyle}
          onClick={onPressDelete}
        >
          {t("Delete")}
        </Badge>
      </>
    )
  }

  const [updateOptionOrders] = useUpdateOptionOrders({
    refetchQueries: [{ query: getRestaurantQuery, variables: { id: restaurantId } }],
    onError: console.log
  })

  const { loading, error, data } = useRestaurantQuery(restaurantId ? restaurantId : "")
  useEffect(() => {
    if (data && data.restaurant && data.restaurant.options) {
      setItems(data.restaurant.options)
    }
  }, [data])



  function onSortEnd({ oldIndex, newIndex }: { oldIndex: number, newIndex: number }) {
    if (getIsDisabled(userType)) return
    const newItems = arrayMove(items, oldIndex, newIndex)
    const newItemIds = newItems.map((item: any) => item._id)
    setItems(newItems)
    updateOptionOrders({
      variables: {
        restaurantId: restaurantId ? restaurantId : "",
        optionIds: newItemIds
      }
    })
  }

  const columns = window.screen.width > PHONE_BOUNDARY
    ? [
      {
        id: "title",
        name: t("Title"),
        cell: (row: RestaurantQueryResponseOption) => <p className="mb-0 text-sm" >{row.title}</p>,
        minWidth: 200,
        maxWidth: 300,
      },
      {
        id: "description",
        name: t("Description"),
        cell: (row: RestaurantQueryResponseOption) => <p className="mb-0 text-sm" >{row.description}</p>,
        minWidth: 200,
        maxWidth: 300,
      },
      {
        id: "nonTaxedPrice",
        name: t("StoreProfit"),
        cell: (row: RestaurantQueryResponseOption) => <p className="mb-0 text-sm" >{row.storeProfit}</p>,
        maxWidth: 100,
      },
      {
        id: "taxInPrice",
        name: t("TaxInPrice"),
        cell: (row: RestaurantQueryResponseOption) => <p className="mb-0 text-sm" >{row.taxInPrice}</p>,
        maxWidth: 100,
      },
      {
        id: "acteion",
        name: t("Action"),
        cell: (row: RestaurantQueryResponseOption) => <ActionButtons row={row} />,
        maxWidth: 100,
      }
    ]
    : [
      {
        id: "title",
        name: t("Title"),
        cell: (row: RestaurantQueryResponseOption) => <p className="mb-0 text-sm" >{row.title}</p>,
        minWidth: 150,
        maxWidth: 300,
      },
      {
        id: "nonTaxedPrice",
        name: t("StoreProfit"),
        cell: (row: RestaurantQueryResponseOption) => <p className="mb-0 text-sm" >{row.storeProfit}</p>,
        maxWidth: 100,
      },
      {
        id: "taxInPrice",
        name: t("TaxInPrice"),
        cell: (row: RestaurantQueryResponseOption) => <p className="mb-0 text-sm" >{row.taxInPrice}</p>,
        maxWidth: 100,
      },
      {
        id: "acteion",
        name: t("Action"),
        cell: (row: RestaurantQueryResponseOption) => <ActionButtons row={row} />,
        maxWidth: 100,
      }
    ]

  if (error) {
    console.log(error)
    return (
      <div >
        <Header />
        <p style={{ marginTop: 30, textAlign: "center" }}>{`${t("Error")}! ${error.message}`}</p>
      </div>
    )
  }

  if (loading || !data || !data.restaurant) {
    return (
      <tr>
        <td>
          loading...
        </td>
      </tr>
    )
  }

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <OptionModal
          restaurant={data.restaurant}
          modalLoading={modalLoading}
          setModalLoading={setModalLoading}
        />
        <Row className="mt-5">
          <div className="col">
            <Card className="shadow">
              {loading
                ? <CustomLoader />
                : <SortableTable
                  items={items}
                  onSortEnd={onSortEnd}
                  columns={columns}
                />
              }
            </Card>
          </div>
        </Row>
        <Modal
          className="modal-dialog-centered"
          size="lg"
          isOpen={editModal}
          toggle={() => { if (!modalLoading) toggleModal(null) }}
        >
          <OptionModal
            option={option}
            restaurant={data.restaurant}
            modalLoading={modalLoading}
            setModalLoading={setModalLoading}
          />
        </Modal>
      </Container>
    </>
  )
}
export default Options
