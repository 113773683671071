import { useQuery } from "@apollo/client"
import gql from "graphql-tag"

const completedOrdersQuery = gql`query ($start: Float, $end: Float) {
  completedOrders(start: $start, end: $end) {
    _id
    orderId
    orderAmount
    storeProfit
    orderStatus
    createdAt
    updatedAt
    startPreparationAt
    finishPreparationAt
    startDeliveryAt
    deliveredAt
    paymentMethod
    deliveryAddress {
      deliveryAddress
      details
    }
    restaurant {
      _id
      name
    }
    user {
      _id
      name
    }
    rider {
      _id
      name
    }
  }
}`

export type CompletedOrdersQueryResponse = {
  _id: string
  orderId: string
  orderAmount: number
  storeProfit: number
  orderStatus: string
  createdAt: string
  updatedAt: string
  startPreparationAt?: string
  finishPreparationAt?: string
  startDeliveryAt?: string
  deliveredAt: string
  paymentMethod?: string
  deliveryAddress: {
    deliveryAddress: string
    details: string
  }
  restaurant: {
    _id: string
    name: string
  }
  user: {
    _id: string
    name: string
  }
  rider: {
    _id: string
    name: string
  }
}

type Response = {
  completedOrders: CompletedOrdersQueryResponse[]
}
export function useCompletedOrdersQuery(start: number, end: number) {

  const { loading, error, data, subscribeToMore } = useQuery<Response>(completedOrdersQuery, { variables: { start, end } })
  let result: CompletedOrdersQueryResponse[]
  if (!data) result = []
  else result = data.completedOrders
  return { loading, error, data: result, subscribeToMore }
}