import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import DataTable, { IDataTableColumn } from "react-data-table-component"
import orderBy from "lodash/orderBy"
import CustomLoader from "../../../components/Loader/CustomLoader"
import { paginationComponentOptions } from "../../../variables/table"
import { paging } from "../../../utils/pagination"

interface Props {
  title: string
  data: any[]
  columns: IDataTableColumn<any>[]
  onTitleClick?: () => void
  onRowClicked: (e: any) => void
  loading: boolean
}

const DT: React.FC<Props> = props => {
  const [page, setPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(30)
  const pagedData = paging(props.data, page, rowsPerPage)

  const propExists = (initialValue: any, path: string) => {
    return path.split(".").reduce((obj, prop) => {
      return obj && obj[prop] ? obj[prop] : ""
    }, initialValue)
  }
  const customSort = (_rows, field: string, direction: boolean | "asc" | "desc") => {
    const handleField = row => {
      if (field && isNaN(propExists(row, field))) {
        return propExists(row, field).toLowerCase()
      }

      return row[field]
    }

    return paging(orderBy(props.data, handleField, direction), page, rowsPerPage)
  }
  const handleSort = (column, sortDirection) => console.log(column.selector, sortDirection)

  const handlePerRowsChange = async (perPage: number, page: number) => {
    setPage(page)
    setRowsPerPage(perPage)
  }

  const handlePageChange = async (page: number) => {
    setPage(page)
  }
  return (
    <DataTable
      title={<Title title={props.title} onTitleClick={props.onTitleClick} />}
      columns={props.columns}
      data={pagedData}
      onRowClicked={props.onRowClicked}
      progressPending={props.loading}
      pointerOnHover
      progressComponent={<CustomLoader />}
      onSort={handleSort}
      sortFunction={customSort}
      pagination
      paginationServer
      paginationPerPage={30}
      paginationRowsPerPageOptions={[10, 20, 30, 40, 50, 100]}
      paginationTotalRows={props.data.length}
      onChangeRowsPerPage={handlePerRowsChange}
      onChangePage={handlePageChange}
      paginationComponentOptions={paginationComponentOptions}
      noDataComponent={""}
      defaultSortField="deliveredAt"
      defaultSortAsc={false}
    />
  )
}
export default DT

interface TitleProps {
  title: string
  onTitleClick?: () => void
}
const Title: React.FC<TitleProps> = props => {
  const { t } = useTranslation()
  return (
    <h2
      onClick={props.onTitleClick}
      style={{
        cursor: !!props.onTitleClick ? "pointer" : "",
        color: !!props.onTitleClick ? "#6FCF97" : "",
      }}
    >
      {t(props.title)}
    </h2>
  )
}