import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import StoreCategoryModal from './StoreCategoryModal'
import CustomLoader from '../../components/Loader/CustomLoader'
import { Badge, Card, Container, Row, Modal } from 'reactstrap'
import Header from '../../components/Headers/Header'
import { getStoreCategories, deleteStoreCategory } from '../../apollo/server'
import DataTable from 'react-data-table-component'
import orderBy from 'lodash/orderBy'
import Loader from 'react-loader-spinner'
import { paginationComponentOptions } from '../../variables/table'
import { useQuery, useMutation, gql, } from "@apollo/client"
import styles from '../styles'

const GET_STORE_CATEGORIES = gql`${getStoreCategories}`
const DELETE_STORE_CATEGORY = gql`${deleteStoreCategory}`

const StoreCategory = () => {
  const { t } = useTranslation()
  const [editModal, setEditModal] = useState(false)
  const [category, setCategory] = useState(null)
  const [modalLoading, setModalLoading] = useState(false)

  const toggleModal = category => {
    setEditModal(!editModal)
    setCategory(category)
  }

  const customSort = (rows, field, direction) => {
    const handleField = row => {
      if (row[field]) {
        return row[field].toLowerCase()
      }

      return row[field]
    }

    return orderBy(rows, handleField, direction)
  }

  const handleSort = (column, sortDirection) =>
    console.log(column.selector, sortDirection)

  const columns = [
    {
      name: t('CategoryTitle'),
      sortable: true,
      selector: 'title'
    },
    {
      name: t('Actions'),
      cell: row => <ActionButtons row={row} />
    }
  ]
  const ActionButtons = props => {
    const { row } = props
    const [deleteCategory, { loading: deleteLoading }] = useMutation(DELETE_STORE_CATEGORY, {
      refetchQueries: [
        { query: GET_STORE_CATEGORIES },
      ],
      onError: console.log
    })

    function onClickDeleteButton(e: React.MouseEvent<HTMLElement, MouseEvent>) {
      e.preventDefault()
      if (window.confirm('削除してもよろしいですか？')) {
        deleteCategory({
          variables: { id: row._id }
        })
      }
    }

    return (
      <>
        <Badge
          style={styles.badgeStyle}
          href="#pablo"
          onClick={e => {
            e.preventDefault()
            toggleModal(row)
          }}
          color="primary">
          {t('Edit')}
        </Badge>
        {" "}

        {deleteLoading
          ? <Loader
            type="ThreeDots"
            color="#BB2124"
            height={20}
            width={40}
            visible={deleteLoading}
          />
          : <Badge
            style={styles.badgeStyle}
            href="#pablo"
            color="danger"
            onClick={onClickDeleteButton}>
            {t('Delete')}
          </Badge>
        }
      </>
    )
  }

  const { loading, error, data } = useQuery(GET_STORE_CATEGORIES)
  if (error) {
    return (
      <span>
        {`${t('Error')}! ${error.message}`}
      </span>
    )
  }
  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <StoreCategoryModal
          modalLoading={modalLoading}
          setModalLoading={setModalLoading}
        />
        <Row className="mt-5">
          <div className="col">
            <Card className="shadow">
              <DataTable
                title={t('Categories')}
                columns={columns}
                data={data && data.storeCategories ? data.storeCategories : []}
                pagination
                progressPending={loading}
                progressComponent={<CustomLoader />}
                onSort={handleSort}
                sortFunction={customSort}
                defaultSortField="title"
                paginationComponentOptions={paginationComponentOptions}
                noDataComponent={t('NoData')}
              />
            </Card>
          </div>
        </Row>
        <Modal
          className="modal-dialog-centered"
          size="lg"
          isOpen={editModal}
          toggle={() => { if (!modalLoading) toggleModal(null) }}
        >
          <StoreCategoryModal
            category={category}
            modalLoading={modalLoading}
            setModalLoading={setModalLoading}
          />
        </Modal>
      </Container>
    </>
  )
}
export default StoreCategory
