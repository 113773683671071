/* eslint-disable react/display-name */
import React, { useState, useEffect, useContext } from 'react'
import { withTranslation } from 'react-i18next'
// reactstrap components
import { Badge, Card, Container, Row, Modal } from 'reactstrap'
import Header from '../../components/Headers/Header'
import VendorComponent from './VendorModal'
import CustomLoader from '../../components/Loader/CustomLoader'
import { getVendors, toggleVendorCapability } from '../../apollo/server'
import DataTable from 'react-data-table-component'
import orderBy from 'lodash/orderBy'
import { paginationComponentOptions } from '../../variables/table'
import { useQuery, gql, useMutation, } from "@apollo/client"
import styles from '../styles'
import { AppContext } from '../../app'
import { SearchBar } from '../../components/SearchBar'

const GET_VENDORS = gql`
  ${getVendors}
`
const TOGGLE_VENDOR_CAPABIlITY = gql`
  ${toggleVendorCapability}
`

const Vendors = props => {
  const { t } = props
  const [searchKeyword, setSearchKeyword] = useState("")
  const [editModal, setEditModal] = useState(false)
  const [vendor, setVendor] = useState(null)
  const appContext = useContext(AppContext)

  function onSearchWordChange(event) {
    event.preventDefault()
    setSearchKeyword(event.target.value)
  }
  const toggleModal = (vendor?: any) => {
    setEditModal(!editModal)
    setVendor(vendor)
  }

  useEffect(() => {
    appContext.setRestaurantId("")
    appContext.setOwnerId("")
  }, [appContext, appContext.setOwnerId, appContext.setRestaurantId])

  const customSort = (rows, field, direction) => {
    const handleField = row => {
      if (row[field]) {
        return row[field].toLowerCase()
      }

      return row[field]
    }

    return orderBy(rows, handleField, direction)
  }

  const handleSort = (column, sortDirection) =>
    console.log(column.selector, sortDirection)

  const columns = [
    {
      name: t('Email'),
      sortable: true,
      maxWidth: '300px',
      selector: 'email'
    },
    {
      name: t('StoreName'),
      sortable: true,
      cell: row => <>{row.restaurants[0] ? row.restaurants[0].name : ''}</>
    },
    {
      name: t('StoreCategory'),
      sortable: true,
      maxWidth: '200px',
      cell: row => (
        <>{row.restaurants[0] && row.restaurants[0].storeCategory && row.restaurants[0].storeCategory.title ? row.restaurants[0].storeCategory.title : ''}</>
      )
    },
    {
      name: t('Actions'),
      maxWidth: '100px',
      cell: row => <>{actionButtons(row)}</>
    },
    {
      name: t('EditPermission'),
      sortable: false,
      maxWidth: '100px',
      cell: row => <AvailableStatus row={row} />
    },
  ]

  const AvailableStatus = props => {
    const { row } = props
    const [on, setOn] = useState(false)
    useEffect(() => {
      if (row.userType === "AUTHORIZED_VENDOR") setOn(true)
      else setOn(false)
    }, [row])

    const [toggleVendor] = useMutation(TOGGLE_VENDOR_CAPABIlITY, {
      refetchQueries: [
        { query: GET_VENDORS },
      ],
      onCompleted: data => {
        console.log("toggled", data.toggleVendorCapability)
      },
      onError: e => console.log
    })

    return (
      <>
        <label className="custom-toggle">
          <input
            checked={on}
            type="checkbox"
            onChange={event => {
              setOn(event.target.checked)
              const userType = row.userType === "AUTHORIZED_VENDOR"
                ? "VENDOR"
                : "AUTHORIZED_VENDOR"
              toggleVendor({ variables: { id: row._id, userType } })
            }}
          />
          <span className="custom-toggle-slider rounded-circle" />
        </label>
      </>
    )
  }
  const actionButtons = row => {
    function onPressRestaurantsButton(e: React.MouseEvent<HTMLElement, MouseEvent>) {
      e.preventDefault()
      appContext.setOwnerId(row._id)
      props.history.push({
        pathname: '/vendor_admin/list',
        state: { id: row._id }
      })
    }

    return (
      <>
        <Badge
          style={styles.badgeStyle}
          href="#pablo"
          onClick={onPressRestaurantsButton}
          color="info">
          {t('Restaurants')}
        </Badge>
        {"  "}
        <Badge
          style={styles.badgeStyle}
          href="#pablo"
          onClick={e => {
            e.preventDefault()
            toggleModal(row)
          }}
          color="primary">
          {t('Edit')}
        </Badge>
      </>
    )
  }

  const { loading, error, data } = useQuery(GET_VENDORS, { onError: error => { console.log(error) }, })
  if (error) {
    return (
      <span>
        {`${t('ErrorMessage')}! ${error.message}`}
      </span>
    )
  }

  const vendors = data && data.vendors ? data.vendors.filter(vendor => {
    if (searchKeyword) {
      return vendor.restaurants.findIndex(res => res.name.indexOf(searchKeyword) !== -1) !== -1
    } else {
      return data.vendors
    }
  }) : []

  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <VendorComponent />
        {/* Table */}
        <Row className="mt-5">
          <div className="col">
            <Card className="shadow">
              <SearchBar
                title={""}
                onSearchKeywordChange={onSearchWordChange}
                searchKeyword={searchKeyword}
                placeholder={t("SearchKeyword")}
              />

              <DataTable
                title={t('Vendors')}
                columns={columns}
                data={vendors}
                pagination
                progressPending={loading}
                progressComponent={<CustomLoader />}
                onSort={handleSort}
                sortFunction={customSort}
                defaultSortField="email"
                paginationComponentOptions={paginationComponentOptions}
                noDataComponent={t('NoData')}
              />
            </Card>
          </div>
        </Row>
        <Modal
          className="modal-dialog-centered"
          size="lg"
          isOpen={editModal}
          toggle={() => {
            toggleModal()
          }}>
          <VendorComponent vendor={vendor} />
        </Modal>
      </Container>
    </>
  )
}

export default withTranslation()(Vendors)
