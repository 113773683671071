import { APIError } from '../utils/errorHandler'

const ja = {
  translation: {
    appName: '３つ星キャリー',
    "Back to Admin": "Back to Admin",
    Back: "戻る",
    CategoryMaster: "カテゴリマスター",
    CSVFile: "CSVファイル",
    "Don'tHavePermission": "編集権限がありません",
    DownloadCSV: "CSVダウンロード",
    FoodMaster: "商品マスター",
    ReadFromCSV: "CSVファイルの読み込み",
    RestaurantList: "店舗一覧",
    SelectFile: "ファイルの選択",
    ShowItems: "表示項目",
    'Sign in credentials': 'ログイン情報',
    'Sign in': 'サインイン',
    SpecialFeatureTitle: "特集タイトル",
    StoreClosed: "全店舗閉店",
    StoreClosedConfirmation: "営業時間に関わらず全店舗閉店してしまいますが、本当によろしいですか？",
    StoreOpenConfirmation: "全店舗閉店を解除しますが、本当によろしいですか？",
    StoreClosedSuccessMessage: "全店舗閉店しました。\n開店日には必ず開店処理を行ってください。",
    StoreOpenSuccessMessage: "全店舗閉店を解除しました。\n各店舗の営業時間に従って開店します。",
    StoreCount: "店舗数",
    'Add Category': 'カテゴリを追加',
    Title: 'タイトル',
    TownName: "町名等",
    Description: '説明',
    Add: '追加',
    Remove: '削除する',
    'Add/Remove': '追加/削除',
    Success: '成功',
    Danger: '注意',
    Categories: 'カテゴリ',
    Image: '画像',
    Header: 'ヘッダー',
    Loading: '読み込み中',
    Error: 'エラー',
    Edit: '編集',
    Delete: '削除',
    'Edit Category': 'カテゴリの編集',
    Save: '保存',
    Saving: '保存中...',
    Sending: '送信中...',
    OrderID: '注文番号',
    'OrderID prefix': '注文番号　プレフィックス',
    Order: '注文',
    Email: 'メールアドレス',
    Password: 'パスワード',
    'Reset Password': 'パスワードのリセット',
    Reset: 'リセット',
    Enable: '有効化',
    Disable: '無効化',
    'Enable/Disable': '有効化',
    Paypal: 'Paypal',
    'Client ID': 'お客様番号',
    ClientId: 'お客様ID',
    'Client Secret': '秘密の暗号',
    Sandbox: 'セキュリティ',
    'Publishable Key': '公開可能キー',
    'Secret Key': 'シークレットキー',
    'Delivery Charges': '配達料（％）',
    DeliveryCharges: '配達料',
    Price: '価格（円）',
    Yen: '円',
    NonTaxedPrice: '価格（税抜き）',
    TaxInPrice: 'アプリ表示価格',
    Discounted: '割引（円）',
    'Add Food': 'フードメニューを追加',
    Category: 'カテゴリー',
    Select: '選択',
    Variations: 'バリエーション',
    Type: '種類',
    'Food Image': '商品画像',
    Foods: 'フードメニュー',
    Action: '操作',
    Actions: '操作',
    State: "状態",
    'Edit Food': '商品を編集',
    Orders: '注文',
    Name: '名前',
    Items: '商品',
    Payment: '支払い',
    Status: '状態',
    Review: 'レビュー',
    'Mark as': '評価して下さい',
    Users: 'ユーザー',
    Phone: '電話番号',
    DisplayPhone: '店舗アプリ表示用電話番号',
    DisplayPhoneSub: '店舗アプリ用',
    Address: '住所',
    2019: '2019',
    2021: '2021',
    'About Us': 'About Us',
    Blog: 'Blog',
    Welcome: 'ようこそ',
    Logout: 'ログアウト',
    Dashboard: 'ダッシュボード',
    Configuration: '設定',
    Login: 'ログイン',
    LoginErrorMessage: 'ログインできませんでした',
    DeleteUser: 'アカウントの削除',
    DeleteUserConfirmation: 'アカウントを削除してもよろしいですか？',
    DeleteUserErrorMessage: 'アカウントの削除ができませんでした',
    Food: 'フードメニュー',
    FOOD: "フード",
    'Character limit of max length 30': '30文字以下',
    'Character limit of max length 50': '50文字以下',
    'Character limit of max length 15': '15文字以下',
    'Character limit of max length 60': '60文字以下',
    'Character limit of max length 20': '20文字以下',
    'Character limit of max length 140': '140文字以下',
    Currency: '通貨',
    'Currency Code': '通貨コード',
    'Currency Symbol': '通貨記号',
    'Quantity Minimum': '最小数量',
    'Must be a less than or equal to Maximum': '最大値以下で設定してください',
    'Quantity Maximum': '最大数量',
    'Must be a greater than or equal to Minimum':
      '最小値以上で設定してください',
    Options: 'オプション',
    Option: 'オプション',
    'Select atleast one Option': '少なくとも1つのオプションを選択してください',
    'Add/Remove Addons': 'アドオンの追加/削除',
    'Edit Coupon': 'クーポンの編集',
    'Add Coupon': 'クーポンの追加',
    'Discount Percent': '割引率',
    'Enabled/Disabled': '有効/無効',
    'Change Password': 'パスワード変更',
    Tag: 'タグ',
    'Must be a number': '数字を入力してください',
    Customer: 'お客様',
    'Order Details': '注文詳細',
    Charges: '注文客支払い',
    'Confirm Password': 'パスワード認証',
    'Edit Rider': 'ライダー編集',
    'Add Rider': 'ライダー追加',
    Username: 'ユーザー名',
    Available: '利用可能',
    Zone: 'ゾーン',
    'Edit Vendor': 'ベンダー編集',
    'Add Vendor': 'ベンダー追加',
    Restaurant: '店舗',
    Restaurants: '店舗',
    RestaurantName: '店舗名',
    RestaurantUserame: 'アプリ用ユーザー名',
    'Edit Zone': 'ゾーン編集',
    'Add Zone': 'ゾーン追加',
    Coordinates: 'マップ',
    Addons: 'アドオン',
    Addon: 'アドオン',
    Coupons: 'クーポン',
    Dispatch: '配達管理',
    Ratings: 'レビュー',
    Riders: 'ライダー',
    Rider: 'ライダー',
    Profile: 'プロフィール',
    'Update Profile': 'プロフィールの更新',
    'Loading...': '読み込み中',
    RestaurantPassword: 'アプリ用パスワード',
    RestaurantAddress: '店舗住所',
    Latitude: '緯度',
    Longitude: '経度',
    'Order Prefix': 'オーダープレフィックス',
    'Minimum Order': '最低受注額（円）',
    'Delivery Time': '配達時間（分）',
    'Updating...': '更新中',
    Update: '更新',
    Vendors: 'ベンダー',
    'Filter Graph': '期間選択',
    'Total Orders': '総注文数',
    TotalPrice: "合計金額",
    'Total Sales': '総売上',
    'Starting Date': '開始日',
    'Ending Date': '終了日',
    'Graph Filter Submit': '適用',
    'Sales value': '売上',
    'Total orders': '注文',
    CategoryTitle: 'カテゴリ名',
    CategoryPlaceholder: '例）メインディッシュ',
    StoreCategoryPlaceholder: '例）フード',
    StoreName: '店舗名',
    OrderPrefix: '店舗番号',
    ActivateBtn: '有効にする',
    InactivateBtn: '無効にする',
    Featured: '特集店舗',
    DiscountPercentage: '割引率（%)',
    NoData: 'データがありません',
    OrderInformation: '注文情報',
    OrderTime: '注文時刻',
    ErrorMessage: 'エラーが発生しました',
    UpdateSuccessMessage: '更新が完了しました',
    AddSuccessMessage: '新規作成が完了しました',
    FoodnamePH: '例）まぐろづくしスペシャル',
    FoodDescriptionPH:
      '例）目利きに定評のある店主が選び抜いた、マグロの「本当に美味しいところだけ」をセットにした６貫セット',
    'Title must be unqiue': '',
    SampleNamePH: '例）３つ星太郎',
    SampleUsernamePH: '例）mitsuboshi',
    CouponCode: 'クーポンコード',
    CouponPH: '例）9Je2BY',
    ZonePH: '例）函館市内エリア',
    ZoneDescriptionPH: '例）函館駅周辺のエリア',
    'Set Zone on Map': 'マップ上でゾーンを指定してください',
    'Please set 3 point to make zone':
      'ゾーン指定は３点以上の地点を指定してください',
    VariationsPH: '例）Sサイズ',
    Min: '最小',
    Max: '最大',
    CreateNewAddon: 'アドオンの作成',
    AddonPH: '例）ランチセット',
    AddonDescriptionPH: '例）お好きなお飲み物をお選びください',
    New: '新規作成',
    OptionPH: '例）ブレンドコーヒー',
    OptionDescriptionPH: '例）当店自慢の深煎りブレンド',
    Custmer: 'お客様',
    Datetime: '注文日時',
    UpdateDatetime: '更新日時',
    'Filter By Order Id': '注文番号で検索',
    'Show/Hide': '表示/非表示',
    Show: '表示',
    Hide: '非表示',
    Subtotal: '商品小計',
    Total: '合計',
    appUsernamePH: '例）storename',
    passwordPH: '例）password',
    addressPH: '例）〇〇県△△市□町xx-xx',
    storeNamePH: '例）〇〇レストラン',
    latitudePH: '例）41.7687',
    longitudePH: '例）140.7291',
    deliveryChargesPH: '例）10',
    minimumOrderPH: '例）500',
    deliveryTimePH: '例）30',
    discountPH: '例）20',
    'Fields Required': '必須項目が未入力です',
    Accepted: '受付完了',
    Accept: '受付',
    Cancelled: 'キャンセル済み',
    Cancel: 'キャンセル',
    RejectReason: 'キャンセルする場合は理由を記入してください',
    CancelReason: 'キャンセル理由',
    EmailConfig: '配信設定',
    SendingEmail: '配信用メールアドレス',
    OpenTime: '開始',
    CloseTime: '終了',
    OpenTime2: '開始 ２',
    CloseTime2: '終了 ２',
    openCloseReverseError: '開始時間と終了時間の設定を逆にしてください',
    RegularHolidays: '定休日',
    ApplyCoupon: 'クーポン適用',
    Tax: '消費税',
    ChargeLimit: '配達料上限額（円）',
    ChargeLimitPH: '例）1000',
    PasswordChanged: 'パスワードの変更が完了しました',
    NewPassword: '新しいパスワード',
    ConfirmNewPassword: '新しいパスワード（確認）',
    Commission: '店舗手数料',
    Create: "作成",
    "Creating...": "作成中...",
    StoreCategory: ' 店舗カテゴリー',
    StoreSubCategory: ' サブカテゴリー',
    AddonMemo: '備考',
    AddonMemoDesc: '（注文アプリには表示されません）',
    SendEmailForm: 'メール配信フォーム',
    SendTestEmailTarget: 'テストメール送信先のメールアドレス',
    EmailTitle: 'タイトル',
    EmailText: '本文',
    SendTestEmail: '配信テスト',
    SendEmail: '一斉送信',
    SendTestMailMessage: '入力されたメールアドレスにテストメールを送信します',
    SendMailMessage: 'オーダーアプリを利用するすべてのユーザーにメールを一斉送信しますが、本当によろしいですか?',
    SendMailMessageAgain: '一斉送信ですが、本当の本当によろしいですか?',
    SalesBoard: '実績管理',
    OrderInfo: '注文情報',
    DeliveryDate: "配達日時",
    AssignedRider: '対応ライダー',
    OrderCount: "注文数",
    ReceiptPrice: '回収',
    Pay: '支払',
    Sales: '売上',
    AverageOrderCount: "平均注文数",
    AverageReceiptPrice: '平均回収',
    AverageOrderPrice: '平均注文金額',
    AveragePay: '平均支払',
    AverageSales: '平均売上',
    Ceiled: "（10円未満切り上げ）",
    StoreSales: "店舗売上",
    StoreCommission: "店舗手数料",
    ReadyToPick: "引渡可能",
    ShouldSelectRider: "ライダーを選択してください",
    EditPermission: "編集権限",
    SalesBoardMode: "表示モード",
    OrderTimes: "所要時間",
    StoreMode: "店舗別",
    SearchKeyword: "検索ワード",
    OrderDataBoard: '注文データ',
    StoreResponse: '店舗レスポンス',
    OrderReceivedAt: '受注日時',
    StartPreparationAt: '準備開始',
    FinishPreparationAt: '準備完了',
    StartDeliveryAt: '配送開始',
    DeliveredAt: '配送完了',
    InDeliveryTime: '配送時間',
    TotalTime: "総時間",
    AcceptanceDuration: "受付待時間",
    PreparationDuration: "店舗準備時間",
    PickupDuration: "受取待時間",
    isAvailable: "在庫あり",
    AddInfoCarousel: "お知らせ追加",
    EditInfoCarousel: "お知らせ編集",
    InfoCarousel: "お知らせ",
    InfoCarouselTitlePH: "新しいおしらせ",
    InfoCarouselDescriptionPH: "新しいおしらせです。新しいお知らせです。新しいお知らせです。",
    InfoCarouselDescription: "サブタイトル",
    InfoCarouselLink: "リンク先",
    TextColor: "文字色",
    SubtitleTextColor: "サブタイトル文字色",
    IrregularOpenTime: "変則的な開始時間",
    IrregularCloseTime: "変則的な終了時間",
    IrregularDays: "変則時間対象の曜日",
    ProxyUsername: "代理注文ユーザー名",
    FavoriteCount: "お気に入り",
    PromotionText: "プロモーションテキスト（80文字以内）",
    PromotionTextTooLongError: "プロモーションテキストは80文字以内で入力してください。",
    Destination: "配達先",
    Label: "ラベル",
    NotSelected: "未指定",
    Link: "リンク",
    isCommingSoon: "近日公開",
    ShowBtn: "表示する",
    HideBtn: "非表示にする",
    StoreProfit: "店舗受取額",
    OrderStatus: "配達ステータス",
    StoreProfitClassification: "売上内訳",
    OrderCountClassification: "注文数内訳",
    ToStoreMessage: "店舗への連絡事項",
    Filter: "絞り込み",
    ALL: "すべて",
    DELIVERED: "配達完了",
    CANCELLED: "キャンセル",
    CANCELLED_BY_USER: "キャンセル(客不出)",
    Graphs: "グラフ",
    Orderer: "注文者",
    PaymentMethod: "支払い方法",
    OrderCountsByTimezone: "注文数（時間帯別）",
    AverageOrderPriceByTimezone: "平均注文金額（時間帯別）",
    SalesByTimezone: "売上金額（時間帯別）",
    OrderCountsByDayOfTheWeek: "注文数（曜日別）",
    SalesByDayOfTheWeek: "売上金額（曜日別）",
    DailySales: "売上金額（日別）",
    NewUsersCount: "新規ユーザー数",
    NewRestaurantsCount: "新規店舗数",
    Count: "数",
    Store: "店舗",
    ZoneStatistics: "分布統計",
    StoreStatus: "営業ステータス",
    StoreStatus_NORMAL: "通常営業",
    StoreStatus_CLOSED_FOR_ONEDAY: "臨時休業",
    StoreStatus_BUSY_NOT_ACCEPTABLE: "一時停止",
    StoreStatus_BUSY_ACCEPTABLE: "混雑中",
    CustomerUnitPrice: "客単価",
    User: "ユーザー",
    JanCode: "JANコード",
    FoodSearchKeywordPlaceholder: "商品名、バリエーション名、JANコードで検索",

    [APIError.OrderStatusHadChanged]: 'エラー：無効な注文ステータスの変更です。適切な変更を行ってください。'
  }
}

export default ja
