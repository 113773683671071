export const errorHandler = (err, t) => {
  const errorMessage = err.message.slice(15)
  switch (errorMessage) {
    case APIError.OrderStatusHadChanged:
      return t(APIError.OrderStatusHadChanged)
    default:
      return t('errorMessage')
  }
}

export const APIError = {
  OrderStatusHadChanged: 'OrderStatusHadChanged',
  WrongArgument: "WrongArgument",
}
