import React, { useContext, useEffect, useState } from "react"
import {
  Card,
  Container,
  Row,
  Modal,
} from "reactstrap"
import Header from "../../components/Headers/Header"
import {
  calcBusinessEndTime,
  calcBusinessStartTime,
  getBusinessDate,
  getFirstDayOfMonth,
} from "../../utils/calcBusinessTime"
import { calcDateDifference } from "../../utils/date"
import { CompletedOrdersQueryResponse } from "../../domain/graphql/query/useCompletedOrdersQuery"
import FormItems from "./FormItems"
import Numbers from "./Numbers"
import Users from "./DataTable/Users"
import {
  useStoreSalesboardUserDataQuery,
  useStoreSalesboardUserDataMultiTenantQuery,
} from "../../domain/graphql/query/useStoreSalesboardUserDataQuery"
import Foods from "./DataTable/Foods"
import Options from "./DataTable/Options"
import OrderData from "./DataTable/OrderData"
import { useOrderDetailQuery } from "../../domain/graphql/query/useOrderDetailQuery"
import OrderComponent from "../../components/Order/Order"
import { AppContext } from "../../app"

const StoreSalesBoard: React.FC = () => {
  const appContext = useContext(AppContext)
  const restaurantId = appContext.restaurantId
  const ownerId = appContext.ownerId

  const [mode, setMode] = useState<"user" | "food" | "option">("user")
  const [showGraphs, setShowGraphs] = useState(false)

  const [selectedUserId, setSelectedUserId] = useState<string | null>(null)
  const [selectedOrderId, setSelectedOrderId] = useState<string | null>(null)
  const [selectedStatus, setSelectedStatus] = useState<"DELIVERED" | "CANCELLED" | "CANCELLED_BY_USER" | null>(null)
  const [showModal, setShowModal] = useState(false)
  const toggleModal = (order: CompletedOrdersQueryResponse) => {
    if (order) setSelectedOrderId(order._id)
    setShowModal(prev => !prev)
  }

  const businessToday = getBusinessDate(new Date())
  const [startDate, setStartDate] = useState<Date>(getFirstDayOfMonth(businessToday))
  const [endDate, setEndDate] = useState<Date>(businessToday)
  const dateDiff = calcDateDifference(startDate, endDate)

  const businessStartTime = calcBusinessStartTime(startDate).getTime()
  const businessEndTime = calcBusinessEndTime(endDate).getTime()

  const { error: orderDetailError, data: orderDetailData } = useOrderDetailQuery(selectedOrderId)
  if (orderDetailError) {
    console.log(orderDetailError.message)
  }
  useEffect(() => {
    if (orderDetailData) setShowModal(true)
    else setShowModal(false)
  }, [orderDetailData])

  const isSingleTenant = window.location.href.includes("store_admin")
  const singleTenantResult = useStoreSalesboardUserDataQuery(businessStartTime, businessEndTime, restaurantId, isSingleTenant)
  const multiTenantResult = useStoreSalesboardUserDataMultiTenantQuery(businessStartTime, businessEndTime, ownerId, !isSingleTenant)

  const userData = (
    isSingleTenant
      ? singleTenantResult.data?.storeSalesboardUserData
      : multiTenantResult.data?.storeSalesboardUserDataMultiTenant
  ) ?? []
  const error = isSingleTenant ? singleTenantResult.error : multiTenantResult.error
  if (error) {
    console.log(error)
  }
  const loading = isSingleTenant ? singleTenantResult.loading : multiTenantResult.loading


  const DataTable: React.FC = () => {
    switch (mode) {
      case "user": {
        return selectedUserId ? (
          <OrderData
            userData={userData}
            loading={loading}
            selectedUserId={selectedUserId}
            setSelectedUserId={setSelectedUserId}
            onRowClicked={e => setSelectedOrderId(e._id)}
          />
        ) : (
          <Users
            userData={userData}
            loading={loading}
            setSelectedUserId={setSelectedUserId}
            onRowClicked={e => setSelectedUserId(e.userId)}
          />
        )
      }
      case "food": return (
        <Foods
          userData={userData}
          loading={loading}
          onRowClicked={() => { }}
        />
      )
      case "option": return (
        <Options
          userData={userData}
          loading={loading}
          onRowClicked={() => { }}
        />
      )
    }
  }

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <FormItems
          mode={mode}
          setMode={setMode}
          showGraphs={showGraphs}
          setShowGraphs={setShowGraphs}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          selectedStatus={selectedStatus}
          setSelectedStatus={setSelectedStatus}
        />

        <Numbers
          userData={userData}
          selectedStatus={selectedStatus}
          dateDiff={dateDiff}
          businessStartTime={businessStartTime}
          businessEndTime={businessEndTime}
        />

        {/* {showGraphs && <Graphs
          riders={riders}
          selectedStatus={selectedStatus}
          businessStartTime={businessStartTime}
          businessEndTime={businessEndTime}
        />} */}

        <Row>
          <div className="col">
            <Card className="shadow">
              <DataTable />
            </Card>
          </div>
        </Row>

        <Modal
          className="modal-dialog-centered"
          size="lg"
          isOpen={showModal}
          toggle={() => {
            toggleModal(null)
            setSelectedOrderId(null)
          }}
        >
          <OrderComponent order={orderDetailData?.order} />
        </Modal>
      </Container>
    </>
  )
}

export default StoreSalesBoard
