import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Row,
  Col,
  Card,
  CardHeader,
  Input,
  FormGroup,
  Button
} from 'reactstrap'
import { sendTestMail, sendMail } from '../../apollo/server'
import { useMutation, gql, } from "@apollo/client"

const SEND_TEST_MAIL = gql`
  ${sendTestMail}
`
const SEND_MAIL = gql`
  ${sendMail}
`

function Mailer() {
  const { t } = useTranslation()
  const [email, setEmail] = useState('')
  function onChangeEmail(e) {
    e.preventDefault()
    setEmail(e.target.value)
  }
  const [title, setTitle] = useState('')
  function onChangeTitle(e) {
    e.preventDefault()
    setTitle(e.target.value)
  }
  const [text, setText] = useState('')
  function onChangeText(e) {
    e.preventDefault()
    setText(e.target.value)
  }

  function onCompleted() {
    alert('メールを送信しました')
  }
  function onError(e) {
    console.log(e)
  }

  function submitTest(e, send) {
    e.preventDefault()
    if (!email || !title || !text) {
      alert('空欄があります')
      return
    }
    if (window.confirm(t('SendTestMailMessage'))) {
      send({ variables: { email, title, text } })
    }
  }
  function submit(e, send) {
    e.preventDefault()
    if (!title || !text) {
      alert('空欄があります')
      return
    }
    if (window.confirm(t('SendMailMessage'))) {
      if (window.confirm(t('SendMailMessageAgain'))) {
        send({ variables: { email, title, text } })
      }
    }
  }

  const SendMailButton = ({ isTest }) => {
    const [send, { loading, error }] = useMutation(isTest ? SEND_TEST_MAIL : SEND_MAIL, {
      onCompleted,
      onError,
    })
    if (error) return <p>{t("Error")}</p>

    return (
      <Button
        className="btn-block mb-2"
        type="button"
        color={isTest ? "primary" : "danger"}
        onClick={e => loading ? () => { } : isTest ? submitTest(e, send) : submit(e, send)}
      >
        {loading ? t("Sending") : isTest ? t("SendTestEmail") : t("SendEmail")}
      </Button>
    )
  }

  return (
    <Row className="mt-3">
      <div className="col">
        <Card className="shadow">
          <CardHeader className="border-0">
            <h3 className="mb-0">{t('SendEmailForm')}</h3>
          </CardHeader>
          <form>
            <div className="pl-lg-4 ml-3 mr-3">
              <Row>
                <Col lg="6">
                  <label className="form-control-label" htmlFor="input-email"> {t('SendTestEmailTarget')} </label>
                  <FormGroup>
                    <Input
                      autoCapitalize="none"
                      className="form-control-alternative"
                      id="input-email"
                      placeholder="sample@email.com"
                      type="email"
                      value={email}
                      onChange={onChangeEmail}
                    />
                  </FormGroup>
                </Col>

                <Col md="6">
                  <label className="form-control-label" htmlFor="input-password"> {t('EmailTitle')} </label>
                  <FormGroup>
                    <Input
                      autoCapitalize="none"
                      className="form-control-alternative"
                      id="input-title"
                      placeholder="title"
                      type="text"
                      value={title}
                      onChange={onChangeTitle}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col md="12">
                  <label className="form-control-label" htmlFor="input-enable"> {t('EmailText')} </label>
                  <FormGroup>
                    <Input
                      style={{ whiteSpace: 'pre-wrap' }}
                      autoCapitalize="none"
                      className="form-control-alternative"
                      id="input-textarea"
                      placeholder="textarea"
                      type="textarea"
                      value={text}
                      onChange={onChangeText}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row style={{display: "flex", justifyContent: "flex-end"}}>
                <Col md="2">
                  <SendMailButton isTest={true} />
                </Col>

                <Col md="2">
                  <SendMailButton isTest={false} />
                </Col>
              </Row>
            </div>
          </form>
        </Card>
      </div>
    </Row>
  )
}
export default Mailer
