import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import {
    Container,
    Row,
    Card,
    Modal,
    ListGroup,
    ListGroupItem,
} from "reactstrap"
import DataTable from "react-data-table-component"
import {
    getActiveOrders,
    getRidersByZone,
    subscriptionOrder,
    updateOrderStatus,
    assignRider
} from "../../apollo/server"
import Header from "../../components/Headers/Header"
import CustomLoader from "../../components/Loader/CustomLoader"
import { paginationComponentOptions } from "../../variables/table"
import { convertOrderStatus } from "../../utils/convertOrderStatus"
import { errorHandler } from "../../utils/errorHandler"
import {
    useQuery, useMutation,
    useSubscription,
    gql,
} from "@apollo/client"
import Button from "reactstrap/lib/Button"
import OrderComponent from "../../components/Order/Order"
import { dateToJapanese } from "../../utils/date"

const Orders: React.FC = () => {
    const { t } = useTranslation()

    const [orderStatusModal, setOrderStatusModal] = useState(false)
    const [selectedOrder, setSelectedOrder] = useState(null)
    const [showOrderDetailModal, setOrderDetailModal] = useState(false)
    function toggleOrderDetailModal(order) {
        if (order) setSelectedOrder(order)
        setOrderDetailModal(prev => !prev)
    }

    const OrderInformationCell = props => {
        const { row } = props
        useSubscription(gql` ${subscriptionOrder} `, {
            variables: { id: row._id }
        })
        return <div>
            <p className="mb-0 text-sm">{row.orderId}</p>
            <p className="mb-0 text-sm">{row.deliveryAddress.deliveryAddress}</p>
        </div>
    }

    const OrderStatus = row => {
        const [mutateUpdateStatus] = useMutation(gql`${updateOrderStatus}`, {
            onError: e => {
                window.alert(errorHandler(e, t))
                setOrderStatusModal(false)
            },
            onCompleted: () => {
                setOrderStatusModal(false)
            },
            refetchQueries: [{ query: gql`${getActiveOrders}` }]
        })

        function onPress(e, row) {
            e.preventDefault()
            setOrderStatusModal(true)
            setSelectedOrder(row)
        }

        const OrderStatusModal = () => {
            function onPress(e, orderStatus) {
                mutateUpdateStatus({
                    variables: { id: selectedOrder._id, status: orderStatus, reason: "" }
                })
            }

            return (
                <ListGroup style={{ cursor: "pointer" }}>
                    {selectedOrder.orderStatus === "PENDING" &&
                        <ListGroupItem onClick={e => onPress(e, "ACCEPTED")} >
                            {convertOrderStatus("ACCEPTED")}に変更する
                        </ListGroupItem>
                    }

                    {["PENDING", "ACCEPTED", "PICKED", "READY_TO_PICK"].includes(selectedOrder.orderStatus) &&
                        <>
                            <ListGroupItem onClick={e => onPress(e, "CANCELLED_BY_USER")}>
                                注文を{convertOrderStatus("CANCELLED")}する（客不出）
                            </ListGroupItem>
                            <ListGroupItem onClick={e => onPress(e, "CANCELLED")}>
                                注文を{convertOrderStatus("CANCELLED")}する
                            </ListGroupItem>
                        </>
                    }

                    {["ACCEPTED"].includes(selectedOrder.orderStatus) &&
                        <ListGroupItem onClick={e => onPress(e, "READY_TO_PICK")} >
                            {convertOrderStatus("READY_TO_PICK")}に変更する
                        </ListGroupItem>
                    }

                    {["READY_TO_PICK"].includes(selectedOrder.orderStatus) &&
                        <ListGroupItem
                            onClick={e => {
                                if (!selectedOrder.rider) {
                                    alert(t("ShouldSelectRider"))
                                    return
                                }
                                onPress(e, "PICKED")
                            }}
                        >
                            {convertOrderStatus("PICKED")}に変更する
                        </ListGroupItem>
                    }

                    {["PICKED"].includes(selectedOrder.orderStatus) &&
                        <ListGroupItem onClick={e => onPress(e, "DELIVERED")} >
                            {convertOrderStatus("DELIVERED")}に変更する
                        </ListGroupItem>
                    }
                </ListGroup>
            )
        }

        return (
            <div>
                {!["CANCELLED", "DELIVERED"].includes(row.orderStatus) && (
                    <Button
                        color="secondary"
                        className="btn-block"
                        onClick={e => onPress(e, row)}
                        size="sm"
                    >
                        {convertOrderStatus(row.orderStatus)}
                    </Button>
                )}
                <Modal
                    className="modal-dialog-centered"
                    size="sm"
                    isOpen={orderStatusModal}
                    style={{ width: 300 }}
                    toggle={() => { setOrderStatusModal(prev => !prev) }}
                >
                    <OrderStatusModal />
                </Modal>
            </div>
        )
    }


    const Rider = row => {
        function onPress(e, row) {
            e.preventDefault()
            setSelectedOrder(row)
            setOpenRiderModal(true)
        }

        const RiderModal = () => {
            const [assign] = useMutation(gql` ${assignRider} `, {
                onCompleted: () => {
                    setOpenRiderModal(false)
                },
                onError: e => {
                    window.alert(errorHandler(e, t))
                    setOpenRiderModal(false)
                },
                refetchQueries: [{ query: gql`${getActiveOrders}` }]
            })
            const { loading, error, data, } = useQuery(gql` ${getRidersByZone} `, {
                variables: { id: selectedOrder.zone._id }
            })
            if (loading) return null
            if (error) {
                console.log(error)
                return null
            }

            function onPress(e, riderId) {
                e.preventDefault()
                assign({ variables: { id: selectedOrder._id, riderId: riderId } })
            }

            const selected = (riderId: string) => selectedOrder.rider && selectedOrder.rider._id === riderId

            return (
                <ListGroup>
                    {data && data.ridersByZone && data.ridersByZone.map(rider => (
                        <ListGroupItem
                            key={rider._id}
                            onClick={e => onPress(e, rider._id)}
                            style={{ cursor: "pointer" }}
                        >
                            {selected(rider._id) ? rider.name + "を解除" : rider.name}
                        </ListGroupItem>
                    ))
                    }
                </ListGroup>
            )
        }

        return (
            <div>
                {!["CANCELLED", "DELIVERED"].includes(row.orderStatus) && (
                    <Button
                        color="secondary"
                        className="btn-block"
                        onClick={e => onPress(e, row)}
                        size="sm"
                    >
                        {row.rider ? row.rider.name : t("NotSelected")}
                    </Button>
                )}
                <Modal
                    className="modal-dialog-centered"
                    size="sm"
                    isOpen={openRiderModal}
                    style={{ width: 200 }}
                    toggle={() => { setOpenRiderModal(prev => !prev) }}
                >
                    <RiderModal />
                </Modal>
            </div>
        )
    }

    const CreatedAt = row => <>{dateToJapanese(new Date(row.createdAt))}</>

    const columns = [
        {
            name: t("OrderInformation"),
            sortable: true,
            selector: "orderId",
            // maxWidth: 500,
            cell: row => <OrderInformationCell row={row} />
        },
        {
            name: t("Restaurant"),
            selector: "restaurant.name",
            // maxWidth: 200,
        },
        {
            name: t("Status"),
            selector: "orderStatus",
            cell: OrderStatus,
            maxWidth: "150px",
        },
        {
            name: t("Rider"),
            selector: "rider",
            cell: Rider,
            maxWidth: "150px",
        },
        {
            name: t("OrderTime"),
            cell: CreatedAt,
            maxWidth: "200px",
        }
    ]



    const [openRiderModal, setOpenRiderModal] = useState(false)

    const conditionalRowStyles = [
        {
            when: row => ["DELIVERED", "CANCELLED"].includes(row.orderStatus),
            style: { backgroundColor: "#FDEFDD" }
        }
    ]

    const { loading, error, data, } = useQuery(gql`${getActiveOrders}`, {
        pollInterval: 30000
    })
    if (error) {
        console.log(error)
        return (
            <tr>
                <td>
                    {`${t("Error")}! ${error.message}`}
                </td>
            </tr>
        )
    }
    return (
        <>
            <Header />
            {/* Page content */}
            <Container className="mt--7" fluid>
                {/* Table */}
                <Row>
                    <div className="col">
                        <Card className="shadow">
                            <DataTable
                                title={t("Dispatch")}
                                columns={columns}
                                onRowClicked={toggleOrderDetailModal}
                                data={data && data.getActiveOrders ? data.getActiveOrders : []}
                                progressPending={loading}
                                pointerOnHover
                                progressComponent={<CustomLoader />}
                                pagination
                                conditionalRowStyles={conditionalRowStyles}
                                paginationComponentOptions={paginationComponentOptions}
                                noDataComponent={t("NoData")}
                            />
                        </Card>
                    </div>
                </Row>
            </Container>
            <Modal
                className="modal-dialog-centered"
                size="lg"
                isOpen={selectedOrder && showOrderDetailModal}
                toggle={() => toggleOrderDetailModal(null)}>
                <OrderComponent order={selectedOrder} />
            </Modal>
        </>
    )
}

export default Orders
