import React, { useContext, useEffect, useState } from "react"
import { Container, Badge, Row, Card, Modal } from "reactstrap"
import Header from "../../components/Headers/Header"
import AddonModal from "./AddonModal"
import { deleteAddon, updateAddonOrders } from "../../apollo/server"
import CustomLoader from "../../components/Loader/CustomLoader"
import { useTranslation } from "react-i18next"
import Loader from "react-loader-spinner"
import { getIsDisabled } from "../../utils/getIsDisabled"
import { useMutation, gql } from "@apollo/client"
import { arrayMove } from "../../utils/arrayMove"
import { SortableTable } from "../../components/SortableTable"
import styles from "../styles"
import { getRestaurantQuery, useRestaurantQuery } from "../../domain/graphql/query/useRestaurantQuery"
import { PHONE_BOUNDARY } from "../constants"
import { AppContext } from "../../app"

const DELETE_ADDON = gql`${deleteAddon}`
const UPDATE_ADDON_ORDERS = gql`${updateAddonOrders}`

const Addon = () => {
  const { t } = useTranslation()
  const appContext = useContext(AppContext)
  const userType = appContext.userType
  const restaurantId = appContext.restaurantId
  const [addon, setAddon] = useState(null)
  const [editModal, setEditModal] = useState(false)
  const [modalLoading, setModalLoading] = useState(false)

  const toggleModal = addon => {
    setEditModal(prev => !prev)
    setAddon(addon)
  }

  const ActionButtons = ({ row }) => {
    const [deleteAddon, { loading: deleteLoading }] = useMutation(DELETE_ADDON, {
      refetchQueries: [
        {
          query: getRestaurantQuery,
          variables: { id: restaurantId }
        }
      ],
      onError: e => console.log
    })
    return (
      <>
        <Badge
          style={styles.badgeStyle}
          href="#pablo"
          onClick={e => {
            e.preventDefault()
            toggleModal(row)
          }}
          color="primary">
          {t("Edit")}
        </Badge>
        {"  "}
        {deleteLoading
          ? <Loader
            type="ThreeDots"
            color="#BB2124"
            height={20}
            width={40}
            visible={deleteLoading}
          />
          : <Badge
            style={styles.badgeStyle}
            href="#pablo"
            color="danger"
            onClick={e => {
              e.preventDefault()
              if (getIsDisabled(userType)) return
              if (window.confirm("削除してもよろしいですか？")) {
                deleteAddon({
                  variables: { id: row._id, restaurant: restaurantId }
                })
              }
            }}>
            {t("Delete")}
          </Badge>
        }
      </>
    )
  }

  const { loading, error, data } = useRestaurantQuery(restaurantId)

  const [items, setItems] = useState([])
  useEffect(() => {
    if (data && data.restaurant && data.restaurant.addons) {
      setItems(data.restaurant.addons)
    }
  }, [data])

  const [updateAddonOrders] = useMutation(UPDATE_ADDON_ORDERS, {
    refetchQueries: [
      {
        query: getRestaurantQuery,
        variables: { id: restaurantId }
      }
    ],
    onError: e => console.log
  })

  function onSortEnd({ oldIndex, newIndex }) {
    if (getIsDisabled(userType)) return
    const newItems = arrayMove(items, oldIndex, newIndex)
    const newItemIds = newItems.map(item => item._id)
    setItems(newItems)
    updateAddonOrders({
      variables: {
        restaurantId,
        addonIds: newItemIds
      }
    })
  }

  const columns = window.screen.width > PHONE_BOUNDARY
    ? [
      {
        id: "title",
        name: t("Title"),
        cell: row => <p className="mb-0 text-sm" >{row.title}</p>,
        minWidth: 200,
        maxWidth: 300,
      },
      {
        id: "description",
        name: t("Description"),
        cell: row => <p className="mb-0 text-sm" >{row.description}</p>,
        minWidth: 200,
        maxWidth: 300,
      },
      {
        id: "min",
        name: t("Min"),
        cell: row => <p className="mb-0 text-sm" >{row.quantityMinimum}</p>,
        minWidth: 80,
        maxWidth: 100,
      },
      {
        id: "max",
        name: t("Max"),
        cell: row => <p className="mb-0 text-sm" >{row.quantityMaximum}</p>,
        minWidth: 80,
        maxWidth: 100,
      },
      {
        id: "addonMemo",
        name: t("AddonMemo"),
        cell: row => <p className="mb-0 text-sm" >{row.memo}</p>,
        minWidth: 200,
        maxWidth: 250,
      },
      {
        id: "action",
        name: t("Action"),
        cell: row => <ActionButtons row={row} />,
        minWidth: 100,
        maxWidth: 100,
      }
    ]
    : [
      {
        id: "title",
        name: t("Title"),
        cell: row => <p className="mb-0 text-sm" >{row.title}</p>,
        minWidth: 200,
        maxWidth: 300,
      },
      {
        id: "min",
        name: t("Min"),
        cell: row => <p className="mb-0 text-sm" >{row.quantityMinimum}</p>,
        minWidth: 80,
        maxWidth: 100,
      },
      {
        id: "max",
        name: t("Max"),
        cell: row => <p className="mb-0 text-sm" >{row.quantityMaximum}</p>,
        minWidth: 80,
        maxWidth: 100,
      },
      {
        id: "addonMemo",
        name: t("AddonMemo"),
        cell: row => <p className="mb-0 text-sm" >{row.memo}</p>,
        minWidth: 200,
        maxWidth: 250,
      },
      {
        id: "action",
        name: t("Action"),
        cell: row => <ActionButtons row={row} />,
        minWidth: 100,
        maxWidth: 100,
      }
    ]

  if (error) {
    console.log(error)
    return (
      <div >
        <Header />
        <p style={{ marginTop: 30, textAlign: "center" }}>{`${t("Error")}! ${error.message}`}</p>
      </div>
    )
  }

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <AddonModal
          modalLoading={modalLoading}
          setModalLoading={setModalLoading}
        />
        <Row className="mt-5">
          <div className="col">
            <Card className="shadow">
              {loading
                ? <CustomLoader />
                : <SortableTable
                  items={items}
                  onSortEnd={onSortEnd}
                  columns={columns}
                />
              }
            </Card>
          </div>
        </Row>
        <Modal
          className="modal-dialog-centered"
          size="lg"
          isOpen={editModal}
          toggle={() => { if (!modalLoading) toggleModal(null) }}
        >
          <AddonModal
            addon={addon}
            modalLoading={modalLoading}
            setModalLoading={setModalLoading}
          />
        </Modal>
      </Container>
    </>
  )
}

export default Addon
