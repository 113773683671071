import React, { SetStateAction, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  Row,
  Col,
  UncontrolledAlert
} from 'reactstrap'
import { editCategory, createCategory } from '../../apollo/server'
import { getIsDisabled } from '../../utils/getIsDisabled'
import { useMutation, gql, } from "@apollo/client"
import { getRestaurantQuery } from "../../domain/graphql/query/useRestaurantQuery"
import { AppContext } from '../../app'

const CREATE_CATEGORY = gql`${createCategory}`
const EDIT_CATEGORY = gql`${editCategory}`

interface Props {
  category?: any
  modalLoading: boolean
  setModalLoading: React.Dispatch<SetStateAction<boolean>>
}
const CategoryModal: React.FC<Props> = props => {
  const { t } = useTranslation()
  const appContext = useContext(AppContext)
  const userType = appContext.userType
  const restaurantId = appContext.restaurantId
  const [title, setTitle] = useState(props.category && props.category.title ? props.category.title : "")
  const mutation = props.category ? EDIT_CATEGORY : CREATE_CATEGORY
  const [mainError, mainErrorSetter] = useState('')
  const [success, successSetter] = useState('')

  const clearFields = () => {
    setTitle("")
  }
  const onCompleted = data => {
    const message = props.category
      ? t('UpdateSuccessMessage')
      : t('AddSuccessMessage')
    successSetter(message)
    mainErrorSetter('')
    if (!props.category) clearFields()
    props.setModalLoading(false)
  }
  const onError = error => {
    const message = `${t('ErrorMessage')} ${error}`
    successSetter('')
    mainErrorSetter(message)
    props.setModalLoading(false)
  }


  const SubmitButton = () => {
    const [mutate, { loading, error }] = useMutation(mutation, {
      refetchQueries: [
        {
          query: getRestaurantQuery,
          variables: { id: restaurantId }
        }
      ],
      onCompleted,
      onError,
    })

    if (error) {
      console.log(error)
      return <p>{t("ErrorMessage")}</p>
    }

    function onPressSubmit(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
      e.preventDefault()
      if (getIsDisabled(userType) || loading || props.modalLoading || !title.trim()) return

      props.setModalLoading(true)
      mutate({
        variables: {
          category: {
            _id: props.category
              ? props.category._id
              : '',
            title: title.trim(),
            restaurant: restaurantId
          }
        }
      })
    }

    return (
      <Button
        disabled={loading || props.modalLoading}
        color="primary"
        href="#pablo"
        onClick={onPressSubmit}
        size="md">
        {loading || props.modalLoading ? t("Saving") : t("Save")}
      </Button>
    )
  }

  return (
    <Row>
      <Col className="order-xl-1">
        <Card className="bg-secondary shadow">
          <CardHeader className="bg-white border-0">
            <Row className="align-items-center">
              <Col xs="8">
                <h3 className="mb-0">
                  {props.category ? t('Edit Category') : t('Add Category')}
                </h3>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <form>
              <div className="pl-lg-4">
                <Row>
                  <Col lg="6">
                    <label className="form-control-label" htmlFor="input-title">
                      {t('CategoryTitle')}
                    </label>
                    <FormGroup>
                      <Input
                        autoCapitalize="none"
                        className="form-control-alternative"
                        id="input-title"
                        name="input-title"
                        placeholder={t('CategoryPlaceholder')}
                        type="text"
                        value={title}
                        onChange={e => setTitle(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col className="text-right" xs="12">
                    <SubmitButton />
                  </Col>
                </Row>
                <Row>
                  <Col lg="6">
                    {success && (
                      <UncontrolledAlert color="success" fade={true}>
                        <span className="alert-inner--icon">
                          <i className="ni ni-like-2" />
                        </span>{' '}
                        <span className="alert-inner--text">{success}</span>
                      </UncontrolledAlert>
                    )}
                    {mainError && (
                      <UncontrolledAlert color="danger" fade={true}>
                        <span className="alert-inner--icon">
                          <i className="ni ni-like-2" />
                        </span>{' '}
                        <span className="alert-inner--text">{mainError}</span>
                      </UncontrolledAlert>
                    )}
                  </Col>
                </Row>
              </div>
            </form>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default CategoryModal
