const ONE_DAY = 1000 * 60 * 60 * 24

export const toISOLocaleString = date =>
  `${date.getFullYear()}-${("" + (date.getMonth() + 1)).padStart(2, "0")}-${("" + date.getDate()).padStart(2, "0")}`

export const calcDateDifference = (startDate, endDate) => {
  const copiedStart = new Date(startDate)
  copiedStart.setHours(0, 0, 0, 0)

  const copiedEnd = new Date(endDate)
  copiedEnd.setHours(0, 0, 0, 0)

  const difference = (copiedEnd.getTime() - copiedStart.getTime()) / ONE_DAY
  return difference + 1
}

// ['20210320']の形式の文字列を取得
export const getDateStr = today => `${today.getFullYear()}${('' + (today.getMonth() + 1)).padStart(2, '0')}${('' + today.getDate()).padStart(2, '0')}`
// ['20210320']の形式の文字列からDateオブジェクトを取得
export const getDateFromStr = str => new Date(`${str.slice(0, 4)}-${str.slice(4, 6)}-${str.slice(6)}`)

export const dateToTime: (date: Date) => string =
  date => `${date.getMonth() + 1}/${date.getDate()} ${("" + date.getHours()).padStart(2, "0")}:${("" + date.getMinutes()).padStart(2, "0")}`

export const calcDuration: (date1: Date, date2: Date) => number =
  (date1, date2) => Math.round((date1.getTime() - date2.getTime()) / (1000 * 60))

export const dateToJapanese: (date: Date) => string =
  date => {
    return `${date.getFullYear()}年${date.getMonth() + 1
      }月${date.getDate()}日 ${("" + date.getHours()).padStart(2, "0")}:${("" + date.getMinutes()).padStart(2, "0")}`
  }

export const dateToJpWithDuration: (date1: Date, date2: Date) => string =
  (date1, date2) => {
    const duration = calcDuration(date1, date2)
    return `${duration}分（${("" + date1.getHours()).padStart(2, "0")}:${("" + date1.getMinutes()).padStart(2, "0")}）`
  }
