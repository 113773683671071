import React, { useContext } from 'react'
import RestaurantCard from '../components/Restaurant/Card'
import { withRouter, Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Container, Row } from 'reactstrap'
import Header from '../components/Headers/Header'
import { restaurantByOwner } from '../apollo/server'
import { useQuery, gql, } from "@apollo/client"
import { AppContext } from '../app'

const RESTAURANT_BY_OWNER = gql`
  ${restaurantByOwner}
`

const Restaurant = props => {
  const { t } = useTranslation()
  const appContext = useContext(AppContext)
  const ownerId = appContext.ownerId
  const id = props.location.state ? props.location.state.id : ownerId
  const { loading, error, data } = useQuery(RESTAURANT_BY_OWNER, { variables: { id } })

  if (loading) {
    return <div>{t('Loading')}</div>
  }
  if (error) {
    console.log('error', JSON.stringify(error))
    return (
      <div>
        <span>{t('ErrorMessage')}</span>
      </div>
    )
  }

  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          {data?.restaurantByOwner?.restaurants?.map(restaurant =>
            <Link
              key={restaurant._id}
              onClick={() => appContext.setRestaurantId(restaurant._id)}
              to={'/store_admin/dashboard'}
            >
              <RestaurantCard rest={restaurant} />
            </Link>
          ) ?? []}
        </Row>
      </Container>
    </>
  )
}

export default withRouter(Restaurant)
