import React from "react"
import { Input } from "reactstrap"

interface IProps {
  title: string
  searchKeyword: string
  onSearchKeywordChange: React.ChangeEventHandler<HTMLInputElement>
  placeholder: string
}
export const SearchBar: React.FC<IProps> = props => {
  const {
    title,
    searchKeyword,
    onSearchKeywordChange,
    placeholder,
  } = props

  return (
    <div style={{
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginTop: 20,
      marginBottom: 20,
    }}>
      <h2>{title}</h2>
      <form
        style={{
          width: 400,
          display: "flex",
          alignItems: "center",
        }}
      >
        <Input
          autoCapitalize="none"
          className="form-control-alternative"
          placeholder={placeholder}
          value={searchKeyword}
          onChange={onSearchKeywordChange}
        />
      </form>
    </div>
  )
}