import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  Row,
  Col,
  UncontrolledAlert
} from 'reactstrap'
import { getStoreCategories, editStoreCategory, createStoreCategory } from '../../apollo/server'
import { useMutation, gql, } from "@apollo/client"

const CREATE_STORE_CATEGORY = gql`${createStoreCategory}`
const EDIT_STORE_CATEGORY = gql`${editStoreCategory}`
const GET_STORE_CATEGORIES = gql`${getStoreCategories}`

interface IProps {
  category?: any
  modalLoading: boolean
  setModalLoading: React.Dispatch<React.SetStateAction<boolean>>
}
const StoreCategoryModal: React.FC<IProps> = props => {
  const { t } = useTranslation()
  const [title, setTitle] = useState(props.category ? props.category.title : '')
  const mutation = props.category ? EDIT_STORE_CATEGORY : CREATE_STORE_CATEGORY
  const [mainError, mainErrorSetter] = useState('')
  const [success, successSetter] = useState('')

  const onCompleted = () => {
    const message = props.category
      ? t('UpdateSuccessMessage')
      : t('AddSuccessMessage')
    successSetter(message)
    mainErrorSetter('')
    props.setModalLoading(false)
  }
  const onError = error => {
    const message = `${t('ErrorMessage')} ${error}`
    successSetter('')
    mainErrorSetter(message)
    props.setModalLoading(false)
  }

  const SubmitButton = () => {
    const [mutate, { loading }] = useMutation(mutation, {
      refetchQueries: [
        { query: GET_STORE_CATEGORIES },
      ],
      onCompleted,
      onError,
    })

    function onPressSubmit(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
      e.preventDefault()
      if (loading || props.modalLoading || !title.trim().length) return

      props.setModalLoading(true)
      mutate({
        variables: {
          id: props.category
            ? props.category._id
            : undefined,
          title: title.trim()
        }
      })
    }

    return (
      <Button
        disabled={loading || props.modalLoading}
        color="primary"
        href="#pablo"
        onClick={onPressSubmit}
        size="md"
      >
        {loading || props.modalLoading ? t("Saving") : t("Save")}
      </Button>
    )
  }

  return (
    <Row>
      <Col className="order-xl-1">
        <Card className="bg-secondary shadow">
          <CardHeader className="bg-white border-0">
            <Row className="align-items-center">
              <Col xs="8">
                <h3 className="mb-0">
                  {props.category ? t('Edit Category') : t('Add Category')}
                </h3>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <form>
              <div className="pl-lg-4">
                <Row>
                  <Col lg="6">
                    <label className="form-control-label" htmlFor="input-title">
                      {t('CategoryTitle')}
                    </label>
                    <FormGroup>
                      <Input
                        autoCapitalize="none"
                        className="form-control-alternative"
                        id="input-title"
                        name="input-title"
                        placeholder={t('StoreCategoryPlaceholder')}
                        type="text"
                        value={title}
                        onChange={e => setTitle(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col className="text-right" xs="12">
                    <SubmitButton />
                  </Col>
                </Row>
                <Row>
                  <Col lg="6">
                    {success && (
                      <UncontrolledAlert color="success" fade={true}>
                        <span className="alert-inner--icon">
                          <i className="ni ni-like-2" />
                        </span>{' '}
                        <span className="alert-inner--text">{success}</span>
                      </UncontrolledAlert>
                    )}
                    {mainError && (
                      <UncontrolledAlert color="danger" fade={true}>
                        <span className="alert-inner--icon">
                          <i className="ni ni-like-2" />
                        </span>{' '}
                        <span className="alert-inner--text">{mainError}</span>
                      </UncontrolledAlert>
                    )}
                  </Col>
                </Row>
              </div>
            </form>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default StoreCategoryModal
