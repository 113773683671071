import React, { useState } from 'react'
import { withTranslation } from 'react-i18next'
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  Row,
  Col,
  UncontrolledAlert
} from 'reactstrap'
// core components
import {
  createInfoCarousel,
  editInfoCarousel,
  getInfoCarousels,
} from '../../apollo/server'
import { useMutation, gql, } from "@apollo/client"

const CREATE_INFO = gql`${createInfoCarousel}`
const EDIT_INFO = gql`${editInfoCarousel}`
const GET_INFOS = gql`${getInfoCarousels}`

function InfoCarouselComponent(props) {
  const mutation = props.infoCarousel ? EDIT_INFO : CREATE_INFO
  const [title, setTitle] = useState(props.infoCarousel && props.infoCarousel.title ? props.infoCarousel.title : "")
  const [titleColor, setTitleColor] = useState(props.infoCarousel && props.infoCarousel.titleColor ? props.infoCarousel.titleColor : "#000000")
  const [description, setDescription] = useState(props.infoCarousel && props.infoCarousel.description ? props.infoCarousel.description : "")
  const [descriptionColor, setDescriptionColor] = useState(props.infoCarousel && props.infoCarousel.descriptionColor ? props.infoCarousel.descriptionColor : "#000000")
  const [link, setLink] = useState(props.infoCarousel && props.infoCarousel.link ? props.infoCarousel.link : "")
  const imageUrl = props.infoCarousel && props.infoCarousel.image ? props.infoCarousel.image : ""
  const [imageFile, setImageFile] = useState("")
  const [base64Image, setBase64Image] = useState("")
  const [isAvailable, setIsAvailable] = useState(props.infoCarousel && props.infoCarousel.isAvailable ? props.infoCarousel.isAvailable : true)

  const [mainError, mainErrorSetter] = useState('')
  const [success, successSetter] = useState('')

  const filterImage = event => {
    let images = []
    for (var i = 0; i < event.target.files.length; i++) {
      images[i] = event.target.files.item(i)
    }
    images = images.filter(image => image.name.match(/\.(jpg|jpeg|png|gif)$/))
    return images.length ? images[0] : undefined
  }
  const selectImage = (event, state) => {
    const result = filterImage(event)
    if (result) {
      setImageFile(result)
      imageToBase64(result)
    }
  }
  const imageToBase64 = imgUrl => {
    const fileReader = new FileReader()
    fileReader.onloadend = () => {
      setBase64Image(fileReader.result)
    }
    fileReader.readAsDataURL(imgUrl)
  }

  const clearFields = () => {
    setTitle("")
    setDescription("")
    setLink("")
    setImageFile("")
    setIsAvailable(true)
  }
  const hideAlert = () => {
    mainErrorSetter("")
    successSetter("")
  }
  const { t } = props

  const SubmitButton = () => {
    const onCompleted = data => {
      if (!props.infoCarousel) clearFields()
      const message = props.infoCarousel
        ? t("UpdateSuccessMessage")
        : t("AddSuccessMessage")
      mainErrorSetter("")
      successSetter(message)
      setTimeout(hideAlert, 3000)
    }
    const onError = ({ graphQLErrors, networkError }) => {
      if (graphQLErrors && graphQLErrors.length > 0) {
        mainErrorSetter(graphQLErrors[0].message)
      }
      if (networkError) {
        mainErrorSetter(networkError.result.errors[0].message)
      }
      successSetter("")
      setTimeout(hideAlert, 3000)
    }
    const [mutate, { loading: mutationLoading, error }] = useMutation(mutation, {
      onCompleted,
      onError,
      refetchQueries: [{ query: GET_INFOS }],
    })
    if (error) console.log(error)

    return <Button
      color="primary"
      href="#pablo"
      disabled={mutationLoading}
      onClick={async e => {
        e.preventDefault()
        mutate({
          variables: {
            id: props.infoCarousel ? props.infoCarousel._id : "",
            title: title.trim(),
            titleColor: titleColor.trim(),
            description: description.trim(),
            descriptionColor: descriptionColor.trim(),
            link: link.trim(),
            imageUrl: imageUrl,
            image: imageFile,
            isAvailable
          }
        })
      }}
      size="md">
      {props.rider ? 'Update' : t('Save')}
    </Button>
  }

  return (
    <Row>
      <Col className="order-xl-1">
        <Card className="bg-secondary shadow">
          <CardHeader className="bg-white border-0">
            <Row className="align-items-center">
              <Col xs="8">
                <h3 className="mb-0">
                  {props.infoCarousel ? t("EditInfoCarousel") : t("AddInfoCarousel")}
                </h3>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <form>
              <div className="pl-lg-4">
                <Row>
                  <Col lg="6">
                    <label
                      className="form-control-label"
                      htmlFor="input-title">
                      {t('Title')}
                    </label>
                    <FormGroup>
                      <Input
                        autoCapitalize="none"
                        className="form-control-alternative"
                        id="input-title"
                        name="input-title"
                        placeholder={t("InfoCarouselTitlePH")}
                        type="text"
                        value={title}
                        onChange={e => setTitle(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <label
                      className="form-control-label"
                      htmlFor="input-title-color">
                      {t("TextColor")}
                    </label>
                    <FormGroup>
                      <Input
                        autoCapitalize="none"
                        className="form-control-alternative"
                        id="input-title-color"
                        name="input-title-color"
                        placeholder="#000000"
                        type="text"
                        value={titleColor}
                        onChange={e => setTitleColor(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col lg="6">
                    <label
                      className="form-control-label"
                      htmlFor="input-description">
                      {t("InfoCarouselDescription")}
                    </label>
                    <FormGroup>
                      <Input
                        autoCapitalize="none"
                        className="form-control-alternative"
                        id="input-description"
                        name="input-description"
                        placeholder={t("InfoCarouselDescriptionPH")}
                        type="text"
                        value={description}
                        onChange={e => setDescription(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <label
                      className="form-control-label"
                      htmlFor="input-description-color">
                      {t('SubtitleTextColor')}
                    </label>
                    <FormGroup>
                      <Input
                        autoCapitalize="none"
                        className="form-control-alternative"
                        id="input-description-color"
                        name="input-description-color"
                        placeholder="#000000"
                        type="text"
                        value={descriptionColor}
                        onChange={e => setDescriptionColor(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col lg="6">
                    <label
                      className="form-control-label"
                      htmlFor="input-link">
                      {t("InfoCarouselLink")}
                    </label>
                    <FormGroup>
                      <Input
                        autoCapitalize="none"
                        className="form-control-alternative"
                        id="input-link"
                        name="input-link"
                        placeholder="https://www.link"
                        type="text"
                        value={link}
                        onChange={e => setLink(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <label
                      className="form-control-label"
                      htmlFor="input-isAvailable">
                      {t("Show")}
                    </label>
                    <FormGroup>
                      <label className="custom-toggle mt-3">
                        <input
                          checked={isAvailable}
                          type="checkbox"
                          id="input-isAvailable"
                          name="input-isAvailable"
                          onChange={e => setIsAvailable(e.target.checked)}
                        />
                        <span className="custom-toggle-slider rounded-circle" />
                      </label>
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <h3 className="mb-0"> {t('Food Image')}</h3>
                    <FormGroup>
                      <div className="card-title-image">
                        {(imageFile || imageUrl) && (
                          <a
                            href="#pablo"
                            onClick={e => e.preventDefault()}>
                            <img
                              alt="..."
                              className="rounded-rectangle"
                              src={base64Image ? base64Image : imageUrl ? imageUrl : ""}
                            />
                          </a>
                        )}

                        <input
                          className="mt-4"
                          type="file"
                          onChange={event => {
                            selectImage(event, 'imgMenu')
                          }}
                        />
                      </div>
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col lg="6">
                    {success && (
                      <UncontrolledAlert color="success" fade={true}>
                        <span className="alert-inner--text">
                          {success}
                        </span>
                      </UncontrolledAlert>
                    )}
                    {mainError && (
                      <UncontrolledAlert color="danger" fade={true}>
                        <span className="alert-inner--text">
                          {mainError}
                        </span>
                      </UncontrolledAlert>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col className="text-right" lg="6">
                    <SubmitButton />
                  </Col>
                </Row>
              </div>
            </form>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}
export default withTranslation()(InfoCarouselComponent)
