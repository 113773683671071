import React, { Dispatch, SetStateAction, } from "react"
import {
  Card,
  CardBody,
  Row,
  FormGroup,
  Input,
  Col,
  ButtonGroup,
  Button,
} from "reactstrap"
import { toISOLocaleString } from "../../utils/date"
import { useTranslation } from "react-i18next"


interface Props {
  isAdmin: boolean
  mode: "order" | "store"
  setMode: Dispatch<SetStateAction<"order" | "store">>
  startDate: Date
  setStartDate: Dispatch<SetStateAction<Date>>
  endDate: Date
  setEndDate: Dispatch<SetStateAction<Date>>
}
const FormItems: React.FC<Props> = props => {
  const { t } = useTranslation()

  return (
    <Card className="mb-4 shadow">
      <CardBody>
        <Row>
          {props.isAdmin &&
            <Col xl="4">
              <FormGroup>
                <label className="form-control-label"> {t("SalesBoardMode")} </label>
                <br />
                <ButtonGroup>
                  <Button
                    color={props.mode === "order" ? "primary" : "secondary"}
                    onClick={() => props.setMode("order")}
                  >{t("Order")}</Button>
                  <Button
                    color={props.mode === "store" ? "primary" : "secondary"}
                    onClick={() => props.setMode("store")}
                  >{t("Store")}</Button>
                </ButtonGroup>
              </FormGroup>
            </Col>
          }


          <Col xl="4">
            <label className="form-control-label"> {t("Starting Date")} </label>
            <br />
            <Input
              className="form-control-alternative"
              type="date"
              min={"2021-04-01"}
              max={toISOLocaleString(new Date())}
              onChange={event => props.setStartDate(new Date(event.target.value))}
              value={toISOLocaleString(props.startDate)}
            />
          </Col>

          <Col xl="4">
            <label className="form-control-label"> {t("Ending Date")} </label>
            <br />
            <Input
              className="form-control-alternative"
              type="date"
              min={"2021-04-01"}
              max={toISOLocaleString(new Date())}
              onChange={event => props.setEndDate(new Date(event.target.value))}
              value={toISOLocaleString(props.endDate)}
            />
          </Col>


          {/* <Col xl="1">
                <FormGroup>
                  <label className="form-control-label" > CSV </label>
                  <br />
                  <Button color="primary" onClick={download} >DL</Button>
                </FormGroup>
              </Col> */}
        </Row>
      </CardBody>
    </Card>
  )
}

export default FormItems

