import React from "react"
import { useTranslation } from "react-i18next"
import { StoreSalesboardUserDataQueryResponse } from "../../../domain/graphql/query/useStoreSalesboardUserDataQuery"
import DataTable from "./DataTable"

interface Props {
  userData: StoreSalesboardUserDataQueryResponse[]
  onRowClicked: (e: any) => void
  loading: boolean
}

const Foods: React.FC<Props> = props => {
  const {t} = useTranslation()

  const variations = {}
  props.userData.forEach(user => {
    user.orders.forEach(order => {
      order.items.forEach(item => {
        const key = item.variationId
        if (variations[key]) {
          variations[key].count = variations[key].count + item.quantity
          variations[key].price = variations[key].price + (item.variationStoreProfit * item.quantity)
        } else {
          variations[key] = {
            id: key,
            title: item.foodTitle + (item.variationTitle ? `（${item.variationTitle}）` : ""),
            count: item.quantity,
            price: item.variationStoreProfit * item.quantity
          }
        }
      })
    })
  })

  const columns = [
    {
      selector: "title",
      sortable: true,
      // maxWidth: "600px",
      name: t("Title"),
      cell: row => <>{row.title}</>
    },
    {
      selector: "count",
      name: t("OrderCount"),
      sortable: true,
      width: "100px",
      cell: row => <>{row.count.toLocaleString()}</>
    },
    {
      selector: "price",
      name: t("TotalPrice"),
      sortable: true,
      width: "150px",
      cell: row => <>{row.price.toLocaleString()}</>
    },
  ]

  return (
    <DataTable
      title={t("FOOD")}
      columns={columns}
      data={Object.values(variations)}
      loading={props.loading}
      onRowClicked={props.onRowClicked}
    />
  )
}
export default Foods
