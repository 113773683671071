import React, { useContext, useEffect } from "react"
import { useTranslation } from "react-i18next"
import DataTable from "react-data-table-component"
import orderBy from "lodash/orderBy"
import CustomLoader from "../Loader/CustomLoader"
import { subscribePlaceOrder, orderCount } from "../../apollo/server"
import { Media } from "reactstrap"
import { paginationComponentOptions } from "../../variables/table"
import { convertOrderStatus } from "../../utils/convertOrderStatus"
import { paging } from "../../utils/pagination"
import { gql, useQuery } from "@apollo/client"
import { dateToJapanese } from "../../utils/date"
import { AppContext } from "../../app"

const ORDERCOUNT = gql`
  ${orderCount}
`
const ORDER_PLACED = gql`
  ${subscribePlaceOrder}
`

const OrdersData = props => {
  console.log("OrdersData")
  const {
    selected,
    updateSelected,
    orders,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
  } = props
  const {t} = useTranslation()
  const appContext = useContext(AppContext)
  const restaurantId = appContext.restaurantId

  const getItems = items => {
    return items
      .map(item => ` ${item.title}(${item.variation.title}) x ${item.quantity}`)
      .join("\n")
  }

  const propExists = (obj, path) => {
    return path.split(".").reduce((obj, prop) => {
      return obj && obj[prop] ? obj[prop] : ""
    }, obj)
  }

  const customSort = (rows, field, direction) => {
    const handleField = row => {
      if (field && isNaN(propExists(row, field))) {
        return propExists(row, field).toLowerCase()
      }

      return row[field]
    }

    return paging(orderBy(orders, handleField, direction), page, rowsPerPage)
  }

  const handleSort = (column, sortDirection) => console.log(column.selector, sortDirection)

  const handlePerRowsChange = (perPage, page) => {
    setPage(page)
    setRowsPerPage(perPage)
  }

  const handlePageChange = page => {
    setPage(page)
  }

  const columns = [
    {
      name: t("OrderID"),
      cell: row => (
        <Media>
          <span className="mb-0 text-sm">{row.orderId}</span>
        </Media>
      )
    },
    {
      name: t("Items"),
      cell: row => <>{getItems(row.items)}</>
    },
    {
      name: t("Status"),
      selector: "orderStatus",
      sortable: true,
      cell: row => <>{convertOrderStatus(row.orderStatus)}</>
    },
    {
      name: t("Datetime"),
      selector: "createdAt",
      sortable: true,
      cell: row => <>{dateToJapanese(new Date(row.createdAt))}</>
    },
    {
      name: t("UpdateDatetime"),
      selector: "updatedAt",
      sortable: true,
      cell: row => <>{dateToJapanese(new Date(row.updatedAt))}</>
    },
  ]

  const conditionalRowStyles = [
    {
      when: row =>
        row.orderStatus !== "DELIVERED" && row.orderStatus !== "CANCELLED",
      style: {
        backgroundColor: "rgba(240, 173, 78,0.2)"
      }
    }
  ]
  useEffect(() => {
    props.subscribeToMore({
      document: ORDER_PLACED,
      variables: { restaurant: restaurantId },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev
        if (subscriptionData.data.subscribePlaceOrder.origin === "new") {
          return {
            ordersByRestId: [
              subscriptionData.data.subscribePlaceOrder.order,
              ...prev.ordersByRestId
            ]
          }
        } else {
          const orderIndex = prev.ordersByRestId.findIndex(
            o => subscriptionData.data.subscribePlaceOrder.order._id === o._id
          )
          prev.ordersByRestId[orderIndex] =
            subscriptionData.data.subscribePlaceOrder.order
          return { ordersByRestId: [...prev.ordersByRestId] }
        }
      },
      onError: error => {
        console.log("onError", error)
      }
    })
  }, [props, restaurantId])
  useEffect(() => {
    if (selected) {
      const order = orders.find(o => o._id === selected._id)
      updateSelected(order)
    }
  }, [orders, selected, updateSelected])

  const { loading, error, data } = useQuery(ORDERCOUNT, { variables: { restaurant: restaurantId } })
  if (error) {
    return (
      <tr>
        <td>
          {`${t("Error")}! ${error.message}`}
        </td>
      </tr>
    )
  }
  return (
    <DataTable
      title={t("Orders")}
      columns={columns}
      data={orders}
      onRowClicked={props.toggleModal}
      progressPending={props.loading || loading}
      pointerOnHover
      progressComponent={<CustomLoader />}
      onSort={handleSort}
      sortFunction={customSort}
      subHeader
      pagination
      paginationServer
      paginationTotalRows={data && data.orderCount ? data.orderCount : 0}
      onChangeRowsPerPage={handlePerRowsChange}
      onChangePage={handlePageChange}
      conditionalRowStyles={conditionalRowStyles}
      paginationComponentOptions={paginationComponentOptions}
      noDataComponent={t("NoData")}
      defaultSortField="updatedAt"
      defaultSortAsc={false}
    />
  )
}
export default OrdersData
