import { ApolloError, gql, PureQueryOptions, useMutation, } from "@apollo/client";

export const deleteOptionMutation = gql`mutation ($id: String!, $restaurantId: String!) {
  deleteOption(id: $id, restaurantId: $restaurantId)
}`



export type DeleteOptionMutationInput = {
  id: string;
  restaurantId: string;
}


export type DeleteOptionResponse = {
  deleteOption: boolean
}

export function useDeleteOption(config: {
  variables: DeleteOptionMutationInput,
  refetchQueries: (string | PureQueryOptions)[],
  onCompleted?: (data: DeleteOptionResponse) => void,
  onError: (error: ApolloError) => void,
}) {

  return useMutation<DeleteOptionResponse, DeleteOptionMutationInput>(deleteOptionMutation, {
    variables: config.variables,
    refetchQueries: config.refetchQueries,
    onCompleted: config.onCompleted,
    onError: config.onError,
  })

}

