import { useQuery } from "@apollo/client"
import gql from "graphql-tag"

const salesboardStatisticsQuery = gql`query ($start: Float!, $end: Float!, $dayCount: Int!, $orderStatus: String, $riderId: String) {
  salesboardStatistics(start:$start, end:$end, dayCount:$dayCount, orderStatus:$orderStatus, riderId:$riderId) {
    count
    receipt
    pay
    sales
    averageReceipt
    averagePay
    averageSales
    averageCount
  }
}`

type Variables = {
  start: number
  end: number
  dayCount: number
  orderStatus?: string
  riderId?: string
}
export type SalesboardStatisticsQueryResponse = {
  count: number
  receipt: number
  pay: number
  sales: number
  averageReceipt: number
  averagePay: number
  averageSales: number
  averageCount: number
}

type Response = {
  salesboardStatistics?: SalesboardStatisticsQueryResponse
}
export function useSalesboardStatistics(start: number, end: number, dayCount: number, orderStatus?: string, riderId?: string) {
  return useQuery<Response, Variables>(salesboardStatisticsQuery, { 
    variables: { 
      start, 
      end,
      dayCount,
      orderStatus,
      riderId,
    } })
}