import React from "react"
import { useTranslation } from "react-i18next"
import { StoreSalesboardUserDataQueryResponse } from "../../../domain/graphql/query/useStoreSalesboardUserDataQuery"
import DataTable from "./DataTable"

interface Props {
  userData: StoreSalesboardUserDataQueryResponse[]
  onRowClicked: (e: any) => void
  loading: boolean
}

const Options: React.FC<Props> = props => {
  const {t} = useTranslation()

  const options = {}
  props.userData.forEach(user =>
    user.orders.forEach(order =>
      order.items.forEach(item =>
        item.addons.forEach(addon =>
          addon.options.forEach(option => {
            const key = option.optionId
            if (options[key]) {
              options[key].count = options[key].count + item.quantity
              options[key].price = options[key].price + (option.optionStoreProfit * item.quantity)
            } else {
              options[key] = {
                id: key,
                title: `${addon.addonTitle} （${option.optionTitle}）`,
                count: item.quantity,
                price: option.optionStoreProfit * item.quantity
              }
            }
          })
        ))))

  const columns = [
    {
      selector: "title",
      sortable: true,
      // maxWidth: "600px",
      name: t("Title"),
      cell: row => <>{row.title}</>
    },
    {
      selector: "count",
      name: t("OrderCount"),
      sortable: true,
      width: "100px",
      cell: row => <>{row.count.toLocaleString()}</>
    },
    {
      selector: "price",
      name: t("TotalPrice"),
      sortable: true,
      width: "150px",
      cell: row => <>{row.price.toLocaleString()}</>
    },
  ]

  return (
    <DataTable
      title={t("Option")}
      columns={columns}
      data={Object.values(options)}
      loading={props.loading}
      onRowClicked={props.onRowClicked}
    />
  )
}
export default Options
