import { gql, useQuery } from "@apollo/client"

export const getCategoryMasterQuery = gql`query ($id:String!) {
  categoryMasters(id:$id) {
    _id
    title
    foods {
      _id
      title
      description
      image
      isActive
      variations {
        _id
        title
        storeProfit
        janCode
      }
    }
  }
}`

export type CategoryMaster = {
    _id: string
    title: string
    foods: FoodMaster[]
}
export type FoodMaster = {
  _id: string
  title: string
  description: string
  variations: VariationMaster[]
  image: string
  isActive: boolean
}

export type VariationMaster = {
  _id: string
  title: string
  storeProfit: number
  janCode: string
}

type CategoryMasterQueryInput = {
  id: string
}

type Response = undefined | {
  categoryMasters?: CategoryMaster[]
} 

export function useCategoryMasterQuery(id: string) {
  return useQuery<Response, CategoryMasterQueryInput>(getCategoryMasterQuery, {
    variables: { id: id },
  })
}