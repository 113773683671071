import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Row, Col, Card, CardHeader, FormGroup, Button } from 'reactstrap'
import { validateFunc } from '../../constraints/constraints'
import { Typeahead } from 'react-bootstrap-typeahead'
import { stripeCurrencies } from '../../config/currencies'
import { saveCurrencyConfiguration } from '../../apollo/server'
import { useMutation, gql, } from "@apollo/client"

const SAVE_CURRENCY_CONFIGURATION = gql`
  ${saveCurrencyConfiguration}
`

function Currency(props) {
  const { t } = useTranslation()
  const [currencyCode, currencyCodeSetter] = useState(props.currencyCode ?? "")
  const [currencySymbol, currencySymbolSetter] = useState(props.currencySymbol ?? "")

  const [currencyCodeError, currencyCodeErrorSetter] = useState(null)
  const [currencySymbolError, currencySymbolErrorSetter] = useState(null)

  const validateInput = () => {
    const currencyCodeError = !validateFunc(
      { currencyCode: currencyCode },
      'currencyCode'
    )
    const currencySymbolError = !validateFunc(
      { currencySymbol: currencySymbol },
      'currencySymbol'
    )
    currencyCodeErrorSetter(currencyCodeError)
    currencySymbolErrorSetter(currencySymbolError)
    return currencyCodeError && currencySymbolError
  }
  const onCompleted = data => {
    console.log(data)
  }
  const onError = error => {
    console.log(error)
  }

  const currencyCodes = stripeCurrencies.map(val => val.currency)
  const currencySymbols = stripeCurrencies.map(val => val.currencySymbol)

  const SubmitButton = () => {
    const [saveConfiguration, { loading, error }] = useMutation(SAVE_CURRENCY_CONFIGURATION, {
      onCompleted,
      onError,
    })
    if (error) return <p>{t("Error")}</p>

    return (
      <Button
        className="btn-block mb-2"
        type="button"
        color="primary"
        onClick={e => {
          e.preventDefault()
          if (!loading && validateInput()) {
            saveConfiguration({
              variables: {
                configurationInput: {
                  currency: currencyCode,
                  currencySymbol: currencySymbol
                }
              }
            })
          }
        }}
        size="lg">
        {loading ? t('Saving') : t('Save')}
      </Button>
    )
  }

  return (
    <Row className="mt-3">
      <div className="col">
        <Card className="shadow">
          <CardHeader className="border-0">
            <h3 className="mb-0">{t('Currency')}</h3>
          </CardHeader>

          <Row className="ml-3 mr-3" style={{ display: "flex", alignItems: "center" }}>
            <Col md="5">
              <label className="form-control-label" htmlFor="input-orderid">
                {t('Currency Code')}
              </label>
              <FormGroup
                className={
                  currencyCodeError === null
                    ? ''
                    : currencyCodeError
                      ? 'has-success'
                      : 'has-danger'
                }>
                <Typeahead
                  defaultSelected={[currencyCode || '']}
                  onInputChange={currencyCodeSetter}
                  labelKey="currencyCode"
                  options={currencyCodes}
                  placeholder={t('Currency Code')}
                  id="CurrencyCode"
                  onChange={values => currencyCodeSetter(values[0])}
                />
              </FormGroup>
            </Col>
            <Col md="5">
              <label className="form-control-label" htmlFor="input-orderid">
                {t('Currency Symbol')}
              </label>
              <FormGroup
                className={
                  currencySymbolError === null
                    ? ''
                    : currencySymbolError
                      ? 'has-success'
                      : 'has-danger'
                }>
                <Typeahead
                  defaultSelected={[currencySymbol || '']}
                  onInputChange={value => {
                    currencySymbolSetter(value)
                  }}
                  labelKey="currencySymbol"
                  options={currencySymbols}
                  placeholder={t('Currency Symbol')}
                  id="CurrencySymbol"
                  onChange={values => currencySymbolSetter(values[0])}
                />
              </FormGroup>
            </Col>
            <Col md="2">
              <SubmitButton />
            </Col>
          </Row>
        </Card>
      </div>
    </Row>
  )
}

export default Currency
