import React, { useState } from 'react'
import { withTranslation } from 'react-i18next'
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
  UncontrolledAlert
} from 'reactstrap'

import { deleteUser } from '../apollo/server'
import { validateFunc } from '../constraints/constraints'
import { useMutation, gql, } from "@apollo/client"

const DELETE_USER = gql`
  ${deleteUser}
`

const DeleteUser = props => {
  const { t } = props
  const [stateData, setStateData] = useState({
    email: '',
    password: '',
    emailError: null,
    passwordError: null,
    error: null,
  })

  const validate = () => {
    const emailError = !validateFunc({ email: stateData.email }, 'email')
    const passwordError = !validateFunc(
      { password: stateData.password },
      'password'
    )
    setStateData({ ...stateData, emailError, passwordError })
    return emailError && passwordError
  }

	const [deleteUser] = useMutation(DELETE_USER, {
		onCompleted: () => {
			setStateData({
				email: '',
				password: '',
				emailError: null,
				passwordError: null,
				error: null,
			})
			window.alert("アカウントの削除が完了しました。")
		},
		onError: (e) => {
			console.log(JSON.stringify(e))
			setStateData({
				...stateData,
				error: t('DeleteUserErrorMessage')
			})
		},
	})

  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-transparent pb-5">
            <div className="text-muted text-center mt-2 mb-3">
              <small>{t('DeleteUser')}</small>
            </div>
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-5">
            <Form role="form">
              <FormGroup
                className={
                  stateData.emailError === null
                    ? ''
                    : stateData.emailError
                      ? 'has-success'
                      : 'has-danger'
                }>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    autoCapitalize="none"
                    value={stateData.email}
                    onChange={event => {
                      setStateData({ ...stateData, email: event.target.value })
                    }}
                    placeholder={t('Email')}
                    type="email"
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup
                className={
                  stateData.passwordError === null
                    ? ''
                    : stateData.passwordError
                      ? 'has-success'
                      : 'has-danger'
                }>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    value={stateData.password}
                    onChange={event => {
                      setStateData({
                        ...stateData,
                        password: event.target.value
                      })
                    }}
                    placeholder={t('Password')}
                    type="password"
                  />
                </InputGroup>
              </FormGroup>

              <div className="text-center">
                <Button
                  className="my-4"
                  color="danger"
                  type="button"
                  onClick={() => {
                    setStateData({
                      ...stateData,
                      emailError: null,
                      passwordError: null
                    })
                    if (validate() && window.confirm(t('DeleteUserConfirmation'))) {
                      deleteUser({ variables: { email: stateData.email.trim(), password: stateData.password.trim() } })
                    }
                  }}>
                  {t('DeleteUser')}
                </Button>
              </div>
              {stateData.error && (
                <UncontrolledAlert color="danger" fade={true}>
                  <span className="alert-inner--text">{stateData.error}</span>
                </UncontrolledAlert>
              )}
            </Form>
          </CardBody>
        </Card>
      </Col>
    </>
  )
}

export default withTranslation()(DeleteUser)
