import React, { useState } from "react"
import { validateFunc } from "../../constraints/constraints"
import { withTranslation } from "react-i18next"
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Input,
    Row,
    Col,
    UncontrolledAlert
} from "reactstrap"
// core components
import {
    createRider,
    editRider,
    getRiders,
    getZones,
    getAvailableRiders
} from "../../apollo/server"
import { useMutation, useQuery, gql, } from "@apollo/client"

const CREATE_RIDER = gql`
  ${createRider}
`
const EDIT_RIDER = gql`
  ${editRider}
`
const GET_RIDERS = gql`
  ${getRiders}
`
const GET_ZONES = gql`
  ${getZones}
`
const GET_AVAILABLE_RIDERS = gql`
  ${getAvailableRiders}
`

function Rider(props) {
    const mutation = props.rider ? EDIT_RIDER : CREATE_RIDER
    const [name, setName] = useState(props.rider && props.rider.name ? props.rider.name : "")
    const [username, setUsername] = useState(props.rider && props.rider.username ? props.rider.username : "")
    const [password, setPassword] = useState(props.rider && props.rider.password ? props.rider.password : "")
    const [phone, setPhone] = useState(props.rider && props.rider.phone ? props.rider.phone : "")
    const [displayPhone, setDisplayPhone] = useState(props.rider && props.rider.displayPhone ? props.rider.displayPhone : "")
    const [email, setEmail] = useState(props.rider && props.rider.email ? props.rider.email : "")
    const [available, setAvailable] = useState(props.rider && props.rider.available ? props.rider.available : true)
    const [zone, setZone] = useState(props.rider && props.rider.zone && props.rider.zone._id ? props.rider.zone._id : "")

    const [mainError, mainErrorSetter] = useState("")
    const [success, successSetter] = useState("")
    const [nameError, nameErrorSetter] = useState(null)
    const [usernameError, usernameErrorSetter] = useState(null)
    const [passwordError, passwordErrorSetter] = useState(null)
    const [phoneError, phoneErrorSetter] = useState(null)
    const [emailError, emailErrorSetter] = useState(null)
    const [zoneError, zoneErrorSetter] = useState(null)

    // const onBlur = (setter, field, state) => {
    //   setter(!validateFunc({ [field]: state }, field))
    // }
    const onSubmitValidaiton = () => {
        const nameError = !validateFunc(
            { name: name.trim() },
            "name"
        )
        const usernameError = !validateFunc(
            { username: username.trim() },
            "username"
        )
        const passwordError = !validateFunc(
            { password: password.trim() },
            "password"
        )
        const phoneError = !validateFunc(
            { phone: phone.trim() },
            "phone"
        )
        const emailError = !validateFunc(
            { email: email.trim() },
            "email"
        )
        const zoneError = !validateFunc(
            { zone: zone },
            "zone"
        )

        nameErrorSetter(nameError)
        usernameErrorSetter(usernameError)
        phoneErrorSetter(phoneError)
        emailErrorSetter(emailError)
        passwordErrorSetter(passwordError)
        zoneErrorSetter(zoneError)
        return (
            nameError && usernameError && phoneError && emailError && passwordError && zoneError
        )
    }
    const clearFields = () => {
        setName("")
        setUsername("")
        setPassword("")
        setPhone("")
        setEmail("")
        setAvailable(true)
        setZone("")
        nameErrorSetter(null)
        usernameErrorSetter(null)
        passwordErrorSetter(null)
        phoneErrorSetter(null)
        emailErrorSetter(null)
        zoneErrorSetter(null)
    }
    const hideAlert = () => {
        mainErrorSetter("")
        successSetter("")
    }
    const { t } = props

    const SubmitButton = () => {
        const onCompleted = data => {
            if (!props.rider) clearFields()
            const message = props.rider
                ? t("UpdateSuccessMessage")
                : t("AddSuccessMessage")
            mainErrorSetter("")
            successSetter(message)
            setTimeout(hideAlert, 5000)
        }
        const onError = ({ graphQLErrors, networkError }) => {
            if (graphQLErrors) {
                mainErrorSetter(graphQLErrors[0].message)
            }
            if (networkError) {
                mainErrorSetter(networkError.result.errors[0].message)
            }
            successSetter("")
            setTimeout(hideAlert, 5000)
        }
        const [mutate, { loading: mutationLoading, error }] = useMutation(mutation, {
            onCompleted,
            onError,
            refetchQueries: [
                { query: GET_RIDERS },
                { query: GET_AVAILABLE_RIDERS }
            ],
        })
        if (error) console.log(error)

        return <Button
            color="primary"
            href="#pablo"
            disabled={mutationLoading}
            onClick={async e => {
                e.preventDefault()
                if (onSubmitValidaiton()) {
                    mutate({
                        variables: {
                            riderInput: {
                                _id: props.rider ? props.rider._id : "",
                                name: name.trim(),
                                username: username.trim(),
                                password: password.trim(),
                                phone: phone.trim(),
                                displayPhone: displayPhone.trim(),
                                email: email.trim(),
                                zone: zone,
                                available: available
                            }
                        }
                    })
                }
            }}
            size="md">
            {props.rider ? "Update" : t("Save")}
        </Button>
    }

    const { data, loading, error } = useQuery(GET_ZONES)
    if (loading) return "Loading...."
    if (error) return t("Error")
    return (
        <Row>
            <Col className="order-xl-1">
                <Card className="bg-secondary shadow">
                    <CardHeader className="bg-white border-0">
                        <Row className="align-items-center">
                            <Col xs="8">
                                <h3 className="mb-0">
                                    {props.rider ? t("Edit Rider") : t("Add Rider")}
                                </h3>
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        <form>
                            <div className="pl-lg-4">
                                <Row>
                                    <Col lg="6">
                                        <label
                                            className="form-control-label"
                                            htmlFor="input-name">
                                            {t("Name")}
                                        </label>
                                        <FormGroup
                                            className={
                                                nameError === null
                                                    ? ""
                                                    : nameError
                                                        ? "has-success"
                                                        : "has-danger"
                                            }>
                                            <Input
                                                autoCapitalize="none"
                                                className="form-control-alternative"
                                                id="input-name"
                                                name="input-name"
                                                placeholder={t("SampleNamePH")}
                                                type="text"
                                                value={name}
                                                onChange={e => setName(e.target.value)}
                                            // onBlur={event => {
                                            //   onBlur(
                                            //     nameErrorSetter,
                                            //     "name",
                                            //     event.target.value
                                            //   )
                                            // }}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col lg="6">
                                        <label
                                            className="form-control-label"
                                            htmlFor="input-username">
                                            {t("Username")}
                                        </label>
                                        <FormGroup
                                            className={
                                                usernameError === null
                                                    ? ""
                                                    : usernameError
                                                        ? "has-success"
                                                        : "has-danger"
                                            }>
                                            <Input
                                                autoCapitalize="none"
                                                className="form-control-alternative"
                                                id="input-username"
                                                name="input-userName"
                                                placeholder={t("SampleUsernamePH")}
                                                type="text"
                                                value={username}
                                                onChange={e => setUsername(e.target.value)}
                                            // onBlur={event =>
                                            //   onBlur(
                                            //     usernameErrorSetter,
                                            //     "username",
                                            //     event.target.value
                                            //   )
                                            // }
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="6">
                                        <label
                                            className="form-control-label"
                                            htmlFor="input-phone">
                                            {t("Phone")}
                                        </label>
                                        <FormGroup
                                            className={
                                                phoneError === null
                                                    ? ""
                                                    : phoneError
                                                        ? "has-success"
                                                        : "has-danger"
                                            }>
                                            <Input
                                                autoCapitalize="none"
                                                className="form-control-alternative"
                                                id="input-phone"
                                                name="input-phone"
                                                placeholder="09000000000"
                                                type="number"
                                                value={phone}
                                                onChange={e => setPhone(e.target.value)}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md="6">
                                        <label
                                            className="form-control-label"
                                            htmlFor="input-display-phone">
                                            {t("DisplayPhone")}
                                        </label>
                                        <FormGroup
                                            className={
                                                phoneError === null
                                                    ? ""
                                                    : phoneError
                                                        ? "has-success"
                                                        : "has-danger"
                                            }>
                                            <Input
                                                autoCapitalize="none"
                                                className="form-control-alternative"
                                                id="input-display-phone"
                                                name="input-display-phone"
                                                placeholder="09000000000"
                                                type="number"
                                                value={displayPhone}
                                                onChange={e => setDisplayPhone(e.target.value)}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="6">
                                        <label
                                            className="form-control-label"
                                            htmlFor="input-password">
                                            {t("Password")}
                                        </label>
                                        <FormGroup
                                            className={
                                                passwordError === null
                                                    ? ""
                                                    : passwordError
                                                        ? "has-success"
                                                        : "has-danger"
                                            }>
                                            <Input
                                                autoCapitalize="none"
                                                className="form-control-alternative"
                                                id="input-password"
                                                name="input-password"
                                                placeholder="samplepassword"
                                                type="text"
                                                value={password}
                                                onChange={e => setPassword(e.target.value)}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md="6">
                                        <label
                                            className="form-control-label"
                                            htmlFor="input-email">
                                            {t("Email")}
                                        </label>
                                        <FormGroup
                                            className={
                                                emailError === null
                                                    ? ""
                                                    : emailError
                                                        ? "has-success"
                                                        : "has-danger"
                                            }>
                                            <Input
                                                autoCapitalize="none"
                                                className="form-control-alternative"
                                                id="input-email"
                                                name="input-email"
                                                placeholder="sample@email.com"
                                                type="email"
                                                value={email}
                                                onChange={e => setEmail(e.target.value)}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="6">
                                        <label
                                            className="form-control-label"
                                            htmlFor="input-category">
                                            {t("Zone")}
                                        </label>
                                        <FormGroup
                                            className={
                                                zoneError === null
                                                    ? ""
                                                    : zoneError
                                                        ? "has-success"
                                                        : "has-danger"
                                            }>
                                            <Input
                                                type="select"
                                                id="exampleSelect"
                                                name="input-zone"
                                                value={zone}
                                                onChange={e => setZone(e.target.value)}
                                            >
                                                {!zone && (
                                                    <option value={""}>{t("Select")}</option>
                                                )}
                                                {data && data.zones && data.zones.map(zone => (
                                                    <option value={zone._id} key={zone._id}>
                                                        {zone.title}
                                                    </option>
                                                ))}
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                    <Col lg="6">
                                        <label
                                            className="form-control-label"
                                            htmlFor="input-available">
                                            {t("Available")}
                                        </label>
                                        <FormGroup>
                                            <label className="custom-toggle">
                                                <input
                                                    checked={available}
                                                    type="checkbox"
                                                    id="input-available"
                                                    name="input-available"
                                                    onChange={e => setAvailable(e.target.checked)}
                                                />
                                                <span className="custom-toggle-slider rounded-circle" />
                                            </label>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="6">
                                        {success && (
                                            <UncontrolledAlert color="success" fade={true}>
                                                <span className="alert-inner--text">
                                                    {success}
                                                </span>
                                            </UncontrolledAlert>
                                        )}
                                        {mainError && (
                                            <UncontrolledAlert color="danger" fade={true}>
                                                <span className="alert-inner--text">
                                                    {mainError}
                                                </span>
                                            </UncontrolledAlert>
                                        )}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="text-right" lg="6">
                                        <SubmitButton />
                                    </Col>
                                </Row> </div>
                        </form>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    )
}
export default withTranslation()(Rider)
