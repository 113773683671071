/* eslint-disable react/display-name */
import React, { useState } from "react"
import { withTranslation } from "react-i18next"
import DataTable from "react-data-table-component"
import orderBy from "lodash/orderBy"
import CustomLoader from "../../components/Loader/CustomLoader"
import { paginationComponentOptions } from "../../variables/table"
import { paging } from "../../utils/pagination"

const Stores = props => {
  console.log("SalesBoardOrders")
  const {
    allStores,
    loading: propsLoading,
    t,
  } = props

  const [page, setPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(30)
  const stores = paging(allStores, page, rowsPerPage)

  const propExists = (obj, path) => {
    return path.split(".").reduce((obj, prop) => {
      return obj && obj[prop] ? obj[prop] : ""
    }, obj)
  }

  const customSort = (_rows, field, direction) => {
    const handleField = row => {
      if (field && isNaN(propExists(row, field))) {
        if (field === "rider") {
          return row.rider.name
        }
        return propExists(row, field).toLowerCase()
      }

      return row[field]
    }

    return paging(orderBy(allStores, handleField, direction), page, rowsPerPage)
  }

  const handleSort = (column, sortDirection) =>
    console.log(column.selector, sortDirection)

  const handlePerRowsChange = async (perPage, page) => {
    setPage(page)
    setRowsPerPage(perPage)
  }

  const handlePageChange = async page => {
    setPage(page)
  }

  const columns = [
    {
      name: t("StoreName"),
      maxWidth: "400px",
      cell: row => <>{row.name}</>
    },
    {
      name: t("OrderCount"),
      sortable: true,
      selector: "count",
      maxWidth: "200px",
      cell: row => <>{row.count}</>
    },
    {
      name: t("ReceiptPrice"),
      sortable: true,
      selector: "receipt",
      maxWidth: "200px",
      cell: row => <>{row.receipt.toLocaleString()}</>
    },
    {
      name: t("Pay"),
      sortable: true,
      selector: "pay",
      maxWidth: "200px",
      cell: row => <>{row.pay.toLocaleString()}</>
    },
    {
      name: t("Sales"),
      sortable: true,
      selector: "sales",
      maxWidth: "200px",
      cell: row => <>{row.sales.toLocaleString()}</>
    },
  ]

  return (
    <DataTable
      title={t("StoreMode")}
      columns={columns}
      data={stores}
      progressPending={propsLoading}
      pointerOnHover
      progressComponent={<CustomLoader />}
      onSort={handleSort}
      sortFunction={customSort}
      pagination
      paginationServer
      paginationPerPage={30}
      paginationRowsPerPageOptions={[10, 20, 30, 40, 50, 100]}
      paginationTotalRows={stores.length}
      onChangeRowsPerPage={handlePerRowsChange}
      onChangePage={handlePageChange}
      paginationComponentOptions={paginationComponentOptions}
      noDataComponent={""}
      defaultSortField="receipt"
      defaultSortAsc={false}
    />
  )
}
export default withTranslation()(Stores)
