import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ResetPassword from '../ResetPassword/ResetPassword'
// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Navbar,
  Nav,
  Container,
  Media,
  Modal
} from 'reactstrap'
import { AppContext } from '../../app'

function AdminNavbar(props) {
  const appContext = useContext(AppContext)
  const [showRestPasswordModal, setShowResetPasswordModal] = useState(false)
  const toggleResetPasswordModal = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault()
    setShowResetPasswordModal(prev => !prev)
  }
  const { t } = useTranslation()
  const userData = JSON.parse(localStorage.getItem('user-enatega'))
  const isVendor = userData
    ? userData.userType === 'VENDOR' || userData.userType === "AUTHORIZED_VENDOR"
    : false
  return (
    <>
      <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
        <Container fluid>
          <span className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block">
            {props.match.path === '/restaurant' ? '' : t(props.brandText)}
          </span>

          <Nav className="align-items-center d-none d-md-flex" navbar>
            <UncontrolledDropdown nav>
              <DropdownToggle className="pr-0" nav>
                <Media className="align-items-center">
                  <span className="avatar avatar-sm rounded-circle">
                    <img
                      alt="..."
                      src={require('../../assets/img/theme/avatar_300x300.png').default}
                    />
                  </span>
                  <Media className="ml-2 d-none d-lg-block">
                    <span className="mb-0 text-sm font-weight-bold">Admin</span>
                  </Media>
                </Media>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem className="noti-title" header tag="div">
                  <h6 className="text-overflow m-0">{t('Welcome')}!</h6>
                </DropdownItem>
                <DropdownItem divider />
                {isVendor && (
                  <DropdownItem onClick={toggleResetPasswordModal}>
                    <i className="ni ni-key-25" />
                    <span>{t('Change Password')}</span>
                  </DropdownItem>
                )}
                <DropdownItem
                  href="#pablo"
                  onClick={e => {
                    e.preventDefault()
                    localStorage.removeItem('user-enatega')
                    appContext.setRestaurantId("")
                    if (props.client) {
                      props.client.clearStore()
                    }
                    props.history.push('/auth/login')
                  }}>
                  <i className="ni ni-user-run" />
                  <span>{t('Logout')}</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
          <Modal
            className="modal-dialog-centered"
            size="lg"
            isOpen={showRestPasswordModal}
            toggle={toggleResetPasswordModal}>
            <ResetPassword />
          </Modal>
        </Container>
      </Navbar>
    </>
  )
}

export default AdminNavbar
