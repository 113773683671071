const en = {
  translation: {
    appName: 'Mitsuboshi Eats',
    "Back to Admin": "Back to Admin",
    CategoryMaster: "Category Master",
    CSVFile: "CSV File",
    "Don'tHavePermission": "You do not have edit permission",
    DownloadCSV: "Download CSV file",
    FoodMaster: "Food Master",
    ReadFromCSV: "Read from CSV file",
    SelectFile: "Select file",
    'Sign in credentials': 'Sign in credentials',
    'Sign in': 'Sign in',
    SpecialFeatureTitle: "Special Feature Title",
    'Add Category': 'Add Category',
    Title: 'Title',
    Description: 'Description',
    Add: 'Add',
    Remove: 'Remove',
    'Add/Remove': 'Add/Remove',
    Success: 'Success',
    Danger: 'Danger',
    Categories: 'Categories',
    Image: 'Image',
    Header: 'Header',
    Loading: 'Loading',
    Error: 'Error',
    Edit: 'Edit',
    Delete: 'Delete',
    'Edit Category': 'Edit Category',
    Save: 'Save',
    'OrderID prefix': 'OrderID prefix',
    Order: 'Order',
    Email: 'Email',
    Password: 'Password',
    Enable: 'Enable',
    Disable: 'Disable',
    'Enable/Disable': 'Enable/Disable',
    Saving: 'Saving',
    Paypal: 'Paypal',
    'Client ID': 'Client ID',
    'Client Secret': 'Client Secret',
    Sandbox: 'Sandbox',
    'Publishable Key': 'Publishable Key',
    'Secret Key': 'Secret Key',
    'Delivery Charges': 'Delivery Charges',
    Price: 'Price',
    'Add Food': 'Add Food',
    Category: 'Category',
    Select: 'Select',
    Variations: 'Variations',
    Type: 'Type',
    'Food Image': 'Food Image',
    Foods: 'Foods',
    Actions: 'Actions',
    'Edit Food': 'Edit Food',
    OrderID: 'OrderID',
    Orders: 'Orders',
    Name: 'Name',
    Items: 'Items',
    Payment: 'Payment',
    Status: 'Status',
    Review: 'Review',
    'Mark as': 'Mark as',
    Users: 'Users',
    Phone: 'Phone',
    Address: 'Address',
    2019: '2019',
    'About Us': 'About Us',
    Blog: 'Blog',
    Welcome: 'Welcome',
    Logout: 'Logout',
    Dashboard: 'Dashboard',
    Configuration: 'Configuration',
    Login: 'Login',
    Food: 'Food',
    'Character limit of max length 50': 'Character limit of max length 50',
    'Character limit of max length 15': 'Character limit of max length 15',
    'Character limit of max length 60': 'Character limit of max length 60',
    'Character limit of max length 20': 'Character limit of max length 20',
    'Character limit of max length 140': 'Character limit of max length 140',
    Currency: 'Currency',
    'Currency Code': 'Currency Code',
    'Currency Symbol': 'Currency Symbol',
    'Filter Graph': 'Filter Graph',
    'Total Orders': 'Total Orders',
    'Total Sales': 'Total Sales',
    'Starting Date': 'Starting Date',
    'Ending Date': 'Ending Date',
    'Graph Filter Submit': 'Submit',
    'Sales value': 'Sales value',
    'Total orders': 'Total orders',
    CategoryTitle: 'Title',
    CategoryPlaceholder: 'e.g Breakfast',
    'Total Restaurants': 'Total Restaurants',
    StoreName: 'Name',
    OrderPrefix: 'Order Prefix',
    Featured: 'Featured',
    DiscountPercentage: 'Discount %',
    NoData: 'There are no records to display',
    OrderInformation: 'Order Information',
    OrderTime: 'Order time',
    ErrorMessage: 'An error occured,Try again',
    UpdateSuccessMessage: 'updated successfully',
    AddSuccessMessage: 'added successfully',
    FoodnamePH: 'e.g. Food',
    FoodDescriptionPH: 'e.g All happiness depends on leisurely breakfast.',
    'Title must be unqiue': 'Title must be unqiue',
    SampleNamePH: 'e.g John Doe',
    SampleUsernamePH: 'e.g ridername007',
    CouponPH: 'e.g SALE50',
    ZonePH: 'e.g Title',
    ZoneDescriptionPH: 'e.g Description',
    'Set Zone on Map': 'Set Zone on Map',
    'Please set 3 point to make zone': 'Please set 3 point to make zone',
    VariationsPH: 'e.g Small,Medium,Large',
    Min: 'Min',
    Max: 'Max',
    CreateNewAddon: 'New Addon',
    AddonPH: 'e.g Pepsi',
    AddonDescriptionPH: 'e.g optional',
    New: 'New',
    OptionPH: 'e.g Pepsi',
    OptionDescriptionPH: 'e.g Optional',
    CustomerUnitPrice: "Customer Unit Price",
    JanCode: "EAN Code",
    FoodSearchKeywordPlaceholder: "search by title or EAN code",
  }
}

export default en
