/* eslint-disable react/display-name */
import React, { useContext, useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { useTranslation } from "react-i18next"
import CustomLoader from "../components/Loader/CustomLoader"
import { Badge, Card, Container, Row } from "reactstrap"
import Header from "../components/Headers/Header"
import {
  toggleFeatured,
  deactivateRestaurant,
  isCommingSoonRestaurantWEB,
  isNotCommingSoonRestaurantWEB
} from "../apollo/server"
import DataTable from "react-data-table-component"
import orderBy from "lodash/orderBy"
import Loader from "react-loader-spinner"
import { paginationComponentOptions } from "../variables/table"
import { useMutation, gql, } from "@apollo/client"
import styles from "./styles"
import { RestaurantListQueryResponse, restaurantQuery, useRestaurantListQuery } from "../domain/graphql/query/useRestaurantListQuery"
import { AppContext } from "../app"
import { SearchBar } from "../components/SearchBar"

const TOGGLE_FEATURED = gql`${toggleFeatured}`
const IS_COMMING_SOON = gql`${isCommingSoonRestaurantWEB}`
const IS_NOT_COMMING_SOON = gql`${isNotCommingSoonRestaurantWEB}`
const DEACTIVATE = gql`${deactivateRestaurant}`


const Restaurants = () => {
  const { t } = useTranslation()
  const appContext = useContext(AppContext)

  const [searchKeyword, setSearchKeyword] = useState("")
  const refetchQueries = [{ query: restaurantQuery }]
  const history = useHistory()

  const customSort = (rows, field, direction) => {
    const handleField = row => {
      if (row[field]) {
        return row[field].toLowerCase()
      }

      return row[field]
    }

    return orderBy(rows, handleField, direction)
  }

  const columns = [
    {
      name: t("Image"),
      cell: row => (
        <>
          {!!row.image && (
            <img
              className="img-responsive"
              src={row.image}
              alt="img menu"
              onClick={() => {
                appContext.setRestaurantId(row._id)
                appContext.setOwnerId(row.ownerId)
                history.push("/store_admin/dashboard")
              }}
            />
          )}
          {!row.image && "No Image"}
        </>
      ),
      selector: "image",
      width: "100px",
    },
    {
      name: t("OrderPrefix"),
      selector: "orderPrefix",
      width: "100px",
    },
    {
      name: t("Name"),
      sortable: true,
      selector: "name"
    },
    {
      name: t("Address"),
      maxWidth: "400px",
      selector: "address"
    },
    {
      name: t("Featured"),
      width: "80px",
      cell: row => <AvailableStatus row={row} />,
    },
    {
      name: t("isCommingSoon"),
      width: "80px",
      cell: row => <IsCommingSoonFlag row={row} />,
    },
    {
      name: t("Show/Hide"),
      width: "100px",
      cell: row => <ActionButtons row={row} />
    },
    {
      name: t("State"),
      width: "80px",
      cell: row => <>{row.isActive ? t("Show") : t("Hide")}</>
    },
  ]

  const ActionButtons = props => {
    const { row } = props
    const [mutate, { loading }] = useMutation(DEACTIVATE, {
      onError: console.log,
      refetchQueries,
    })

    if (loading) {
      return (
        <Loader
          type="ThreeDots"
          color="#BB2124"
          height={20}
          width={40}
          visible={loading}
        />
      )
    }

    return (
      <Badge
        style={styles.badgeStyle}
        href="#pablo"
        color={row.isActive ? "danger" : "success"}
        onClick={e => {
          e.preventDefault()
          mutate({ variables: { id: row._id } })
        }}>
        {row.isActive ? t("HideBtn") : t("ShowBtn")}
      </Badge>
    )
  }

  const AvailableStatus = props => {
    const { row } = props
    const [on, setOn] = useState(false)

    useEffect(() => {
      setOn(row.featured)
    }, [row])

    const [toggleFeatured] = useMutation(TOGGLE_FEATURED, {
      onError: console.log,
      refetchQueries,
    })

    return (
      <label className="custom-toggle">
        <input
          checked={on}
          type="checkbox"
          onChange={event => {
            setOn(event.target.checked)
            toggleFeatured({ variables: { restaurantId: row._id } })
          }}
        />
        <span className="custom-toggle-slider rounded-circle" />
      </label>
    )
  }

  const IsCommingSoonFlag = ({ row }) => {
    const [on, setOn] = useState(false)
    useEffect(() => {
      setOn(row.isCommingSoon)
    }, [row])

    const [isCommingSoon] = useMutation(IS_COMMING_SOON, {
      onError: console.log,
      refetchQueries,
    })

    const [isNotCommingSoon] = useMutation(IS_NOT_COMMING_SOON, {
      onError: console.log,
      refetchQueries,
    })

    const mutate = row.isCommingSoon ? isNotCommingSoon : isCommingSoon
    return (
      <label className="custom-toggle">
        <input
          checked={on}
          type="checkbox"
          onChange={event => {
            setOn(event.target.checked)
            mutate({ variables: { id: row._id } })
          }}
        />
        <span className="custom-toggle-slider rounded-circle" />
      </label>
    )
  }

  const conditionalRowStyles = [
    {
      when: (row: RestaurantListQueryResponse) => !row.isActive,
      style: {
        backgroundColor: "#ddd"
      }
    },
  ]

  const { loading, error, data: restaurants } = useRestaurantListQuery()
  if (error) {
    return (
      <span>
        {`${t("ErrorMessage")}! ${error.message}`}
      </span>
    )
  }

  function onSearchWordChange(event) {
    event.preventDefault()
    setSearchKeyword(event.target.value)
  }

  const shops = restaurants.filter(res => {
    if (searchKeyword) {
      return res.name.indexOf(searchKeyword) !== -1
    } else {
      return !res.isDeleted
    }
  })

  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row className="mt-5">
          <div className="col">
            <Card className="shadow">

              <div className="ml-4">
                <SearchBar
                  title={t("Restaurants")}
                  onSearchKeywordChange={onSearchWordChange}
                  searchKeyword={searchKeyword}
                  placeholder={t("SearchKeyword")}
                />
              </div>

              <DataTable
                columns={columns}
                data={shops}
                pagination
                progressPending={loading}
                progressComponent={<CustomLoader />}
                onSort={() => { }}
                sortFunction={customSort}
                defaultSortField="name"
                onRowClicked={row => {
                  appContext.setRestaurantId(row._id)
                  appContext.setOwnerId(row.ownerId)
                  history.push("/store_admin/dashboard")
                }}
                conditionalRowStyles={conditionalRowStyles}
                paginationComponentOptions={paginationComponentOptions}
                noDataComponent={t("NoData")}
                paginationPerPage={10}
                paginationRowsPerPageOptions={[10, 20, 30, 40, 50, 100]}
              />
            </Card>
          </div>
        </Row>
      </Container>
    </>
  )
}

export default Restaurants
