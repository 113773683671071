import React from "react"
import { SortableContainer, SortableElement, SortableHandle, } from "react-sortable-hoc"
import { BsList } from "react-icons/bs"

type Value = {
  _id: string;
  isAvailable?: boolean;
  title: string;
}
type Column<T> = {
  id: string;
  name: string;
  minWidth?: number;
  maxWidth: number;
  cell: (value: T) => JSX.Element;
}

interface SortableTableProps<T,U> {
  items: T[];
  columns: U[];
  onSortEnd: (_arg: { oldIndex: number, newIndex: number }) => void;
  storeProfitFilter?: (item: T) => boolean;
}
export function SortableTable<T extends Value, U extends Column<Value>>(props: SortableTableProps<T, U>) {
  return (
    <div style={{
      display: "block",
      overflowX: "scroll",
      whiteSpace: "nowrap",
      margin: 20,
    }}>
      <table>
        <SortableListHeader columns={props.columns} />
        <SortableList
          items={props.items}
          onSortEnd={props.onSortEnd}
          useDragHandle={true}
          columns={props.columns}
          storeProfitFilter={props.storeProfitFilter}
        />
      </table>
    </div>
  )
}

interface SortableListHeaderProps<T> {
  columns: T[];
}
const SortableListHeader = <T extends Column<Value>>(props: SortableListHeaderProps<T>) => (
  <thead>
    <tr style={{ borderBottom: "1px solid #eee", }}>
      <th style={{ padding: 10 }} ><BsList size={25} color="transparent" /></th>
      {props.columns.map((column, index) =>
        <th
          key={column.name ? column.name + index : index }
          style={{ padding: 10, textAlign: "center", }}
        >{column.name ? column.name : ""}</th>)
      }
    </tr>
  </thead>
)


	interface SortableListProps<T, U> {
  items: T[];
  columns: U[];
  storeProfitFilter?: (item: T) => boolean;
}
const SortableList = SortableContainer(<T extends Value, U extends Column<Value>>(props: SortableListProps<T, U>) => {
  return (
    <tbody>
      {props.items.map((value, index) => {
        return (
          <SortableItem
            key={index + value._id}
            index={index}
            value={value}
            columns={props.columns}
            storeProfitFilter={props.storeProfitFilter}
          />
        )
      })}
    </tbody>
  )
})

const DragHandle = SortableHandle(() => <td style={{ padding: 10 }}><BsList size={25} /></td>)

interface SorableItemProps<T, U> {
  value: T;
  columns: U[];
  storeProfitFilter?: (item: T) => boolean;
}
const SortableItem = SortableElement(<T extends Value, U extends Column<Value>>(props: SorableItemProps<T, U>) => {
  const isAvailable = props.columns.reduce((a, x) => {
    if (x.id === "isAvailable" && props.value.isAvailable === false) a = false
    return a
  }, true)

  return (
    <tr style={{ borderBottom: "1px solid #eee" }}>
      <DragHandle />
      {props.columns.map((column, i) =>
        <td
          key={props.value._id + i}
          style={{
            backgroundColor: "#fdd",
            padding: "10px 20px",
            textAlign: "center",
            minWidth: column.minWidth,
            maxWidth: column.maxWidth,
            overflow: "hidden",
            background: props.storeProfitFilter && props.storeProfitFilter(props.value) ? "#fdd" : isAvailable ? "white" : "#eee"
          }}
        >
          {column.cell(props.value)}
        </td>)
      }
    </tr>
  )
})
