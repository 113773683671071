import Login from './views/Login'
import DeleteUser from './views/DeleteUser'
import Category from './views/Category'
import Food from './views/Food'
import Profile from './views/VendorProfile'
import Orders from './views/StoreOrders'
import Configuration from './views/Configuration'
import Users from './views/Users'
import Vendors from './views/Vendors'
import RestaurantList from './views/RestaurantList'
import ResetPassword from './views/ForgotPassword'
import Riders from './views/Riders'
import Options from './views/Options'
import Addons from './views/Addon'
// import Coupons from './views/Coupons'
import Dashboard from './views/Dashboard'
import Restaurant from './views/Restaurant'
import Ratings from './views/Rating'
import Dispatch from './views/Dispatch'
// import Offer from "./views/Offer" // TODO: excluding from v1
import Zone from './views/Zone'
import StoreCategory from './views/StoreCategory'
import SalesBoard from './views/SalesBoard'
import InfoCarousel from './views/InfoCarousel'
import ZoneStatistics from './views/ZoneStatistics'
import CategoryMasterComponent from './views/CategoryMaster'
import FoodMasterComponent from './views/FoodMaster'
import StoreSalesBoard from './views/StoreSalesBoard'

var routes = [
  /**
   * store admin
   */
  {
    path: '/dashboard',
    name: 'Dashboard',
    icon: 'ni ni-tv-2 text-primary',
    component: Dashboard,
    layout: '/store_admin',
    appearInSidebar: false,
    admin: false
  },
  {
    path: '/profile',
    name: 'Profile',
    icon: 'ni ni-single-02 text-blue',
    component: Profile,
    layout: '/store_admin',
    appearInSidebar: false,
    admin: false
  },
  {
    path: '/category',
    name: 'Category',
    icon: 'ni ni-chart-pie-35 text-red',
    component: Category,
    layout: '/store_admin',
    appearInSidebar: false,
    admin: false
  },
  {
    path: '/food',
    name: 'Food',
    icon: 'ni ni-tie-bow text-green',
    component: Food,
    layout: '/store_admin',
    appearInSidebar: false,
    admin: false
  },
  {
    path: '/addons',
    name: 'Addons',
    icon: 'ni ni-app text-indigo',
    component: Addons,
    layout: '/store_admin',
    appearInSidebar: false,
    admin: false
  },
  {
    path: '/option',
    name: 'Option',
    icon: 'ni ni-atom text-purple',
    component: Options,
    layout: '/store_admin',
    appearInSidebar: false,
    admin: false
  },
  {
    path: '/orders',
    name: 'Orders',
    icon: 'ni ni-delivery-fast text-orange',
    component: Orders,
    layout: '/store_admin',
    appearInSidebar: false,
    admin: false
  },
  // {
  //   path: '/coupons',
  //   name: 'Coupons',
  //   icon: 'ni ni-ungroup text-yellow',
  //   component: Coupons,
  //   layout: '/admin',
  //   appearInSidebar: true,
  //   admin: false
  // },
  {
    path: '/ratings',
    name: 'Ratings',
    icon: 'fas fa-star text-yellow',
    component: Ratings,
    layout: '/store_admin',
    appearInSidebar: false,
    admin: false
  },
  {
    path: '/storeSalesBoard',
    name: 'SalesBoard',
    icon: 'ni ni-tv-2 text-primary',
    component: StoreSalesBoard,
    layout: '/store_admin',
    appearInSidebar: false,
    admin: false
  },
  {
    path: '/zoneStatistics',
    name: 'ZoneStatistics',
    icon: 'ni ni-square-pin text-red',
    component: ZoneStatistics,
    layout: '/store_admin',
    appearInSidebar: false,
    admin: false
  },

  /**
   * Vendor
   */
  {
    path: '/categoryMaster',
    name: 'CategoryMaster',
    icon: 'ni ni-chart-pie-35 text-red',
    component: CategoryMasterComponent,
    layout: '/vendor_admin',
    appearInSidebar: false,
    admin: false
  },
  {
    path: '/foodMaster',
    name: 'FoodMaster',
    icon: 'ni ni-tie-bow text-green',
    component: FoodMasterComponent,
    layout: '/vendor_admin',
    appearInSidebar: false,
    admin: false
  },
  {
    path: '/storeSalesBoard',
    name: 'SalesBoard',
    icon: 'ni ni-tv-2 text-primary',
    component: StoreSalesBoard,
    layout: '/vendor_admin',
    appearInSidebar: false,
    admin: false
  },
  {
    path: '/zoneStatistics',
    name: 'ZoneStatistics',
    icon: 'ni ni-square-pin text-red',
    component: ZoneStatistics,
    layout: '/vendor_admin',
    appearInSidebar: false,
    admin: false
  },
  {
    path: '/list',
    name: 'RestaurantList',
    icon: 'ni ni-collection text-yellow',
    component: Restaurant,
    layout: '/vendor_admin',
    appearInSidebar: true,
    admin: false
  },
  /**
  * auth
  */
  {
    path: '/login',
    name: 'Login',
    icon: 'ni ni-key-25 text-info',
    component: Login,
    layout: '/auth',
    appearInSidebar: false
  },
  {
    path: '/delete_user',
    name: 'DeleteUser',
    icon: 'ni ni-key-25 text-info',
    component: DeleteUser,
    layout: '/auth',
    appearInSidebar: false
  },
  {
    path: '/reset',
    name: 'ResetPassword',
    icon: 'ni ni-key-25 text-info',
    component: ResetPassword,
    layout: '/auth',
    appearInSidebar: false
  },
  /**
  * move to master management
  */
  {
    path: '/list',
    name: 'RestaurantList',
    icon: 'ni ni-collection text-yellow',
    component: Restaurant,
    layout: '/vendor_admin',
    appearInSidebar: false,
    admin: false
  },
  /**
   * super admin
   */
  {
    path: '/vendors',
    name: 'Vendors',
    icon: 'ni ni-collection text-primary',
    component: Vendors,
    layout: '/super_admin',
    appearInSidebar: true,
    admin: true
  },
  {
    path: '/restaurants',
    name: 'Restaurants',
    icon: 'fas fa-star text-yellow',
    component: RestaurantList,
    layout: '/super_admin',
    appearInSidebar: true,
    admin: true
  },
  {
    path: '/storecategory',
    name: 'StoreCategory',
    icon: 'ni ni-chart-pie-35 text-red',
    component: StoreCategory,
    layout: '/super_admin',
    appearInSidebar: true,
    admin: true
  },
  // {
  //   path: "/offers",
  //   name: "Offers",
  //   icon: "ni ni-atom text-red",
  //   component: Offer,
  //   layout: "/super_admin",
  //   appearInSidebar: true,
  //   admin: true
  // },
  {
    path: '/users',
    name: 'Users',
    icon: 'ni ni-single-02 text-green',
    component: Users,
    layout: '/super_admin',
    appearInSidebar: true,
    admin: true
  },
  {
    path: '/riders',
    name: 'Riders',
    icon: 'ni ni-delivery-fast text-indigo',
    component: Riders,
    layout: '/super_admin',
    appearInSidebar: true,
    admin: true
  },
  {
    path: '/vendors',
    name: 'Back to Admin',
    icon: 'ni ni-curved-next text-black',
    component: Vendors,
    layout: '/super_admin',
    appearInSidebar: true,
    admin: false
  },
  {
    path: '/zones',
    name: 'Zone',
    icon: 'ni ni-square-pin text-blue',
    component: Zone,
    layout: '/super_admin',
    appearInSidebar: true,
    admin: true
  },
  {
    path: '/configuration',
    name: 'Configuration',
    icon: 'ni ni-settings text-blue',
    component: Configuration,
    layout: '/super_admin',
    appearInSidebar: true,
    admin: true
  },
  {
    path: '/infoCarousel',
    name: 'InfoCarousel',
    icon: 'ni ni-tie-bow text-green',
    component: InfoCarousel,
    layout: '/super_admin',
    appearInSidebar: true,
    admin: true
  },
  {
    path: '/dispatch',
    name: 'Dispatch',
    icon: 'ni ni-collection text-primary',
    component: Dispatch,
    layout: '/super_admin',
    appearInSidebar: true,
    admin: true
  },
  {
    path: '/salesBoard',
    name: 'SalesBoard',
    icon: 'ni ni-tv-2 text-primary',
    component: SalesBoard,
    layout: '/super_admin',
    appearInSidebar: true,
    admin: true
  },
  {
    path: '/zoneStatistics',
    name: 'ZoneStatistics',
    icon: 'ni ni-square-pin text-red',
    component: ZoneStatistics,
    layout: '/super_admin',
    appearInSidebar: true,
    admin: true
  },
]
export default routes
