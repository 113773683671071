import { ApolloError, gql, PureQueryOptions, useMutation, } from "@apollo/client";

const editOptionMutation = gql`mutation ($optionInput:editOptionInput) {
  editOption(optionInput:$optionInput)
}`


export type EditOptionMutationInput = {
  optionInput: {
    option: {
      _id: string;
      title: string;
      description: string;
      storeProfit: number;
    };
    restaurant: string;
  }
}


export type EditOptionResponse = {
  editOption: boolean
}

export function useEditOption(config: {
  variables: EditOptionMutationInput,
  refetchQueries: (string | PureQueryOptions)[],
  onCompleted: (data: EditOptionResponse) => void,
  onError: (error: ApolloError) => void,
}) {

  return useMutation<EditOptionResponse, EditOptionMutationInput>(editOptionMutation, {
    variables: config.variables,
    refetchQueries: config.refetchQueries,
    onCompleted: config.onCompleted,
    onError: config.onError,
  })

}

