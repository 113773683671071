import React, { useRef, useState } from 'react'
import { validateFunc } from '../../constraints/constraints'
import { useTranslation, } from 'react-i18next'
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  Row,
  Col,
  UncontrolledAlert
} from 'reactstrap'
// core components
import {
  createVendor,
  editVendor,
  getZones,
  getVendors,
  getStoreCategories
} from '../../apollo/server'
import { useMutation, useQuery, gql, } from "@apollo/client"

const CREATE_VENDOR = gql`
  ${createVendor}
`
const EDIT_VENDOR = gql`
  ${editVendor}
`
const GET_VENDORS = gql`
  ${getVendors}
`
const GET_ZONES = gql`
  ${getZones}
`
const GET_STORE_CATEGORIES = gql`
  ${getStoreCategories}
`

function VendorModal(props) {
  const formRef = useRef()
  const [restaurant, restaurantSetter] = useState(
    props.vendor && props.vendor.restaurants.length
      ? props.vendor.restaurants.map(
        ({ _id, name, zone, commission, storeCategory, storeSubCategory }) => ({
          _id: _id,
          name,
          zone: zone._id,
          commission,
          storeCategory,
          storeSubCategory,
          nameError: null,
          zoneError: null,
          commisionError: null,
          storeCategoryError: null,
          storeSubCategoryError: null,
        })
      )
      : [
        {
          _id: '',
          name: '',
          zone: '',
          commission: '',
          storeCategory: '',
          storeSubCategory: '',
          nameError: null,
          zoneError: null,
          commisionError: null,
          storeCategoryError: null,
          storeSubCategoryError: null,
        }
      ]
  )
  const mutation = props.vendor ? EDIT_VENDOR : CREATE_VENDOR
  const email = props.vendor ? props.vendor.email : ''
  const length = props.vendor ? props.vendor.restaurants.length : 1
  const [error, errorSetter] = useState('')
  const [success, successSetter] = useState('')
  const [emailError, emailErrorSetter] = useState(null)
  const [passError, passErrorSetter] = useState(null)
  const { t } = useTranslation()

  // const onBlur = (setter, field, state) => {
  //   setter(!validateFunc({ [field]: state }, field))
  // }
  const handleChange = (value, index, type) => {
    console.log("here")
    let error = false
    const restaurants = restaurant
    if (type === 'name') {
      restaurants[index][type] = value.length === 1 ? value : value
      const occ = restaurants.filter(v => v.name === restaurants[index][type])
      if (occ.length > 1) error = true
    } else if (type === 'zone') {
      restaurants[index][type] = value
    } else if (type === 'commission') {
      restaurants[index][type] = value
    } else if (type === 'storeCategory') {
      restaurants[index][type] = value
    } else if (type === "storeSubCategory") {
      restaurants[index][type] = value
    }
    restaurants[index][type + 'Error'] = error
      ? !error
      : !validateFunc({ [type]: restaurants[index][type] }, type)
    restaurantSetter([...restaurants])
  }

  const onAdd = index => {
    const restaurants = restaurant
    if (index === restaurants.length - 1) {
      restaurants.push({
        _id: '',
        name: '',
        nameError: null,
        zoneError: null,
        commisionError: null,
        storeCategoryError: null
      })
    } else {
      restaurants.splice(index + 1, 0, {
        _id: '',
        name: '',
        nameError: null,
        zoneError: null,
        commissionError: null,
        storeCategoryError: null
      })
    }
    restaurantSetter([...restaurants])
  }
  const onRemove = index => {
    if (restaurant.length === 1 && index === 0) {
      return
    }
    const restaurants = restaurant
    restaurants.splice(index, 1)
    restaurantSetter([...restaurants])
  }
  const onSubmitValidaiton = () => {
    const emailError = !validateFunc(
      { email: formRef.current['input-email'].value },
      'email'
    )
    const passwordError = props.vendor
      ? true
      : !validateFunc(
        { password: formRef.current['input-password'].value },
        'password'
      )
    const restaurants = restaurant

    restaurants.map(restaurant => {
      let error = false

      const occ = restaurants.filter(v => v.name === restaurant.name)
      if (occ.length > 1) error = true

      restaurant.nameError = error
        ? !error
        : !validateFunc({ name: restaurant.name }, 'name')

      restaurant.zoneError = !validateFunc({ zone: restaurant.zone }, 'zone')
      restaurant.commissionError = !validateFunc({ commission: restaurant.commission }, 'commission')
      restaurant.storeCategoryError = !validateFunc({ storeCategory: restaurant.storeCategory }, 'storeCategory')
      return restaurant
    })
    const restaurantsError = !restaurants.filter(restaurant => !restaurant.nameError).length
    const restaurantZoneError = !restaurants.filter(restaurant => !restaurant.zoneError).length
    const restaurantCommissionError = !restaurants.filter(restaurant => !restaurant.commissionError).length
    const restaurantStoreCategoryError = !restaurants.filter(restaurant => !restaurant.storeCategoryError).length

    emailErrorSetter(emailError)
    passErrorSetter(passwordError)
    restaurantSetter([...restaurant])
    return (
      emailError && passwordError && restaurantsError && restaurantZoneError && restaurantCommissionError && restaurantStoreCategoryError
    )
  }
  const clearFields = () => {
    formRef.current.reset()
    emailErrorSetter('')
    passErrorSetter('')
    restaurantSetter([
      {
        _id: '',
        name: '',
        nameError: null
      }
    ])
  }

  const hideAlert = () => {
    errorSetter('')
    successSetter('')
  }

  const SubmitButton = () => {
    const onCompleted = data => {
      if (!props.vendor) clearFields()
      const message = props.vendor
        ? t('UpdateSuccessMessage')
        : t('AddSuccessMessage')
      errorSetter('')
      successSetter(message)
      setTimeout(hideAlert, 5000)
    }

    const onError = ({ graphQLErrors, networkError }) => {
      successSetter('')
      if (graphQLErrors) {
        console.log(graphQLErrors)
      } else if (networkError) {
        console.log(networkError)
      }
      errorSetter(t('ErrorMessage'))
      setTimeout(hideAlert, 5000)
    }

    const [mutate, { loading: mutationLoading, error }] = useMutation(mutation, {
      onCompleted,
      onError,
      refetchQueries: [{ query: GET_VENDORS }]
    })
    if (error) console.log(error)

    return <Button
      disabled={mutationLoading}
      color="primary"
      href="#pablo"
      onClick={async e => {
        e.preventDefault()
        if (onSubmitValidaiton() && !mutationLoading) {
          mutate({
            variables: {
              vendorInput: {
                _id: props.vendor ? props.vendor._id : '',
                email: formRef.current[
                  'input-email'
                ].value.toLowerCase(),
                password: formRef.current[
                  'input-password'
                ]
                  ? formRef.current['input-password']
                    .value
                  : '',
                restaurants: restaurant.map(
                  ({
                    _id,
                    name,
                    zone,
                    commission,
                    storeCategory,
                    storeSubCategory,
                  }) => {
                    return {
                      _id,
                      name,
                      zone,
                      commission: parseFloat(commission),
                      storeCategory: storeCategory._id ? storeCategory._id : storeCategory,
                      storeSubCategory: storeSubCategory && storeSubCategory._id ? storeSubCategory._id : storeSubCategory ?? ""
                    }
                  }
                )
              }
            }
          })
        }
      }}
      size="md">
      {props.vendor ? 'Update' : t('Save')}
    </Button>
  }

  const { data: zoneData, loading: zoneLoading, error: zoneError } = useQuery(GET_ZONES)
  const { data: categoryData, loading: categoryLoading, error: categoryError } = useQuery(GET_STORE_CATEGORIES)

  if (zoneLoading || !zoneData || categoryLoading || !categoryData) return <p>{'Loading....'}</p>
  if (zoneError || categoryError) return <p>{t('Error')}</p>

  return (
    <Row>
      <Col className="order-xl-1">
        <Card className="bg-secondary shadow">
          <CardHeader className="bg-white border-0">
            <Row className="align-items-center">
              <Col xs="8">
                <h3 className="mb-0">
                  {props.vendor ? t('Edit Vendor') : t('Add Vendor')}
                </h3>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <form ref={formRef}>
              <div className="pl-lg-4">
                <Row>
                  <Col lg="6">
                    <label
                      className="form-control-label"
                      htmlFor="input-email">
                      {t('Email')}
                    </label>
                    <FormGroup
                      className={
                        emailError === null
                          ? ''
                          : emailError
                            ? 'has-success'
                            : 'has-danger'
                      }>
                      <Input
                        autoCapitalize="none"
                        className="form-control-alternative text-lowercase"
                        id="input-email"
                        name="input-email"
                        placeholder="sample@gmail.com"
                        type="email"
                        defaultValue={email}
                      />
                    </FormGroup>
                  </Col>
                  {!props.vendor && (
                    <Col lg="6">
                      <label
                        className="form-control-label"
                        htmlFor="input-password">
                        {t('Password')}
                      </label>
                      <FormGroup
                        className={
                          passError === null
                            ? ''
                            : passError
                              ? 'has-success'
                              : 'has-danger'
                        }>
                        <Input
                          autoCapitalize="none"
                          className="form-control-alternative"
                          id="input-password"
                          name="input-password"
                          placeholder="samplepassword"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                  )}
                </Row>
                <Row>
                  <Col lg="6">
                    <h3 className="mb-0">{t('Restaurants')}</h3>
                    <br />
                  </Col>
                </Row>
                {restaurant.map((ItemRestaurant, index) => (
                  <div key={index}>
                    <Row>
                      <Col lg="6">
                        <label
                          className="form-control-label"
                          htmlFor="input-type-name">
                          {t("RestaurantName")}
                        </label>
                        <br />
                        <FormGroup
                          className={
                            ItemRestaurant.nameError === false
                              ? 'has-danger'
                              : ItemRestaurant.nameError === true
                                ? 'has-success'
                                : ''
                          }>
                          <Input
                            autoCapitalize="none"
                            className="form-control-alternative"
                            defaultValue={ItemRestaurant.name}
                            id="input-type-name"
                            name="input-type-name"
                            placeholder="店舗名"
                            type="text"
                            autoComplete="off"
                            onChange={event => {
                              handleChange(
                                event.target.value,
                                index,
                                'name'
                              )
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <label
                          className="form-control-label"
                          htmlFor="input-category">
                          {t('Zone')}
                        </label>
                        <FormGroup
                          className={
                            ItemRestaurant.zoneError === null
                              ? ''
                              : ItemRestaurant.zoneError
                                ? 'has-success'
                                : 'has-danger'
                          }>
                          <Input
                            type="select"
                            name="selectZone"
                            id="exampleSelect"
                            defaultValue={ItemRestaurant.zone}
                            onChange={event =>
                              handleChange(
                                event.target.value,
                                index,
                                'zone'
                              )
                            }
                          >
                            {!ItemRestaurant.zone && (
                              <option value={''}>
                                {t('Select')}
                              </option>
                            )}
                            {zoneData && zoneData.zones && zoneData.zones.map(zone => (
                              <option
                                value={zone._id}
                                key={zone._id}>
                                {zone.title}
                              </option>
                            ))}
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg="6">
                        <label
                          className="form-control-label"
                          htmlFor="input-category">
                          {t('StoreCategory')}
                        </label>
                        <FormGroup
                          className={
                            ItemRestaurant.storeCategoryError ===
                              null
                              ? ''
                              : ItemRestaurant.storeCategoryError
                                ? 'has-success'
                                : 'has-danger'
                          }>
                          <Input
                            type="select"
                            name="selectStoreCategory"
                            id="exampleSelectStoreCategory"
                            defaultValue={
                              ItemRestaurant.storeCategory && ItemRestaurant.storeCategory._id
                                ? categoryData.storeCategories.filter(category => category._id === ItemRestaurant.storeCategory._id).length > 0
                                  ? categoryData.storeCategories.filter(category => category._id === ItemRestaurant.storeCategory._id)[0]._id
                                  : {}
                                : {}
                            }
                            onChange={event =>
                              handleChange(
                                event.target.value,
                                index,
                                'storeCategory'
                              )
                            }
                          >
                            {!ItemRestaurant.storeCategory && (
                              <option value={''}>
                                {t('Select')}
                              </option>
                            )}
                            {categoryData.storeCategories.map(
                              storeCategory => (
                                <option
                                  value={storeCategory._id}
                                  key={storeCategory._id}>
                                  {storeCategory.title}
                                </option>
                              )
                            )}
                          </Input>
                        </FormGroup>
                      </Col>

                      <Col lg="6">
                        <label
                          className="form-control-label"
                          htmlFor="input-subCategory">
                          {t('StoreSubCategory')}
                        </label>
                        <FormGroup>
                          <Input
                            type="select"
                            name="input-subCategory"
                            id="input-subCategory"
                            defaultValue={
                              ItemRestaurant.storeSubCategory && ItemRestaurant.storeSubCategory._id
                                ? categoryData.storeCategories.filter(category => category._id === ItemRestaurant.storeSubCategory._id).length > 0
                                  ? categoryData.storeCategories.filter(category => category._id === ItemRestaurant.storeSubCategory._id)[0]._id
                                  : {}
                                : {}
                            }
                            onChange={event =>
                              handleChange(
                                event.target.value,
                                index,
                                'storeSubCategory'
                              )
                            }
                          >
                            {!ItemRestaurant.storeSubCategory && (
                              <option value={''}>
                                {t('Select')}
                              </option>
                            )}
                            {categoryData.storeCategories.map(storeCategory => (
                                <option
                                  value={storeCategory._id}
                                  key={storeCategory._id}>
                                  {storeCategory.title}
                                </option>
                              )
                            )}
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg="6">
                        <label
                          className="form-control-label"
                          htmlFor="input-type-commission">
                          {t('Commission')}（％）
                        </label>
                        <br />
                        <FormGroup
                          className={
                            ItemRestaurant.commissionError === false
                              ? 'has-danger'
                              : ItemRestaurant.commissionError === true
                                ? 'has-success'
                                : ''
                          }>
                          <Input
                            autoCapitalize="none"
                            className="form-control-alternative"
                            defaultValue={ItemRestaurant.commission}
                            id="input-type-commission"
                            name="input-type-commission"
                            placeholder={t('Commission')}
                            type="text"
                            autoComplete="off"
                            onChange={event => {
                              handleChange(
                                event.target.value,
                                index,
                                'commission'
                              )
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>


                    {restaurant.length - 1 === index && (
                      <Row className="mt-2 ">
                        <Col lg="6">
                          <Row>
                            {restaurant.length > length && restaurant.length - 1 === index && (
                              <FormGroup className="ml-2">
                                <Button color="danger" onClick={() => { onRemove(index) }}> - </Button>
                              </FormGroup>
                            )}
                            <FormGroup className="ml-2">
                              <Button onClick={() => { onAdd(index) }} color="primary"> + </Button>
                            </FormGroup>
                          </Row>
                        </Col>
                      </Row>
                    )}
                  </div>
                ))}

                <Row>
                  <Col className="text-right" lg="6">
                    <SubmitButton />
                  </Col>
                </Row>
              </div>
            </form>
            <Row>
              <Col lg="6">
                {success && (
                  <UncontrolledAlert color="success" fade={true}>
                    <span className="alert-inner--icon">
                      <i className="ni ni-like-2" />
                    </span>{' '}
                    <span className="alert-inner--text">
                      <strong>{t('Success')}!</strong> {success}
                    </span>
                  </UncontrolledAlert>
                )}
                {error && (
                  <UncontrolledAlert color="danger" fade={true}>
                    <span className="alert-inner--icon">
                      <i className="ni ni-like-2" />
                    </span>{' '}
                    <span className="alert-inner--text">
                      <strong>{t('Danger')}!</strong> {error}
                    </span>
                  </UncontrolledAlert>
                )}
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}
export default VendorModal
