import React, { useContext, useState } from "react"
import {
  Card,
  CardBody,
  CardHeader,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Button,
  Label,
  Modal,
  Alert
} from "reactstrap"
import { useTranslation } from "react-i18next"
import {
  createAddons,
  editAddon
} from "../../apollo/server"
import OptionModal from "../Options/OptionModal"
import { validateFunc } from "../../constraints/constraints"
import { getIsDisabled } from "../../utils/getIsDisabled"
import { useMutation, gql, } from "@apollo/client"
import { useRestaurantQuery } from "../../domain/graphql/query/useRestaurantQuery"
import { AppContext } from "../../app"

const CREATE_ADDONS = gql`${createAddons}`
const EDIT_ADDON = gql`${editAddon}`

interface Props {
  addon?: any
  modalLoading: boolean
  setModalLoading: React.Dispatch<React.SetStateAction<boolean>>
}
const AddonModal: React.FC<Props> = (props) => {
  const { t } = useTranslation()
  const appContext = useContext(AppContext)
  const userType = appContext.userType
  const restaurantId = appContext.restaurantId
  const [addon, addonSetter] = useState(
    props.addon
      ? [
        {
          ...props.addon,
          options: props.addon.options,
          titleError: false,
          optionsError: false,
          quantityMinimumError: false,
          quantityMaximumError: false
        }
      ]
      : [
        {
          title: "",
          description: "",
          quantityMinimum: 0,
          quantityMaximum: 1,
          memo: "",
          options: [],
          titleError: false,
          optionsError: false,
          quantityMinimumError: false,
          quantityMaximumError: false
        }
      ]
  )
  const [modal, modalSetter] = useState(false)
  const [success, successSetter] = useState("")
  const [mainError, mainErrorSetter] = useState("")

  const onChange = (event, index, state) => {
    const addons = addon
    addons[index][state] = event.target.value
    addonSetter([...addons])
  }
  const onBlur = (index, state) => {
    const addons = addon
    if (state === "title") {
      addons[index].titleError = !!validateFunc({ addonTitle: addons[index][state] }, "addonTitle")
    }
    if (state === "quantityMinimum") {
      addons[index].quantityMinimumError = !!validateFunc({ addonQuantityMinimum: addons[index][state] }, "addonQuantityMinimum")
      addons[index].quantityMinimumError = addons[index].quantityMinimumError || addons[index].quantityMinimum > addons[index].quantityMaximum
      addons[index].quantityMinimumError = addons[index].options.length < addons[index][state]
    }
    if (state === "quantityMaximum") {
      addons[index].quantityMaximumError = !!validateFunc({ addonQuantityMaximum: addons[index][state] }, "addonQuantityMaximum")
      addons[index].quantityMaximumError = addons[index].quantityMaximumError || addons[index].quantityMaximum < addons[index].quantityMinimum
    }
    if (state === "options") {
      addons[index].optionsError = addons[index].options.length === 0
    }
    addonSetter([...addons])
  }
  const onSelectOption = (index, id) => {
    const addons = addon
    const optionIndex = addons[index].options.indexOf(id)
    if (optionIndex === -1) addons[index].options = [...addons[index].options, id]
    else addons[index].options = addons[index].options.filter(optionId => optionId !== id)
    addonSetter([...addons])
  }

  const onAdd = index => {
    const addons = addon
    if (index === addons.length - 1) {
      addons.push({
        title: "",
        description: "",
        quantityMinimum: 0,
        quantityMaximum: 1,
        options: [],
        memo: ""
      })
    } else {
      addons.splice(index + 1, 0, {
        title: "",
        description: "",
        quantityMinimum: 0,
        quantityMaximum: 1,
        options: [],
        memo: ""
      })
    }
    addonSetter([...addons])
  }

  const onRemove = index => {
    if (addon.length === 1 && index === 0) {
      return
    }
    const addons = addon
    addons.splice(index, 1)
    addonSetter([...addons])
  }

  const toggleModal = index => {
    modalSetter(prev => !prev)
  }

  const validate = () => {
    const addons = addon
    addons.forEach((addon, index) => {
      onBlur(index, "title")
      onBlur(index, "quantityMinimum")
      onBlur(index, "quantityMaximum")
      onBlur(index, "options")
      return addon
    })
    const error = addons.filter(addon =>
      addon.titleError ||
      addon.quantityMinimumError ||
      addon.quantityMaximumError ||
      addon.optionsError
    )
    if (!error.length) return true
    return false
  }

  const onCompleted = ({ createAddons, editAddon }) => {
    if (createAddons) {
      addonSetter([
        {
          title: "",
          description: "",
          quantityMinimum: 0,
          quantityMaximum: 1,
          options: [],
          memo: "",
          titleError: false,
          optionsError: false,
          quantityMinimumError: false,
          quantityMaximumError: false
        }
      ])
      successSetter(t("AddSuccessMessage"))
      mainErrorSetter("")
    }
    if (editAddon) {
      successSetter(t("UpdateSuccessMessage"))
      mainErrorSetter("")
    }
    props.setModalLoading(false)
  }
  const onError = error => {
    console.log(error)
    mainErrorSetter(t("ErrorMessage"))
    successSetter("")
    props.setModalLoading(false)
  }
  const onDismiss = () => {
    mainErrorSetter("")
    successSetter("")
  }

  function SubmitButton() {
    const [mutate, { loading, error }] = useMutation(props.addon ? EDIT_ADDON : CREATE_ADDONS, {
      onCompleted,
      onError,
    })

    if (error) {
      console.log(error)
      return <p>{t("ErrorMessage")}</p>
    }

    function onPressSubmit(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
      e.preventDefault()
      if (getIsDisabled(userType) || loading || props.modalLoading || !validate()) return

      const variables = props.addon
        ? {
          addonInput: {
            addons: {
              _id: props.addon._id,
              title: addon[0].title.trim(),
              description: addon[0].description.trim(),
              options: addon[0].options,
              quantityMinimum: Number(addon[0].quantityMinimum),
              quantityMaximum: Number(addon[0].quantityMaximum),
              memo: addon[0].memo.trim()
            },
            restaurant: restaurantId
          }
        }
        : {
          addonInput: {
            addons: addon.map(
              ({
                title,
                description,
                options,
                quantityMinimum,
                quantityMaximum,
                memo
              }) => ({
                title: title.trim(),
                description: description.trim(),
                options,
                quantityMinimum: Number(quantityMinimum),
                quantityMaximum: Number(quantityMaximum),
                memo: memo.trim()
              })
            ),
            restaurant: restaurantId
          }
        }

      props.setModalLoading(true)
      mutate({ variables })
    }

    return <Button
      disabled={loading || props.modalLoading}
      color="primary"
      size="lg"
      block
      onClick={onPressSubmit}
    >
      {loading || props.modalLoading ? t("Saving") : t("Save")}
    </Button>
  }


  const { loading, error, data } = useRestaurantQuery(restaurantId)

  if (loading) return <p>Loading ...</p>
  if (error) return <p>Error ...</p>

  return (
    <Card>
      <CardHeader>{t("Addons")}</CardHeader>
      <CardBody>
        <Form>
          <div>
            {addon.map((addonItem, index) => (
              <div key={index}>
                <Row>
                  <Col lg="6">
                    <Row>
                      <Col lg="12">
                        <label
                          className="form-control-label"
                          htmlFor="input-title">
                          {t("Title")}
                        </label>
                        <FormGroup className={addonItem.titleError === true ? "has-danger" : ""}>
                          <Input
                            autoCapitalize="none"
                            className="form-control-alternative"
                            id="input-title"
                            placeholder={t("AddonPH")}
                            type="text"
                            value={addonItem.title}
                            onChange={event => { onChange(event, index, "title") }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="12">
                        <label className="form-control-label" htmlFor="input-description">
                          {t("Description")}
                        </label>
                        <FormGroup>
                          <Input
                            autoCapitalize="none"
                            className="form-control-alternative"
                            id="input-description"
                            placeholder={t("AddonDescriptionPH")}
                            type="text"
                            value={addonItem.description || ""}
                            onChange={event => onChange(event, index, "description")}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="12">
                        <label className="form-control-label" htmlFor="input-minimum">
                          {t("Quantity Minimum")}
                        </label>

                        <br />

                        <small>
                          {t("Must be a less than or equal to Maximum")}
                        </small>
                        <FormGroup className={addonItem.quantityMinimumError === true ? "has-danger" : ""}>
                          <Input
                            autoCapitalize="none"
                            className="form-control-alternative"
                            id="input-minimum"
                            placeholder="e.g 90.25"
                            type="number"
                            value={addonItem.quantityMinimum}
                            onChange={event => { onChange(event, index, "quantityMinimum") }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="12">
                        <label className="form-control-label" htmlFor="input-maximum">
                          {t("Quantity Maximum")}
                        </label>

                        <br />

                        <small>
                          {t("Must be a greater than or equal to Minimum")}
                        </small>

                        <FormGroup className={addonItem.quantityMaximumError === true ? "has-danger" : ""}>
                          <Input
                            autoCapitalize="none"
                            className="form-control-alternative"
                            id="input-maximum"
                            placeholder="e.g 90.25"
                            type="number"
                            value={addonItem.quantityMaximum}
                            onChange={event => { onChange(event, index, "quantityMaximum") }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="12">
                        <label className="form-control-label" htmlFor="input-memo">
                          {`${t("AddonMemo")} ${t("AddonMemoDesc")}`}
                        </label>
                        <FormGroup>
                          <Input
                            autoCapitalize="none"
                            className="form-control-alternative"
                            id="input-memo"
                            type="text"
                            value={addonItem.memo}
                            onChange={event => { onChange(event, index, "memo") }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg="6">
                    <Row className="mb-2">
                      <Col>
                        <label className="form-control-label">
                          {t("Options")}
                        </label>
                        <br />
                        {!addon[index].options.length && (
                          <small className="text-red">
                            {t("Select atleast one Option")}
                          </small>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Button color="warning" onClick={() => toggleModal(index)} >
                            {t("New")}
                          </Button>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row style={{ maxHeight: "67vh", overflowY: "scroll" }}>
                      <Col>
                        {data && data.restaurant && data.restaurant.options && data.restaurant.options.map(option => (
                          <FormGroup
                            key={option._id}
                            check
                            style={{ width: "100%", marginTop: "10px" }}>
                            <Label check>
                              <Input
                                autoCapitalize="none"
                                defaultChecked={addon[index].options.includes(option._id)}
                                value={option._id}
                                type="checkbox"
                                onClick={() => onSelectOption(index, option._id)}
                              />
                              {`${option.title} ${option.description ? "(" + option.description + ")" : ""}`}
                              {` : ${option.taxInPrice}${t("Yen")}`}
                            </Label>
                          </FormGroup>
                        ))}
                      </Col>
                    </Row>
                    {!props.addon && (
                      <Row className="mt-2">
                        <Col>
                          <label className="form-control-label">
                            {t("Add/Remove Addons")}
                          </label>
                          <FormGroup>
                            <Button color="danger" onClick={() => { onRemove(index) }} > - </Button>
                            <Button onClick={() => { onAdd(index) }} color="primary"> + </Button>
                          </FormGroup>
                        </Col>
                      </Row>
                    )}
                  </Col>
                </Row>
                <hr />
              </div>
            ))}
            <Row>
              <Col lg={{ offset: 4, size: 4 }}>
                <SubmitButton />
              </Col>
              <Alert color="success" isOpen={!!success} toggle={onDismiss}>
                {success}
              </Alert>
              <Alert color="danger" isOpen={!!mainError} toggle={onDismiss}>
                {mainError}
              </Alert>
            </Row>
          </div>
        </Form>
      </CardBody>
      <Modal
        className="modal-dialog-centered"
        size="lg"
        isOpen={modal}
        toggle={() => { toggleModal(null) }}
      >
        <OptionModal
          restaurant={data.restaurant}
          modalLoading={props.modalLoading}
          setModalLoading={props.setModalLoading}
        />
      </Modal>
    </Card>
  )
}

export default AddonModal
