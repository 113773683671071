import React from "react"

import {
  Card,
  CardBody,
  Form,
  Row,
  Col
} from "reactstrap"

import { GoogleMap, InfoWindow, } from "@react-google-maps/api"
import { ZoneStatisticsQueryResponse } from "../../domain/graphql/query/useZoneStatistics"

interface Props {
  data: ZoneStatisticsQueryResponse[]
}
const Map: React.FC<Props> = props => {
  const center = { lat: 41.8029, lng: 140.7468 }

  return (
    <Row>
      <Col className="order-xl-1">
        <Card className="bg-secondary shadow">
          <CardBody>
            <Form>
              <div className="pl-lg-4">
                <Row>
                  <GoogleMap
                    mapContainerStyle={{ height: "800px", width: "100%" }}
                    id="example-map"
                    zoom={13}
                    center={center}
                  >
                    <Markers data={props.data} />
                  </GoogleMap>
                </Row>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default Map



const Markers: React.FC<Props> = props => (<>
  {props.data.map(place =>
    <InfoWindow key={place._id} position={{ lat: place.point[1], lng: place.point[0], }} onCloseClick={() => { }} >
      <div style={styles.windowContainer}>
        <p style={styles.title}>{place.title}</p>
        <p style={styles.count}>{place.count}</p>
      </div>
    </InfoWindow>
  )}
</>)

const styles = {
  windowContainer: {
    display: "flex",
    flexDirection: "column" as const,
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    fontSize: 9,
    margin: 0,
  },
  count: {
    fontSize: 9,
    margin: 0,
  },
}

