import { useQuery } from "@apollo/client"
import gql from "graphql-tag"

const adminQuery = gql`query ($storeId: String!, $start: Float!, $end: Float!) {
    ordersByStoreId(storeId: $storeId, start: $start, end: $end) {
        _id
        orderId
        orderAmount
        taxInSubtotal
        customerCharge
        chargeLimit
        noChargeLimit
        deliveryCharges
        storeProfit
        storeCommission
        storeCommissionRate
        orderStatus
        memo
        isActive
        updatedAt
        createdAt
        deliveredAt
        reason
        restaurant {
            _id
            name
        }
        deliveryAddress {
            deliveryAddress
            details
            label
            phone
        }
        items {
            _id
            title
            quantity
            variation {
                _id
                title
                price
                taxInPrice
                storeProfit
            }
            addons {
                _id
                title
                options {
                    _id
                    title
                    price
                    taxInPrice
                    storeProfit
                }
            }
            isActive
            createdAt
            updatedAt
        }
        user {
            _id
            name
            phone
            email
        }
        rider {
            _id
            name
            phone
        }
    }
}`
const storeQuery = gql`query ($storeId: String!, $start: Float!, $end: Float!) {
    ordersByStoreId(storeId: $storeId, start: $start, end: $end) {
        _id
        orderId
        orderAmount
        taxInSubtotal
        customerCharge
        chargeLimit
        noChargeLimit
        deliveryCharges
        storeProfit
        storeCommission
        storeCommissionRate
        orderStatus
        memo
        isActive
        updatedAt
        createdAt
        deliveredAt
        reason
        restaurant {
            _id
            name
        }
        items {
            _id
            title
            quantity
            variation {
                _id
                title
                price
                taxInPrice
                storeProfit
            }
            addons {
                _id
                title
                options {
                    _id
                    title
                    price
                    taxInPrice
                    storeProfit
                }
            }
            isActive
            createdAt
            updatedAt
        }
        rider {
            _id
            name
            phone
        }
    }
}`
export type OrdersByStoreIdQueryResponse = {
    _id: string
    orderId: string
    orderAmount: number
    taxInSubtotal: number
    customerCharge: number
    chargeLimit: number
    deliveryCharges: number
    storeProfit: number
    storeCommission: number
    storeCommissionRate: number
    orderStatus: string
    memo: string
    isActive: boolean
    noChargeLimit: boolean
    updatedAt: string
    createdAt: string
    deliveredAt: string
    reason: string
    restaurant: {
        _id: string
        name: string
    }
    deliveryAddress: {
        deliveryAddress: string
        details: string
        label: string
        phone: string
    }
    items: OrdersByStoreIdQueryResponseItem[]
    user: {
        _id: string
        name: string
        phone: string
        email: string
    }
    rider: {
        _id: string
        name: string
        phone: string
    }
}
export type OrdersByStoreIdQueryResponseForStore = {
    _id: string
    orderId: string
    orderAmount: number
    taxInSubtotal: number
    customerCharge: number
    chargeLimit: number
    deliveryCharges: number
    storeProfit: number
    storeCommission: number
    storeCommissionRate: number
    orderStatus: string
    memo: string
    isActive: boolean
    noChargeLimit: boolean
    updatedAt: string
    createdAt: string
    deliveredAt: string
    reason: string
    restaurant: {
        _id: string
        name: string
    }
    items: OrdersByStoreIdQueryResponseItem[]
    rider: {
        _id: string
        name: string
        phone: string
    }
}
export type OrdersByStoreIdQueryResponseItem = {
    _id: string
    title: string
    quantity: number
    variation: {
        _id: string
        title: string
        price: number
        taxInPrice: number
        storeProfit: number
    }
    addons: {
        _id: string
        title: string
        options: {
            _id: string
            title: string
            price: number
            taxInPrice: number
            storeProfit: number
        }[]
    }[]
    isActive: boolean
    createdAt: string
    updatedAt: string
}

type Response = {
    ordersByStoreId: OrdersByStoreIdQueryResponse[]
}
type ResponseForStore = {
    ordersByStoreId: OrdersByStoreIdQueryResponse[]
}
export function useOrdersByStoreIdQuery(storeId: string, start: number, end: number, userType: string) {

    const { loading, error, data, subscribeToMore } = useQuery<Response | ResponseForStore>(userType === "ADMIN" ? adminQuery : storeQuery, { variables: { storeId, start, end } })
    if (userType === "ADMIN") {
        let result: OrdersByStoreIdQueryResponse[]
        if (!data) result = []
        else result = data.ordersByStoreId
        return { loading, error, data: result, subscribeToMore }
    } else {
        let result: OrdersByStoreIdQueryResponseForStore[]
        if (!data) result = []
        else result = data.ordersByStoreId
        return { loading, error, data: result, subscribeToMore }
    }
}