import { useQuery } from "@apollo/client"
import gql from "graphql-tag"
import { StoreStatusType } from "../../ValueObject/storeStatusType"

export const restaurantQuery = gql`query {
  restaurants {
    _id
    ownerId
    name
    phone
    image
    orderPrefix
    address
    chargeLimit
    commission
    deliveryTime
    minimumOrder
    zone {
      _id
      title
    }
    featured
    isActive
    isDeleted
    isCommingSoon
    categories {
      _id
      foods {
        _id
        variations {
          _id
          storeProfit
        }
        isActive
      }
    }
    options {
      _id
      storeProfit
    }
    storeStatus {
      status
    }
  }
}
`
export type RestaurantListQueryResponse = {
  _id: string;
  ownerId: string;
  name: string;
  phone: string;
  image: string;
  orderPrefix: string;
  address: string;
  chargeLimit: number;
  commission: number;
  deliveryTime: number;
  minimumOrder: number;
  zone: {
    _id: string;
    title: string;
  };
  featured: boolean;
  isActive: boolean;
  isDeleted: boolean;
  isCommingSoon: boolean;
  categories: {
    _id: string;
    foods: {
      _id: string;
      variations: {
        _id: string;
        storeProfit: number;
      }[];
      isActive: boolean;
    }[];
  }[];
  options: {
    _id: string;
    storeProfit: number;
  }[];
  storeStatus?: {
    status: StoreStatusType
  }
}
type Response = {
  restaurants: RestaurantListQueryResponse[]
}
export function useRestaurantListQuery() {
  const { loading, error, data } = useQuery<Response>(restaurantQuery)
  let result: RestaurantListQueryResponse[]
  if (!data) result = []
  else result = data.restaurants
  return { loading, error, data: result }
}