import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import CategoryModal from './CategoryModal'
import { Badge, Card, Container, Row, Modal } from 'reactstrap'
import Header from '../../components/Headers/Header'
import {
  deleteCategory,
  updateCategoryOrders,
} from '../../apollo/server'
import Loader from 'react-loader-spinner'
import { getIsDisabled } from '../../utils/getIsDisabled'
import { useMutation, gql } from "@apollo/client"
import { SortableTable } from '../../components/SortableTable'
import CustomLoader from '../../components/Loader/CustomLoader'
import { arrayMove } from "../../utils/arrayMove"
import styles from '../styles'
import { getRestaurantQuery, useRestaurantQuery } from '../../domain/graphql/query/useRestaurantQuery'
import { AppContext } from '../../app'

const DELETE_CATEGORY = gql`${deleteCategory}`
const UPDATE_CATEGORY_ORDERS = gql`${updateCategoryOrders}`

const Category = () => {
  const { t } = useTranslation()
  const appContext = useContext(AppContext)
  const userType = appContext.userType
  const restaurantId = appContext.restaurantId
  const [editModal, setEditModal] = useState(false)
  const [category, setCategory] = useState(null)
  const [modalLoading, setModalLoading] = useState(false)

  const toggleModal = category => {
    setEditModal(prev => !prev)
    setCategory(category)
  }

  const ActionButtons = ({ row }) => {
    const [deleteCategory, { loading: deleteLoading }] = useMutation(DELETE_CATEGORY, {
      refetchQueries: [
        {
          query: getRestaurantQuery,
          variables: { id: restaurantId }
        }
      ],
      onError: e => console.log
    })
    return (
      <>
        <Badge
          style={styles.badgeStyle}
          href="#pablo"
          onClick={e => {
            e.preventDefault()
            toggleModal(row)
          }}
          color="primary">
          {t('Edit')}
        </Badge>
        {"  "}
        {deleteLoading
          ? <Loader
            type="ThreeDots"
            color="#BB2124"
            height={20}
            width={40}
            visible={deleteLoading}
          />
          : <Badge
            style={styles.badgeStyle}
            href="#pablo"
            color="danger"
            onClick={e => {
              e.preventDefault()
              if (getIsDisabled(userType)) return
              if (window.confirm('削除してもよろしいですか？')) {
                deleteCategory({
                  variables: { id: row._id, restaurant: restaurantId }
                })
              }
            }}>
            {t('Delete')}
          </Badge>
        }
      </>
    )
  }

  const { loading, error, data } = useRestaurantQuery(restaurantId)

  const [items, setItems] = useState([])
  useEffect(() => {
    if (data && data.restaurant && data.restaurant.categories) {
      setItems(data.restaurant.categories)
    }
  }, [data])
  const [updateCategoryOrders] = useMutation(UPDATE_CATEGORY_ORDERS, {
    refetchQueries: [
      {
        query: getRestaurantQuery,
        variables: { id: restaurantId }
      }
    ],
    onError: e => console.log
  })
  function onSortEnd({ oldIndex, newIndex }) {
    if (getIsDisabled(userType)) return
    const newItems = arrayMove(items, oldIndex, newIndex)
    const newItemIds = newItems.map(item => item._id)
    setItems(newItems)
    updateCategoryOrders({
      variables: {
        restaurantId,
        categoryIds: newItemIds
      }
    })
  }

  const columns = [
    {
      id: "titel",
      name: t('CategoryTitle'),
      cell: row => <p className="mb-0 text-sm">{row.title}</p>,
      minWidth: 200,
      maxWidth: 400,
    },
    {
      id: "action",
      name: t('Actions'),
      cell: row => <ActionButtons row={row} />,
      minWidth: 200,
      maxWidth: 400,
    }
  ]

  if (error) {
    console.log(error)
    return (
      <div >
        <Header />
        <p style={{ marginTop: 30, textAlign: "center" }}>{`${t("Error")}! ${error.message}`}</p>
      </div>
    )
  }
  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <CategoryModal
          modalLoading={modalLoading}
          setModalLoading={setModalLoading}
        />
        <Row className="mt-5">
          <div className="col">
            <Card className="shadow">
              {loading
                ? <CustomLoader />
                : <SortableTable
                  items={items}
                  onSortEnd={onSortEnd}
                  columns={columns}
                />
              }
            </Card>
          </div>
        </Row>
        <Modal
          className="modal-dialog-centered"
          size="lg"
          isOpen={editModal}
          toggle={() => { if (!modalLoading) toggleModal(null) }}
        >
          <CategoryModal
            category={category}
            modalLoading={modalLoading}
            setModalLoading={setModalLoading}
          />
        </Modal>
      </Container>
    </>
  )
}
export default Category
