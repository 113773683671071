import React, { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import CategoryMasterModal from "./CategoryMasterModal"
import { Badge, Card, Container, Row, Modal } from "reactstrap"
import Header from "../../components/Headers/Header"
import {
  deleteCategoryMaster,
  updateCategoryMasterOrders,
} from "../../apollo/server"
import Loader from "react-loader-spinner"
import { getIsDisabled } from "../../utils/getIsDisabled"
import { useMutation, gql } from "@apollo/client"
import { SortableTable } from "../../components/SortableTable"
import CustomLoader from "../../components/Loader/CustomLoader"
import { arrayMove } from "../../utils/arrayMove"
import styles from "../styles"
import { getCategoryMasterQuery, useCategoryMasterQuery } from "../../domain/graphql/query/useCategoryMasterQuery"
import { AppContext } from "../../app"

const DELETE_CATEGORY = gql`${deleteCategoryMaster}`
const UPDATE_CATEGORY_ORDERS = gql`${updateCategoryMasterOrders}`

const CategoryMasterComponent = () => {
  console.log("views/CategoryMaster")
  const { t } = useTranslation()
  const appContext = useContext(AppContext)
  const userType = appContext.userType
  const ownerId = appContext.ownerId
  const [editModal, setEditModal] = useState(false)
  const [category, setCategory] = useState(null)
  const [modalLoading, setModalLoading] = useState(false)

  const toggleModal = category => {
    setEditModal(prev => !prev)
    setCategory(category)
  }

  const ActionButtons = ({ row }) => {
    const [deleteCategory, { loading: deleteLoading }] = useMutation(DELETE_CATEGORY, {
      refetchQueries: [{
        query: getCategoryMasterQuery,
        variables: { id: ownerId }
      }],
      onError: e => console.log
    })
    return (
      <>
        <Badge
          style={styles.badgeStyle}
          href="#pablo"
          onClick={e => {
            e.preventDefault()
            toggleModal(row)
          }}
          color="primary">
          {t("Edit")}
        </Badge>
        {"  "}
        {deleteLoading
          ? <Loader
            type="ThreeDots"
            color="#BB2124"
            height={20}
            width={40}
            visible={deleteLoading}
          />
          : <Badge
            style={styles.badgeStyle}
            href="#pablo"
            color="danger"
            onClick={e => {
              e.preventDefault()
              if (getIsDisabled(userType)) return
              if (window.confirm("削除してもよろしいですか？")) {
                deleteCategory({ variables: { categoryId: row._id, ownerId } })
              }
            }}>
            {t("Delete")}
          </Badge>
        }
      </>
    )
  }

  const { loading, error, data } = useCategoryMasterQuery(ownerId)

  const [items, setItems] = useState([])
  useEffect(() => {
    if (data && data.categoryMasters) {
      setItems(data.categoryMasters)
    }
  }, [data])
  const [updateCategoryOrders] = useMutation(UPDATE_CATEGORY_ORDERS, {
    refetchQueries: [
      {
        query: getCategoryMasterQuery,
        variables: { id: ownerId }
      }
    ],
    onError: e => console.log
  })
  function onSortEnd({ oldIndex, newIndex }) {
    if (getIsDisabled(userType)) return
    const newItems = arrayMove(items, oldIndex, newIndex)
    const newItemIds = newItems.map(item => item._id)
    setItems(newItems)
    updateCategoryOrders({
      variables: {
        ownerId,
        categoryIds: newItemIds
      }
    })
  }

  const columns = [
    {
      id: "titel",
      name: t("CategoryTitle"),
      cell: row => <p className="mb-0 text-sm">{row.title}</p>,
      minWidth: 200,
      maxWidth: 500,
    },
    {
      id: "action",
      name: t("Actions"),
      cell: row => <ActionButtons row={row} />,
      maxWidth: 500,
    }
  ]

  if (error) {
    let message = ""
    if (error.message.indexOf("Forbidden") !== -1) message = t("Don'tHavePermission")
    else message = `${t("Error")}! ${error.message}`

    return (
      <div >
        <Header />
        <p style={{ marginTop: 30, textAlign: "center" }}>{message}</p>
      </div>
    )
  }

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <CategoryMasterModal
          ownerId={ownerId}
          items={items}
          modalLoading={modalLoading}
          setModalLoading={setModalLoading}
        />
        <Row className="mt-5">
          <div className="col">
            <Card className="shadow">
              {loading
                ? <CustomLoader />
                : <SortableTable
                  items={items}
                  onSortEnd={onSortEnd}
                  columns={columns}
                />
              }
            </Card>
          </div>
        </Row>
        <Modal
          className="modal-dialog-centered"
          size="lg"
          isOpen={editModal}
          toggle={() => { if (!modalLoading) toggleModal(null) }}
        >
          <CategoryMasterModal
            category={category}
            ownerId={ownerId}
            modalLoading={modalLoading}
            setModalLoading={setModalLoading}
          />
        </Modal>
      </Container>
    </>
  )
}
export default CategoryMasterComponent
