import React, { useContext, useState } from 'react'
import { withTranslation } from 'react-i18next'
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
  UncontrolledAlert
} from 'reactstrap'

import {
  // Redirect,
  useHistory
} from 'react-router-dom'
import { ownerLogin } from '../apollo/server'
import { validateFunc } from '../constraints/constraints'
import { useMutation, gql, } from "@apollo/client"
import { AppContext } from '../app'

const LOGIN = gql`
  ${ownerLogin}
`

const Login = props => {
  const { t } = props
  const [stateData, setStateData] = useState({
    email: '',
    password: '',
    emailError: null,
    passwordError: null,
    error: null,
    type: null, /// 0 for vendor
    redirectToReferrer: !!localStorage.getItem('user-enatega')
  })
  const history = useHistory()
  const authContext = useContext(AppContext)

  const validate = () => {
    const emailError = !validateFunc({ email: stateData.email }, 'email')
    const passwordError = !validateFunc(
      { password: stateData.password },
      'password'
    )
    setStateData({ ...stateData, emailError, passwordError })
    return emailError && passwordError
  }

  function onCompleted(data) {
    localStorage.setItem('user-enatega', JSON.stringify(data.ownerLogin))
    const userType = data && data.ownerLogin && data.ownerLogin.userType ? data.ownerLogin.userType : ""
    const userId = data && data.ownerLogin && data.ownerLogin.userId ? data.ownerLogin.userId : ""
    authContext.setUserType(userType)
    authContext.setOwnerId(userId)
    if (userType === 'VENDOR' || userType === "AUTHORIZED_VENDOR") {
      history.push("/restaurant/list")
    } else {
      history.push("/super_admin/vendors")
    }
  }

  function onError(error) {
    console.log(JSON.stringify(error))
    if (error && error.graphQLErrors && error.graphQLErrors[0]) {
      console.error(error.graphQLErrors[0].message)
      setStateData({
        error: t('LoginErrorMessage')
      })
    }
    if (error && error.networkErrorr && error.networkError.result.errors[0]) {
      console.error(error.networkError.result.errors[0].message)
      setStateData({
        error: t('LoginErrorMessage')
      })
    }
  }
  const [ownerLogin] = useMutation(LOGIN, { onCompleted, onError, })

  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-transparent pb-5">
            <div className="text-muted text-center mt-2 mb-3">
              <small>{t('Sign in credentials')}</small>
            </div>
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-5">
            <Form role="form">
              <FormGroup
                className={
                  stateData.emailError === null
                    ? ''
                    : stateData.emailError
                      ? 'has-success'
                      : 'has-danger'
                }>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    autoCapitalize="none"
                    value={stateData.email}
                    onChange={event => {
                      setStateData({ ...stateData, email: event.target.value })
                    }}
                    placeholder={t('Email')}
                    type="email"
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup
                className={
                  stateData.passwordError === null
                    ? ''
                    : stateData.passwordError
                      ? 'has-success'
                      : 'has-danger'
                }>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    value={stateData.password}
                    onChange={event => {
                      setStateData({
                        ...stateData,
                        password: event.target.value
                      })
                    }}
                    placeholder={t('Password')}
                    type="password"
                  />
                </InputGroup>
              </FormGroup>

              <div className="text-center">
                <Button
                  className="my-4"
                  color="primary"
                  type="button"
                  onClick={() => {
                    setStateData({
                      ...stateData,
                      emailError: null,
                      passwordError: null
                    })
                    if (validate()) {
                      ownerLogin({ variables: { ...stateData } })
                    }
                  }}>
                  {t('Sign in')}
                </Button>
              </div>
              {stateData.error && (
                <UncontrolledAlert color="danger" fade={true}>
                  <span className="alert-inner--text">{stateData.error}</span>
                </UncontrolledAlert>
              )}
            </Form>
          </CardBody>
        </Card>
      </Col>
    </>
  )
}

export default withTranslation()(Login)
