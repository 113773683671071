import React, { useState } from 'react'

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
  UncontrolledAlert
} from 'reactstrap'
import { withTranslation } from 'react-i18next'

import { resetPassword } from '../apollo/server'
import { validateFunc } from '../constraints/constraints'
import { useMutation, gql, } from "@apollo/client"

const RESET_PASSWORD = gql`
  ${resetPassword}
`

const ResetPassword = props => {
  const { t } = props
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [confirmPasswordError, setConfirmPasswordError] = useState(null)
  const [passwordError, setPasswordError] = useState(null)
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(null)
  // const onBlur = (event, field) => {
  //   if (field === 'password') {
  //     setPasswordError(!validateFunc({ password: password }, 'password'))
  //   } else if (field === 'confirmPassword') {
  //     setConfirmPasswordError(
  //       !validateFunc(
  //         { confirmPassword: confirmPassword, password: password },
  //         'confirmPassword'
  //       )
  //     )
  //   }
  // }
  const validate = () => {
    const confirmPasswordErrorDisplay = !validateFunc(
      { password: password, confirmPassword: confirmPassword },
      'confirmPassword'
    )
    const passwordErrorDisplay = !validateFunc(
      { password: password },
      'password'
    )
    setConfirmPasswordError(confirmPasswordErrorDisplay)
    setPasswordError(passwordErrorDisplay)
    return confirmPasswordErrorDisplay && passwordErrorDisplay
  }
  const onCompleted = data => {
    setConfirmPasswordError(null)
    setPasswordError(null)
    setSuccess(t('PasswordChanged'))
  }

  const [resetPassword] = useMutation(RESET_PASSWORD, {
    onCompleted: onCompleted,
    onError: error => {
      setConfirmPasswordError(null)
      setPasswordError(null)
      console.error(error.networkError.result.errors[0].message)
      setError(t('ErrorMessage'))
    }
  })

  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-transparent pb-5">
            <div className="text-muted text-center mt-2 mb-3">
              {t('Reset Password')}
            </div>
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-5">
            <Form role="form">
              <FormGroup
                className={
                  passwordError === null
                    ? ''
                    : passwordError
                      ? 'has-success'
                      : 'has-danger'
                }>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    autoCapitalize="none"
                    value={password}
                    onChange={event => {
                      setPassword(event.target.value)
                    }}
                    // onBlur={event => {
                    //   onBlur(event, 'password')
                    // }}
                    placeholder={t('NewPassword')}
                    type="password"
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup
                className={
                  confirmPasswordError === null
                    ? ''
                    : confirmPasswordError
                      ? 'has-success'
                      : 'has-danger'
                }>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    autoCapitalize="none"
                    value={confirmPassword}
                    onChange={event => {
                      setConfirmPassword(event.target.value)
                    }}
                    // onBlur={event => {
                    //   onBlur(event, 'confirmPassword')
                    // }}
                    placeholder={t('ConfirmNewPassword')}
                    type="password"
                  />
                </InputGroup>
              </FormGroup>
              <div className="text-center">
                <Button
                  className="my-4"
                  color="primary"
                  type="button"
                  onClick={() => {
                    setConfirmPasswordError(null)
                    setPasswordError(null)
                    setError(null)
                    setSuccess(null)
                    const params = new URLSearchParams(
                      props.location.search
                    )
                    if (validate() && params.get('reset')) {
                      resetPassword({
                        variables: {
                          password: password,
                          token: params.get('reset')
                        }
                      })
                    }
                  }}>
                  {t('Reset')}
                </Button>
              </div>
              {error && (
                <UncontrolledAlert color="danger" fade={true}>
                  <span className="alert-inner--text">{error}</span>
                </UncontrolledAlert>
              )}
              {success && (
                <UncontrolledAlert color="success" fade={true}>
                  <span className="alert-inner--text">{success}</span>
                </UncontrolledAlert>
              )}
            </Form>
          </CardBody>
        </Card>
      </Col>
    </>
  )
}

export default withTranslation()(ResetPassword)
