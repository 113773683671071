import React, { useContext, useEffect, useState } from "react"
import {
  Card,
  Container,
} from "reactstrap"
import Header from "../../components/Headers/Header"
import { calcBusinessEndTime, calcBusinessStartTime, getBusinessDate, getFirstDayOfMonth, } from "../../utils/calcBusinessTime"
import FormItems from "./FormItems"
import DataTable from "react-data-table-component"
import CustomLoader from "../../components/Loader/CustomLoader"
import { useTranslation } from "react-i18next"
import { useZoneStatistics, useZoneStatisticsForStore } from "../../domain/graphql/query/useZoneStatistics"
import { paginationComponentOptions } from "../../variables/table"
import Map from "./Map"
import { AppContext } from "../../app"

const ZoneStatistics: React.FC = () => {

  const { t } = useTranslation()
  const appContext = useContext(AppContext)
  const restaurantId = appContext.restaurantId
  const ownerId = appContext.ownerId
  const [mode, setMode] = useState<"order" | "store">("order")

  const businessToday = getBusinessDate(new Date())
  const [startDate, setStartDate] = useState(getFirstDayOfMonth(businessToday))
  const [endDate, setEndDate] = useState(businessToday)

  const businessStartTime = calcBusinessStartTime(startDate).getTime()
  const businessEndTime = calcBusinessEndTime(endDate).getTime()


  const href = window.location.href
  const isAdmin = href.includes("super_admin")
  const isVendor = href.includes("vendor_admin")
  const isStore = href.includes("store_admin")

  const adminResult = useZoneStatistics({
    start: businessStartTime,
    end: businessEndTime,
    mode,
  }, isAdmin)

  const storeResult = useZoneStatisticsForStore({
    start: businessStartTime,
    end: businessEndTime,
    mode: isVendor ? "vendor" : "store",
    ownerId: ownerId,
    restaurantId: restaurantId,
  }, isVendor || isStore )

  useEffect(() => {
    if (isAdmin && adminResult.data) adminResult.refetch()
    if ((isVendor || isStore) && storeResult.data) storeResult.refetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, isAdmin, isVendor, isStore])

  const countName = mode === "order"
    ? t("OrderCount")
    : t("StoreCount")
  const columns = [
    {
      name: t("TownName"),
      selector: "title",
      sortable: true,
      cell: row => <>{row.title}</>,
      maxWidth: "250px",
    },
    {
      name: countName,
      selector: "count",
      sortable: true,
      cell: row => <>{row.count}</>,
    },
  ]

  const error =
    adminResult?.error
    ?? storeResult?.error
    ?? null
  const loading =
    adminResult?.loading
    ?? storeResult?.loading
    ?? false
  const data =
    adminResult?.data?.zoneStatistics
    ?? storeResult?.data?.zoneStatisticsForStore
    ?? []

  if (error) {
    console.log(error.message)
  }

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <FormItems
          isAdmin={isAdmin}
          mode={mode}
          setMode={setMode}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
        />

        <Map data={data} />

        <Card className="shadow">
          <DataTable
            columns={columns}
            data={data}
            progressPending={loading}
            progressComponent={<CustomLoader />}
            pagination
            paginationComponentOptions={paginationComponentOptions}
            paginationPerPage={20}
            paginationRowsPerPageOptions={[20, 30, 40, 50, 100]}
            noDataComponent={t("NoData")}
          />
        </Card>
      </Container>
    </>
  )
}

export default ZoneStatistics