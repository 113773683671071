import { useQuery } from "@apollo/client"
import gql from "graphql-tag"

const storeSalesboardUserDataQuery = gql`query ($start: Float, $end: Float, $restaurantId: String!) {
  storeSalesboardUserData(start: $start, end: $end, restaurantId: $restaurantId) {
    userId
    orders {
      _id
      orderId
      totalStoreProfit
      createdAt
      items {
        quantity
        foodId
        foodTitle
        variationId
        variationTitle
        variationStoreProfit
        addons {
          addonId
          addonTitle
          options {
            optionId
            optionTitle
            optionStoreProfit
          }
        }
      }
    }
  }
}`

const storeSalesboardUserDataMultiTenantQuery = gql`query ($start: Float, $end: Float, $ownerId: String) {
  storeSalesboardUserDataMultiTenant(start: $start, end: $end, ownerId: $ownerId) {
    userId
    orders {
      _id
      orderId
      totalStoreProfit
      createdAt
      items {
        quantity
        foodId
        foodTitle
        variationId
        variationTitle
        variationStoreProfit
        addons {
          addonId
          addonTitle
          options {
            optionId
            optionTitle
            optionStoreProfit
          }
        }
      }
    }
  }
}`

export type StoreSalesboardUserDataQueryResponse = {
  _id: string
  userId: string
  orders: {
    orderId: string
    totalStoreProfit: number
    createdAt: string
    items: {
      quantity: number
      foodId: string
      foodTitle: string
      variationId: string
      variationTitle: string
      variationStoreProfit: number
      addons: {
        addonId: string
        addonTitle: string
        options: {
          optionId: string
          optionTitle: string
          optionStoreProfit: number
        }[]
      }[]
    }[]
  }[]
}

export type StoreSalesboardUserDataResponse = {
  storeSalesboardUserData: StoreSalesboardUserDataQueryResponse[]
}
export function useStoreSalesboardUserDataQuery(start: number, end: number, restaurantId: string, active: boolean) {
  return useQuery<StoreSalesboardUserDataResponse>(storeSalesboardUserDataQuery, { 
    variables: { start, end, restaurantId },
    skip: !active,
  })
}

export type StoreSalesboardUserDataMultiTenantResponse = {
  storeSalesboardUserDataMultiTenant: StoreSalesboardUserDataQueryResponse[]
}
export function useStoreSalesboardUserDataMultiTenantQuery(start: number, end: number, ownerId: string, active: boolean) {
  return useQuery<StoreSalesboardUserDataMultiTenantResponse>(storeSalesboardUserDataMultiTenantQuery, { 
    variables: { start, end, ownerId },
    skip: !active,
  })
}

