import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Row,
  Col,
  Card,
  CardHeader,
  FormGroup,
  Input,
  Button
} from 'reactstrap'
import { validateFunc } from '../../constraints/constraints'
import { saveEmailConfiguration } from '../../apollo/server'
import { useMutation, gql, } from "@apollo/client"

const SAVE_EMAIL_CONFIGURATION = gql`
  ${saveEmailConfiguration}
`

function Email(props) {
  const { t } = useTranslation()
  const [email, setEmail] = useState(props.email ?? "")
  const [password, setPassword] = useState(props.password ?? "")
  const [enableEmail, setEnableEmail] = useState(props.enabled)

  const [emailError, emailErrorSetter] = useState(null)
  const [passwordError, passwordErrorSetter] = useState(null)

  const validateInput = () => {
    let emailResult = true
    let passwordResult = true
    emailResult = !validateFunc(
      { email: email.trim() },
      'email'
    )
    passwordResult = !validateFunc(
      { password: password.trim() },
      'password'
    )
    emailErrorSetter(emailResult)
    passwordErrorSetter(passwordResult)
    return emailResult && passwordResult
  }
  const onCompleted = data => {
    console.log(data)
  }
  const onError = error => {
    console.log(error)
  }
  const SubmitButton = () => {
    const [saveConfiguration, { loading, error }] = useMutation(SAVE_EMAIL_CONFIGURATION, {
      onCompleted,
      onError,
    })
    if (error) return <p>{t("Error")}</p>

    return (
      <Button
        className="btn-block mb-2"
        type="button"
        color="primary"
        onClick={e => {
          e.preventDefault()
          if (!loading && validateInput()) {
            saveConfiguration({
              variables: {
                configurationInput: {
                  email: email.trim(),
                  password: password.trim(),
                  enableEmail: enableEmail
                }
              }
            })
          }
        }}
        size="lg">
        {loading ? t('Saving') : t('Save')}
      </Button>
    )
  }

  return (
    <Row className="mt-3">
      <div className="col">
        <Card className="shadow">

          <CardHeader className="border-0">
            <h3 className="mb-0">{t('EmailConfig')}</h3>
          </CardHeader>

          <Row className="ml-3 mr-3">
            <Col md="4">
              <label className="form-control-label" htmlFor="input-email">
                {t('SendingEmail')}
              </label>
              <FormGroup
                className={
                  emailError === null
                    ? ''
                    : emailError
                      ? 'has-success'
                      : 'has-danger'
                }>
                <Input
                  autoCapitalize="none"
                  className="form-control-alternative"
                  id="input-email"
                  name="input-email"
                  placeholder="sample@email.com"
                  type="email"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                />
              </FormGroup>
            </Col>

            <Col md="4">
              <label
                className="form-control-label"
                htmlFor="input-password">
                {t('Password')}
              </label>
              <FormGroup
                className={
                  passwordError === null
                    ? ''
                    : passwordError
                      ? 'has-success'
                      : 'has-danger'
                }>
                <Input
                  autoCapitalize="none"
                  className="form-control-alternative"
                  id="input-password"
                  name="input-password"
                  placeholder="samplepassword"
                  type="password"
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                />
              </FormGroup>
            </Col>

            <Col md="2">
              <label className="form-control-label" htmlFor="input-enable">
                {t('Enable/Disable')}
              </label>
              <FormGroup>
                <label className="custom-toggle">
                  <input
                    name="input-enable"
                    checked={enableEmail}
                    type="checkbox"
                    onChange={e => setEnableEmail(e.target.checked)}
                  />
                  <span className="custom-toggle-slider rounded-circle" />
                </label>
              </FormGroup>
            </Col>
            <Col md="2">
              <SubmitButton />
            </Col>
          </Row>
        </Card>
      </div>
    </Row>
  )
}
export default Email
