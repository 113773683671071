export function setOwnerId(ownerId: string): void {
  localStorage.setItem("ownerId", ownerId)
}

export function getOwnerId(): string {
  return localStorage.getItem("ownerId") ?? ""
}

export function removeOwnerId(): void {
  localStorage.removeItem('ownerId')
}

export function setRestaurantId(restaurantId: string): void {
  localStorage.setItem("restaurant_id", restaurantId)
}

export function getRestaurantId(): string {
  return localStorage.getItem("restaurant_id") ?? ""
}

export function removeRestaurantId(): void {
  localStorage.removeItem('restaurant_id')
}

export const getUserType = () => {
  const result = localStorage.getItem('user-enatega')
  return result ? JSON.parse(result).userType : null
}