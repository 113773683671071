/* eslint-disable react/display-name */
import React, { useContext, useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
// reactstrap components
import { Badge, Card, Container, Row, Media, Modal } from 'reactstrap'
// core components
import Header from '../../components/Headers/Header'
import { getInfoCarousels, toggleInfoCarouselAvailability, updateInfoCarouselOrders, deleteInfoCarousel, } from '../../apollo/server'
import InfoCarouselComponent from './InfoCarouselComponent'
import CustomLoader from '../../components/Loader/CustomLoader'
import { transformToNewline } from '../../utils/stringManipulations'
import Loader from 'react-loader-spinner'
import { getIsDisabled } from '../../utils/getIsDisabled'
import { useQuery, useMutation, gql, } from "@apollo/client"
import { SortableTable } from '../../components/SortableTable'
import { arrayMove } from '../../utils/arrayMove'
import styles from '../styles'
import { AppContext } from '../../app'

const GET_INFOS = gql`${getInfoCarousels}`
const TOGGLE_INFO = gql`${toggleInfoCarouselAvailability}`
const UPDATE_INFO_ORDERS = gql`${updateInfoCarouselOrders}`
const DELETE_INFO = gql`${deleteInfoCarousel}`

const InfoCarousel = props => {
  const { t } = props
  const appContext = useContext(AppContext)
  const userType = appContext.userType
  const [editModal, setEditModal] = useState(false)
  const [infoCarousel, setInfoCarousel] = useState(null)

  const [items, setItems] = useState([])

  const [updateInfoOrders] = useMutation(UPDATE_INFO_ORDERS, {
    refetchQueries: [ { query: GET_INFOS, } ],
    onError: e => console.log
  })

  const toggleModal = info => {
    setEditModal(!editModal)
    setInfoCarousel(info)
  }

  const ActionButtons = props => {
    const { row } = props
    const [deleteInfoCarousel, { loading: deleteLoading }] = useMutation(DELETE_INFO, {
      refetchQueries: [
        { query: GET_INFOS, },
      ],
      onError: e => console.log
    })
    if (deleteLoading) {
      return (
        <Loader
          type="ThreeDots"
          color="#BB2124"
          height={20}
          width={40}
          visible={deleteLoading}
        />
      )
    }
    return (
      <>
        <Badge
          style={styles.badgeStyle}
          href="#pablo"
          onClick={e => {
            e.preventDefault()
            toggleModal(row)
          }}
          color="primary">
          {t('Edit')}
        </Badge>
        {" "}
        <Badge
          style={styles.badgeStyle}
          href="#pablo"
          color="danger"
          onClick={e => {
            e.preventDefault()
            if (getIsDisabled(userType)) return
            if (window.confirm('削除してもよろしいですか？')) {
              deleteInfoCarousel({
                variables: { id: row._id, }
              })
            }
          }}>
          {t('Delete')}
        </Badge>
      </>
    )
  }

  const { loading, error, data } = useQuery(GET_INFOS)

  useEffect(() => {
    if (data && data.infoCarousels) {
      setItems(data.infoCarousels)
    }
  }, [data, setItems])

  function onSortEnd({ oldIndex, newIndex }) {
    if (getIsDisabled(userType)) return
    const newItems = arrayMove(items, oldIndex, newIndex)
    const newItemIds = newItems.map(item => item._id)
    setItems(newItems)
    updateInfoOrders({
      variables: {
        ids: newItemIds
      }
    })
  }

  const columns = [
    {
      name: t('Title'),
      cell: row => (
        <>
          <Media>
            <span className="mb-0 text-sm">{row.title}</span>
          </Media>
        </>
      )
    },
    {
      name: t("InfoCarouselDescription"),
      cell: row => <>{transformToNewline(row.description, 3)}</>
    },
    {
      name: t("InfoCarouselLink"),
      cell: row => <>{transformToNewline(row.link, 3)}</>
    },
    {
      name: t('Image'),
      cell: row => (
        <>
          {!!row.image && (
            <img className="img-responsive" src={row.image} alt="img menu" />
          )}
          {!row.image && 'No Image'}
        </>
      )
    },
    {
      name: t("Show"),
      maxWidth: '100px',
      cell: row => <AvailableStatus row={row} />
    },
    {
      name: t('Action'),
      cell: row => <ActionButtons row={row} />
    }
  ]

  const AvailableStatus = props => {
    const { row } = props
    const [on, setOn] = useState(true)
    useEffect(() => {
      setOn(row.isAvailable)
    }, [row])

    const [toggleInfoCarousel] = useMutation(TOGGLE_INFO, {
      refetchQueries: [
        { query: GET_INFOS, }
      ],
      onError: e => console.log
    })
    return (
      <>
        {row.isAvailable}
        <label className="custom-toggle mt-3">
          <input
            checked={on}
            type="checkbox"
            onChange={event => {
              setOn(event.target.checked)
              toggleInfoCarousel({ variables: { id: row._id, } })
            }}
          />
          <span className="custom-toggle-slider rounded-circle" />
        </label>
      </>
    )
  }

  if (error) {
    return (
      <span>
        {`${'Error'}! ${error.message}`}
      </span>
    )
  }

  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <InfoCarouselComponent />
        {/* Table */}
        <Row className="mt-5">
          <div className="col">
            <Card className="shadow">
              {loading
                ? <CustomLoader />
                : <SortableTable
                  items={items}
                  onSortEnd={onSortEnd}
                  columns={columns}
                  useDragHandle={true}
                />
              }
            </Card>
          </div>
        </Row>

        <Modal
          className="modal-dialog-centered"
          size="lg"
          isOpen={editModal}
          toggle={() => {
            toggleModal()
          }}>
          <InfoCarouselComponent infoCarousel={infoCarousel} />
        </Modal>

      </Container>
    </>
  )
}

export default withTranslation()(InfoCarousel)
