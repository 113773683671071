export class TaxCalculator {
  constructor() {
    this._TAX_RATE = 0.1
  }

  tax(price) {
    return Math.ceil(Number(price) * this._TAX_RATE)
  }

  taxInPrice(price) {
    return Number(price) + this.tax(price)
  }

  nonTaxedPrice(taxInPrice) {
    return Math.floor(Number(taxInPrice) * 10 / ((1 + this._TAX_RATE) * 10))
  }
}
