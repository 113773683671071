import React, { useState, Dispatch, SetStateAction, } from "react"
import {
  Card,
  CardBody,
  Row,
  FormGroup,
  Input,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ButtonGroup,
  Button,
} from "reactstrap"
import { dateToJapanese, toISOLocaleString } from "../../utils/date"
import { downloadCSV } from "../../utils/downloadCSV"
import { useTranslation } from "react-i18next"
import { CompletedOrdersQueryResponse } from "../../domain/graphql/query/useCompletedOrdersQuery"
import { retrieveRiders, Rider, transformOrdersToStores } from "./lib"


interface Props {
  mode: "order" | "orderTimes" | "store"
  setMode: Dispatch<SetStateAction<"order" | "orderTimes" | "store">>
  filteredOrders: CompletedOrdersQueryResponse[]
  filteredStores: ReturnType<typeof transformOrdersToStores>
  showGraphs: boolean
  setShowGraphs: Dispatch<SetStateAction<boolean>>
  startDate: Date
  setStartDate: Dispatch<SetStateAction<Date>>
  endDate: Date
  setEndDate: Dispatch<SetStateAction<Date>>
  riders: ReturnType<typeof retrieveRiders>
  selectedRider: Rider
  setSelectedRider: Dispatch<SetStateAction<Rider>>
  selectedStatus: string
  setSelectedStatus: Dispatch<SetStateAction<string>>
}
const FormItems: React.FC<Props> = props => {
  const { t } = useTranslation()
  const statusList = {
    DELIVERED: t("DELIVERED"),
    CANCELLED: t("CANCELLED"),
    CANCELLED_BY_USER: t("CANCELLED_BY_USER"),
  }

  const [isOpenRiderDD, setIsOpenRiderDD] = useState(false)
  const toggleRiderDD = () => setIsOpenRiderDD(prev => !prev)
  const [isOpenStatusDD, setIsOpenStatusDD] = useState(false)
  const toggleStatusDD = () => setIsOpenStatusDD(prev => !prev)


  function download() {
    if (window.confirm("注文データをCSV形式でダウンロードしますか？")) {
      let csv: string
      if (props.mode === "order") {
        csv = "店舗ID,店舗名,注文番号,配達日時,対応ライダー,回収,支払い,支払い方法,売上\n"
        props.filteredOrders.forEach(order => { csv += `${order.restaurant._id},${order.restaurant.name},${order.orderId},${dateToJapanese(new Date(order.updatedAt))},${order.rider ? order.rider.name : ""},${order.orderStatus === "DELIVERED" ? order.orderAmount : 0},${order.storeProfit},${order.paymentMethod},${order.orderStatus === "DELIVERED" ? order.orderAmount - order.storeProfit : -order.storeProfit}\n` })
      } else {
        csv = "店舗ID,店舗名,注文数,回収,支払い,売上\n"
        props.filteredStores.forEach(store => { csv += `${store._id},${store.name},${store.count},${store.receipt},${store.pay},${store.sales}\n` })
      }
      const now = new Date()
      const filename = `${props.mode}_${now.getFullYear()}${("" + (now.getMonth() + 1)).padStart(2, "0")}${("" + now.getDate()).padStart(2, "0")}.csv`
      downloadCSV(filename, csv)
    }
  }


  return (
    <Row className="mb-lg-3">
      <div className="col">
        <Card className="shadow">
          <CardBody>
            <Row>
              <Col xl="4">
                <FormGroup>
                  <label className="form-control-label"> {t("SalesBoardMode")} </label>
                  <br />
                  <ButtonGroup>
                    <Button
                      color={props.mode === "order" ? "primary" : "secondary"}
                      onClick={() => props.setMode("order")}
                    >{t("Order")}</Button>
                    <Button
                      color={props.mode === "orderTimes" ? "primary" : "secondary"}
                      onClick={() => props.setMode("orderTimes")}
                    >{t("OrderTimes")}</Button>
                    <Button
                      color={props.mode === "store" ? "primary" : "secondary"}
                      onClick={() => props.setMode("store")}
                    >{t("StoreMode")}</Button>
                  </ButtonGroup>
                </FormGroup>
              </Col>

              <Col xl="4">
                <FormGroup>
                  <label className="form-control-label"> {t("Graphs")} </label>
                  <br />
                  <ButtonGroup>
                    <Button
                      color={props.showGraphs ? "primary" : "secondary"}
                      onClick={() => props.setShowGraphs(prev => !prev)}
                    >{t("Show")}</Button>
                    <Button
                      color={props.showGraphs ? "secondary" : "primary"}
                      onClick={() => props.setShowGraphs(prev => !prev)}
                    >{t("Hide")}</Button>
                  </ButtonGroup>
                </FormGroup>
              </Col>

              <Col xl="1">
                <FormGroup>
                  <label className="form-control-label" > CSV </label>
                  <br />
                  <Button color="primary" onClick={download} >DL</Button>
                </FormGroup>
              </Col>

            </Row>

            <Row>
              <Col xl="4">
                <FormGroup>
                  <label className="form-control-label"> {t("Starting Date")} </label>
                  <br />
                  <Input
                    className="form-control-alternative"
                    type="date"
                    min={"2021-04-01"}
                    max={toISOLocaleString(new Date())}
                    onChange={event => props.setStartDate(new Date(event.target.value))}
                    value={toISOLocaleString(props.startDate)}
                  />
                </FormGroup>
              </Col>

              <Col xl="4">
                <FormGroup>
                  <label className="form-control-label"> {t("Ending Date")} </label>
                  <br />
                  <Input
                    className="form-control-alternative"
                    type="date"
                    min={"2021-04-01"}
                    max={toISOLocaleString(new Date())}
                    onChange={event => props.setEndDate(new Date(event.target.value))}
                    value={toISOLocaleString(props.endDate)}
                  />
                </FormGroup>
              </Col>
              <Col xl="2">
                <FormGroup>
                  <label className="form-control-label" > {t("AssignedRider")} </label>
                  <br />
                  <Dropdown isOpen={isOpenRiderDD} toggle={toggleRiderDD} >
                    <DropdownToggle caret>{props.selectedRider ? props.selectedRider.name : t("ALL")}</DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem onClick={() => props.setSelectedRider(null)} > {t("ALL")}</DropdownItem>
                      {Object.keys(props.riders).map(rider =>
                        <DropdownItem key={rider} onClick={() => props.setSelectedRider(props.riders[rider])} >
                          {rider}
                        </DropdownItem>
                      )}
                    </DropdownMenu>
                  </Dropdown>
                </FormGroup>
              </Col>

              <Col xl="2">
                <FormGroup>
                  <label className="form-control-label" > {t("Filter")} </label>
                  <br />
                  <Dropdown isOpen={isOpenStatusDD} toggle={toggleStatusDD} >
                    <DropdownToggle caret>{props.selectedStatus ? statusList[props.selectedStatus] : t("ALL")}</DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem onClick={() => props.setSelectedStatus(null)} > {t("ALL")}</DropdownItem>
                      {Object.keys(statusList).map(key =>
                        <DropdownItem key={key} onClick={() => props.setSelectedStatus(key)} >
                          {statusList[key]}
                        </DropdownItem>
                      )}
                    </DropdownMenu>
                  </Dropdown>
                </FormGroup>
              </Col>

            </Row>
          </CardBody>
        </Card>
      </div>
    </Row>
  )
}

export default FormItems

