import { gql, useQuery } from "@apollo/client"

export const getRestaurantQuery = gql`query ($id:String) {
  restaurant(id:$id) {
    _id
    orderId
    orderPrefix
    name
    phone
    image
    address
    location {
      coordinates
    }
    deliveryCharges
    chargeLimit
    commission
    deliveryTime
    minimumOrder
    isRawPrice

    openTime
    closeTime
    openTime2
    closeTime2

    mondayOpenTime
    mondayCloseTime
    mondayOpenTime2
    mondayCloseTime2

    tuesdayOpenTime
    tuesdayCloseTime
    tuesdayOpenTime2
    tuesdayCloseTime2

    wednesdayOpenTime
    wednesdayCloseTime
    wednesdayOpenTime2
    wednesdayCloseTime2

    thursdayOpenTime
    thursdayCloseTime
    thursdayOpenTime2
    thursdayCloseTime2

    fridayOpenTime
    fridayCloseTime
    fridayOpenTime2
    fridayCloseTime2

    saturdayOpenTime
    saturdayCloseTime
    saturdayOpenTime2
    saturdayCloseTime2

    sundayOpenTime
    sundayCloseTime
    sundayOpenTime2
    sundayCloseTime2

    holidayOpenTime
    holidayCloseTime
    holidayOpenTime2
    holidayCloseTime2

    beforeHolidayOpenTime
    beforeHolidayCloseTime
    beforeHolidayOpenTime2
    beforeHolidayCloseTime2

    regularHolidays
    tempHolidays

    zone {
      _id
      title
    }
    categories {
      _id
      title
      foods {
        _id
        title
        description
        variations {
          _id
          title
          price
          taxInPrice
          storeProfit
          discounted
          janCode
          addons
        }
        image
        isActive
        isAvailable
      }
    }
    options {
      _id
      title
      description
      taxInPrice
      storeProfit
    }
    addons {
      _id
      options
      title
      description
      quantityMinimum
      quantityMaximum
      memo
    }
  }
}`

export type RestaurantQueryResponse = {
    _id: string;
    orderId: number;
    orderPrefix: string;
    name: string;
    phone: string;
    image: string;
    address: string;
    location: {
      coordinates: string[];
    };
    deliveryCharges: number;
    chargeLimit: number;
    commission: number;
    deliveryTime: number;
    minimumOrder: number;
    isRawPrice: boolean

    openTime: string;
    closeTime: string;
    openTime2: string;
    closeTime2: string;

    mondayOpenTime: string;
    mondayCloseTime: string;
    mondayOpenTime2: string;
    mondayCloseTime2: string;

    tuesdayOpenTime: string;
    tuesdayCloseTime: string;
    tuesdayOpenTime2: string;
    tuesdayCloseTime2: string;

    wednesdayOpenTime: string;
    wednesdayCloseTime: string;
    wednesdayOpenTime2: string;
    wednesdayCloseTime2: string;

    thursdayOpenTime: string;
    thursdayCloseTime: string;
    thursdayOpenTime2: string;
    thursdayCloseTime2: string;

    fridayOpenTime: string;
    fridayCloseTime: string;
    fridayOpenTime2: string;
    fridayCloseTime2: string;

    saturdayOpenTime: string;
    saturdayCloseTime: string;
    saturdayOpenTime2: string;
    saturdayCloseTime2: string;

    sundayOpenTime: string;
    sundayCloseTime: string;
    sundayOpenTime2: string;
    sundayCloseTime2: string;

    holidayOpenTime: string;
    holidayCloseTime: string;
    holidayOpenTime2: string;
    holidayCloseTime2: string;

    beforeHolidayOpenTime: string;
    beforeHolidayCloseTime: string;
    beforeHolidayOpenTime2: string;
    beforeHolidayCloseTime2: string;

    regularHolidays: number[];
    tempHolidays: string[];

    zone: {
      _id: string;
      title: string;
    };
    categories: RestaurantQueryResponseCategory[];
    options: RestaurantQueryResponseOption[];
    addons: RestaurantQueryResponseAddon[];
}

export type RestaurantQueryResponseCategory = {
  _id: string;
  title: string;
  foods: {
    _id: string;
    title: string;
    description: string;
    variations: {
      _id: string;
      title: string;
      price: number;
      taxInPrice: number;
      storeProfit: number;
      discounted: number;
      janCode?: string
      addons: string[];
    }[];
    image: string;
    isActive: boolean;
    isAvailable: boolean;
  }[];
}
export type RestaurantQueryResponseOption = {
  _id: string;
  title: string;
  description: string;
  taxInPrice: number;
  storeProfit: number;
}

export type RestaurantQueryResponseAddon = {
  _id: string;
  options: string[];
  title: string;
  description: string;
  quantityMinimum: number;
  quantityMaximum: number;
  memo: string;
}

type RestaurantQueryInput = {
  id: string
}

type Response = undefined | {
  restaurant?: RestaurantQueryResponse
} 

export function useRestaurantQuery(id: string) {
  return useQuery<Response, RestaurantQueryInput>(getRestaurantQuery, {
    variables: { id: id },
    skip: !id,
  })
}