import React, { useContext, useState } from "react"
import { useTranslation } from "react-i18next"
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  Row,
  Col,
  Alert,
  ListGroup,
  ListGroupItem,
  Badge,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown
} from "reactstrap"
import { validateFunc } from "../../constraints/constraints"
import { updateOrderStatus, getConfiguration } from "../../apollo/server"
import Loader from "react-loader-spinner"
import { getIsDisabled } from "../../utils/getIsDisabled"
import { orderItemAdapter } from "../../utils/orderItemAdapter"
import { convertOrderStatus } from "../../utils/convertOrderStatus"
import { useMutation, useQuery, gql, } from "@apollo/client"
import { calcDuration, dateToTime } from "../../utils/date"
import { AppContext } from "../../app"

// constants
const UPDATE_STATUS = gql` ${updateOrderStatus} `
const GET_CONFIGURATION = gql` ${getConfiguration} `
function Order(props) {
  console.log("Order")
  const { order } = props
  const { t } = useTranslation()
  const appContext = useContext(AppContext)
  const userType = appContext.userType
  const [reason, reasonSetter] = useState("")
  const [reasonError, reasonErrorSetter] = useState(null)
  const [error, errorSetter] = useState("")
  const [success, successSetter] = useState("")
  let enrichedItems
  if (order) {
    enrichedItems = orderItemAdapter(order.items)
  }

  const { data, loading, queryError } = useQuery(GET_CONFIGURATION)
  const validateReason = () => {
    const reasonError = !validateFunc({ reason }, "reason")
    reasonErrorSetter(reasonError)
    return reasonError
  }
  const onDismiss = () => {
    errorSetter("")
    successSetter("")
  }

  if (!order) return null

  const subtotal = order.taxInSubtotal
  const charge = order.customerCharge
  const storeProfit = order.storeProfit
  const total = order.orderAmount

  const SubmitButton = props => {
    const { isCancel } = props
    const onCompleted = ({ updateOrderStatus }) => {
      if (updateOrderStatus) {
        successSetter(t("UpdateSuccessMessage"))
      }
    }
    const onError = error => {
      console.log(error)
      errorSetter(t("ErrorMessage"))
    }

    const [mutateStatus, { loading, error }] = useMutation(UPDATE_STATUS, {
      onCompleted,
      onError,
    })
    if (loading) {
      return (
        <Loader
          className="text-center"
          type="TailSpin"
          color="#fb6340"
          height={40}
          width={40}
          visible={loading}
        />
      )
    }
    if (error) console.log(error)

    const label = isCancel
      ? t("Cancel")
      : order.orderStatus === "PENDING"
        ? t("Accept")
        : t("ReadyToPick")

    function submit(isCancel) {
      if (getIsDisabled(userType)) return

      if (isCancel) {
        if (validateReason()) {
          mutateStatus({
            variables: {
              id: order._id,
              status: "CANCELLED",
              reason: reason
            }
          })
        }
      } else {
        const status = order.orderStatus === "PENDING"
          ? "ACCEPTED"
          : "READY_TO_PICK"
        mutateStatus({
          variables: {
            id: order._id,
            status,
            reason: ""
          }
        })
      }
    }
    let disabled = false
    if (isCancel) {
      if (order.orderStatus !== "PENDING") {
        disabled = true
      }
    } else {
      if (order.orderStatus !== "PENDING" && order.orderStatus !== "ACCEPTED") {
        disabled = true
      }
    }

    return <Button
      disabled={disabled}
      color={isCancel ? "danger" : "success"}
      onClick={() => submit(isCancel)}>
      {label}
    </Button>
  }

  if (loading) return null
  if (queryError) return null

  return (
    <Card className="bg-secondary shadow">
      <CardHeader className="bg-white border-0">
        <Row className="align-items-center">
          <Col xs="8">
            <h3 className="mb-0">
              <Badge
                style={{
                  fontSize: "12px",
                  backgroundColor: "grey",
                  marginRight: "10px"
                }}
                pill
              >
                {convertOrderStatus(order.orderStatus)}
              </Badge>
              {t("Order")}#{order.orderId}{" "}{order.restaurant.name}
            </h3>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Form>
          <div className="pl-lg-4">
            {(error || success) && (
              <Row>
                <Col lg="12">
                  <Alert
                    color="success"
                    isOpen={!!success}
                    fade={true}
                    toggle={onDismiss}>
                    <span className="alert-inner--text">{success}</span>
                  </Alert>
                  <Alert
                    color="danger"
                    isOpen={!!error}
                    fade={true}
                    toggle={onDismiss}>
                    <span className="alert-inner--text">{error}</span>
                  </Alert>
                </Col>
              </Row>
            )}

            {
              (order.orderStatus === "PENDING" || order.orderStatus === "ACCEPTED") &&
              (userType === "ADMIN" || userType === "AUTHORIZED_VENDOR") && (
                <Row className="mb-2">
                  <Col lg="12">
                    <div>
                      <FormGroup
                        className={
                          reasonError === null
                            ? ""
                            : reasonError
                              ? "has-success"
                              : "has-danger"
                        }>
                        <InputGroup>
                          <InputGroupAddon addonType="prepend">
                            <SubmitButton isCancel={false} />
                          </InputGroupAddon>
                          <Input
                            style={{ marginLeft: "5px" }}
                            placeholder={t("RejectReason")}
                            value={order.reason || reason}
                            onChange={event => reasonSetter(event.target.value)}
                            maxLength={500}
                          />
                          <InputGroupAddon addonType="append">
                            <SubmitButton isCancel={true} />
                          </InputGroupAddon>
                        </InputGroup>
                      </FormGroup>
                    </div>
                  </Col>
                </Row>
              )}



            {order.reason &&
              <Row>
                  <Col>
                    <label className="form-control-label">
                      {t("CancelReason")}
                    </label>
                    <FormGroup>
                      <Input
                        readOnly
                        className="form-control-alternative"
                        type="text"
                        defaultValue={order.reason}
                      />
                    </FormGroup>
                  </Col>
                </Row>
            }



            {userType === "ADMIN" &&
              <>
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-1">{t("Customer")}</h3>
                  </Col>
                </Row>
                <Row>
                  <Col lg="4">
                    <label className="form-control-label" htmlFor="input-name">
                      {t("Name")}
                    </label>
                    <FormGroup>
                      <Input
                        readOnly
                        className="form-control-alternative"
                        id="input-name"
                        type="text"
                        defaultValue={order.user ? order.user.name : ""}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="4">
                    <label className="form-control-label" htmlFor="input-phone">
                      {t("Phone")}
                    </label>
                    <FormGroup>
                      <Input
                        readOnly
                        className="form-control-alternative"
                        id="input-phone"
                        type="text"
                        defaultValue={order.user ? order.user.phone : ""}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="4">
                    <label className="form-control-label" htmlFor="input-email">
                      {t("Email")}
                    </label>
                    <FormGroup>
                      <Input
                        readOnly
                        className="form-control-alternative"
                        id="input-email"
                        type="text"
                        defaultValue={order.user ? order.user.email : ""}
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-1">{t("Destination")}</h3>
                  </Col>
                </Row>
                <Row>
                  <Col lg="6">
                    <label className="form-control-label" htmlFor="input-label">
                      {t("Label")}
                    </label>
                    <FormGroup>
                      <Input
                        readOnly
                        className="form-control-alternative"
                        id="input-label"
                        type="text"
                        defaultValue={order.deliveryAddress.label}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <label className="form-control-label" htmlFor="input-destination-phone">
                      {t("Phone")}
                    </label>
                    <FormGroup>
                      <Input
                        readOnly
                        className="form-control-alternative"
                        id="input-destination-phone"
                        type="text"
                        defaultValue={order.deliveryAddress.phone}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col lg="12">
                    <label className="form-control-label" htmlFor="input-address">
                      {t("Address")}
                    </label>
                    <FormGroup>
                      <Input
                        readOnly
                        className="form-control-alternative"
                        id="input-address"
                        type="text"
                        defaultValue={order.deliveryAddress.deliveryAddress + order.deliveryAddress.details}
                      />
                    </FormGroup>
                  </Col>
                </Row>

              </>
            }


            {order.memo &&
                <Row>
                  <Col>
                    <label className="form-control-label">
                      {t("ToStoreMessage")}
                    </label>
                    <FormGroup>
                      <Input
                        readOnly
                        className="form-control-alternative"
                        type="textarea"
                        defaultValue={order.memo}
                      />
                    </FormGroup>
                  </Col>
                </Row>
            }


            {userType === "ADMIN" && order.startPreparationAt &&
              <>
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-1">{t("OrderTimes")}</h3>
                  </Col>
                </Row>
                <Row>
                  <Col lg="2">
                    <label className="form-control-label" htmlFor="input-label">
                      {t("OrderReceivedAt")}
                    </label>
                    <FormGroup>
                      <Input
                        readOnly
                        className="form-control-alternative"
                        id="input-label"
                        type="text"
                        defaultValue={dateToTime(new Date(order.createdAt))}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="2">
                    <label className="form-control-label" htmlFor="input-label">
                      {t("AcceptanceDuration")}
                    </label>
                    <FormGroup>
                      <Input
                        readOnly
                        className="form-control-alternative"
                        id="input-label"
                        type="text"
                        defaultValue={calcDuration(new Date(order.startPreparationAt), new Date(order.createdAt))}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="2">
                    <label className="form-control-label" htmlFor="input-label">
                      {t("PreparationDuration")}
                    </label>
                    <FormGroup>
                      <Input
                        readOnly
                        className="form-control-alternative"
                        id="input-label"
                        type="text"
                        defaultValue={calcDuration(new Date(order.finishPreparationAt), new Date(order.startPreparationAt))}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="2">
                    <label className="form-control-label" htmlFor="input-label">
                      {t("PickupDuration")}
                    </label>
                    <FormGroup>
                      <Input
                        readOnly
                        className="form-control-alternative"
                        id="input-label"
                        type="text"
                        defaultValue={calcDuration(new Date(order.startDeliveryAt), new Date(order.finishPreparationAt))}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="2">
                    <label className="form-control-label" htmlFor="input-label">
                      {t("InDeliveryTime")}
                    </label>
                    <FormGroup>
                      <Input
                        readOnly
                        className="form-control-alternative"
                        id="input-label"
                        type="text"
                        defaultValue={calcDuration(new Date(order.deliveredAt), new Date(order.startDeliveryAt))}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="2">
                    <label className="form-control-label" htmlFor="input-label">
                      {t("TotalTime")}
                    </label>
                    <FormGroup>
                      <Input
                        readOnly
                        className="form-control-alternative"
                        id="input-label"
                        type="text"
                        defaultValue={calcDuration(new Date(order.deliveredAt), new Date(order.createdAt))}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </>
            }


            <Row className="align-items-center">
              <Col xs="8">
                <h3 className="mb-1">{t("Order Details")}</h3>
              </Col>
            </Row>

            <Row>
              <Col lg="8">
                <label
                  className="form-control-label"
                  htmlFor="input-items">
                  {t("Items")}
                </label>
                <FormGroup>
                  <ListGroup id="input-items">
                    {enrichedItems.map(item => {
                      return (
                        <ListGroupItem
                          key={item._id}
                          className="justify-content-between">
                          <Badge
                            style={{
                              fontSize: "12px",
                              backgroundColor: "grey",
                              marginRight: "10px"
                            }}
                            pill>
                            {item.quantity}
                          </Badge>
                          {`${item.title}(${item.variation.title})`}
                          <Badge
                            style={{
                              fontSize: "12px",
                              backgroundColor: "black",
                              float: "right"
                            }}
                            pill>
                            {data && data.configuration && data.configuration.currencySymbol}{" "}
                            {item.variation.taxInPrice * item.quantity}
                          </Badge>
                          {!!item.addons.length && (
                            <UncontrolledDropdown>
                              <DropdownToggle caret>
                                {t("Addons")}
                              </DropdownToggle>
                              <DropdownMenu>
                                {item.addons.map(addon => {
                                  return addon.options.map(
                                    (option, index) => (
                                      <DropdownItem key={index}>
                                        {addon.title}: {option.title}{" "}
                                        <Badge
                                          style={{
                                            fontSize: "12px",
                                            backgroundColor: "black",
                                            float: "right"
                                          }}
                                          pill
                                        >
                                          {data.configuration.currencySymbol}{" "} {option.taxInPrice * item.quantity}
                                        </Badge>
                                      </DropdownItem>
                                    )
                                  )
                                })}
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          )}
                        </ListGroupItem>
                      )
                    })}
                  </ListGroup>
                </FormGroup>
              </Col>

              <Col lg="4">
                <label className="form-control-label" htmlFor="input-rider">
                  {t("AssignedRider")}
                </label>
                <FormGroup>
                  <ListGroup id="input-price">
                    <ListGroupItem className="justify-content-between">
                      {order.rider ? order.rider.name : t("NotSelected")}
                    </ListGroupItem>
                    {userType === "ADMIN" && order.rider && order.rider.phone &&
                      <ListGroupItem className="justify-content-between">
                        {order.rider.phone}
                      </ListGroupItem>
                    }
                  </ListGroup>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col lg="6">
                <FormGroup>
                  <ListGroup id="input-price">
                    <ListGroupItem className="justify-content-between">
                      {t("Subtotal")}
                      <Badge
                        style={{
                          fontSize: "12px",
                          float: "right",
                          color: "black"
                        }}>
                        {data && data.configuration && data.configuration.currencySymbol}{" "} {subtotal}
                      </Badge>
                    </ListGroupItem>


                    {userType === "ADMIN" &&
                      <> <ListGroupItem className="justify-content-between">
                          {t("DeliveryCharges") + "  (" + order.deliveryCharges + "%)"}
                          <Badge
                            style={{
                              fontSize: "12px",
                              float: "right",
                              color: "black"
                            }}>
                            {data && data.configuration && data.configuration.currencySymbol}{" "} {charge}
                          </Badge>
                        </ListGroupItem>

                        <ListGroupItem className="justify-content-between">
                          {t("Total") + t("Ceiled")}
                          <Badge
                            style={{
                              fontSize: "12px",
                              color: "black",
                              float: "right"
                            }}>
                            {data && data.configuration && data.configuration.currencySymbol}{" "} {total}
                          </Badge>
                        </ListGroupItem>
                      </> }
                  </ListGroup>
                </FormGroup>
              </Col>

              <Col lg="6">
                <FormGroup>
                  <ListGroup id="input-price">
                    <ListGroupItem className="justify-content-between">
                      {t("StoreSales")}
                      <Badge
                        style={{
                          fontSize: "12px",
                          color: "black",
                          float: "right"
                        }}>
                        {data && data.configuration && data.configuration.currencySymbol}{" "}
                        {storeProfit}
                      </Badge>
                    </ListGroupItem>
                  </ListGroup>
                </FormGroup>
              </Col>

            </Row>
          </div>
        </Form>
      </CardBody>
    </Card>
  )
}
export default Order
