import React, { useRef, useEffect } from 'react'
import { Route, Switch } from 'react-router-dom'
// reactstrap components
import { Container } from 'reactstrap'
// core components
import AdminNavbar from '../components/Navbars/AdminNavbar'
import AdminFooter from '../components/Footers/AdminFooter'
import Sidebar from '../components/Sidebar/Sidebar'

import Category from '../views/Category'
import Food from '../views/Food'
import Profile from '../views/VendorProfile'
import Orders from '../views/StoreOrders'
import Options from '../views/Options'
import Addons from '../views/Addon'
import Dashboard from '../views/Dashboard'
import Ratings from '../views/Rating'
import ZoneStatistics from '../views/ZoneStatistics'
import StoreSalesBoard from '../views/StoreSalesBoard'
import Restaurant from '../views/Restaurant'

import routes from '../routes'
const sidebarRoutes = [
  /**
   * store admin
   */
  {
    path: '/dashboard',
    name: 'Dashboard',
    icon: 'ni ni-tv-2 text-primary',
    component: Dashboard,
    layout: '/store_admin',
    appearInSidebar: true,
    admin: false
  },
  {
    path: '/profile',
    name: 'Profile',
    icon: 'ni ni-single-02 text-blue',
    component: Profile,
    layout: '/store_admin',
    appearInSidebar: true,
    admin: false
  },
  {
    path: '/category',
    name: 'Category',
    icon: 'ni ni-chart-pie-35 text-red',
    component: Category,
    layout: '/store_admin',
    appearInSidebar: true,
    admin: false
  },
  {
    path: '/food',
    name: 'Food',
    icon: 'ni ni-tie-bow text-green',
    component: Food,
    layout: '/store_admin',
    appearInSidebar: true,
    admin: false
  },
  {
    path: '/addons',
    name: 'Addons',
    icon: 'ni ni-app text-indigo',
    component: Addons,
    layout: '/store_admin',
    appearInSidebar: true,
    admin: false
  },
  {
    path: '/option',
    name: 'Option',
    icon: 'ni ni-atom text-purple',
    component: Options,
    layout: '/store_admin',
    appearInSidebar: true,
    admin: false
  },
  {
    path: '/orders',
    name: 'Orders',
    icon: 'ni ni-delivery-fast text-orange',
    component: Orders,
    layout: '/store_admin',
    appearInSidebar: true,
    admin: false
  },
  // {
  //   path: '/coupons',
  //   name: 'Coupons',
  //   icon: 'ni ni-ungroup text-yellow',
  //   component: Coupons,
  //   layout: '/admin',
  //   appearInSidebar: true,
  //   admin: false
  // },
  {
    path: '/ratings',
    name: 'Ratings',
    icon: 'fas fa-star text-yellow',
    component: Ratings,
    layout: '/store_admin',
    appearInSidebar: true,
    admin: false
  },
  {
    path: '/storeSalesBoard',
    name: 'SalesBoard',
    icon: 'ni ni-tv-2 text-primary',
    component: StoreSalesBoard,
    layout: '/store_admin',
    appearInSidebar: true,
    admin: false
  },
  {
    path: '/zoneStatistics',
    name: 'ZoneStatistics',
    icon: 'ni ni-square-pin text-red',
    component: ZoneStatistics,
    layout: '/store_admin',
    appearInSidebar: true,
    admin: false
  },
  {
    path: '/list',
    name: 'RestaurantList',
    icon: 'ni ni-collection text-yellow',
    component: Restaurant,
    layout: '/vendor_admin',
    appearInSidebar: true,
    admin: false
  },
]

function Admin(props) {
  var divRef = useRef(null)
  useEffect(() => {
    document.documentElement.scrollTop = 0
    document.scrollingElement.scrollTop = 0
    divRef.current.scrollTop = 0
  }, [])
  const getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.layout === '/store_admin') {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        )
      } else {
        return null
      }
    })
  }
  const getBrandText = path => {
    for (let i = 0; i < routes.length; i++) {
      if (
        props.location.pathname.indexOf(routes[i].layout + routes[i].path) !==
        -1
      ) {
        return routes[i].name
      }
    }
    return 'Brand'
  }

  return (
    <>
      <Sidebar
        {...props}
        routes={sidebarRoutes}
        logo={{
          innerLink: '/store_admin/dashboard',
          imgSrc: require('../assets/img/brand/logo.png'),
          imgAlt: '...'
        }}
      />
      <div className="main-content" ref={divRef}>
        <AdminNavbar
          {...props}
          brandText={getBrandText(props.location.pathname)}
        />
        <Switch>{getRoutes(routes)}</Switch>
        <Container fluid>
          <AdminFooter />
        </Container>
      </div>
    </>
  )
}

export default Admin
