import { CompletedOrdersQueryResponse } from "../../domain/graphql/query/useCompletedOrdersQuery"

const DELIVERED = "DELIVERED"
const CANCELLED = "CANCELLED"
const CANCELLED_BY_USER = "CANCELLED_BY_USER"
export function calcPrices(orders: CompletedOrdersQueryResponse[], dayCount: number) {
  const prices = orders.reduce((acc: { receipt: number, pay: number, count: number, }, order) => {
    let receipt: number, pay: number
    const ceiledOrderAmount = Math.ceil(order.orderAmount / 10) * 10
    // 店舗への支払い
    if (order.orderStatus === DELIVERED || order.orderStatus === CANCELLED_BY_USER) {
      pay = acc.pay + order.storeProfit
    } else {
      pay = acc.pay
    }

    //
    // 配達済みの場合のみ受け取り金額加算
    //
    if (order.orderStatus === DELIVERED) {
      receipt = acc.receipt + ceiledOrderAmount
    } else {
      receipt = acc.receipt
    }
    return {
      count: order.orderStatus === CANCELLED ? acc.count : acc.count + 1,
      receipt,
      pay,
    }
  }, {
    count: 0,
    receipt: 0,
    pay: 0,
  })
  const sales = prices.receipt - prices.pay
  const averageReceipt = Math.ceil(prices.receipt / dayCount)
  const averagePay = Math.ceil(prices.pay / dayCount)
  const averageSales = Math.ceil(sales / dayCount)
  const averageCount = Math.ceil(prices.count / dayCount * 10) / 10
  return {
    ...prices,
    sales,
    averageReceipt,
    averagePay,
    averageSales,
    averageCount,
  }
}

export type Rider = {
  _id: string
  name: string
}
export function retrieveRiders(orders?: CompletedOrdersQueryResponse[]) {
  if (!orders) return {}
  return orders.reduce<{[k:string]: Rider}>((acc, order) => {
    if (!order.rider) return acc

    if (!acc[order.rider.name]) {
      acc[order.rider.name] = {
        _id: order.rider._id,
        name: order.rider.name,
      }
    }

    return acc
  }, {})
}

export function filteredByRider(orders?: CompletedOrdersQueryResponse[], rider?: Rider) {
  if (!orders) return []
  if (!rider) return orders
  return orders.filter(order => order.rider && order.rider._id === rider._id)
}

export function filteredByStatus(orders: CompletedOrdersQueryResponse[], status: "DELIVERED" | "CANCELLED" | "CANCELLED_BY_USER" | null) {
  if (!orders) return []
  if (!status) return orders
  return orders.filter(order => order.orderStatus === status)
}

export function transformOrdersToStores(orders: CompletedOrdersQueryResponse[], dayCount: number) {
  const obj = orders.reduce((a, x) => {
    const storeId = x.restaurant._id
    if (a[storeId]) {
      return {
        ...a,
        [storeId]: [...a[storeId], x]
      }
    } else {
      return {
        ...a,
        [storeId]: [x]
      }
    }
  }, {})

  return Object.keys(obj).map(key => ({
    ...calcPrices(obj[key], dayCount),
    _id: obj[key][0].restaurant._id,
    name: obj[key][0].restaurant.name,
  }))
}

export const timezoneKeys = [
  "11:00~12:00",
  "12:00~13:00",
  "13:00~14:00",
  "14:00~15:00",
  "15:00~16:00",
  "16:00~17:00",
  "17:00~18:00",
  "18:00~19:00",
  "19:00~20:00",
  "20:00~21:00",
  "21:00~22:00",
  "22:00~23:00",
  "23:00~0:00",
  "0:00~1:00",
  "1:00~2:00",
]
