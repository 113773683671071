import { deepCopy } from './deepCopy'
import { TaxCalculator } from './TaxCalculator'

export const orderItemAdapter = items => {
  const newItems = deepCopy(items)
  const tc = new TaxCalculator()
  for (let i = 0; i < items.length; i++) {
    if (!items[i].variation.taxInPrice) {
      newItems[i].variation.taxInPrice = tc.taxInPrice(items[i].variation.price)
    }
    for (let j = 0; j < items[i].addons.length; j++) {
      for (let k = 0; k < items[i].addons[j].options.length; k++) {
        if (!items[i].addons[j].options[k].taxInPrice) {
          newItems[i].addons[j].options[k].taxInPrice = tc.taxInPrice(items[i].addons[j].options[k].price)
        }
      }
    }
  }

  return newItems
}
