import React, { Dispatch, SetStateAction, } from "react"
import {
  Card,
  CardBody,
  Row,
  FormGroup,
  Input,
  Col,
  ButtonGroup,
  Button,
} from "reactstrap"
import { toISOLocaleString } from "../../utils/date"
import { useTranslation } from "react-i18next"


interface Props {
  mode: "user" | "food" | "option"
  setMode: Dispatch<SetStateAction<"user" | "food" | "option">>
  showGraphs: boolean
  setShowGraphs: Dispatch<SetStateAction<boolean>>
  startDate: Date
  setStartDate: Dispatch<SetStateAction<Date>>
  endDate: Date
  setEndDate: Dispatch<SetStateAction<Date>>
  selectedStatus: string
  setSelectedStatus: Dispatch<SetStateAction<string>>
}
const FormItems: React.FC<Props> = props => {
  const { t } = useTranslation()
  return (
    <Row className="mb-lg-3">
      <div className="col">
        <Card className="shadow">
          <CardBody>
            <Row>
              <Col xl="4">
                <FormGroup>
                  <label className="form-control-label"> {t("ShowItems")} </label>
                  <br />
                  <ButtonGroup>
                    <Button
                      color={props.mode === "user" ? "primary" : "secondary"}
                      onClick={() => props.setMode("user")}
                    >{t("User")}</Button>
                    <Button
                      color={props.mode === "food" ? "primary" : "secondary"}
                      onClick={() => props.setMode("food")}
                    >{t("FOOD")}</Button>
                    <Button
                      color={props.mode === "option" ? "primary" : "secondary"}
                      onClick={() => props.setMode("option")}
                    >{t("Option")}</Button>
                  </ButtonGroup>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xl="4">
                <FormGroup>
                  <label className="form-control-label"> {t("Starting Date")} </label>
                  <br />
                  <Input
                    className="form-control-alternative"
                    type="date"
                    min={"2021-04-01"}
                    max={toISOLocaleString(new Date())}
                    onChange={event => props.setStartDate(new Date(event.target.value))}
                    value={toISOLocaleString(props.startDate)}
                  />
                </FormGroup>
              </Col>
              <Col xl="4">
                <FormGroup>
                  <label className="form-control-label"> {t("Ending Date")} </label>
                  <br />
                  <Input
                    className="form-control-alternative"
                    type="date"
                    min={"2021-04-01"}
                    max={toISOLocaleString(new Date())}
                    onChange={event => props.setEndDate(new Date(event.target.value))}
                    value={toISOLocaleString(props.endDate)}
                  />
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    </Row>
  )
}

export default FormItems

