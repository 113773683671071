/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
// reactstrap components
import { Badge, Card, Container, Row, Modal } from 'reactstrap'
import Header from '../components/Headers/Header'
import CustomLoader from '../components/Loader/CustomLoader'
import DataTable from 'react-data-table-component'
import orderBy from 'lodash/orderBy'
import RiderComponent from '../components/Rider/Rider'
import {
  getRiders,
  deleteRider,
  toggleAvailablity,
  getAvailableRiders
} from '../apollo/server'
import Loader from 'react-loader-spinner'
import { paginationComponentOptions } from '../variables/table'
import { useQuery, useMutation, gql, } from "@apollo/client"
import styles from './styles'

const GET_RIDERS = gql`
  ${getRiders}
`
const DELETE_RIDER = gql`
  ${deleteRider}
`
const TOGGLE_RIDER = gql`
  ${toggleAvailablity}
`
const GET_AVAILABLE_RIDERS = gql`
  ${getAvailableRiders}
`

function Riders(props) {
  const [editModal, setEditModal] = useState(false)
  const [rider, setRider] = useState(null)

  const toggleModal = rider => {
    setEditModal(!editModal)
    setRider(rider)
  }

  const customSort = (rows, field, direction) => {
    const handleField = row => {
      if (row[field]) {
        return row[field].toLowerCase()
      }

      return row[field]
    }

    return orderBy(rows, handleField, direction)
  }

  const handleSort = (column, sortDirection) =>
    console.log(column.selector, sortDirection)

  const { t } = props

  const columns = [
    {
      name: t('Name'),
      sortable: true,
      maxWidth: '150px',
      selector: 'name'
    },
    {
      name: t('Username'),
      sortable: true,
      maxWidth: '120px',
      selector: 'username'
    },
    {
      name: t('Phone'),
      sortable: true,
      maxWidth: '150px',
      selector: 'phone'
    },
    {
      name: t('DisplayPhoneSub'),
      sortable: true,
      maxWidth: '150px',
      selector: 'displayPhone'
    },
    {
      name: t('Email'),
      sortable: true,
      selector: 'email'
    },
    {
      name: t('Zone'),
      maxWidth: '200px',
      selector: 'zone.title'
    },
    {
      name: t('Available'),
      maxWidth: '100px',
      cell: row => <AvailableStatus row={row} />
    },
    {
      name: t('Actions'),
      maxWidth: '100px',
      cell: row => <ActionButtons row={row} />
    }
  ]

  const AvailableStatus = props => {
    const {row} = props
    const [on, setOn] = useState(false)
    useEffect(() => {
      setOn(row.available)
    }, [row])

    const [toggleRider] = useMutation(TOGGLE_RIDER, {
      refetchQueries: [
        { query: GET_RIDERS },
        { query: GET_AVAILABLE_RIDERS },
      ],
      onError: e => console.log
    })
    return (
      <>
        {row.available}
        <label className="custom-toggle mt-2">
          <input
            checked={on}
            type="checkbox"
            onChange={event => {
              setOn(event.target.checked)
              toggleRider({ variables: { id: row._id } })
            }}
          />
          <span className="custom-toggle-slider rounded-circle" />
        </label>
      </>
    )
  }

  const ActionButtons = props => {
    const { row } = props
    const [deleteRider, { loading: deleteLoading }] = useMutation(DELETE_RIDER, {
      refetchQueries: [
        { query: GET_RIDERS },
      ],
      onError: e => console.log
    })
    if (deleteLoading) {
      return (
        <Loader
          type="ThreeDots"
          color="#BB2124"
          height={20}
          width={40}
          visible={deleteLoading}
        />
      )
    }

    return (
      <>
        <Badge
          style={styles.badgeStyle}
          href="#pablo"
          onClick={e => {
            e.preventDefault()
            toggleModal(row)
          }}
          color="primary">
          {t('Edit')}
        </Badge>
        {" "}
        <Badge
          style={styles.badgeStyle}
          href="#pablo"
          color="danger"
          onClick={e => {
            e.preventDefault()
            if (window.confirm('削除してもよろしいですか？')) {
              deleteRider({ variables: { id: row._id } })
            }
          }}>
          {t('Delete')}
        </Badge>
      </>
    )
  }

  const { loading, error, data } = useQuery(GET_RIDERS, {
    onError: error => {
      console.log(error)
    }
  })
  if (error) {
    return (
      <tr>
        <td>
          {`${t('Error')}! ${error.message}`}
        </td>
      </tr>
    )
  }

  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <RiderComponent />
        {/* Table */}
        <Row className="mt-5">
          <div className="col">
            <Card className="shadow">
              <DataTable
                title={t('Riders')}
                columns={columns}
                data={data && data.riders ? data.riders : []}
                pagination
                progressPending={loading}
                progressComponent={<CustomLoader />}
                onSort={handleSort}
                sortFunction={customSort}
                paginationComponentOptions={paginationComponentOptions}
                noDataComponent={t('NoData')}
              />
            </Card>
          </div>
        </Row>
        <Modal
          className="modal-dialog-centered"
          size="lg"
          isOpen={editModal}
          toggle={() => {
            toggleModal()
          }}>
          <RiderComponent rider={rider} />
        </Modal>
      </Container>
    </>
  )
}

export default withTranslation()(Riders)
