import { useQuery } from "@apollo/client"
import gql from "graphql-tag"

const salesboardGraphDataQuery = gql`query ($start: Float!, $end: Float!, $orderStatus: String, $riderId: String) {
  salesboardGraphData(start:$start, end:$end, orderStatus:$orderStatus, riderId:$riderId) {
    orderCountsByTimezone
    salesByTimezone
    orderPricesByTimezone

    dailySales {
      labels
      values
    }
    orderCountsByDayOfTheWeek
    salesByDayOfTheWeek
  }
}`

type Variables = {
  start: number
  end: number
  orderStatus?: string
  riderId?: string
}
export type SalesboardGraphDataQueryResponse = {
  orderCountsByTimezone: number[]
  salesByTimezone: number[]
  orderPricesByTimezone: number[]
  dailySales: {
    labels: string[]
    values: number[]
  }
  orderCountsByDayOfTheWeek: number[]
  salesByDayOfTheWeek: number[]
}

type Response = {
  salesboardGraphData?: SalesboardGraphDataQueryResponse
}
export function useSalesboardGraphData(start: number, end: number, orderStatus?: string, riderId?: string) {
  return useQuery<Response, Variables>(salesboardGraphDataQuery, {
    variables: {
      start,
      end,
      orderStatus,
      riderId,
    } })
}