import React, { useState, useRef, useContext } from "react"
import { validateFunc } from "../../constraints/constraints"
import { useTranslation } from "react-i18next"
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  Row,
  Col,
  Alert,
  Modal,
  Label
} from "reactstrap"
import { createFood, editFood } from "../../apollo/server"
import AddonComponent from "../Addon/AddonModal"
import { getIsDisabled } from "../../utils/getIsDisabled"
import { useMutation, gql, } from "@apollo/client"
import { zenkakuToHankaku } from "../../utils/transformZenkaku"
import { Calculator } from "../../utils/Calculator"
import { getRestaurantQuery, RestaurantQueryResponse, useRestaurantQuery } from "../../domain/graphql/query/useRestaurantQuery"
import { AppContext } from "../../app"

const CREATE_FOOD = gql`${createFood}`
const EDIT_FOOD = gql`${editFood}`

interface Props {
  food?: any
  restaurant?: RestaurantQueryResponse
  modalLoading: boolean
  setModalLoading: React.Dispatch<React.SetStateAction<boolean>>
}
const FoodModal: React.FC<Props> = (props) => {
  const { t } = useTranslation()
  const appContext = useContext(AppContext)
  const userType = appContext.userType
  const restaurantId = appContext.restaurantId
  const formRef = useRef(null)
  const mutation = props.food ? EDIT_FOOD : CREATE_FOOD

  const [title, setTitle] = useState(props.food && props.food.title ? props.food.title : "")
  const [description, setDescription] = useState(props.food && props.food.description ? props.food.description : "")
  const [category, setCategory] = useState(props.food && props.food.categoryId ? props.food.categoryId : "")
  const [imgUrl, setImgUrl] = useState(props.food && props.food.image ? props.food.image : "")
  const [imgFile, setImgFile] = useState(null)

  const [mainError, mainErrorSetter] = useState("")
  const [success, successSetter] = useState("")

  const [titleError, titleErrorSetter] = useState(null)
  const [categoryError, categoryErrorSetter] = useState(null)

  const [addonModal, addonModalSetter] = useState(false)
  const [variation, variationSetter] = useState(
    props.food && props.food.variations
      ? props.food.variations.map(({ title, taxInPrice, storeProfit, discounted, janCode, addons }) => {
        return {
          title,
          taxInPrice,
          storeProfit,
          discounted,
          janCode,
          addons,
          titleError: null,
          taxInPriceError: null,
        }
      })
      : [
        {
          title: "",
          taxInPrice: "",
          storeProfit: "",
          discounted: "",
          janCode: "",
          addons: [],
          titleError: null,
          taxInPriceError: null,
        }
      ]
  )
  const filterImage = event => {
    let images = []
    for (var i = 0; i < event.target.files.length; i++) {
      images[i] = event.target.files.item(i)
    }
    images = images.filter(image => image.name.match(/\.(jpg|jpeg|png|gif)$/))
    const message = `${images.length} valid image(s) selected`
    console.log(message)
    return images.length ? images[0] : undefined
  }
  const selectImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const image = filterImage(event)
    if (image) {
      setImgFile(image)
      imageToBase64(image)
    }
  }

  const onAdd = (index: number) => {
    const variations = variation
    if (index === variations.length - 1) {
      variations.push({
        title: "",
        taxInPrice: "",
        storeProfit: "",
        discounted: "",
        janCode: "",
        addons: [],
        titleError: null,
        taxInPriceError: null,
      })
    } else {
      variations.splice(index + 1, 0, {
        title: "",
        taxInPrice: "",
        storeProfit: "",
        discounted: "",
        janCode: "",
        addons: [],
        titleError: null,
        taxInPriceError: null,
      })
    }
    variationSetter([...variations])
  }
  const onRemove = (index: number) => {
    if (variation.length === 1 && index === 0) {
      return
    }
    const variations = variation
    variations.splice(index, 1)
    variationSetter([...variations])
  }
  const handleVariationChange = (event, index, type) => {
    const variations = variation

    if (type === "title") {
      variations[index][type] = event.target.value
      variationSetter([...variations])
    } else {
      variations[index][type] = event.target.value
      variationSetter([...variations])
    }
  }
  const onSubmitValidaiton = () => {
    const titleError = !validateFunc({ title: title.trim() }, "title")
    const categoryError = !validateFunc({ category: category.trim() }, "category")
    titleErrorSetter(titleError)
    categoryErrorSetter(categoryError)
    return titleError && categoryError
  }

  const clearFields = () => {
    formRef.current.reset()
    setTitle("")
    setDescription("")
    setCategory("")
    setImgUrl("")
    variationSetter([
      {
        title: "",
        taxInPrice: "",
        storeProfit: "",
        discounted: "",
        janCode: "",
        addons: [],
        titleError: null,
        taxInPriceError: null,
      }
    ])
    titleErrorSetter(null)
    categoryErrorSetter(null)
  }
  const onCompleted = data => {
    if (!props.food) clearFields()
    const message = props.food
      ? t("UpdateSuccessMessage")
      : t("AddSuccessMessage")
    mainErrorSetter("")
    successSetter(message)
    props.setModalLoading(false)
  }

  const onError = error => {
    console.log(error)
    mainErrorSetter(t("ErrorMessage"))
    successSetter("")
    props.setModalLoading(false)
  }

  const toggleModal = (index?: number) => {
    addonModalSetter(prev => !prev)
  }

  const onSelectAddon = (variationIndex, addonId) => {
    const variations = [...variation]
    const addon = variations[variationIndex].addons.indexOf(addonId)
    if (addon < 0) variations[variationIndex].addons = [...variations[variationIndex].addons, addonId]
    else variations[variationIndex].addons = variations[variationIndex].addons.filter(addon => addon !== addonId)
    variationSetter([...variations])
  }

  const onDismiss = () => {
    successSetter("")
    mainErrorSetter("")
  }

  const imageToBase64 = imgUrl => {
    const fileReader = new FileReader()
    fileReader.onloadend = () => {
      setImgUrl(fileReader.result)
    }
    fileReader.readAsDataURL(imgUrl)
  }

  const SubmitButton = () => {
    const [mutate, { loading, error }] = useMutation(mutation, {
      onCompleted,
      onError,
      refetchQueries: [
        { query: getRestaurantQuery, variables: { id: restaurantId } },
      ]
    })

    if (error) {
      console.log(error)
      return <p>{t("ErrorMessage")}</p>
    }

    function onPressSubmit(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
      e.preventDefault()
      if (getIsDisabled(userType) || !onSubmitValidaiton() || loading || props.modalLoading) return

      let variables
      let zeroFlag = false
      let minusFlag = false
      if (props.food) {
        variables = {
          oldCategoryId: props.food && props.food.categoryId ? props.food.categoryId : "",
          foodInput: {
            restaurant: restaurantId,
            _id: props.food && props.food._id ? props.food._id : "",
            title: title.trim(),
            description: description.trim(),
            image: imgFile,
            category,
            isAvailable: props.food && props.food.isAvailable !== undefined ? props.food.isAvailable : true,
            variations: variation.map(({ title, storeProfit, janCode, addons }) => {
              if (zenkakuToHankaku(storeProfit) === 0) zeroFlag = true
              if (zenkakuToHankaku(storeProfit) < 0) minusFlag = true
              return {
                title: title.trim(),
                storeProfit: zenkakuToHankaku(storeProfit),
                janCode: janCode.trim(),
                addons
              }
            })
          }
        }
      } else {
        variables = {
          foodInput: {
            restaurant: restaurantId,
            title: title.trim(),
            description: description.trim(),
            image: imgFile,
            category,
            isAvailable: true,
            variations: variation.map(({ title, storeProfit, janCode, addons }) => {
              if (zenkakuToHankaku(storeProfit) === 0) zeroFlag = true
              if (zenkakuToHankaku(storeProfit) < 0) minusFlag = true
              return {
                title: title.trim(),
                storeProfit: zenkakuToHankaku(storeProfit),
                janCode: janCode.trim(),
                addons
              }
            })
          }
        }
      }

      if (zeroFlag && !window.confirm("０円でもよろしいですか？")) {
        return
      }
      if (minusFlag) {
        window.alert("０円未満は入力できません")
        return
      }

      props.setModalLoading(true)
      mutate({ variables })
    }
    return (
      <Button
        disabled={loading || props.modalLoading}
        color="primary"
        href="#pablo"
        className="btn-block"
        onClick={onPressSubmit}
        size="lg"
      >
        {loading || props.modalLoading ? t("Saving") : t("Save")}
      </Button>
    )
  }

  const { data, loading, error } = useRestaurantQuery(restaurantId)
  const SelectCategory = () => {
    if (loading) return <p>{t("Loading")}</p>
    if (error) return <p>{t("Error")}</p>

    return <FormGroup
      className={
        categoryError === null
          ? ""
          : categoryError
            ? "has-success"
            : "has-danger"
      }>
      <Input
        type="select"
        name="input-category"
        id="exampleSelect"
        value={category}
        onChange={e => setCategory(e.target.value)}
      >
        {!category && (
          <option value={""}>
            {t("Select")}
          </option>
        )}
        {data && data.restaurant && data.restaurant.categories && data.restaurant.categories.map(
          category => (
            <option
              value={category._id}
              key={category._id}>
              {category.title}
            </option>
          )
        )}
      </Input>
    </FormGroup>
  }

  const AddonsComponent = props => {
    const { variationIndex } = props
    if (loading) return null
    if (error) return null
    if (data && data.restaurant && data.restaurant.addons) {
      return (
        <>
          {data.restaurant.addons.map((addon, indexAddon) =>
            <FormGroup
              key={indexAddon}
              check
              className="mb-2">
              <Label check>
                <Input
                  value={addon._id}
                  type="checkbox"
                  checked={variation[variationIndex] && variation[variationIndex].addons && variation[variationIndex].addons.includes(addon._id)}
                  onChange={() => onSelectAddon(variationIndex, addon._id)}
                />
                {`${addon.title} : ${addon.description} (${t("Min")}: ${addon.quantityMinimum}、${t("Max")}: ${addon.quantityMaximum}) ${addon.memo ? "備考 : " + addon.memo : ""}`}
              </Label>
            </FormGroup>
          )}
        </>
      )
    } else {
      return null
    }
  }

  return (
    <>
      <Row>
        <Col className="order-xl-1">
          <Card className="bg-secondary shadow">
            <CardHeader className="bg-white border-0">
              <Row className="align-items-center">
                <Col xs="8">
                  <h3 className="mb-0">{props.food ? t("Edit") : t("Add")}</h3>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <form ref={formRef}>
                <div className="pl-lg-4">
                  <Row>
                    <Col lg="5">
                      <Row>
                        <Col>
                          <label
                            className="form-control-label"
                            htmlFor="input-title">
                            {t("Title")}
                          </label>
                          <FormGroup
                            className={
                              titleError === null
                                ? ""
                                : titleError
                                  ? "has-success"
                                  : "has-danger"
                            }>
                            <Input
                              autoCapitalize="none"
                              className="form-control-alternative"
                              id="input-title"
                              name="input-title"
                              placeholder={t("FoodnamePH")}
                              type="text"
                              value={title}
                              onChange={e => setTitle(e.target.value)}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <label
                            className="form-control-label"
                            htmlFor="input-description">
                            {t("Description")}
                          </label>
                          <FormGroup>
                            <Input
                              className="form-control-alternative"
                              id="input-description"
                              name="input-description"
                              placeholder={t("FoodDescriptionPH")}
                              type="textarea"
                              value={description}
                              onChange={e => setDescription(e.target.value)}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <label
                            className="form-control-label"
                            htmlFor="input-category">
                            {t("Category")}
                          </label>
                          <SelectCategory />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <h3 className="mb-0"> {t("Food Image")}</h3>
                          <FormGroup>
                            <div className="card-title-image">
                              {imgUrl && typeof imgUrl === "string" && (
                                <a
                                  href="#pablo"
                                  onClick={e => e.preventDefault()}>
                                  <img
                                    alt="..."
                                    className="rounded-rectangle"
                                    src={imgUrl}
                                  />
                                </a>
                              )}
                              <input
                                className="mt-4"
                                type="file"
                                onChange={selectImage}
                              />
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>

                    <Col lg="7">
                      <h3 className="mb-2">{t("Variations")}</h3>
                      {variation.map((variationItem, variationIndex) => (
                        <div key={variationIndex}>
                          <Row>
                            <Col lg="6">
                              <FormGroup>
                                <label className="form-control-label" htmlFor="input-variation-title" >
                                  {t("Title")}
                                </label>
                                <Input
                                  autoCapitalize="none"
                                  className="form-control-alternative"
                                  value={variationItem.title}
                                  id="input-variation-title"
                                  placeholder={t("VariationsPH")}
                                  type="text"
                                  autoComplete="off"
                                  onChange={event => {
                                    handleVariationChange(
                                      event,
                                      variationIndex,
                                      "title",
                                    )
                                  }}
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="6">
                              <FormGroup>
                                <label className="form-control-label" htmlFor="input-janCode">
                                  {t("JanCode")}
                                </label>
                                <Input
                                  autoCapitalize="none"
                                  className="form-control-alternative"
                                  value={variationItem.janCode}
                                  id="input-janCode"
                                  type="text"
                                  autoComplete="off"
                                  onChange={event => {
                                    handleVariationChange(
                                      event,
                                      variationIndex,
                                      "janCode",
                                    )
                                  }}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="6">
                              <FormGroup>
                                <label className="form-control-label" htmlFor="input-storeProfit">
                                  {t("StoreProfit")}
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  value={variationItem.storeProfit}
                                  id="input-storeProfit"
                                  type="number"
                                  onChange={event => {
                                    handleVariationChange(
                                      event,
                                      variationIndex,
                                      "storeProfit",
                                    )
                                  }}
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="6">
                              <FormGroup>
                                <label className="form-control-label" htmlFor="input-tax-in-price">
                                  {t("TaxInPrice")}
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  value={variationItem.storeProfit
                                    ? props.restaurant.isRawPrice
                                      ? variationItem.taxInPrice
                                      : new Calculator(variation[variationIndex]["storeProfit"], props.restaurant ? props.restaurant.commission : 0).taxInPrice
                                    : ""
                                  }
                                  id="input-tax-in-price"
                                  type="number"
                                  disabled
                                />
                              </FormGroup>
                            </Col>
                          </Row>

                          <Row className="mb-2">
                            <Col lg="6">
                              <Button onClick={() => toggleModal(variationIndex)} color="warning">
                                {t("CreateNewAddon")}
                              </Button>
                            </Col>
                          </Row>
                          <Row
                            style={{
                              maxHeight: "67vh",
                              overflowY: "scroll"
                            }}>
                            <Col lg="12">
                              <AddonsComponent variationIndex={variationIndex} />
                            </Col>
                          </Row>
                          <Row className="mt-2">
                            <Col lg="6">
                              <FormGroup>
                                <Button onClick={() => { onRemove(variationIndex) }} color="danger" > - </Button>
                                <Button onClick={() => { onAdd(variationIndex) }} color="primary" > + </Button>
                              </FormGroup>
                            </Col>
                          </Row>
                        </div>
                      ))}
                    </Col>
                  </Row>
                  <hr />
                  <Row className="mt-2 justify-content-center">
                    <Col xs="4">
                      <SubmitButton />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6">
                      <Alert
                        color="success"
                        isOpen={!!success}
                        toggle={onDismiss}>
                        <span className="alert-inner--icon">
                          <i className="ni ni-like-2" />
                        </span>{" "}
                        <span className="alert-inner--text">
                          <strong>{t("Success")}!</strong> {success}
                        </span>
                      </Alert>
                      <Alert
                        color="danger"
                        isOpen={!!mainError}
                        toggle={onDismiss}>
                        <span className="alert-inner--icon">
                          <i className="ni ni-like-2" />
                        </span>{" "}
                        <span className="alert-inner--text">
                          <strong>{t("Danger")}!</strong> {mainError}
                        </span>
                      </Alert>
                    </Col>
                  </Row>
                </div>
              </form>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Modal
        className="modal-dialog-centered"
        size="lg"
        isOpen={addonModal}
        toggle={() => { if (!props.modalLoading) toggleModal(null) }}
      >
        <AddonComponent
          modalLoading={props.modalLoading}
          setModalLoading={props.setModalLoading}
        />
      </Modal>
    </>
  )
}
export default FoodModal
