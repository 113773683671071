import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import {
  Row,
  Col,
  Card,
  CardHeader,
  Input,
  Button,
  Alert
} from "reactstrap"
import { saveSpecialFeatureTitleConfiguration } from "../../apollo/server"
import { useMutation, gql, } from "@apollo/client"

const SAVE_TITLE_CONFIGURATION = gql`${saveSpecialFeatureTitleConfiguration}`

interface IProps {
  title: string
}
const SpecialFeatureTitle: React.FC<IProps> = props => {
  const { t } = useTranslation()
  const [title, setTitle] = useState(props.title ?? "")
  const [successMessage, setSuccessMessage] = useState("")
  const [errorMessage, setErrorMessage] = useState("")
  const onDismiss = () => {
    setSuccessMessage("")
    setErrorMessage("")
  }

  const [save, { loading, error }] = useMutation(SAVE_TITLE_CONFIGURATION, {
    onCompleted: () => setSuccessMessage(t("UpdateSuccessMessage")),
    onError: e => {
      setErrorMessage(t("ErrorMessage"))
      console.log(e)
    },
  })
  if (error) return <p>{t("Error")}</p>

  function submit(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.preventDefault()
    if (loading) return
    save({ variables: { title: title.trim() } })
  }

  return (
    <Row className="mt-3">
      <div className="col">
        <Card className="shadow">
          <CardHeader className="border-0">
            <h3 className="mb-0">{t("SpecialFeatureTitle")}</h3>
          </CardHeader>

          <Row className="ml-3 mr-3" style={{ display: "flex", alignItems: "center" }}>
            <Col md="10">
              <Input
                autoCapitalize="none"
                className="form-control-alternative"
                id="title"
                name="title"
                placeholder="特集タイトル"
                value={title}
                onChange={e => setTitle(e.target.value)}
              />
            </Col>
            <Col md="2">
              <Button
                className="btn-block mb-2"
                type="button"
                color="primary"
                onClick={submit}
                size="lg">
                {loading ? t("Saving") : t("Save")}
              </Button>
            </Col>
          </Row>
          <Row className="mx-3" style={{display: "flex", justifyContent: "flex-end"}}>
            <Col md="6">
              <Alert
                color="success"
                isOpen={!!successMessage}
                toggle={onDismiss}>
                <span className="alert-inner--icon">
                  <i className="ni ni-like-2" />
                </span>{" "}
                <span className="alert-inner--text" style={{ whiteSpace: "pre-wrap" }}>
                  <strong>{t("Success")}! </strong>{successMessage}
                </span>
              </Alert>
              <Alert
                color="danger"
                isOpen={!!errorMessage}
                toggle={onDismiss}>
                <span className="alert-inner--icon">
                  <i className="ni ni-like-2" />
                </span>{" "}
                <span className="alert-inner--text">
                  <strong>{t("Danger")}!</strong> {errorMessage}
                </span>
              </Alert>
            </Col>
          </Row>
        </Card>
      </div>
    </Row>
  )
}
export default SpecialFeatureTitle
