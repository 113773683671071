import React, { SetStateAction } from "react"
import { useTranslation } from "react-i18next"
import { StoreSalesboardUserDataQueryResponse } from "../../../domain/graphql/query/useStoreSalesboardUserDataQuery"
import { dateToJapanese } from "../../../utils/date"
import DataTable from "./DataTable"

interface Props {
  userData: StoreSalesboardUserDataQueryResponse[]
  onRowClicked: (e: any) => void
  loading: boolean
  selectedUserId: string
  setSelectedUserId: React.Dispatch<SetStateAction<string | null>>
}

const getItems = items => {
  return items
    .map(item => ` ${item.foodTitle}(${item.variationTitle}) x ${item.quantity}`)
    .join("\n")
}
const OrderData: React.FC<Props> = props => {
  const { t } = useTranslation()

  const columns = [
    {
      selector: "orderId",
      sortable: true,
      maxWidth: "100px",
      name: t("OrderID"),
      cell: row => <>{row.orderId}</>
    },
    {
      name: t("Items"),
      cell: row => <>{getItems(row.items)}</>
    },
    {
      selector: "",
      name: t("DeliveryDate"),
      sortable: true,
      width: "170px",
      cell: row => <>{dateToJapanese(new Date(row.createdAt))}</>
    },
    {
      name: t("TotalPrice"),
      selector: "",
      sortable: true,
      width: "170px",
      cell: row => <>{row.totalStoreProfit.toLocaleString()}</>
    },
  ]

  const orders = props.userData.find(data => data.userId === props.selectedUserId).orders

  return (
    <DataTable
      title={"< " + t("Back")}
      columns={columns}
      data={orders}
      loading={props.loading}
      onTitleClick={() => props.setSelectedUserId(null)}
      onRowClicked={props.onRowClicked}
    />
  )
}
export default OrderData
