import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Row,
  Col,
  Card,
  CardHeader,
  FormGroup,
  Input,
  Button
} from 'reactstrap'
import { saveStripeConfiguration } from '../../apollo/server'
import { useMutation, gql } from "@apollo/client"

const SAVE_STRIPE_CONFIGURATION = gql`
  ${saveStripeConfiguration}
`

function Stripe(props) {
  const { t } = useTranslation()
  const formRef = useRef(null)
  const publishableKey = props.publishableKey || ''
  const secretKey = props.secretKey || ''
  const [publishError, publishErrorSetter] = useState(null)
  const [secretError, secretErrorSetter] = useState(null)

  const validateInput = () => {
    let publishableKeyResult = true
    let secretKeyResult = true
    publishableKeyResult = !!formRef.current['input-publishablekey'].value
    secretKeyResult = !!formRef.current['input-secretkey'].value
    publishErrorSetter(publishableKeyResult)
    secretErrorSetter(secretKeyResult)
    return publishableKeyResult && secretKeyResult
  }

  const SubmitButton = () => {
    const onCompleted = data => {
      console.log(data)
    }
    const onError = error => {
      console.log(error)
    }

    const [saveConfiguration, { loading, error }] = useMutation(SAVE_STRIPE_CONFIGURATION, {
      onCompleted,
      onError,
    })
    if (error) return <p>{t("Error")}</p>

    return (
      <Button
        className="btn-block mb-2"
        type="button"
        color="primary"
        onClick={e => {
          e.preventDefault()
          if (validateInput() && !loading) {
            saveConfiguration({
              variables: {
                configurationInput: {
                  publishableKey:
                    formRef.current['input-publishablekey']
                      .value,
                  secretKey:
                    formRef.current['input-secretkey'].value
                }
              }
            })
          }
        }}
        size="lg">
        {loading ? t('Saving') : t('Save')}
      </Button>
    )
  }

  return (
    <Row className="mt-3">
      <div className="col">
        <Card className="shadow">
          <CardHeader className="border-0">
            <h3 className="mb-0">Stripe</h3>
          </CardHeader>
          <form ref={formRef}>
            <div className="pl-lg-4">
              <Row>
                <Col md="8">
                  <label
                    className="form-control-label"
                    htmlFor="input-publishablekey">
                    {t('Publishable Key')}
                  </label>
                  <FormGroup
                    className={
                      publishError === null
                        ? ''
                        : publishError
                          ? 'has-success'
                          : 'has-danger'
                    }>
                    <Input
                      autoCapitalize="none"
                      className="form-control-alternative"
                      id="input-publishablekey"
                      name="input-publishablekey"
                      placeholder="e.g pk_test_lEaBbVGnTkzja2FyFiNlbqtw"
                      type="password"
                      defaultValue={publishableKey}
                      ></Input>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="8">
                  <label
                    className="form-control-label"
                    htmlFor="input-secretkey">
                    {t('Secret Key')}
                  </label>
                  <FormGroup
                    className={
                      secretError === null
                        ? ''
                        : secretError
                          ? 'has-success'
                          : 'has-danger'
                    }>
                    <Input
                      autoCapitalize="none"
                      className="form-control-alternative"
                      id="input-secretkey"
                      placeholder="e.g sk_test_rKNqVc2tSkdgZHNO3XnPCLn4"
                      type="password"
                      defaultValue={secretKey}
                      >
                      </Input>
                  </FormGroup>
                </Col>
              </Row>
              <Row className="mx-3" style={{ display: "flex", justifyContent: "flex-end" }}>
                <Col md="2">
                  <SubmitButton />
                </Col>
              </Row>
            </div>
          </form>
        </Card>
      </div>
    </Row>
  )
}
export default Stripe
