import React, { useEffect, useRef } from 'react'
import { Route, Switch } from 'react-router-dom'
// reactstrap components
import { Container } from 'reactstrap'
// core components
import AdminNavbar from '../components/Navbars/AdminNavbar'
import AdminFooter from '../components/Footers/AdminFooter'
import AdminSidebar from '../components/Sidebar/AdminSidebar'
import Configuration from '../views/Configuration'
import Users from '../views/Users'
import Vendors from '../views/Vendors'
import RestaurantList from '../views/RestaurantList'
import Riders from '../views/Riders'
import Dispatch from '../views/Dispatch'
import Zone from '../views/Zone'
import StoreCategory from '../views/StoreCategory'
import SalesBoard from '../views/SalesBoard'
import InfoCarousel from '../views/InfoCarousel'
import ZoneStatistics from '../views/ZoneStatistics'

import routes from '../routes'
const sidebarRoutes = [
  {
    path: '/vendors',
    name: 'Vendors',
    icon: 'ni ni-collection text-primary',
    component: Vendors,
    layout: '/super_admin',
    appearInSidebar: true,
    admin: true
  },
  {
    path: '/restaurants',
    name: 'Restaurants',
    icon: 'fas fa-star text-yellow',
    component: RestaurantList,
    layout: '/super_admin',
    appearInSidebar: true,
    admin: true
  },
  {
    path: '/storecategory',
    name: 'StoreCategory',
    icon: 'ni ni-chart-pie-35 text-red',
    component: StoreCategory,
    layout: '/super_admin',
    appearInSidebar: true,
    admin: true
  },
  // {
  //   path: "/offers",
  //   name: "Offers",
  //   icon: "ni ni-atom text-red",
  //   component: Offer,
  //   layout: "/super_admin",
  //   appearInSidebar: true,
  //   admin: true
  // },
  {
    path: '/users',
    name: 'Users',
    icon: 'ni ni-single-02 text-green',
    component: Users,
    layout: '/super_admin',
    appearInSidebar: true,
    admin: true
  },
  {
    path: '/riders',
    name: 'Riders',
    icon: 'ni ni-delivery-fast text-indigo',
    component: Riders,
    layout: '/super_admin',
    appearInSidebar: true,
    admin: true
  },
  {
    path: '/vendors',
    name: 'Back to Admin',
    icon: 'ni ni-curved-next text-black',
    component: Vendors,
    layout: '/super_admin',
    appearInSidebar: true,
    admin: false
  },
  {
    path: '/zones',
    name: 'Zone',
    icon: 'ni ni-square-pin text-blue',
    component: Zone,
    layout: '/super_admin',
    appearInSidebar: true,
    admin: true
  },
  {
    path: '/configuration',
    name: 'Configuration',
    icon: 'ni ni-settings text-blue',
    component: Configuration,
    layout: '/super_admin',
    appearInSidebar: true,
    admin: true
  },
  {
    path: '/infoCarousel',
    name: 'InfoCarousel',
    icon: 'ni ni-tie-bow text-green',
    component: InfoCarousel,
    layout: '/super_admin',
    appearInSidebar: true,
    admin: true
  },
  {
    path: '/dispatch',
    name: 'Dispatch',
    icon: 'ni ni-collection text-primary',
    component: Dispatch,
    layout: '/super_admin',
    appearInSidebar: true,
    admin: true
  },
  {
    path: '/salesBoard',
    name: 'SalesBoard',
    icon: 'ni ni-tv-2 text-primary',
    component: SalesBoard,
    layout: '/super_admin',
    appearInSidebar: true,
    admin: true
  },
  {
    path: '/zoneStatistics',
    name: 'ZoneStatistics',
    icon: 'ni ni-square-pin text-red',
    component: ZoneStatistics,
    layout: '/super_admin',
    appearInSidebar: true,
    admin: true
  },
]

function SuperAdmin(props) {
  var divRef = useRef(null)
  useEffect(() => {
    document.documentElement.scrollTop = 0
    document.scrollingElement.scrollTop = 0
    divRef.current.scrollTop = 0
  }, [])
  const getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.layout === '/super_admin') {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        )
      } else {
        return null
      }
    })
  }
  const getBrandText = path => {
    for (let i = 0; i < routes.length; i++) {
      if (
        props.location.pathname.indexOf(routes[i].layout + routes[i].path) !==
        -1
      ) {
        return routes[i].name
      }
    }
    return 'Brand'
  }
  return (
    <>
      <AdminSidebar
        {...props}
        routes={sidebarRoutes}
        logo={{
          innerLink: '/super_admin/vendors',
          imgSrc: require('../assets/img/brand/logo.png'),
          imgAlt: '...'
        }}
      />
      <div className="main-content" ref={divRef}>
        <AdminNavbar
          {...props}
          brandText={getBrandText(props.location.pathname)}
        />
        <Switch>{getRoutes(routes)}</Switch>
        <Container fluid>
          <AdminFooter />
        </Container>
      </div>
    </>
  )
}

export default SuperAdmin
