import BigNumber from "bignumber.js"
export class Calculator {
	_TAX_RATE: number
	_storeProfit: number
	_storeCommissionRate: number
	constructor(storeProfit: number, storeCommissionRate: number) {
		this._TAX_RATE = 1.1
		this._storeProfit = storeProfit
		this._storeCommissionRate = storeCommissionRate
	}

	get taxInPrice(): number {
        const storeProfit = new BigNumber(this._storeProfit)
        const origin = storeProfit.div(100 - this._storeCommissionRate).multipliedBy(100).toNumber()
        return Math.ceil(origin / 10) * 10
    }
    get price(): number {
        const taxInPrice = new BigNumber(this.taxInPrice)
        const origin = taxInPrice.dividedBy(this._TAX_RATE).toNumber()
        return Math.floor(origin)
    }
}