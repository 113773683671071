import React, { useContext, useState } from "react"
import {
  Alert,
  Card,
  CardHeader,
  CardBody,
  Form,
  Row,
  Col,
  FormGroup,
  Input,
  Button
} from "reactstrap"
import { useTranslation } from "react-i18next"
import { validateFunc } from "../../constraints/constraints"
import { getIsDisabled } from "../../utils/getIsDisabled"
import { PC_BOUNDARY } from "../constants"
import { zenkakuToHankaku } from "../../utils/transformZenkaku"
import { Calculator } from "../../utils/Calculator"
import { getRestaurantQuery, RestaurantQueryResponse } from "../../domain/graphql/query/useRestaurantQuery"
import { ApolloError } from "@apollo/client"
import { CreateOptionsResponse, useCreateOptions } from "../../domain/graphql/mutation/useCreateOptionsMutation"
import { EditOptionResponse, useEditOption } from "../../domain/graphql/mutation/useEditOptionMutation"
import { AppContext } from "../../app"

interface Props {
  option?: any;
  restaurant: RestaurantQueryResponse;
  modalLoading: boolean
  setModalLoading: React.Dispatch<React.SetStateAction<boolean>>
}
const Option: React.FC<Props> = props => {
  const { t } = useTranslation()
  const isEditMode = !!props.option
  const appContext = useContext(AppContext)
  const userType = appContext.userType
  const restaurantId = appContext.restaurantId
  const [option, optionSetter] = useState(
    props.option
      ? [{ ...props.option, titleError: false, storeProfitError: false }]
      : [
        {
          title: "",
          description: "",
          storeProfit: "",
          titleError: false,
          storeProfitError: false
        }
      ]
  )
  const [mainError, mainErrorSetter] = useState("")
  const [success, successSetter] = useState("")

  const onBlur = (index: number, state: string) => {
    const options = option
    if (state === "title") {
      options[index].titleError = !!validateFunc({ optionTitle: options[index][state] }, "optionTitle")
    }
    if (state === "storeProfit") {
      options[index].storeProfitError = !!validateFunc({ optionStoreProfit: options[index][state] }, "optionStoreProfit")
    }
    optionSetter([...options])
  }
  const onAdd = (index: number) => {
    const options = option
    if (index === options.length - 1) {
      options.push({ title: "", description: "", storeProfit: "" })
    } else {
      options.splice(index + 1, 0, { title: "", description: "", storeProfit: "" })
    }
    optionSetter([...options])
  }
  const onRemove = (index: number) => {
    if (option.length === 1 && index === 0) {
      return
    }
    const options = option
    options.splice(index, 1)
    console.log(options)
    optionSetter([...options])
  }
  const onChange = (event: any, index: number, state: string) => {
    const options = option
    options[index][state] = event.target.value
    optionSetter([...options])
  }
  const validate = () => {
    const options = option
    options.map((option, index) => {
      onBlur(index, "title")
      onBlur(index, "description")
      onBlur(index, "storeProfit")
      return option
    })
    const error = options.filter(option => option.titleError || option.storeProfitError)
    if (!error.length) return true
    return false
  }
  const onDismiss = () => {
    successSetter("")
    mainErrorSetter("")
  }

  const SubmitButton = () => {
    const refetchQueries = [{ query: getRestaurantQuery, variables: { id: restaurantId } }]
    const onError = (error: ApolloError) => {
      mainErrorSetter(t("ErrorMessage"))
      successSetter("")
      props.setModalLoading(false)
    }

    const [createMutate, { loading: createLoading, error: createError }] = useCreateOptions({
      variables: {
        optionInput: {
          options: option.map(
            ({ title, description, storeProfit, }) => ({
              title: title.trim(),
              description: description.trim(),
              storeProfit: zenkakuToHankaku(storeProfit),
            })
          ),
          restaurant: restaurantId ? restaurantId : "",
        }
      },
      onCompleted: (data: CreateOptionsResponse) => {
        if (data.createOptions) {
          optionSetter([
            {
              title: "",
              description: "",
              storeProfit: 0,
              titleError: false,
              storeProfitError: false
            }
          ])
          successSetter(t("AddSuccessMessage"))
          mainErrorSetter("")
        }
        props.setModalLoading(false)
      },
      refetchQueries,
      onError,
    })

    const [editMutate, { loading: editLoading, error: editError }] = useEditOption({
      variables: {
        optionInput: {
          option: {
            _id: props.option && props.option._id ? props.option._id : "",
            title: option[0].title.trim(),
            description: option[0].description.trim(),
            storeProfit: zenkakuToHankaku(option[0].storeProfit)
          },
          restaurant: restaurantId ? restaurantId : "",
        }
      },
      onCompleted: (data: EditOptionResponse) => {
        if (data.editOption) {
          successSetter(t("UpdateSuccessMessage"))
          mainErrorSetter("")
        }
        props.setModalLoading(false)
      },
      refetchQueries,
      onError,
    })

    if (createError || editError) {
      console.log(createError || editError)
      return <p>{t("ErrorMessage")}</p>
    }

    function onPressSubmit(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
      e.preventDefault()
      if (getIsDisabled(userType) || !validate() || createLoading || editLoading || props.modalLoading) return

      if (isEditMode) {
        if (zenkakuToHankaku(option[0].storeProfit) === 0) {
          if (!window.confirm("０円でもよろしいですか？")) {
            return
          }
        }
        if (zenkakuToHankaku(option[0].storeProfit) < 0) {
          window.alert("０円未満は入力できません")
          return
        }

        props.setModalLoading(true)
        editMutate()
      } else {
        let zeroFlag = false
        option.forEach(op => {
          if (zenkakuToHankaku(op.storeProfit) === 0) {
            zeroFlag = true
          }
        })
        if (zeroFlag) {
          if (!window.confirm("０円でもよろしいですか？")) {
            return
          }
        }
        let minusFlag = false
        option.forEach(op => {
          if (zenkakuToHankaku(op.storeProfit) < 0) {
            minusFlag = true
          }
        })
        if (minusFlag) {
          window.alert("０円未満は入力できません")
          return
        }

        props.setModalLoading(true)
        createMutate()
      }
    }

    return (
      <Button
        disabled={createLoading || editLoading || props.modalLoading}
        color="primary"
        onClick={onPressSubmit}
      >
        {createLoading || editLoading || props.modalLoading ? t("Saving") : t("Save")}
      </Button>
    )
  }

  const lg = window.screen.width > PC_BOUNDARY
  return (
    <Card>
      <CardHeader>{t("Option")}</CardHeader>
      <CardBody>
        <Form>
          <div>
            {lg &&
              <Row>
                <Col lg="3">
                  <label className="form-control-label" htmlFor="input-title"> {t("Title")} </label>
                </Col>
                <Col lg="3">
                  <label className="form-control-label" htmlFor="input-description">
                    {t("Description")}
                  </label>
                </Col>
                <Col lg="2">
                  <label className="form-control-label" htmlFor="input-storeProfit">
                    {t("StoreProfit")}
                  </label>
                </Col>
                <Col lg="2">
                  <label className="form-control-label" htmlFor="input-tax-in-price">
                    {t("TaxInPrice")}
                  </label>
                </Col>
                {!props.option && (
                  <Col lg="2">
                    <label className="form-control-label">
                      {t("Add/Remove")}
                    </label>
                  </Col>
                )}
              </Row>
            }

            {option.map((optionItem, index) => {
              const calc = new Calculator(option[index].storeProfit, props.restaurant ? props.restaurant.commission : 0)
              const taxInPrice = isNaN(calc.taxInPrice) ? "" : calc.taxInPrice

              return (
                <Row key={index}>
                  <Col lg="3">
                    <FormGroup className={optionItem.titleError === true ? "has-danger" : ""}>
                      {!lg && <label className="form-control-label" htmlFor="input-title"> {t("Title")} </label>}
                      <Input
                        autoCapitalize="none"
                        className="form-control-alternative"
                        id="input-title"
                        placeholder={t("OptionPH")}
                        type="text"
                        value={optionItem.title}
                        onChange={(e: any) => onChange(e, index, "title")}
                      />
                    </FormGroup>
                  </Col>

                  <Col lg="3">
                    <FormGroup>
                      {!lg && <label className="form-control-label" htmlFor="input-description"> {t("Description")}</label>}
                      <Input
                        autoCapitalize="none"
                        className="form-control-alternative"
                        id="input-description"
                        placeholder={t("OptionDescriptionPH")}
                        type="text"
                        value={optionItem.description}
                        onChange={(e: any) => onChange(e, index, "description")}
                      />
                    </FormGroup>
                  </Col>

                  <Col lg="2">
                    <FormGroup className={optionItem.storeProfitError === true ? "has-danger" : ""} >
                      {!lg && <label className="form-control-label" htmlFor="input-storeProfit"> {t("StoreProfit")} </label>}
                      <Input
                        className="form-control-alternative"
                        id="input-storeProfit"
                        value={optionItem.storeProfit}
                        type="number"
                        onChange={(e: any) => onChange(e, index, "storeProfit")}
                      />
                    </FormGroup>
                  </Col>

                  <Col lg="2">
                    <FormGroup >
                      {!lg && <label className="form-control-label" htmlFor="input-tax-in-price"> {t("TaxInPrice")} </label>}
                      <Input
                        autoCapitalize="none"
                        className="form-control-alternative"
                        id="input-tax-in-price"
                        type="number"
                        value={taxInPrice}
                        disabled={true}
                      />
                    </FormGroup>
                  </Col>

                  {!props.option && lg && (
                    <Col lg="2">
                      <Button color="danger" onClick={() => { onRemove(index) }}>-</Button>
                      <Button color="primary" onClick={() => { onAdd(index) }} >+</Button>
                    </Col>
                  )}
                </Row>
              )
            })}

            <Row>
              <Col lg="4">
                <SubmitButton />
              </Col>
              <Alert color="success" isOpen={!!success} toggle={onDismiss}>
                {success}
              </Alert>
              <Alert color="danger" isOpen={!!mainError} toggle={onDismiss}>
                {mainError}
              </Alert>
            </Row>
          </div>
        </Form>
      </CardBody>
    </Card>
  )
}

export default Option
