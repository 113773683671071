import React from 'react'
import ReactDOM from 'react-dom'
import { ApolloClient, InMemoryCache, defaultDataIdFromObject, from, split, ApolloLink, Observable, } from '@apollo/client'
import { ApolloProvider } from '@apollo/client/react'
import { WebSocketLink } from '@apollo/client/link/ws'
import { getMainDefinition } from '@apollo/client/utilities'

import { LoadScript } from '@react-google-maps/api'
import 'firebase/messaging'
import './assets/vendor/nucleo/css/nucleo.css'
import './assets/vendor/@fortawesome/fontawesome-free/css/all.min.css'
import './assets/scss/argon-dashboard-react.scss'

import { WS_SERVER_URL, SERVER_URL, GOOGLE_MAP_API_KEY } from './config/config'
import {createUploadLink} from "apollo-upload-client"
import App from './app'
// const GET_ORDERS = gql`
//   ${getOrders}
// `
// const UPLOAD_TOKEN = gql`
//   ${uploadToken}
// `

// var firebaseConfig = {
//   apiKey: 'AIzaSyBirsIm38engerok0qKLcGtIOGNh2sGBeM',
//   authDomain: 'fooddelivery202011.firebaseapp.com',
//   databaseURL: 'https://fooddelivery202011.firebaseio.com',
//   projectId: 'fooddelivery202011',
//   storageBucket: 'fooddelivery202011.appspot.com',
//   messagingSenderId: '257857487878',
//   appId: '1:257857487878:web:8852f7453d3a4da586af87',
//   measurementId: 'G-NNKWNPT5QZ'
// }

const cache = new InMemoryCache({
  dataIdFromObject: object => {
    switch (object.__typename) {
      case 'Item':
      case 'ItemVariation':
      case 'ItemAddon':
      case 'ItemOption': return null
      default: return defaultDataIdFromObject(object)
    }
  }
})

const uploadLink = createUploadLink({
  uri: `${SERVER_URL}graphql`
})
const wsLink = new WebSocketLink({
  uri: `${WS_SERVER_URL}graphql`,
  options: {
    reconnect: true
  }
})
const request = async operation => {
  const data = localStorage.getItem('user-enatega')

  let token = null
  if (data) {
    token = JSON.parse(data).token
  }
  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : ''
    }
  })
}

const requestLink = new ApolloLink(
  (operation, forward) =>
    new Observable(observer => {
      let handle
      Promise.resolve(operation)
        .then(oper => request(oper))
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer)
          })
        })
        .catch(observer.error.bind(observer))

      return () => {
        if (handle) handle.unsubscribe()
      }
    })
)
const terminatingLink = split(({ query }) => {
  const { kind, operation } = getMainDefinition(query)
  return kind === 'OperationDefinition' && operation === 'subscription'
}, wsLink)
const client = new ApolloClient({
  link: from([terminatingLink, requestLink, uploadLink]),
  cache
})

// // Initialize Firebase
// firebase.initializeApp(firebaseConfig)
// if (firebase.messaging.isSupported()) {
//   const messaging = firebase.messaging()
//   messaging.usePublicVapidKey(
//     'BEDusPsr1yF4xvpIXtEm9_YpsHIQYs_HVnpy0O9DzbUCotZzqwML83aKXSBk6yeswFDKcth_E5ouQCs5d4ciYKY'
//   )
// messaging
//   .requestPermission()
//   .then(function() {
//     messaging
//       .getToken()
//       .then(function(currentToken) {
//         const data = localStorage.getItem('user-enatega')

//         if (currentToken && data) {
//           const id = JSON.parse(data).userId.toString()
//           client
//             .mutate({
//               mutation: UPLOAD_TOKEN,
//               variables: { id, pushToken: currentToken }
//             })
//             .then(() => {})
//             .catch(() => {})
//         } else {
//         }
//       })
//       .catch(function() {})
//   })
//   .catch(function() {})

// messaging.onMessage(function(payload) {
//   console.log('Notification Payload', payload)
//   var notificationTitle = 'New Order on Enatega Multivendor'
//   var notificationOptions = {
//     body: payload.data.orderid,
//     icon: 'https://multivendor.ninjascode.com/assets/images/logo.png'
//   }
//   const nt = new Notification(notificationTitle, notificationOptions)
//   nt.onclick = function(event) {
//     event.preventDefault() // prevent the browser from focusing the Notification's tab
//     window.open('https://multivendor.ninjascode.com/dashboard')
//     nt.close()
//   }

//   client.query({ query: GET_ORDERS, fetchPolicy: 'network-only' })
// })
// }

ReactDOM.render(
  <ApolloProvider client={client}>
    <LoadScript id="script-loader" googleMapsApiKey={GOOGLE_MAP_API_KEY}>
      <App />
    </LoadScript>
  </ApolloProvider>,
  document.getElementById('root')
)
