import React, { SetStateAction } from "react"
import { useTranslation } from "react-i18next"
import { StoreSalesboardUserDataQueryResponse } from "../../../domain/graphql/query/useStoreSalesboardUserDataQuery"
import DataTable from "./DataTable"

interface Props {
  userData: StoreSalesboardUserDataQueryResponse[]
  setSelectedUserId: React.Dispatch<SetStateAction<string | null>>
  onRowClicked: (e: any) => void
  loading: boolean
}

const Users: React.FC<Props> = props => {
  const {t} = useTranslation()

  const columns = [
    {
      selector: "userId",
      sortable: true,
      // maxWidth: "200px",
      name: t("ClientId"),
      cell: row => <>{row.userId.substr(0, 20) + "..."}</>
    },
    {
      selector: "",
      name: t("OrderCount"),
      sortable: true,
      width: "100px",
      cell: row => <>{row.orders.length}</>
    },
    {
      name: t("TotalPrice"),
      selector: "",
      sortable: true,
      width: "150px",
      cell: row => <>{row.orders.reduce((a,x) => a += x.totalStoreProfit, 0).toLocaleString()}</>
    },
  ]

  return (
    <DataTable
      title={t("User")}
      columns={columns}
      data={props.userData}
      loading={props.loading}
      onRowClicked={props.onRowClicked}
    />
  )
}
export default Users
