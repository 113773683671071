import React from "react"
import {
  Card,
  CardBody,
  CardHeader,
  Row,
  Col,
} from "reactstrap"
import {
  retrieveRiders,
  Rider,
  timezoneKeys,
} from "./lib"
import { Bar, Line } from "react-chartjs-2"
import { useTranslation } from "react-i18next"
import { useSalesboardGraphData } from "../../domain/graphql/query/useSalesboardGraphData"


interface Props {
  riders: ReturnType<typeof retrieveRiders>
  selectedRider: Rider | null
  selectedStatus: "DELIVERED" | "CANCELLED" | "CANCELLED_BY_USER" | null
  businessStartTime: number
  businessEndTime: number
}
const Graphs: React.FC<Props> = props => {
  const { t } = useTranslation()

  const { error, data } = useSalesboardGraphData(
    props.businessStartTime,
    props.businessEndTime,
    props.selectedStatus,
    props.selectedRider?._id,
  )
  if (error) {
    console.log(error)
  }

  const orderCountsByTimezone = data && data.salesboardGraphData && [...data.salesboardGraphData.orderCountsByTimezone]
  const salesByTimezone = data && data.salesboardGraphData && [...data.salesboardGraphData.salesByTimezone]
  const averageOrderPriceByTimezone = data?.salesboardGraphData
    ? [...data.salesboardGraphData.orderCountsByTimezone.map((count, i) => {
      if (count === 0) return 0
      return Math.ceil(data.salesboardGraphData.orderPricesByTimezone[i] / count * 10) / 10
    })]
    : []
  const dayOfTheWeekLabels = ["月", "火", "水", "木", "金", "土", "日"]
  const orderCountsByDayOfTheWeek = data && data.salesboardGraphData && [...data.salesboardGraphData.orderCountsByDayOfTheWeek]
  const salesByDayOfTheWeek = data && data.salesboardGraphData && [...data.salesboardGraphData.salesByDayOfTheWeek]
  const dailySalesLabels = data && data.salesboardGraphData && [...data.salesboardGraphData.dailySales.labels]
  const dailySalesValues = data && data.salesboardGraphData && [...data.salesboardGraphData.dailySales.values]

  return (
    <>
      <Row className="mb-3">
        <Col xl="4">
          <Card className="shadow">
            <BarChart title={t("OrderCountsByTimezone")} labels={timezoneKeys} datasets={orderCountsByTimezone ?? []} />
          </Card>
        </Col>
        <Col xl="4">
          <Card className="shadow">
            <BarChart title={t("SalesByTimezone")} labels={timezoneKeys} datasets={salesByTimezone ?? []} />
          </Card>
        </Col>
        <Col xl="4">
          <Card className="shadow">
            <BarChart title={t("AverageOrderPriceByTimezone")} labels={timezoneKeys ?? []} datasets={averageOrderPriceByTimezone ?? []} />
          </Card>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col xl="4">
          <Card className="shadow">
            <BarChart title={t("OrderCountsByDayOfTheWeek")} labels={dayOfTheWeekLabels} datasets={orderCountsByDayOfTheWeek ?? []} />
          </Card>
        </Col>
        <Col xl="4">
          <Card className="shadow">
            <BarChart title={t("SalesByDayOfTheWeek")} labels={dayOfTheWeekLabels} datasets={salesByDayOfTheWeek ?? []} />
          </Card>
        </Col>
        <Col xl="4">
          <Card className="shadow">
            <LineChart title={t("DailySales")} labels={dailySalesLabels ?? []} datasets={dailySalesValues ?? []} />
          </Card>
        </Col>
      </Row>
      <Row className="mb-3">
      </Row>
    </>
  )
}

export default Graphs

interface ChartProps {
  title: string
  labels: string[]
  datasets: number[]

}
const BarChart: React.FC<ChartProps> = props => <>
  <CardHeader className="bg-transparent">
    <Row className="align-items-center">
      <div className="col">
        <h2 className="mb-0">{props.title}</h2>
      </div>
    </Row>
  </CardHeader>
  <CardBody>
    <div className="chart">
      <Bar
        data={{
          labels: props.labels,
          datasets: [
            {
              label: props.title,
              data: props.datasets,
              backgroundColor: [
                "rgba(255, 99, 132, 0.2)",
                "rgba(255, 159, 64, 0.2)",
                "rgba(255, 205, 86, 0.2)",
                "rgba(75, 192, 192, 0.2)",
                "rgba(54, 162, 235, 0.2)",
                "rgba(153, 102, 255, 0.2)",
                "rgba(201, 203, 207, 0.2)",
                "rgba(255, 99, 132, 0.2)",
                "rgba(255, 159, 64, 0.2)",
                "rgba(255, 205, 86, 0.2)",
                "rgba(75, 192, 192, 0.2)",
                "rgba(54, 162, 235, 0.2)",
                "rgba(153, 102, 255, 0.2)",
                "rgba(201, 203, 207, 0.2)",
                "rgba(255, 99, 132, 0.2)",
              ],
              borderColor: [
                "rgb(255, 99, 132)",
                "rgb(255, 159, 64)",
                "rgb(255, 205, 86)",
                "rgb(75, 192, 192)",
                "rgb(54, 162, 235)",
                "rgb(153, 102, 255)",
                "rgb(201, 203, 207)",
                "rgb(255, 99, 132)",
                "rgb(255, 159, 64)",
                "rgb(255, 205, 86)",
                "rgb(75, 192, 192)",
                "rgb(54, 162, 235)",
                "rgb(153, 102, 255)",
                "rgb(201, 203, 207)",
                "rgb(255, 99, 132)",
              ],
              borderWidth: 1,
            }
          ]
        }}
        width={10}
        height={50}
        options={{
          maintainAspectRatio: false,
          legend: { display: false },
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero: true,
                min: 0,
              }
            }]
          }
        }}
      />
    </div>
  </CardBody>
</>


const LineChart: React.FC<ChartProps> = props => <>
  <CardHeader className="bg-transparent">
    <Row className="align-items-center">
      <div className="col">
        <h2 className="mb-0">{props.title}</h2>
      </div>
    </Row>
  </CardHeader>
  <CardBody>
    <div className="chart">
      <Line
        data={{
          labels: props.labels,
          datasets: [
            {
              label: props.title,
              data: props.datasets,
              backgroundColor: "rgba(54, 162, 235, 0.2)",
              borderColor: "rgb(54, 162, 235)",
              borderWidth: 1,
            }
          ]
        }}
        width={10}
        height={50}
        options={{
          maintainAspectRatio: false,
          legend: { display: false },
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero: true,
                min: 0,
              }
            }]
          }
        }}
      />
    </div>
  </CardBody>
</>