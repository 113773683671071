import React, { useState, useRef, useEffect, useContext } from "react"
import { validateFunc } from "../constraints/constraints"
import { withTranslation } from "react-i18next"
import Header from "../components/Headers/Header"
import {
  getRestaurant,
  editRestaurant,
} from "../apollo/server"
// reactstrap components
import {
  Button,
  Card,
  Alert,
  CardHeader,
  CardBody,
  Container,
  FormGroup,
  Input,
  Row,
  Col
} from "reactstrap"
import { getIsDisabled } from "../utils/getIsDisabled"
import { useQuery, useMutation, gql, } from "@apollo/client"
import DayPicker from "react-day-picker"
import "react-day-picker/lib/style.css"
import DataTable from "react-data-table-component"
import { paginationComponentOptions } from "../variables/table"
import CustomLoader from "../components/Loader/CustomLoader"
import { getDateFromStr, getDateStr } from "../utils/date"
import { PC_BOUNDARY } from "./constants"
import { AppContext } from "../app"

const EDIT_RESTAURANT = gql`
  ${editRestaurant}
`

const VendorProfile = props => {
  const { t } = props
  const appContext = useContext(AppContext)
  const userType = appContext.userType
  const restaurantId = appContext.restaurantId



  const [isMondayActive, setIsMondayActive] = useState(true)
  const [isTuesdayActive, setIsTuesdayActive] = useState(true)
  const [isWednesdayActive, setIsWednesdayActive] = useState(true)
  const [isThursdayActive, setIsThursdayActive] = useState(true)
  const [isFridayActive, setIsFridayActive] = useState(true)
  const [isSaturdayActive, setIsSaturdayActive] = useState(true)
  const [isSundayActive, setIsSundayActive] = useState(true)
  const [isHolidayActive, setIsHolidayActive] = useState(true)
  const [isBeforeHolidayActive, setIsBeforeHolidayActive] = useState(true)

  const [tempHolidays, setTempHolidays] = useState([])

  const [imgUrl, setImgUrl] = useState("")
  const [imgFile, setImgFile] = useState(null)

  const [nameError, setNameError] = useState(null)
  const [usernameError, setUsernameError] = useState(null)
  const [phoneError, setPhoneError] = useState(null)
  const [passwordError, setPasswordError] = useState(null)
  const [addressError, setAddressError] = useState(null)

  const [latError, setLatError] = useState(null)
  const [longError, setLongError] = useState(null)

  const [prefixError, setPrefixError] = useState(null)

  const [deliveryChargesError, setDeliveryChargesError] = useState(null)
  const [chargeLimitError, setChargeLimitError] = useState(null)
  const [deliveryTimeError, setDeliveryTimeError] = useState(null)
  const [minimumOrderError, setMinimumOrderError] = useState(null)
  const [promotionTextError, setPromotionTextError] = useState(null)


  const [errors, setErrors] = useState("")
  const [success, setSuccess] = useState("")

  const formRef = useRef(null)

  const selectImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const image = filterImage(event)
    if (image) {
      setImgFile(image)
      imageToBase64(image)
    }
  }


  const filterImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    let images = []
    for (var i = 0; i < event.target.files.length; i++) {
      images[i] = event.target.files.item(i)
    }
    images = images.filter(image => image.name.match(/\.(jpg|jpeg|png|gif)$/))
    return images.length ? images[0] : undefined
  }
  const imageToBase64 = (file: File) => {
    const fileReader = new FileReader()
    fileReader.onloadend = () => {
      setImgUrl(fileReader.result as string)
    }
    fileReader.readAsDataURL(file)
  }

  const onSubmitValidaiton = () => {
    const form = formRef.current
    const name = form.name.value
    const phone = form.phone.value
    const address = form.address.value
    const latitude = form.latitude.value
    const longitude = form.longitude.value
    const username = form.username.value
    const password = form.password.value
    const prefix = form.prefix.value
    const deliveryTime = form.deliveryTime.value
    const minimumOrder = form.minimumOrder.value
    const promotionText = form.promotionText.value


    const nameErrors = validateFunc({ name }, "name")
    const addressErrors = validateFunc({ address }, "address")
    const latErrors = validateFunc({ lat: latitude }, "lat")
    const longErrors = validateFunc({ long: longitude }, "long")
    const prefixErrors = validateFunc({ prefix: prefix }, "prefix")
    const deliveryTimeErrors = validateFunc({ deliveryTime: deliveryTime }, "deliveryTime")
    const minimumOrderErrors = validateFunc({ minimumOrder: minimumOrder }, "minimumOrder")
    const promotionTextErrors = validateFunc({ promotionText }, "promotionText")

    const usernameErrors = validateFunc({ name: username }, "name")
    const phoneErrors = validateFunc({ phone }, "phone")
    const passwordErrors = validateFunc({ password }, "password")

    setNameError(nameErrors)
    setPhoneError(phoneErrors)
    setAddressError(addressErrors)
    setLatError(latErrors)
    setLongError(longErrors)
    setPrefixError(prefixErrors)
    setUsernameError(usernameErrors)
    setPasswordError(passwordErrors)
    setDeliveryTimeError(deliveryTimeErrors)
    setMinimumOrderError(minimumOrderErrors)
    setPromotionTextError(promotionTextErrors)


    if (
      (
        nameErrors &&
        phoneErrors &&
        addressErrors &&
        latErrors &&
        longErrors &&
        prefixErrors &&
        usernameErrors &&
        passwordErrors &&
        deliveryTimeErrors &&
        minimumOrderErrors
      )
    ) {
      setErrors(t("Fields Required"))
    }
    if (promotionTextErrors) {
      setErrors(t("PromotionTextTooLongError"))
    }
    return !(
      nameErrors ||
      phoneErrors ||
      addressErrors ||
      latErrors ||
      longErrors ||
      prefixErrors ||
      usernameErrors ||
      passwordErrors ||
      deliveryTimeErrors ||
      minimumOrderErrors ||
      promotionTextErrors
    )
  }

  function treatRegularHolidays() {
    const holidays = [] // const form = formRef.current
    if (!isMondayActive) holidays.push(1)
    if (!isTuesdayActive) holidays.push(2)
    if (!isWednesdayActive) holidays.push(3)
    if (!isThursdayActive) holidays.push(4)
    if (!isFridayActive) holidays.push(5)
    if (!isSaturdayActive) holidays.push(6)
    if (!isSundayActive) holidays.push(0)
    if (!isHolidayActive) holidays.push(7)
    if (!isBeforeHolidayActive) holidays.push(8)
    return holidays
  }

  const onCompleted = data => {
    setNameError(null)
    setPhoneError(null)
    setAddressError(null)
    setLatError(null)
    setLongError(null)
    setDeliveryChargesError(null)
    setChargeLimitError(null)
    setPrefixError(null)
    setUsernameError(null)
    setPasswordError(null)
    setDeliveryTimeError(null)
    setMinimumOrderError(null)
    setPromotionTextError(null)
    setErrors("")
    setSuccess(t("UpdateSuccessMessage"))
  }

  const onError = ({ graphQLErrors, networkError }) => {
    setNameError(null)
    setPhoneError(null)
    setAddressError(null)
    setLatError(null)
    setLongError(null)
    setDeliveryChargesError(null)
    setChargeLimitError(null)
    setPrefixError(null)
    setUsernameError(null)
    setPasswordError(null)
    setDeliveryTimeError(null)
    setMinimumOrderError(null)
    setPromotionTextError(null)
    setSuccess("")
    if (graphQLErrors) {
      console.log(graphQLErrors)
      setErrors(t("ErrorMessage"))
    }
    if (networkError) {
      console.error(networkError)
      setErrors(t("ErrorMessage"))
    }
  }
  const onDismiss = () => {
    setNameError(null)
    setPhoneError(null)
    setAddressError(null)
    setLatError(null)
    setLongError(null)
    setDeliveryChargesError(null)
    setChargeLimitError(null)
    setUsernameError(null)
    setPasswordError(null)
    setDeliveryTimeError(null)
    setMinimumOrderError(null)
    setPromotionTextError(null)
    setSuccess("")
    setErrors("")
  }

  const [mutateEditRestaurant, { loading: mutationLoading }] = useMutation(EDIT_RESTAURANT, { onError, onCompleted, })
  async function updateRestarant(e) {
    e.preventDefault()
    if (getIsDisabled(userType)) return
    if (onSubmitValidaiton()) {
      // const imgUpload = await uploadImageToCloudinary()
      const form = formRef.current
      const name = form.name.value
      const phone = form.phone.value
      const address = form.address.value
      const latitude = "" + parseFloat(form.latitude.value)
      const longitude = "" + parseFloat(form.longitude.value)
      const prefix = form.prefix.value // can we not update this?
      const deliveryCharges = form.deliveryCharges?.value ?? data.restaurant.deliveryCharges
      const minimumOrder = form.minimumOrder.value
      const chargeLimit = form.chargeLimit?.value ?? data.restaurant.chargeLimit
      const deliveryTime = form.deliveryTime.value
      const promotionText = form.promotionText.value.trim()
      const link = form.link.value.trim()
      const videoId = form.videoId.value.trim()

      const regularHolidays = treatRegularHolidays()

      const mondayOpenTime = form.mondayOpenTime && form.mondayOpenTime.value ? form.mondayOpenTime.value : "00:00"
      const mondayCloseTime = form.mondayCloseTime && form.mondayCloseTime.value ? form.mondayCloseTime.value : "00:00"
      const mondayOpenTime2 = form.mondayOpenTime2 && form.mondayOpenTime2.value ? form.mondayOpenTime2.value : "00:00"
      const mondayCloseTime2 = form.mondayCloseTime2 && form.mondayCloseTime2.value ? form.mondayCloseTime2.value : "00:00"

      const tuesdayOpenTime = form.tuesdayOpenTime && form.tuesdayOpenTime.value ? form.tuesdayOpenTime.value : "00:00"
      const tuesdayCloseTime = form.tuesdayCloseTime && form.tuesdayCloseTime.value ? form.tuesdayCloseTime.value : "00:00"
      const tuesdayOpenTime2 = form.tuesdayOpenTime2 && form.tuesdayOpenTime2.value ? form.tuesdayOpenTime2.value : "00:00"
      const tuesdayCloseTime2 = form.tuesdayCloseTime2 && form.tuesdayCloseTime2.value ? form.tuesdayCloseTime2.value : "00:00"

      const wednesdayOpenTime = form.wednesdayOpenTime && form.wednesdayOpenTime.value ? form.wednesdayOpenTime.value : "00:00"
      const wednesdayCloseTime = form.wednesdayCloseTime && form.wednesdayCloseTime.value ? form.wednesdayCloseTime.value : "00:00"
      const wednesdayOpenTime2 = form.wednesdayOpenTime2 && form.wednesdayOpenTime2.value ? form.wednesdayOpenTime2.value : "00:00"
      const wednesdayCloseTime2 = form.wednesdayCloseTime2 && form.wednesdayCloseTime2.value ? form.wednesdayCloseTime2.value : "00:00"

      const thursdayOpenTime = form.thursdayOpenTime && form.thursdayOpenTime.value ? form.thursdayOpenTime.value : "00:00"
      const thursdayCloseTime = form.thursdayCloseTime && form.thursdayCloseTime.value ? form.thursdayCloseTime.value : "00:00"
      const thursdayOpenTime2 = form.thursdayOpenTime2 && form.thursdayOpenTime2.value ? form.thursdayOpenTime2.value : "00:00"
      const thursdayCloseTime2 = form.thursdayCloseTime2 && form.thursdayCloseTime2.value ? form.thursdayCloseTime2.value : "00:00"

      const fridayOpenTime = form.fridayOpenTime && form.fridayOpenTime.value ? form.fridayOpenTime.value : "00:00"
      const fridayCloseTime = form.fridayCloseTime && form.fridayCloseTime.value ? form.fridayCloseTime.value : "00:00"
      const fridayOpenTime2 = form.fridayOpenTime2 && form.fridayOpenTime2.value ? form.fridayOpenTime2.value : "00:00"
      const fridayCloseTime2 = form.fridayCloseTime2 && form.fridayCloseTime2.value ? form.fridayCloseTime2.value : "00:00"

      const saturdayOpenTime = form.saturdayOpenTime && form.saturdayOpenTime.value ? form.saturdayOpenTime.value : "00:00"
      const saturdayCloseTime = form.saturdayCloseTime && form.saturdayCloseTime.value ? form.saturdayCloseTime.value : "00:00"
      const saturdayOpenTime2 = form.saturdayOpenTime2 && form.saturdayOpenTime2.value ? form.saturdayOpenTime2.value : "00:00"
      const saturdayCloseTime2 = form.saturdayCloseTime2 && form.saturdayCloseTime2.value ? form.saturdayCloseTime2.value : "00:00"

      const sundayOpenTime = form.sundayOpenTime && form.sundayOpenTime.value ? form.sundayOpenTime.value : "00:00"
      const sundayCloseTime = form.sundayCloseTime && form.sundayCloseTime.value ? form.sundayCloseTime.value : "00:00"
      const sundayOpenTime2 = form.sundayOpenTime2 && form.sundayOpenTime2.value ? form.sundayOpenTime2.value : "00:00"
      const sundayCloseTime2 = form.sundayCloseTime2 && form.sundayCloseTime2.value ? form.sundayCloseTime2.value : "00:00"

      const holidayOpenTime = form.holidayOpenTime && form.holidayOpenTime.value ? form.holidayOpenTime.value : "00:00"
      const holidayCloseTime = form.holidayCloseTime && form.holidayCloseTime.value ? form.holidayCloseTime.value : "00:00"
      const holidayOpenTime2 = form.holidayOpenTime2 && form.holidayOpenTime2.value ? form.holidayOpenTime2.value : "00:00"
      const holidayCloseTime2 = form.holidayCloseTime2 && form.holidayCloseTime2.value ? form.holidayCloseTime2.value : "00:00"

      const beforeHolidayOpenTime = form.beforeHolidayOpenTime && form.beforeHolidayOpenTime.value ? form.beforeHolidayOpenTime.value : "00:00"
      const beforeHolidayCloseTime = form.beforeHolidayCloseTime && form.beforeHolidayCloseTime.value ? form.beforeHolidayCloseTime.value : "00:00"
      const beforeHolidayOpenTime2 = form.beforeHolidayOpenTime2 && form.beforeHolidayOpenTime2.value ? form.beforeHolidayOpenTime2.value : "00:00"
      const beforeHolidayCloseTime2 = form.beforeHolidayCloseTime2 && form.beforeHolidayCloseTime2.value ? form.beforeHolidayCloseTime2.value : "00:00"

      const username = form.username.value
      const password = form.password.value

      mutateEditRestaurant({
        variables: {
          restaurantInput: {
            _id: restaurantId,
            name,
            phone,
            address,
            image: imgFile,
            lat: latitude,
            long: longitude,
            orderPrefix: prefix,
            deliveryCharges: Number(deliveryCharges),
            chargeLimit: Number(chargeLimit),
            deliveryTime: Number(deliveryTime),
            minimumOrder: Number(minimumOrder),
            promotionText,
            link,
            videoId,

            regularHolidays,
            tempHolidays,

            mondayOpenTime,
            mondayCloseTime,
            mondayOpenTime2,
            mondayCloseTime2,

            tuesdayOpenTime,
            tuesdayCloseTime,
            tuesdayOpenTime2,
            tuesdayCloseTime2,

            wednesdayOpenTime,
            wednesdayCloseTime,
            wednesdayOpenTime2,
            wednesdayCloseTime2,

            thursdayOpenTime,
            thursdayCloseTime,
            thursdayOpenTime2,
            thursdayCloseTime2,

            fridayOpenTime,
            fridayCloseTime,
            fridayOpenTime2,
            fridayCloseTime2,

            saturdayOpenTime,
            saturdayCloseTime,
            saturdayOpenTime2,
            saturdayCloseTime2,

            sundayOpenTime,
            sundayCloseTime,
            sundayOpenTime2,
            sundayCloseTime2,

            holidayOpenTime,
            holidayCloseTime,
            holidayOpenTime2,
            holidayCloseTime2,

            beforeHolidayOpenTime,
            beforeHolidayCloseTime,
            beforeHolidayOpenTime2,
            beforeHolidayCloseTime2,

            username: username,
            password: password
          }
        }
      })
    }
  }

  const { loading, error, data } = useQuery(gql`${getRestaurant}`, {
    variables: { id: restaurantId },
    onError: error => {
      console.log(error)
    },
  })
  
  if (error) {
    console.log(error)
    return (
      <div >
        <Header />
        <p style={{ marginTop: 30, textAlign: "center" }}>{`${t("Error")}! ${error.message}`}</p>
      </div>
    )
  }

  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-1">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">{t("Update Profile")}</h3>
                  </Col>
                </Row>
              </CardHeader>

              <CardBody>
                {loading
                  ? <CustomLoader />
                  : <>
                    <form ref={formRef}>
                      <Row>
                        <Col lg="4">
                          <label
                            className="form-control-label"
                            htmlFor="input-type-id">
                              ID
                          </label>
                          <br />
                          <FormGroup>
                            <Input
                              name="id"
                              className="form-control-alternative"
                              id="input-type-id"
                              type="text"
                              defaultValue={data.restaurant._id || ""}
                              disabled
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <label
                            className="form-control-label"
                            htmlFor="input-type-order_id_prefix">
                            {t("OrderPrefix")}
                          </label>
                          <br />
                          <FormGroup
                            className={
                              prefixError === false
                                ? "has-danger"
                                : prefixError === true
                                  ? "has-success"
                                  : ""
                            }>
                            <Input
                              autoCapitalize="none"
                              name="prefix"
                              className="form-control-alternative"
                              id="input-type-order_id_prefix"
                              placeholder="e.g -33.45"
                              type="text"
                              defaultValue={data.restaurant.orderPrefix}
                              autoComplete="off"
                              disabled
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <label
                            className="form-control-label"
                            htmlFor="input-type-phone">
                            {t("Phone")}
                          </label>
                          <br />
                          <FormGroup
                            className={
                              phoneError === false
                                ? "has-danger"
                                : phoneError === true
                                  ? "has-success"
                                  : ""
                            }>
                            <Input
                              autoCapitalize="none"
                              name="phone"
                              className="form-control-alternative"
                              id="input-type-phone"
                              type="text"
                              defaultValue={data.restaurant.phone}
                              autoComplete="off"
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="6">
                          <label
                            className="form-control-label"
                            htmlFor="input-type-name">
                            {t("RestaurantName")}
                          </label>
                          <br />
                          <FormGroup
                            className={
                              nameError === false
                                ? "has-danger"
                                : nameError === true
                                  ? "has-success"
                                  : ""
                            }>
                            <Input
                              autoCapitalize="none"
                              name="name"
                              className="form-control-alternative"
                              id="input-type-name"
                              placeholder={t("storeNamePH")}
                              type="text"
                              defaultValue={data.restaurant.name || ""}
                              autoComplete="off"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <label
                            className="form-control-label"
                            htmlFor="input-type-address">
                            {t("RestaurantAddress")}
                          </label>
                          <br />
                          <FormGroup
                            className={
                              addressError === false
                                ? "has-danger"
                                : addressError === true
                                  ? "has-success"
                                  : ""
                            }>
                            <Input
                              autoCapitalize="none"
                              name="address"
                              className="form-control-alternative"
                              id="input-type-address"
                              placeholder={t("addressPH")}
                              type="text"
                              defaultValue={data.restaurant.address}
                              autoComplete="off"
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="6">
                          <label
                            className="form-control-label"
                            htmlFor="promotionText">
                            {t("PromotionText")}
                          </label>
                          <br />
                          <FormGroup
                            className={
                              promotionTextError === false
                                ? "has-danger"
                                : promotionTextError === true
                                  ? "has-success"
                                  : ""
                            } >
                            <Input
                              name="promotionText"
                              className="form-control-alternative"
                              id="promotionText"
                              type="text"
                              defaultValue={data.restaurant.promotionText || ""}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <label
                            className="form-control-label"
                            htmlFor="link">
                            {t("Link")}
                          </label>
                          <br />
                          <FormGroup>
                            <Input
                              name="link"
                              className="form-control-alternative"
                              id="link"
                              type="text"
                              defaultValue={data.restaurant.link || ""}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <label className="form-control-label" htmlFor="videoId">
                            Youtube Video ID
                          </label>
                          <br />
                          <FormGroup >
                            <Input
                              name="videoId"
                              className="form-control-alternative"
                              id="videoId"
                              type="text"
                              defaultValue={data.restaurant.videoId || ""}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="3">
                          <label
                            className="form-control-label"
                            htmlFor="input-type-username">
                            {t("RestaurantUserame")}
                          </label>
                          <FormGroup
                            className={
                              usernameError === false
                                ? "has-danger"
                                : usernameError === true
                                  ? "has-success"
                                  : ""
                            }>
                            <Input
                              autoCapitalize="none"
                              name="username"
                              className="form-control-alternative"
                              id="input-type-username"
                              placeholder={t("appUsernamePH")}
                              type="text"
                              defaultValue={data.restaurant.username || ""}
                              autoComplete="off"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <label
                            className="form-control-label"
                            htmlFor="input-type-password">
                            {t("RestaurantPassword")}
                          </label>
                          <FormGroup
                            className={
                              passwordError === false
                                ? "has-danger"
                                : passwordError === true
                                  ? "has-success"
                                  : ""
                            }>
                            <Input
                              autoCapitalize="none"
                              name="password"
                              className="form-control-alternative"
                              id="input-type-password"
                              placeholder={t("passwordPH")}
                              type="text"
                              defaultValue={data.restaurant.password || ""}
                              autoComplete="off"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <label
                            className="form-control-label"
                            htmlFor="input-type-lat">
                            {t("Latitude")}
                          </label>
                          <br />
                          <FormGroup
                            className={
                              latError === false
                                ? "has-danger"
                                : latError === true
                                  ? "has-success"
                                  : ""
                            }>
                            <Input
                              autoCapitalize="none"
                              name="latitude"
                              className="form-control-alternative"
                              id="input-type-lat"
                              placeholder={t("latitudePH")}
                              type="text"
                              defaultValue={
                                data.restaurant.location
                                  ? data.restaurant.location
                                    .coordinates[1] || ""
                                  : ""
                              }
                              autoComplete="off"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <label
                            className="form-control-label"
                            htmlFor="input-type-long">
                            {t("Longitude")}
                          </label>
                          <br />
                          <FormGroup
                            className={
                              longError === false
                                ? "has-danger"
                                : longError === true
                                  ? "has-success"
                                  : ""
                            }>
                            <Input
                              autoCapitalize="none"
                              name="longitude"
                              className="form-control-alternative"
                              id="input-type-long"
                              placeholder={t("longitudePH")}
                              type="text"
                              defaultValue={
                                data.restaurant.location
                                  ? data.restaurant.location
                                    .coordinates[0] || ""
                                  : ""
                              }
                              autoComplete="off"
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        {userType === "ADMIN" &&
                          <Col lg="3">
                            <label
                              className="form-control-label"
                              htmlFor="input-type-delivery_charges">
                              {t("Delivery Charges")}
                            </label>
                            <br />
                            <FormGroup
                              className={
                                deliveryChargesError === false
                                  ? "has-danger"
                                  : deliveryChargesError === true
                                    ? "has-success"
                                    : ""
                              }>
                              <Input
                                name="deliveryCharges"
                                className="form-control-alternative"
                                id="input-type-delivery_charges"
                                placeholder={t("deliveryChargesPH")}
                                type="number"
                                defaultValue={data.restaurant.deliveryCharges}
                                autoComplete="off"
                              />
                            </FormGroup>
                          </Col>
                        }

                        {userType === "ADMIN" &&
                          <Col lg="3">
                            <label
                              className="form-control-label"
                              htmlFor="input-type-charge-limit">
                              {t("ChargeLimit")}
                            </label>
                            <br />
                            <FormGroup
                              className={
                                chargeLimitError === false
                                  ? "has-danger"
                                  : chargeLimitError === true
                                    ? "has-success"
                                    : ""
                              }>
                              <Input
                                autoCapitalize="none"
                                name="chargeLimit"
                                className="form-control-alternative"
                                id="input-type-charge-limit"
                                placeholder={t("ChargeLimitPH")}
                                type="number"
                                defaultValue={data.restaurant.chargeLimit}
                                autoComplete="off"
                              />
                            </FormGroup>
                          </Col>
                        }

                        <Col lg="3">
                          <label
                            className="form-control-label"
                            htmlFor="input-type-minimum-order">
                            {t("Minimum Order")}
                          </label>
                          <br />
                          <FormGroup
                            className={
                              minimumOrderError === false
                                ? "has-danger"
                                : minimumOrderError === true
                                  ? "has-success"
                                  : ""
                            }>
                            <Input
                              name="minimumOrder"
                              className="form-control-alternative"
                              id="input-type-minimum-order"
                              placeholder={t("minimumOrderPH")}
                              type="number"
                              defaultValue={data.restaurant.minimumOrder}
                              autoComplete="off"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <label
                            className="form-control-label"
                            htmlFor="input-type-delivery-time">
                            {t("Delivery Time")}
                          </label>
                          <br />
                          <FormGroup
                            className={
                              deliveryTimeError === false
                                ? "has-danger"
                                : deliveryTimeError === true
                                  ? "has-success"
                                  : ""
                            }>
                            <Input
                              name="deliveryTime"
                              className="form-control-alternative"
                              id="input-type-delivery-time"
                              placeholder={t("deliveryTimePH")}
                              type="number"
                              defaultValue={data.restaurant.deliveryTime}
                              autoComplete="off"
                            />
                          </FormGroup>
                        </Col>
                      </Row>





                      <OpenTimes
                        t={t}
                        restaurant={data.restaurant}
                        isMondayActive={isMondayActive}
                        setIsMondayActive={setIsMondayActive}
                        isTuesdayActive={isTuesdayActive}
                        setIsTuesdayActive={setIsTuesdayActive}
                        isWednesdayActive={isWednesdayActive}
                        setIsWednesdayActive={setIsWednesdayActive}
                        isThursdayActive={isThursdayActive}
                        setIsThursdayActive={setIsThursdayActive}
                        isFridayActive={isFridayActive}
                        setIsFridayActive={setIsFridayActive}
                        isSaturdayActive={isSaturdayActive}
                        setIsSaturdayActive={setIsSaturdayActive}
                        isSundayActive={isSundayActive}
                        setIsSundayActive={setIsSundayActive}
                        isHolidayActive={isHolidayActive}
                        setIsHolidayActive={setIsHolidayActive}
                        isBeforeHolidayActive={isBeforeHolidayActive}
                        setIsBeforeHolidayActive={setIsBeforeHolidayActive}
                      />

                      <TemporaryHolidays
                        t={t}
                        restaurant={data.restaurant}
                        setTempHolidays={setTempHolidays}
                      />



                      <Row>
                        <Col lg="6">
                          <label
                            className="form-control-label"
                            htmlFor="input-type-image">
                            {t("Image")}
                          </label>
                          <br />
                          <FormGroup>
                            <div className="card-title-image">
                              <a
                                href="#pablo"
                                onClick={e => e.preventDefault()}>
                                <img
                                  alt="..."
                                  className="rounded-rectangle"
                                  src={imgUrl || data.restaurant.image}
                                />
                              </a>
                            </div>
                            <input
                              className="mt-2"
                              type="file"
                              onChange={selectImage}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <hr />
                      <Row className="mt-2 justify-content-center">
                        <Col xs="4">
                          <Button
                            color="primary"
                            href="#pablo"
                            className="btn-block"
                            onClick={updateRestarant}
                            size="lg">
                            {mutationLoading
                              ? t("Updating...")
                              : t("Update")}
                          </Button>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <Alert
                            color="success"
                            isOpen={!!success}
                            toggle={onDismiss}>
                            <span className="alert-inner--icon">
                              <i className="ni ni-like-2" />
                            </span>{" "}
                            <span className="alert-inner--text">
                              <strong>{t("Success")}!</strong> {success}
                            </span>
                          </Alert>
                          <Alert
                            color="danger"
                            isOpen={!!errors}
                            toggle={onDismiss}>
                            <span className="alert-inner--icon">
                              <i className="ni ni-like-2" />
                            </span>{" "}
                            <span className="alert-inner--text">
                              <strong>{t("Danger")}!</strong> {errors}
                            </span>
                          </Alert>
                        </Col>
                      </Row>
                    </form>
                  </>
                }

              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}

function OpenTimes(props) {
  const {
    t,
    restaurant,
    isMondayActive,
    setIsMondayActive,
    isTuesdayActive,
    setIsTuesdayActive,
    isWednesdayActive,
    setIsWednesdayActive,
    isThursdayActive,
    setIsThursdayActive,
    isFridayActive,
    setIsFridayActive,
    isSaturdayActive,
    setIsSaturdayActive,
    isSundayActive,
    setIsSundayActive,
    isHolidayActive,
    setIsHolidayActive,
    isBeforeHolidayActive,
    setIsBeforeHolidayActive,
  } = props

  useEffect(() => {
    if (restaurant) {
      setIsMondayActive(restaurant.regularHolidays.indexOf(1) === -1)
      setIsTuesdayActive(restaurant.regularHolidays.indexOf(2) === -1)
      setIsWednesdayActive(restaurant.regularHolidays.indexOf(3) === -1)
      setIsThursdayActive(restaurant.regularHolidays.indexOf(4) === -1)
      setIsFridayActive(restaurant.regularHolidays.indexOf(5) === -1)
      setIsSaturdayActive(restaurant.regularHolidays.indexOf(6) === -1)
      setIsSundayActive(restaurant.regularHolidays.indexOf(0) === -1)
      setIsHolidayActive(restaurant.regularHolidays.indexOf(7) === -1)
      setIsBeforeHolidayActive(restaurant.regularHolidays.indexOf(8) === -1)
    }
  }, [
    restaurant,
    setIsMondayActive,
    setIsTuesdayActive,
    setIsWednesdayActive,
    setIsThursdayActive,
    setIsFridayActive,
    setIsSaturdayActive,
    setIsSundayActive,
    setIsHolidayActive,
    setIsBeforeHolidayActive,
  ])


  const items = [
    {
      id: "monday",
      label: "月曜日",
      checked: isMondayActive,
      onChangeCheckbox: e => setIsMondayActive(e.target.checked),
      defaultOpenValue: restaurant.mondayOpenTime !== restaurant.mondayCloseTime ? restaurant.mondayOpenTime : restaurant.openTime,
      defaultCloseValue: restaurant.mondayOpenTime !== restaurant.mondayCloseTime ? restaurant.mondayCloseTime : restaurant.closeTime,
      defaultOpenValue2: restaurant.mondayOpenTime2 !== restaurant.mondayCloseTime2 ? restaurant.mondayOpenTime2 : restaurant.openTime2,
      defaultCloseValue2: restaurant.mondayOpenTime2 !== restaurant.mondayCloseTime2 ? restaurant.mondayCloseTime2 : restaurant.closeTime2,
    },
    {
      id: "tuesday",
      label: "火曜日",
      checked: isTuesdayActive,
      onChangeCheckbox: e => setIsTuesdayActive(e.target.checked),
      defaultOpenValue: restaurant.tuesdayOpenTime !== restaurant.tuesdayCloseTime ? restaurant.tuesdayOpenTime : restaurant.openTime,
      defaultCloseValue: restaurant.tuesdayOpenTime !== restaurant.tuesdayCloseTime ? restaurant.tuesdayCloseTime : restaurant.closeTime,
      defaultOpenValue2: restaurant.tuesdayOpenTime2 !== restaurant.tuesdayCloseTime2 ? restaurant.tuesdayOpenTime2 : restaurant.openTime2,
      defaultCloseValue2: restaurant.tuesdayOpenTime2 !== restaurant.tuesdayCloseTime2 ? restaurant.tuesdayCloseTime2 : restaurant.closeTime2,
    },
    {
      id: "wednesday",
      label: "水曜日",
      checked: isWednesdayActive,
      onChangeCheckbox: e => setIsWednesdayActive(e.target.checked),
      defaultOpenValue: restaurant.wednesdayOpenTime !== restaurant.wednesdayCloseTime ? restaurant.wednesdayOpenTime : restaurant.openTime,
      defaultCloseValue: restaurant.wednesdayOpenTime !== restaurant.wednesdayCloseTime ? restaurant.wednesdayCloseTime : restaurant.closeTime,
      defaultOpenValue2: restaurant.wednesdayOpenTime2 !== restaurant.wednesdayCloseTime2 ? restaurant.wednesdayOpenTime2 : restaurant.openTime2,
      defaultCloseValue2: restaurant.wednesdayOpenTime2 !== restaurant.wednesdayCloseTime2 ? restaurant.wednesdayCloseTime2 : restaurant.closeTime2,
    },
    {
      id: "thursday",
      label: "木曜日",
      checked: isThursdayActive,
      onChangeCheckbox: e => setIsThursdayActive(e.target.checked),
      defaultOpenValue: restaurant.thursdayOpenTime !== restaurant.thursdayCloseTime ? restaurant.thursdayOpenTime : restaurant.openTime,
      defaultCloseValue: restaurant.thursdayOpenTime !== restaurant.thursdayCloseTime ? restaurant.thursdayCloseTime : restaurant.closeTime,
      defaultOpenValue2: restaurant.thursdayOpenTime2 !== restaurant.thursdayCloseTime2 ? restaurant.thursdayOpenTime2 : restaurant.openTime2,
      defaultCloseValue2: restaurant.thursdayOpenTime2 !== restaurant.thursdayCloseTime2 ? restaurant.thursdayCloseTime2 : restaurant.closeTime2,
    },
    {
      id: "friday",
      label: "金曜日",
      checked: isFridayActive,
      onChangeCheckbox: e => setIsFridayActive(e.target.checked),
      defaultOpenValue: restaurant.fridayOpenTime !== restaurant.fridayCloseTime ? restaurant.fridayOpenTime : restaurant.openTime,
      defaultCloseValue: restaurant.fridayOpenTime !== restaurant.fridayCloseTime ? restaurant.fridayCloseTime : restaurant.closeTime,
      defaultOpenValue2: restaurant.fridayOpenTime2 !== restaurant.fridayCloseTime2 ? restaurant.fridayOpenTime2 : restaurant.openTime2,
      defaultCloseValue2: restaurant.fridayOpenTime2 !== restaurant.fridayCloseTime2 ? restaurant.fridayCloseTime2 : restaurant.closeTime2,
    },
    {
      id: "saturday",
      label: "土曜日",
      checked: isSaturdayActive,
      onChangeCheckbox: e => setIsSaturdayActive(e.target.checked),
      defaultOpenValue: restaurant.saturdayOpenTime !== restaurant.saturdayCloseTime ? restaurant.saturdayOpenTime : restaurant.openTime,
      defaultCloseValue: restaurant.saturdayOpenTime !== restaurant.saturdayCloseTime ? restaurant.saturdayCloseTime : restaurant.closeTime,
      defaultOpenValue2: restaurant.saturdayOpenTime2 !== restaurant.saturdayCloseTime2 ? restaurant.saturdayOpenTime2 : restaurant.openTime2,
      defaultCloseValue2: restaurant.saturdayOpenTime2 !== restaurant.saturdayCloseTime2 ? restaurant.saturdayCloseTime2 : restaurant.closeTime2,
    },
    {
      id: "sunday",
      label: "日曜日",
      checked: isSundayActive,
      onChangeCheckbox: e => setIsSundayActive(e.target.checked),
      defaultOpenValue: restaurant.sundayOpenTime !== restaurant.sundayCloseTime ? restaurant.sundayOpenTime : restaurant.openTime,
      defaultCloseValue: restaurant.sundayOpenTime !== restaurant.sundayCloseTime ? restaurant.sundayCloseTime : restaurant.closeTime,
      defaultOpenValue2: restaurant.sundayOpenTime2 !== restaurant.sundayCloseTime2 ? restaurant.sundayOpenTime2 : restaurant.openTime2,
      defaultCloseValue2: restaurant.sundayOpenTime2 !== restaurant.sundayCloseTime2 ? restaurant.sundayCloseTime2 : restaurant.closeTime2,
    },
    {
      id: "holiday",
      label: "祝日",
      checked: isHolidayActive,
      onChangeCheckbox: e => setIsHolidayActive(e.target.checked),
      defaultOpenValue: restaurant.holidayOpenTime !== restaurant.holidayCloseTime ? restaurant.holidayOpenTime : restaurant.openTime,
      defaultCloseValue: restaurant.holidayOpenTime !== restaurant.holidayCloseTime ? restaurant.holidayCloseTime : restaurant.closeTime,
      defaultOpenValue2: restaurant.holidayOpenTime2 !== restaurant.holidayCloseTime2 ? restaurant.holidayOpenTime2 : restaurant.openTime2,
      defaultCloseValue2: restaurant.holidayOpenTime2 !== restaurant.holidayCloseTime2 ? restaurant.holidayCloseTime2 : restaurant.closeTime2,
    },
    {
      id: "beforeHoliday",
      label: "祝前日",
      checked: isBeforeHolidayActive,
      onChangeCheckbox: e => setIsBeforeHolidayActive(e.target.checked),
      defaultOpenValue: restaurant.beforeHolidayOpenTime !== restaurant.beforeHolidayCloseTime ? restaurant.beforeHolidayOpenTime : restaurant.openTime,
      defaultCloseValue: restaurant.beforeHolidayOpenTime !== restaurant.beforeHolidayCloseTime ? restaurant.beforeHolidayCloseTime : restaurant.closeTime,
      defaultOpenValue2: restaurant.beforeHolidayOpenTime2 !== restaurant.beforeHolidayCloseTime2 ? restaurant.beforeHolidayOpenTime2 : restaurant.openTime2,
      defaultCloseValue2: restaurant.beforeHolidayOpenTime2 !== restaurant.beforeHolidayCloseTime2 ? restaurant.beforeHolidayCloseTime2 : restaurant.closeTime2,
    },
  ]

  const lg = window.screen.width > PC_BOUNDARY


  return (
    <Card className="shadow mb-4">
      <CardHeader className="bg-white border-0">
        <Row className="align-items-center">
          <Col xs="8">
            <h3 className="mb-0">営業時間の設定</h3>
          </Col>
        </Row>
      </CardHeader>

      {lg &&
        <Row className="ml-4 mr-1 mb-1">
          <Col lg="3"> <label className="form-control-label" > {t("OpenTime")} </label> </Col>

          <Col lg="3"> <label className="form-control-label" > {t("CloseTime")} </label> </Col>

          <Col lg="3"> <label className="form-control-label" > {t("OpenTime2")} </label> </Col>

          <Col lg="3"> <label className="form-control-label"> {t("CloseTime2")} </label> </Col>
        </Row>
      }

      {items.map((item, index) => (
        <div key={item.label}>
          <Row className="ml-1 mr-1" style={{ marginBottom: -20 }}>
            <Col lg="2">
              <FormGroup>
                <input
                  type="checkbox"
                  id={item.id}
                  name={item.id}
                  checked={item.checked}
                  onChange={item.onChangeCheckbox}
                />
                <label className="ml-2 form-control-label" htmlFor={item.id} >{item.label}</label>
              </FormGroup>
            </Col>
          </Row>

          {item.checked &&
            <Row className="ml-1 mr-1 mb-3">
              <Col lg="3">
                <FormGroup>
                  {!lg &&
                    <label className="form-control-label" > {t("OpenTime")} </label>
                  }
                  <Input
                    id={`${item.id}OpenTime`}
                    name={`${item.id}OpenTime`}
                    className="form-control-alternative"
                    type="time"
                    defaultValue={item.defaultOpenValue}
                    disabled={!item.checked}
                  />
                </FormGroup>
              </Col>

              <Col lg="3">
                <FormGroup>
                  {!lg &&
                    <label className="form-control-label" > {t("CloseTime")} </label>
                  }
                  <Input
                    id={`${item.id}CloseTime`}
                    name={`${item.id}CloseTime`}
                    className="form-control-alternative"
                    type="time"
                    defaultValue={item.defaultCloseValue}
                    disabled={!item.checked}
                  />
                </FormGroup>
              </Col>

              <Col lg="3">
                <FormGroup>
                  {!lg &&
                    <label className="form-control-label" > {t("OpenTime2")} </label>
                  }
                  <Input
                    id={`${item.id}OpenTime2`}
                    name={`${item.id}OpenTime2`}
                    className="form-control-alternative"
                    type="time"
                    defaultValue={item.defaultOpenValue2}
                    disabled={!item.checked}
                  />
                </FormGroup>
              </Col>

              <Col lg="3">
                <FormGroup>
                  {!lg &&
                    <label className="form-control-label"> {t("CloseTime2")} </label>
                  }
                  <Input
                    id={`${item.id}CloseTime2`}
                    name={`${item.id}CloseTime2`}
                    className="form-control-alternative"
                    type="time"
                    defaultValue={item.defaultCloseValue2}
                    disabled={!item.checked}
                  />
                </FormGroup>
              </Col>

            </Row>
          }
        </div>
      ))}
    </Card>
  )
}

function TemporaryHolidays(props) {
  const {
    t,
    restaurant,
    setTempHolidays,
  } = props
  const ref = useRef()
  const [selectedDays, setSelectedDays] = useState([])

  useEffect(() => {
    if (restaurant) {
      setSelectedDays(restaurant.tempHolidays.map(getDateFromStr))
      setTempHolidays(restaurant.tempHolidays)
    }
  }, [restaurant, setSelectedDays, setTempHolidays])

  function onChange(day) {
    const selected = [...selectedDays]
    const selectedIndex = selected.findIndex(selectedDay => {
      const selectedDayStr = `${selectedDay.getFullYear()}${selectedDay.getMonth() + 1}${selectedDay.getDate()}`
      const dayStr = `${day.getFullYear()}${day.getMonth() + 1}${day.getDate()}`
      return selectedDayStr === dayStr
    })
    if (selectedIndex === -1) {
      selected.push(day)
    } else {
      selected.splice(selectedIndex, 1)
    }
    selected.sort((a, b) => a - b)
    setSelectedDays(selected)
    setTempHolidays(selected.map(getDateStr))
  }
  function reset(e) {
    e.preventDefault()
    setSelectedDays([])
    setTempHolidays([])
  }

  return (
    <>
      <Row>
        <Col lg="5">
          <Card className="shadow">
            <CardHeader className="bg-white border-0">
              <Row className="align-items-center">
                <Col xs="8">
                  <h3 className="mb-0">臨時休業日</h3>
                </Col>
              </Row>
            </CardHeader>

            <DayPicker
              ref={ref}
              selectedDays={selectedDays}
              onDayClick={e => onChange(e)}
              months={["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"]}
              weekdaysShort={["日", "月", "火", "水", "木", "金", "土"]}
              weekdaysLong={["日", "月", "火", "水", "木", "金", "土"]}
              firstDayOfWeek={1}
            />
            {selectedDays.length > 0 && (
              <Row className="mt-2 mb-4 justify-content-center">
                <Col xs="6">
                  <Button
                    color="primary"
                    href="#pablo"
                    className="btn-block"
                    onClick={reset}
                  > リセット </Button>
                </Col>
              </Row>
            )}
          </Card>
        </Col>
        <Col lg="7">
          <Card className="shadow">
            <DataTable
              data={selectedDays}
              columns={[
                {
                  name: "設定日",
                  cell: row => <h3>{`${row.getFullYear()}年${row.getMonth() + 1}月${row.getDate()}日`}</h3>
                }]
              }
              noDataComponent={t("NoData")}
              pagination
              progressComponent={<CustomLoader />}
              paginationComponentOptions={paginationComponentOptions}
              paginationPerPage={5}
              paginationRowsPerPageOptions={[5, 10]}
            />
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default withTranslation()(VendorProfile)
