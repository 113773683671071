import React, { useCallback, useContext, useEffect, useState } from "react"
import { Line, Bar, Pie } from "react-chartjs-2"
import { useTranslation } from "react-i18next"
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Container,
  Row,
  FormGroup,
  Input,
  Col,
} from "reactstrap"
import Header from "../../components/Headers/Header"
import { getStoreDashboardData } from "../../apollo/server"
import { calcBusinessEndTime, calcBusinessStartTime, getBusinessDate } from "../../utils/calcBusinessTime"
import { APIError } from "../../utils/errorHandler"
import { useQuery, gql, } from "@apollo/client"
import { calcPieData } from "./lib"
import { OrdersByStoreIdQueryResponse, OrdersByStoreIdQueryResponseForStore, useOrdersByStoreIdQuery } from "../../domain/graphql/query/useOrdersByStoreIdQuery"
import { AppContext } from "../../app"
const GET_STORE_DASHBOARD_DATA = gql`${getStoreDashboardData}`

const dataLine = {
  datasets: {
    label: "売上",
    fill: false,
    lineTension: 0.1,
    backgroundColor: "rgba(75,192,192,0.4)",
    borderColor: "rgba(75,192,192,1)",
    borderCapStyle: "butt",
    borderDash: [],
    borderDashOffset: 0.0,
    borderJoinStyle: "miter",
    pointBorderColor: "rgba(75,192,192,1)",
    pointBackgroundColor: "#fff",
    pointBorderWidth: 1,
    pointHoverRadius: 5,
    pointHoverBackgroundColor: "rgba(75,192,192,1)",
    pointHoverBorderColor: "rgba(220,220,220,1)",
    pointHoverBorderWidth: 2,
    pointRadius: 1,
    pointHitRadius: 10
  }
}
const dataBar = {
  datasets: {
    label: "注文数",
    backgroundColor: "rgba(255,99,132,0.2)",
    borderColor: "rgba(255,99,132,1)",
    borderWidth: 1,
    hoverBackgroundColor: "rgba(255,99,132,0.4)",
    hoverBorderColor: "rgba(255,99,132,1)"
  }
}

const pieDataDefault: PieDataType = {
  labels: [],
  datasets: [
    {
      label: "",
      data: [],
      backgroundColor: [
        "rgba(255, 99, 132, 0.2)",
        "rgba(54, 162, 235, 0.2)",
        "rgba(255, 206, 86, 0.2)",
        "rgba(75, 192, 192, 0.2)",
        "rgba(153, 102, 255, 0.2)",
        "rgba(255, 159, 64, 0.2)",
      ],
      borderColor: [
        "rgba(255, 99, 132, 1)",
        "rgba(54, 162, 235, 1)",
        "rgba(255, 206, 86, 1)",
        "rgba(75, 192, 192, 1)",
        "rgba(153, 102, 255, 1)",
        "rgba(255, 159, 64, 1)",
      ],
      borderWidth: 1,

    },
  ],
}

type PieDataType = {
  labels: any[]
  datasets: {
    label: string
    data: any[]
    backgroundColor: string[]
    borderColor: string[]
    borderWidth: number
  }[]
}

const Dashboard = () => {
  const { t } = useTranslation()
  const appContext = useContext(AppContext)
  const userType = appContext.userType
  const restaurantId = appContext.restaurantId
  const intializeStartDate = date => {
    var d = new Date(date.getTime())
    d.setDate(date.getDate() - 7)
    return d
  }
  const initialDate = getBusinessDate(new Date())

  const [startDate, setStartDate] = useState(intializeStartDate(initialDate))
  const [endDate, setEndDate] = useState(initialDate)

  const { error: storeError, data: orders } = useOrdersByStoreIdQuery(restaurantId, calcBusinessStartTime(startDate).getTime(), calcBusinessEndTime(endDate).getTime(), userType)
  const [profitPieData, setProfitPieData] = useState(pieDataDefault)
  const [counterPieData, setCounterPieData] = useState(pieDataDefault)


  const calcPieDataCallback = useCallback((orders: OrdersByStoreIdQueryResponse[] | OrdersByStoreIdQueryResponseForStore[], isCounter: boolean) => calcPieData(orders, isCounter), [])

  useEffect(() => {
    if (orders.length > 0) {
      setProfitPieData(calcPieDataCallback(orders, false))
      setCounterPieData(calcPieDataCallback(orders, true))
    }
  }, [orders, calcPieDataCallback])


  const { loading, error, data } = useQuery(GET_STORE_DASHBOARD_DATA, {
    variables: {
      startingDate: calcBusinessStartTime(startDate).getTime(),
      endingDate: calcBusinessEndTime(endDate).getTime(),
      restaurant: restaurantId
    }
  })
  const [hadShowedAlert, setHadShowedAlert] = useState(false)
  if (error && error.message.indexOf(APIError.WrongArgument) !== -1 && !hadShowedAlert) {
    alert("2021年4月1日以降で選択してください。")
    setHadShowedAlert(true)
  }
  if (error && error.message.indexOf(APIError.WrongArgument) === -1) {
    console.log(error)
  }
  if (storeError) {
    console.log(storeError)
  }

  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row className="mb-lg-5 mb-sm-3">
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="shadow">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <h6 className="text-uppercase text-light ls-1 mb-1">
                    {t("Filter Graph")}
                  </h6>
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col xl="4">
                    <FormGroup>
                      <label className="form-control-label">
                        {t("Starting Date")}
                      </label>
                      <Input
                        className="form-control-alternative"
                        type="date"
                        max={new Date().toISOString().substr(0, 10)}
                        min={"2021-04-01"}
                        onChange={event => setStartDate(new Date(event.target.value))}
                        value={startDate.toISOString().substr(0, 10)}
                      />
                    </FormGroup>
                  </Col>
                  <Col xl="4">
                    <FormGroup>
                      <label className="form-control-label">
                        {t("Ending Date")}
                      </label>
                      <Input
                        className="form-control-alternative"
                        type="date"
                        max={new Date().toISOString().substr(0, 10)}
                        min={"2021-04-01"}
                        onChange={event => setEndDate(new Date(event.target.value))}
                        value={endDate.toISOString().substr(0, 10)}
                      />
                    </FormGroup>
                  </Col>
                  {/* <Col xl="1">
                                        <FormGroup>
                                            <label className="form-control-label" > CSV </label>
                                            <br />
                                            <Button color="primary" onClick={() => download(orders)} >DL</Button>
                                        </FormGroup>
                                    </Col> */}
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>

          <Col className="mb-lg-5 mb-sm-3" xl="4">
            <Card className="card-stats mb-4 mb-lg-0">
              <CardBody>
                <Row>
                  <div className="col">
                    <CardTitle className="text-uppercase text-muted mb-0">
                      {t("Total Orders")}
                    </CardTitle>
                    <span className="h2 font-weight-bold mb-0">
                      {loading
                        ? "..."
                        : data && data.getStoreDashboardData && data.getStoreDashboardData.totalOrders ? data.getStoreDashboardData.totalOrders : 0}
                    </span>
                  </div>
                </Row>
              </CardBody>
            </Card>
          </Col>

          <Col className="mb-lg-5 mb-sm-3" xl="4">
            <Card className="card-stats mb-4 mb-lg-0">
              <CardBody>
                <Row>
                  <div className="col">
                    <CardTitle className="text-uppercase text-muted mb-0">
                      {t("Total Sales")}
                    </CardTitle>
                    <span className="h2 font-weight-bold mb-0">
                      {loading
                        ? "..."
                        : data && data.getStoreDashboardData && data.getStoreDashboardData.totalSales ? data.getStoreDashboardData.totalSales.toLocaleString() : 0}
                    </span>
                  </div>
                </Row>
              </CardBody>
            </Card>
          </Col>

          <Col className="mb-lg-5 mb-sm-3" xl="4">
            <Card className="card-stats mb-4 mb-lg-0">
              <CardBody>
                <Row>
                  <div className="col">
                    <CardTitle className="text-uppercase text-muted mb-0">
                      {t("FavoriteCount")}
                    </CardTitle>
                    <span className="h2 font-weight-bold mb-0">
                      {loading
                        ? "..."
                        : data && data.getStoreDashboardData && data.getStoreDashboardData.favoriteCount ? data.getStoreDashboardData.favoriteCount : 0}
                    </span>
                  </div>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col className="mb-5 mb-xl-0" xl="8">
            <Card className="bg-gradient-default shadow">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h2 className="text-white mb-0">
                      {t("Sales value")}
                    </h2>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                {/* Chart */}
                <div className="chart">
                  <Line
                    data={{
                      labels: loading
                        ? []
                        : data && data.getStoreDashboardData && data.getStoreDashboardData.orderData ? data.getStoreDashboardData.orderData.map(d => d.date) : [],
                      datasets: [
                        {
                          ...dataLine.datasets,
                          data: loading
                            ? []
                            : data && data.getStoreDashboardData && data.getStoreDashboardData.orderData ? data.getStoreDashboardData.orderData.map(d => d.sales) : []
                        }
                      ]
                    }}
                    options={{
                      maintainAspectRatio: false,
                      legend: { display: false }
                    }}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>

          <Col xl="4">
            <Card className="shadow">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h2 className="mb-0">{t("Total orders")}</h2>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                {/* Chart */}
                <div className="chart">
                  <Bar
                    data={{
                      labels: loading
                        ? []
                        : data && data.getStoreDashboardData && data.getStoreDashboardData.orderData ? data.getStoreDashboardData.orderData.map(d => d.date) : [],
                      datasets: [
                        {
                          ...dataBar.datasets,
                          data: loading
                            ? []
                            : data && data.getStoreDashboardData && data.getStoreDashboardData.orderData ? data.getStoreDashboardData.orderData.map(d => d.count) : [],
                        }
                      ]
                    }}
                    width={100}
                    height={50}
                    options={{
                      maintainAspectRatio: false,
                      legend: { display: false }
                    }}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>


        <Row className="mt-5">
          <Col xl="6">
            <Card className="shadow">
              <CardHeader className="bg-transparent">
                <h2 className="mb-0">{t("StoreProfitClassification")}</h2>
              </CardHeader>
              <CardBody>
                <Pie data={profitPieData} />
              </CardBody>
            </Card>
          </Col>

          <Col xl="6">
            <Card className="shadow">
              <CardHeader className="bg-transparent">
                <h2 className="mb-0">{t("OrderCountClassification")}</h2>
              </CardHeader>
              <CardBody>
                <Pie data={counterPieData} />
              </CardBody>
            </Card>
          </Col>
        </Row>

      </Container>
    </>
  )
}

export default Dashboard
