import React from "react"
import {
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
} from "reactstrap"
import { useTranslation } from "react-i18next"
import { StoreSalesboardUserDataQueryResponse } from "../../domain/graphql/query/useStoreSalesboardUserDataQuery"


interface Props {
  userData: StoreSalesboardUserDataQueryResponse[]
  selectedStatus: "DELIVERED" | "CANCELLED" | "CANCELLED_BY_USER" | null
  dateDiff: number
  businessStartTime: number
  businessEndTime: number
}
const Numbers: React.FC<Props> = props => {
  const { t } = useTranslation()

  const initialData = {
    total: 0,
    orderCount: 0,
  }
  const data = props.userData.reduce<{ total: number; orderCount: number } >((a, x) => {
    a.orderCount += x.orders.length
    a.total += x.orders.reduce((a,x) => a+=x.totalStoreProfit, 0)

    return a
  }, initialData)

  const cup = props.userData.length > 0 ? data.total / props.userData.length : 0

  return (
      <Row>
        <Cell size={4} title={t("OrderCount")} value={data.orderCount.toLocaleString()} />
        <Cell size={4} title={t("Sales")} value={data.total.toLocaleString()} />
        <Cell size={4} title={t("CustomerUnitPrice")} value={cup.toLocaleString()} />
      </Row>
  )
}

export default Numbers

interface CellProps {
  size: number
  title: string
  value: string | number
}
const Cell: React.FC<CellProps> = props => (
  <Col className="mb-lg-3 mb-sm-3" xl={props.size}>
    <Card className="card-stats mb-4 mb-lg-0" >
      <CardBody>
        <Row>
          <div className="col">
            <CardTitle className="text-uppercase text-muted mb-0">
              {props.title}
            </CardTitle>
            <span className="h2 font-weight-bold mb-0">
              {props.value}
            </span>
          </div>
        </Row>
      </CardBody>
    </Card>
  </Col >
)
