/* eslint-disable react/display-name */
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Container, Row, Card } from 'reactstrap'
import Header from '../components/Headers/Header'
import DataTable from 'react-data-table-component'
import orderBy from 'lodash/orderBy'
import CustomLoader from '../components/Loader/CustomLoader'
import { reviews } from '../apollo/server'
import { paginationComponentOptions } from '../variables/table'
import { useQuery, gql, } from "@apollo/client"
import { AppContext } from '../app'

const REVIEWS = gql`
  ${reviews}
`

const Ratings = props => {
  const { t } = useTranslation()
  const appContext = useContext(AppContext)
  const restaurantId = appContext.restaurantId
  const columns = [
    {
      name: t('Name'),
      sortable: true,
      selector: 'user.name',
      cell: row => <>{row.order.user.name}</>
    },
    // {
    //   name: t('Email'),
    //   sortable: true,
    //   selector: 'user.email',
    //   cell: row => <>{row.order.user.email}</>
    // },
    {
      name: t('Items'),
      cell: row => (
        <>
          {row.order.items.map(({ title }) => {
            return title + '\t'
          })}
        </>
      )
    },
    {
      name: t('Review'),
      sortable: true,
      selector: 'description',
      cell: row => <>{row.description}</>
    },
    {
      name: t('Ratings'),
      sortable: true,
      selector: 'rating',
      cell: row => <>{row.rating}</>
    }
  ]
  const propExists = (obj, path) => {
    return path.split('.').reduce((obj, prop) => {
      return obj && obj[prop] ? obj[prop] : ''
    }, obj)
  }

  const customSort = (rows, field, direction) => {
    const handleField = row => {
      if (field && isNaN(propExists(row, field))) {
        return propExists(row, field).toLowerCase()
      }

      return row[field]
    }

    return orderBy(rows, handleField, direction)
  }

  const handleSort = (column, sortDirection) =>
    console.log(column.selector, sortDirection, column)

  const { loading, error, data } = useQuery(REVIEWS, {
    variables: { restaurant: restaurantId },
    onError: error => {
      console.log(error)
    }
  })

  if (error) {
    console.log(error)
    return (
      <div >
        <Header />
        <p style={{ marginTop: 30, textAlign: "center" }}>{`${t("Error")}! ${error.message}`}</p>
      </div>
    )
  }


  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <DataTable
                title={t('Ratings')}
                columns={columns}
                data={data && data.reviews ? data.reviews : []}
                pagination
                progressPending={loading}
                progressComponent={<CustomLoader />}
                onSort={handleSort}
                sortFunction={customSort}
                defaultSortField="order.user.name"
                paginationComponentOptions={paginationComponentOptions}
                noDataComponent={t('NoData')}
              />
            </Card>
          </div>
        </Row>
      </Container>
    </>
  )
}

export default Ratings
