import React, { useState, useRef } from 'react'
import { validateFunc } from '../../constraints/constraints'
import { withTranslation } from 'react-i18next'
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  Row,
  Col,
  UncontrolledAlert
} from 'reactstrap'
import { vendorResetPassword } from '../../apollo/server'
import { useMutation, gql } from "@apollo/client"

const RESET_PASSWORD = gql`
  ${vendorResetPassword}
`

function ResetPassword(props) {
  console.log('ResetPassword')
  const { t } = props
  const formRef = useRef()
  const [passError, passErrorSetter] = useState(null)
  const [confirmPassError, confirmPassErrorSetter] = useState(null)
  const [mainError, mainErrorSetter] = useState('')
  const [success, successSetter] = useState('')

  // const onBlur = (setter, field, state) => {
  //   setter(!validateFunc({ [field]: state }, field))
  // }

  const onSubmitValidaiton = () => {
    const passwordError = !validateFunc(
      { password: formRef.current['input-password'].value },
      'password'
    )
    const confirmPasswordError = !validateFunc(
      {
        password: formRef.current['input-password'].value,
        confirmPassword: formRef.current['input-confirm-password'].value
      },
      'confirmPassword'
    )
    passErrorSetter(passwordError)
    confirmPassErrorSetter(confirmPasswordError)
    return passwordError && confirmPasswordError
  }
  const hideAlert = () => {
    mainErrorSetter('')
    successSetter('')
  }

  const SubmitButton = () => {
    const onCompleted = data => {
      const message = t('PasswordChanged')
      mainErrorSetter('')
      successSetter(message)
      setTimeout(hideAlert, 5000)
    }
    const onError = ({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        console.log(graphQLErrors[0].message)
        mainErrorSetter(t('ErrorMessage'))
      }
      if (networkError) {
        console.log(networkError.result.errors[0].message)
        mainErrorSetter(t('ErrorMessage'))
      }
      successSetter('')
      setTimeout(hideAlert, 5000)
    }
    const [mutate, { loading, error }] = useMutation(RESET_PASSWORD, { onCompleted, onError, })
    if (loading) return t('Loading')
    if (error) console.log(error)
    return <Button
      color="primary"
      href="#pablo"
      onClick={async e => {
        e.preventDefault()
        if (onSubmitValidaiton()) {
          mutate({
            variables: {
              password:
                formRef.current['input-password'].value
            }
          })
        }
      }}
      size="md">
      {t('Change Password')}
    </Button>
  }
  return (
    <>
      <Row>
        <Col className="order-xl-1">
          <Card className="bg-secondary shadow">
            <CardHeader className="bg-white border-0">
              <Row className="align-items-center">
                <Col xs="8">
                  <h3 className="mb-0">Reset Password</h3>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <form ref={formRef}>
                <div className="pl-lg-4">
                  <Row>
                    <Col md="12">
                      <label
                        className="form-control-label"
                        htmlFor="input-password">
                        {t('NewPassword')}
                      </label>
                      <br />
                      <FormGroup
                        className={
                          passError === null
                            ? ''
                            : passError
                              ? 'has-success'
                              : 'has-danger'
                        }>
                        <Input
                          autoCapitalize="none"
                          className="form-control-alternative"
                          id="input-password"
                          name="input-password"
                          maxLength="30"
                          type="password"
                          defaultValue=""
                          // onBlur={event => {
                          //   onBlur(
                          //     passErrorSetter,
                          //     'password',
                          //     event.target.value
                          //   )
                          // }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <label
                        className="form-control-label"
                        htmlFor="input-confirm-password">
                        {t('ConfirmNewPassword')}
                      </label>
                      <br />
                      <FormGroup
                        className={
                          confirmPassError === null
                            ? ''
                            : confirmPassError
                              ? 'has-success'
                              : 'has-danger'
                        }>
                        <Input
                          autoCapitalize="none"
                          className="form-control-alternative"
                          id="input-confirm-password"
                          name="input-confirm-password"
                          maxLength="30"
                          type="password"
                          defaultValue=""
                          // onBlur={event => {
                          //   onBlur(
                          //     confirmPassErrorSetter,
                          //     'password',
                          //     event.target.value
                          //   )
                          // }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="text-right" lg="6">
                      <SubmitButton />
                    </Col>
                  </Row>
                </div>
              </form>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col lg="6">
          {mainError && (
            <UncontrolledAlert color="danger" fade={true}>
              <span className="alert-inner--icon">
                <i className="ni ni-like-2" />
              </span>{' '}
              <span className="alert-inner--text">
                <strong>{t('Danger')}!</strong> {mainError}
              </span>
            </UncontrolledAlert>
          )}
          {success && (
            <UncontrolledAlert color="success" fade={true}>
              <span className="alert-inner--icon">
                <i className="ni ni-like-2" />
              </span>{' '}
              <span className="alert-inner--text">
                <strong>{t('Success')}!</strong> {success}
              </span>
            </UncontrolledAlert>
          )}
        </Col>
      </Row>
    </>
  )
}

export default withTranslation()(ResetPassword)
