import { ApolloError, gql, PureQueryOptions, useMutation, } from "@apollo/client";

const createOptionsMutation = gql`mutation ($optionInput:CreateOptionInput) {
  createOptions(optionInput:$optionInput)
}`


export type CreateOptionsMutationInput = {
  optionInput: {
    options: {
      title: string;
      description: string;
      storeProfit: number;
    }[];
    restaurant: string;
  };
}

export type CreateOptionsResponse = {
  createOptions: boolean
}

export function useCreateOptions(config: {
  variables: CreateOptionsMutationInput,
  refetchQueries: (string | PureQueryOptions)[],
  onCompleted: (data: CreateOptionsResponse) => void,
  onError: (error: ApolloError) => void,
}) {

  return useMutation<CreateOptionsResponse, CreateOptionsMutationInput>(createOptionsMutation, {
    variables: config.variables,
    refetchQueries: config.refetchQueries,
    onCompleted: config.onCompleted,
    onError: config.onError,
  })

}

