import { OrdersByStoreIdQueryResponse, OrdersByStoreIdQueryResponseForStore, OrdersByStoreIdQueryResponseItem } from "../../domain/graphql/query/useOrdersByStoreIdQuery"
import { dateToJapanese } from "../../utils/date"
import { downloadCSV } from "../../utils/downloadCSV"

export function download(orders: OrdersByStoreIdQueryResponseForStore[]) {
	if (window.confirm("注文データをCSV形式でダウンロードしますか？")) {
		let csv: string
		csv = "注文番号,配達日時,商品小計,手数料,売上\n"
		orders.forEach(order => {
			csv += `${order.orderId},${dateToJapanese(new Date(order.deliveredAt))},${order.taxInSubtotal},${order.storeCommission},${order.storeProfit}\n`
		})
		const now = new Date()
		const filename = `${now.getFullYear()}${("" + (now.getMonth() + 1)).padStart(2, "0")}${("" + now.getDate()).padStart(2, "0")}.csv`
		downloadCSV(filename, csv)
	}
}

const calc = (item: OrdersByStoreIdQueryResponseItem, isCounter: boolean) => {
	if (isCounter) return 1
	let price = item.variation.storeProfit
	item.addons.forEach(addon => addon.options.forEach(option => price += option.storeProfit))

	return price * item.quantity
}

const formatItems = (isCounter: boolean) => (order: OrdersByStoreIdQueryResponse) =>
	order.items.reduce((acc, item) => {
		if (acc[item.title]) acc[item.title] = acc[item.title] + calc(item, isCounter)
		else acc[item.title] = calc(item, isCounter)
		return acc
	}, {} as { [k: string]: number })

const formatResult = (formatted: { [k: string]: number }[]) => {
	return formatted.reduce((a, x) => {
		Object.keys(x).forEach(key => {
			if (a[key]) a[key] = a[key] + x[key]
			else a[key] = x[key]
		})
		return a
	}, {} as { [k: string]: number })
}

const rankingResult = (data: { [k: string]: number }) => {
	const sortedKey = Object.keys(data).sort((a, b) => data[b] - data[a])
	const result = {}
	for (let i = 0; i < 5; i++) {
		if (sortedKey[i] !== undefined) {
			result[sortedKey[i]] = data[sortedKey[i]]
		}
	}

	const others = {}
	for (let i = 5; i < sortedKey.length; i++) {
		if (sortedKey[i] !== undefined) {
			others[sortedKey[i]] = data[sortedKey[i]]
		}
	}
	const othersSum = Object.keys(others).reduce((a, x) => a + others[x], 0)

	if (othersSum > 0) result["その他"] = othersSum
	return result
}
export const calcPieData = (orders: OrdersByStoreIdQueryResponse[] | OrdersByStoreIdQueryResponseForStore[], isCounter: boolean) => {
	const filtered = orders.filter(order => order.orderStatus === "DELIVERED" || order.orderStatus === "CANCELLED_BY_USER")
	const result = rankingResult(formatResult(filtered.map(formatItems(isCounter))))
	return {
		labels: Object.keys(result).length > 0 ? Object.keys(result).map(v => v.slice(0, 25) + (v.length > 25 ? "..." : "")) : ["データなし"],
		datasets: [
			{
				data: Object.values(result).length > 0 ? Object.values(result) : [0],
				label: isCounter ? "counter" : "profit",
				backgroundColor: [
					"rgba(255, 99, 132, 0.2)",
					"rgba(54, 162, 235, 0.2)",
					"rgba(255, 206, 86, 0.2)",
					"rgba(75, 192, 192, 0.2)",
					"rgba(153, 102, 255, 0.2)",
					"rgba(255, 159, 64, 0.2)",
				],
				borderColor: [
					"rgba(255, 99, 132, 1)",
					"rgba(54, 162, 235, 1)",
					"rgba(255, 206, 86, 1)",
					"rgba(75, 192, 192, 1)",
					"rgba(153, 102, 255, 1)",
					"rgba(255, 159, 64, 1)",
				],
				borderWidth: 1,
			},
		]
	}
}