import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  Row,
  Col,
  UncontrolledAlert
} from "reactstrap"
import { createCategoryMaster, createMastersFromCSV, editCategoryMaster } from "../../apollo/server"
import { useMutation, gql, ApolloError, } from "@apollo/client"
import { getCategoryMasterQuery } from "../../domain/graphql/query/useCategoryMasterQuery"
import { downloadCSV } from "../../utils/downloadCSV"
import { SEPARATER } from "../constants"

const CREATE_CATEGORY = gql`${createCategoryMaster}`
const EDIT_CATEGORY = gql`${editCategoryMaster}`
const CREATE_MASTERS_FROM_CSV = gql`${createMastersFromCSV}`

interface Props {
  category?: any
  ownerId: string
  items?: any[]
  modalLoading: boolean
  setModalLoading: React.Dispatch<React.SetStateAction<boolean>>
}
const CategoryMasterModal: React.FC<Props> = props => {
  const { t } = useTranslation()
  const [title, setTitle] = useState(props.category && props.category.title ? props.category.title : "")
  const [createCategorySuccessMessage, setCreateCategorySuccessMessage] = useState("")
  const [createCategoryErrorMessage, setCreateCategoryErrorMessage] = useState("")

  const userType = localStorage.getItem('user-enatega')
    ? JSON.parse(localStorage.getItem('user-enatega')).userType
    : null
  const isAdmin = userType === "ADMIN"
  const [csvFile, setCSVFile] = useState(null)
  const [csvText, setCSVText] = useState("")
  const [readFromCSVSuccessMessage, setReadFromCSVSuccessMessage] = useState("")
  const [readFromCSVErrorMessage, setReadFromCSVErrorMessage] = useState("")
  useEffect(() => {
    if (csvFile) {
      const reader = new FileReader()
      reader.readAsText(csvFile)
      reader.onload = (e: ProgressEvent<FileReader>) => {
        setCSVText(e.target.result as string)
      }
    }
  }, [csvFile])

  const clearFields = () => { setTitle("") }

  const SubmitCreateCategoryButton = () => {
    const onCompleted = () => {
      const message = props.category
        ? t("UpdateSuccessMessage")
        : t("AddSuccessMessage")
      setCreateCategorySuccessMessage(message)
      setCreateCategoryErrorMessage("")
      if (!props.category) clearFields()
      props.setModalLoading(false)
    }
    const onError = (error: ApolloError) => {
      const message = `${t("ErrorMessage")} ${error}`
      setCreateCategorySuccessMessage("")
      setCreateCategoryErrorMessage(message)
      props.setModalLoading(false)
    }

    const [mutate, { loading, error }] = useMutation(props.category ? EDIT_CATEGORY : CREATE_CATEGORY, {
      refetchQueries: [
        {
          query: getCategoryMasterQuery,
          variables: { id: props.ownerId }
        }
      ],
      onCompleted,
      onError,
    })
    if (error) console.log(error)

    function onPressSubmit(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
      e.preventDefault()
      if (loading || props.modalLoading || !title.trim()) return

      props.setModalLoading(true)
      mutate({
        variables: {
          category: {
            _id: props.category ? props.category._id : "",
            title: title.trim(),
            ownerId: props.ownerId,
            updateRestaurants: true,
          }
        }
      })
    }

    return (
      <Button
        disabled={loading || props.modalLoading}
        color="primary"
        href="#pablo"
        onClick={onPressSubmit}
        size="md"
      >
        {loading || props.modalLoading ? t("Saving") : t("Save")}
      </Button>
    )
  }

  const ReadFromCSVButton = () => {
    const [mutate, { loading, error }] = useMutation(CREATE_MASTERS_FROM_CSV, {
      refetchQueries: [
        {
          query: getCategoryMasterQuery,
          variables: { id: props.ownerId }
        }
      ],
      onCompleted: () => {
        const message = props.category
          ? t("UpdateSuccessMessage")
          : t("AddSuccessMessage")
        setReadFromCSVSuccessMessage(message)
        setReadFromCSVErrorMessage("")
        if (!props.category) clearFields()
      },
      onError: (error: ApolloError) => {
        const message = `${t("ErrorMessage")} ${error}`
        setReadFromCSVSuccessMessage("")
        setReadFromCSVErrorMessage(message)
      }
    })
    if (loading) return <p>{t("Loading")}</p>
    if (error) console.log(error)

    function onChangeInputFile(e: React.ChangeEvent<HTMLInputElement>) {
      if (e.target.files && e.target.files[0]) {
        setCSVFile(e.target.files[0])
      }
    }

    function onPressSubmit(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
      e.preventDefault()
      if (!csvText) return

      let rows = csvText.split(SEPARATER)
      if (rows.length < 2) {
        alert("CSVファイルのフォーマットが不正です。\n（ヘッダーと値を入力して下さい）")
        return
      }

      let isInvalid = false
      const constructDataArray = (rows) => rows[0].split(",").map(name => {
        switch (name) {
          case "カテゴリー名": return { name: "categoryTitle", index: 0 }
          case "商品名": return { name: "foodTitle", index: 1 }
          case "商品説明": return { name: "foodDescription", index: 2 }
          case "商品画像URL": return { name: "imageUrl", index: 3 }
          case "バリエーション名": return { name: "variationTitle", index: 4 }
          case "JANコード": return { name: "variationJanCode", index: 5 }
          case "店舗受取額": return { name: "variationStoreProfit", index: 6 }
          default: isInvalid = true; return { name: "", index: -1 }
        }
      })
      let columnNames = constructDataArray(rows)

      if (isInvalid) {
        isInvalid = false
        rows = csvText.split("\n")
        if (rows.length < 2) {
          alert("CSVファイルのフォーマットが不正です。\n（ヘッダーと値を入力して下さい）")
          return
        }
        columnNames = constructDataArray(rows)
        if (isInvalid) {
          alert("CSVファイルのフォーマットが不正です。")
          return
        }
      }

      const inputs = []
      for (let i = 1; i < rows.length; i++) {
        const values = rows[i].split(",")
        inputs.push({
          [columnNames[0].name]: values[columnNames[0].index].replace(/^"|"$/g, ""),
          [columnNames[1].name]: values[columnNames[1].index].replace(/^"|"$/g, ""),
          [columnNames[2].name]: values[columnNames[2].index].replace(/^"|"$/g, ""),
          [columnNames[3].name]: values[columnNames[3].index].replace(/^"|"$/g, ""),
          [columnNames[4].name]: values[columnNames[4].index].replace(/^"|"$/g, ""),
          [columnNames[5].name]: values[columnNames[5].index].replace(/^"|"$/g, ""),
          [columnNames[6].name]: values[columnNames[6].index].replace(/^"|"$/g, ""),
        })
      }
      const masterInputs = inputs.map(input => {
        input["variationStoreProfit"] = parseInt(input["variationStoreProfit"], 10)
        if (isNaN(input["variationStoreProfit"])) isInvalid = true
        return input
      })
      if (isInvalid) {
        alert("CSVファイルのフォーマットが不正です。\n（店舗受取額は半角数字で入力して下さい）")
        return
      }

      if (!loading && masterInputs.length > 0) {
        mutate({
          variables: {
            ownerId: props.ownerId,
            isReplaceAll: true,
            masterInputs,
          }
        })
      }
    }

    return (
      <div>
        <Button color="secondary" size="md">
          <label className="m-0">
            {csvFile ? csvFile.name : t("SelectFile")}
            <input type="file" style={{ display: "none" }} accept=".csv" onChange={onChangeInputFile} />
          </label>
        </Button>

        <Button
          disabled={loading}
          color="primary"
          href="#pablo"
          onClick={onPressSubmit}
          size="md"
        >
          {t("Save")}
        </Button>
      </div>
    )
  }

  const OutputToCSVButton = () => {
    function download(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
      e.preventDefault()
      const now = new Date()
      const filename = `${now.getFullYear()}${("" + (now.getMonth() + 1)).padStart(2, "0")}${("" + now.getDate()).padStart(2, "0")}${("" + now.getHours()).padStart(2, "0")}${("" + now.getMinutes()).padStart(2, "0")}_商品マスタ.csv`
      const data = props.items.flatMap(category =>
        category.foods.flatMap(food =>
          food.variations.flatMap(variation => ({
            ct: category.title,
            ft: food.title,
            fd: food.description,
            fu: food.image ?? "",
            vt: variation.title,
            vjc: variation.janCode ?? "",
            vsp: variation.storeProfit,
          }))
        )
      )
      let csv = "カテゴリー名,商品名,商品説明,商品画像URL,バリエーション名,JANコード,店舗受取額"
      data.forEach(d => csv += `${SEPARATER}"${d.ct}","${d.ft}","${d.fd}","${d.fu}","${d.vt}","${d.vjc}","${d.vsp}"`)
      downloadCSV(filename, csv)
    }

    return (
      <div>
        <Button
          disabled={!props.items?.length}
          color="primary"
          href="#pablo"
          onClick={download}
          size="md"
        >
          {t("DownloadCSV")}
        </Button>
      </div>
    )
  }

  return (
    <Row>
      <Col className="order-xl-1">

        {isAdmin && !props.category &&
          <Card className="bg-secondary shadow mb-4">
            <CardHeader className="bg-white border-0">
              <Row className="align-items-center">
                <Col xs="8">
                  <h3 className="mb-0">
                    {t("CSVFile")}
                  </h3>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <div className="pl-lg-4">
                <Row>
                  <Col className="text-left" xs="6">
                    <ReadFromCSVButton />
                  </Col>
                  <Col className="text-right" xs="6">
                    <OutputToCSVButton />
                  </Col>
                </Row>
                <Row>
                  <Col lg="6">
                    {readFromCSVSuccessMessage && (
                      <UncontrolledAlert color="success" fade={true}>
                        <span className="alert-inner--icon">
                          <i className="ni ni-like-2" />
                        </span>{" "}
                        <span className="alert-inner--text">{readFromCSVSuccessMessage}</span>
                      </UncontrolledAlert>
                    )}
                    {readFromCSVErrorMessage && (
                      <UncontrolledAlert color="danger" fade={true}>
                        <span className="alert-inner--icon">
                          <i className="ni ni-like-2" />
                        </span>{" "}
                        <span className="alert-inner--text">{readFromCSVErrorMessage}</span>
                      </UncontrolledAlert>
                    )}
                  </Col>
                </Row>
              </div>
            </CardBody>
          </Card>
        }

        <Card className="bg-secondary shadow">
          <CardHeader className="bg-white border-0">
            <Row className="align-items-center">
              <Col xs="8">
                <h3 className="mb-0">
                  {props.category ? t("Edit Category") : t("Add Category")}
                </h3>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <div className="pl-lg-4">
              <Row>
                <Col lg="6">
                  <label className="form-control-label" htmlFor="input-title">
                    {t("CategoryTitle")}
                  </label>
                  <FormGroup>
                    <Input
                      autoCapitalize="none"
                      className="form-control-alternative"
                      id="input-title"
                      name="input-title"
                      placeholder={t("CategoryPlaceholder")}
                      type="text"
                      value={title}
                      onChange={e => setTitle(e.target.value)}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col className="text-right" xs="12">
                  <SubmitCreateCategoryButton />
                </Col>
              </Row>
              <Row>
                <Col lg="6">
                  {createCategorySuccessMessage && (
                    <UncontrolledAlert color="success" fade={true}>
                      <span className="alert-inner--icon">
                        <i className="ni ni-like-2" />
                      </span>{" "}
                      <span className="alert-inner--text">{createCategorySuccessMessage}</span>
                    </UncontrolledAlert>
                  )}
                  {createCategoryErrorMessage && (
                    <UncontrolledAlert color="danger" fade={true}>
                      <span className="alert-inner--icon">
                        <i className="ni ni-like-2" />
                      </span>{" "}
                      <span className="alert-inner--text">{createCategoryErrorMessage}</span>
                    </UncontrolledAlert>
                  )}
                </Col>
              </Row>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default CategoryMasterModal
