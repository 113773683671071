import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Badge, Card, Container, Row, Modal, } from 'reactstrap'
import Header from '../../components/Headers/Header'
import { deleteFoodMaster, updateFoodMasterOrders } from '../../apollo/server'
import FoodMasterModal from './FoodMasterModal'
import CustomLoader from '../../components/Loader/CustomLoader'
import Loader from 'react-loader-spinner'
import { getIsDisabled } from '../../utils/getIsDisabled'
import { useMutation, gql, } from "@apollo/client"
import { SortableTable } from '../../components/SortableTable'
import { arrayMove } from '../../utils/arrayMove'
import styles from '../styles'
import { PHONE_BOUNDARY } from '../constants'
import { FoodMaster, getCategoryMasterQuery, useCategoryMasterQuery } from '../../domain/graphql/query/useCategoryMasterQuery'
import { AppContext } from '../../app'
import { SearchBar } from '../../components/SearchBar'

const UPDATE_FOOD_ORDERS = gql`${updateFoodMasterOrders}`
const DELETE_FOOD = gql`${deleteFoodMaster}`

const FoodMasterComponent = () => {
  console.log("views/FoodMaster")
  const { t } = useTranslation()
  const appContext = useContext(AppContext)
  const userType = appContext.userType
  const ownerId = appContext.ownerId

  const [searchKeyword, setSearchKeyword] = useState("")
  const [editModal, setEditModal] = useState(false)
  const [food, setFood] = useState(null)
  const [modalLoading, setModalLoading] = useState(false)

  const [items, setItems] = useState<FoodMaster[]>([])
  const filteredItems = useMemo(() => {
    if (items.length === 0) return []
    if (searchKeyword.length === 0) return items

    return items.filter(item =>
      item.title.includes(searchKeyword) ||
      item.variations.some(v => v.title.includes(searchKeyword) || v.janCode.includes(searchKeyword))
    )
  }, [items, searchKeyword])

  const [updateFoodMasterOrders] = useMutation(UPDATE_FOOD_ORDERS, {
    refetchQueries: [
      {
        query: getCategoryMasterQuery,
        variables: { id: ownerId }
      }
    ],
    onError: e => console.log
  })

  const toggleModal = food => {
    setEditModal(!editModal)
    setFood(food)
  }

  const getEnhancedFoodList = categories => {
    const list = []
    categories.forEach(category => {
      if (category.foods && category.foods.length) {
        category.foods.forEach(food => {
          list.push({
            ...food,
            category: category.title,
            categoryId: category._id,
            _id: food._id,
            title: food.title,
          })
        })
      }
    })
    return list
  }

  const { loading, error, data } = useCategoryMasterQuery(ownerId)
  useEffect(() => {
    if (data && data.categoryMasters) {
      setItems(getEnhancedFoodList(data.categoryMasters))
    }
  }, [data])

  if (error) {
    let message = ""
    if (error.message.indexOf("Forbidden") !== -1) message = t("Don'tHavePermission")
    else message = `${t("Error")}! ${error.message}`

    return (
      <div >
        <Header />
        <p style={{ marginTop: 30, textAlign: "center" }}>{message}</p>
      </div>
    )
  }


  function onSortEnd({ oldIndex, newIndex }) {
    if (getIsDisabled(userType)) return
    if (searchKeyword) {
      alert("フィルタリング中の並べ替えはできません")
      return
    }
    const newItems = arrayMove(items, oldIndex, newIndex)
    const newItemIds = newItems.map(item => item._id)
    setItems(newItems)
    updateFoodMasterOrders({ variables: { ownerId, foodIds: newItemIds } })
  }

  const columns = window.screen.width > PHONE_BOUNDARY
    ? [
      {
        id: "title",
        name: t('Title'),
        cell: row => <p className="mb-0 text-sm" >{row.title}</p>,
        minWidth: 200,
        maxWidth: 300,
      },
      {
        id: "description",
        name: t('Description'),
        cell: row => <p className="mb-0 text-xs" >{row.description}</p>,
        minWidth: 200,
        maxWidth: 240,
      },
      {
        id: "category",
        name: t('Category'),
        cell: row => <p className="mb-0 text-sm" >{row.category}</p>,
        maxWidth: 150,
      },
      {
        id: "janCode",
        name: t('JanCode'),
        cell: row => <p className="mb-0 text-sm" style={{ whiteSpace: "pre-wrap" }} >{row.variations.map(v => v.janCode).join("\n")}</p>,
        maxWidth: 200,
      },
      {
        id: "image",
        name: t('Image'),
        cell: row => (
          <>
            {!!row.image && (
              <img className="img-responsive" src={row.image} alt="img menu" />
            )}
            <p className="mb-0 text-sm" >{!row.image && 'No Image'}</p>
          </>
        ),
        maxWidth: 200,
      },
      {
        id: "action",
        name: t('Action'),
        cell: row => <ActionButtons row={row} />,
        maxWidth: 100,
      }
    ]
    : [
      {
        id: "title",
        name: t('Title'),
        cell: row => <p className="mb-0 text-sm" >{row.title}</p>,
        minWidth: 150,
        maxWidth: 300,
      },
      {
        id: "janCode",
        name: t('JanCode'),
        cell: row => <p className="mb-0 text-sm" style={{ whiteSpace: "pre-wrap" }} >{row.variations.map(v => v.janCode).join("\n")}</p>,
        maxWidth: 200,
      },
      {
        id: "image",
        name: t('Image'),
        cell: row => (
          <>
            {!!row.image && (
              <img className="img-responsive" src={row.image} alt="img menu" />
            )}
            <p className="mb-0 text-sm" >{!row.image && 'No Image'}</p>
          </>
        ),
        maxWidth: 200,
      },
      {
        id: "action",
        name: t('Action'),
        cell: row => <ActionButtons row={row} />,
        maxWidth: 100,
      }
    ]

  const ActionButtons = ({ row }) => {
    const [deleteFood, { loading: deleteLoading }] = useMutation(DELETE_FOOD, {
      refetchQueries: [
        {
          query: getCategoryMasterQuery,
          variables: { id: ownerId },
        },
      ],
      onError: e => console.log
    })
    if (deleteLoading) {
      return (
        <Loader
          type="ThreeDots"
          color="#BB2124"
          height={20}
          width={40}
          visible={deleteLoading}
        />
      )
    }
    return (
      <>
        <Badge
          style={styles.badgeStyle}
          href="#pablo"
          onClick={e => {
            e.preventDefault()
            toggleModal(row)
          }}
          color="primary">
          {t('Edit')}
        </Badge>
        {" "}
        <Badge
          style={styles.badgeStyle}
          href="#pablo"
          color="danger"
          onClick={e => {
            e.preventDefault()
            if (getIsDisabled(userType)) return
            if (window.confirm('削除してもよろしいですか？')) {
              deleteFood({
                variables: {
                  ownerId,
                  categoryId: row.categoryId,
                  foodId: row._id,
                }
              })
            }
          }}>
          {t('Delete')}
        </Badge>
      </>
    )
  }

  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>

        <FoodMasterModal
          ownerId={ownerId}
          modalLoading={modalLoading}
          setModalLoading={setModalLoading}
        />

        <SearchBar
          title={""}
          onSearchKeywordChange={e => setSearchKeyword(e.target.value.trim())}
          searchKeyword={searchKeyword}
          placeholder={t("FoodSearchKeywordPlaceholder")}
        />

        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              {loading
                ? <CustomLoader />
                : <SortableTable items={filteredItems} onSortEnd={onSortEnd} columns={columns} />
              }
            </Card>
          </div>
        </Row>
        <Modal
          className="modal-dialog-centered"
          size="lg"
          isOpen={editModal}
          toggle={() => { if (!modalLoading) toggleModal(null) }}
        >
          <FoodMasterModal
            food={food}
            ownerId={ownerId}
            modalLoading={modalLoading}
            setModalLoading={setModalLoading}
          />
        </Modal>
      </Container>
    </>
  )
}

export default FoodMasterComponent
