import React, { createContext, useState } from 'react'
import StoreAdminLayout from './layouts/StoreAdmin'
import VendorAdminLayout from './layouts/VendorAdmin'
import AuthLayout from './layouts/Auth.jsx'
import SuperAdminLayout from './layouts/SuperAdmin'
import { PrivateRoute } from './views/PrivateRoute'
import { AdminPrivateRoute } from './views/AdminPrivateRoute'
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom'
import { 
  getOwnerId, 
  getRestaurantId, 
  setOwnerId as setOwnerIdLocal,
  setRestaurantId as setRestaurantIdLocal,
 } from './utils/localStorage'
require('./i18n')

const initialState = {
  userType: null,
  setUserType: value => { },
  ownerId: null,
  setOwnerId: value => { },
  restaurantId: null,
  setRestaurantId: value => { },
}
export const AppContext = createContext(initialState)
const App = () => {
  const initialUserType = localStorage.getItem('user-enatega')
    ? JSON.parse(localStorage.getItem('user-enatega')).userType
    : null
  const [userType, setUserType] = useState(initialUserType)
  const [ownerId, setOwnerId] = useState(getOwnerId())
  const [restaurantId, setRestaurantId] = useState(getRestaurantId())

  function setOwnerIdFn(value) {
    setOwnerId(value)
    setOwnerIdLocal(value)
  }
  function setRestaurantIdFn(value) {
    setRestaurantId(value)
    setRestaurantIdLocal(value)
  }

  const appState = {
    userType,
    setUserType,
    ownerId,
    setOwnerId: setOwnerIdFn,
    restaurantId,
    setRestaurantId: setRestaurantIdFn,
  }

  const route = userType
    ? userType === 'VENDOR' || userType === "AUTHORIZED_VENDOR"
      ? '/vendor_admin/list'
      : '/super_admin/vendors'
    : '/auth/login'

  return (
    <AppContext.Provider value={appState}>
      <HashRouter>
        <Switch>
          <AdminPrivateRoute
            path="/super_admin"
            component={props => <SuperAdminLayout {...props} />}
          />
          <PrivateRoute
            path="/vendor_admin"
            component={props => <VendorAdminLayout {...props} />}
          />
          <PrivateRoute
            path="/store_admin"
            component={props => <StoreAdminLayout {...props} />}
          />
          <Route path="/auth" component={props => <AuthLayout {...props} setUserType={setUserType} />} />
          <Redirect from="/" to={route} />
        </Switch>
      </HashRouter>
    </AppContext.Provider>
  )
}
export default App
