/* eslint-disable react/display-name */
import React, { useState } from "react"
import { withTranslation } from "react-i18next"
import DataTable from "react-data-table-component"
import orderBy from "lodash/orderBy"
import CustomLoader from "../../components/Loader/CustomLoader"
import { paginationComponentOptions } from "../../variables/table"
import { paging } from "../../utils/pagination"
import { dateToJapanese, dateToJpWithDuration } from "../../utils/date"

const SalesBoardOrderTimes = props => {
  console.log("SalesBoardOrders")
  const {
    allOrders,
    orderCount,
    toggleModal,
    loading: propsLoading,
    t,
  } = props

  const [page, setPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(30)
  const orders = paging(allOrders, page, rowsPerPage)

  const propExists = (obj, path) => {
    return path.split(".").reduce((obj, prop) => {
      return obj && obj[prop] ? obj[prop] : ""
    }, obj)
  }

  const customSort = (_rows, field, direction) => {
    const handleField = row => {
      if (field && isNaN(propExists(row, field))) {
        if (field === "restaurant") {
          return row.restaurant.name
        }
        if (field === "rider") {
          return row.rider.name
        }
        return propExists(row, field).toLowerCase()
      }

      return row[field]
    }

    return paging(orderBy(allOrders, handleField, direction), page, rowsPerPage)
  }

  const handleSort = (column, sortDirection) => console.log(column.selector, sortDirection)

  const handlePerRowsChange = async (perPage, page) => {
    setPage(page)
    setRowsPerPage(perPage)
  }

  const handlePageChange = async page => {
    setPage(page)
  }

  const columns = [
    {
      name: t("Restaurant"),
      sortable: true,
      selector: "restaurant",
      maxWidth: "200px",
      cell: row => <>{row.restaurant.name ?? ""}</>
    },
    {
      name: t("AssignedRider"),
      selector: "rider",
      sortable: true,
      maxWidth: "120px",
      cell: row => <>{row.rider ? row.rider.name : ""}</>
    },
    {
      name: t("OrderReceivedAt"),
      selector: "createdAt",
      sortable: true,
      maxWidth: "200px",
      cell: row => <>{dateToJapanese(new Date(row.createdAt))}</>
    },
    {
      name: t("StartPreparationAt"),
      selector: "startPreparationAt",
      sortable: true,
      maxWidth: "170px",
      cell: row => <>{row.startPreparationAt ? dateToJpWithDuration(new Date(row.startPreparationAt), new Date(row.createdAt)) : "-"}</>
    },
    {
      name: t("FinishPreparationAt"),
      selector: "finishPreparationAt",
      sortable: true,
      maxWidth: "170px",
      cell: row => <>{row.finishPreparationAt ? dateToJpWithDuration(new Date(row.finishPreparationAt), new Date(row.startPreparationAt)) : "-"}</>
    },
    {
      name: t("StartDeliveryAt"),
      selector: "startDeliveryAt",
      sortable: true,
      maxWidth: "170px",
      cell: row => <>{row.startDeliveryAt ? dateToJpWithDuration(new Date(row.startDeliveryAt), new Date(row.finishPreparationAt)) : "-"}</>
    },
    {
      name: t("DeliveredAt"),
      selector: "deliveredAt",
      sortable: true,
      maxWidth: "170px",
      cell: row => <>{row.startDeliveryAt ? dateToJpWithDuration(new Date(row.deliveredAt), new Date(row.startDeliveryAt)) : dateToJapanese(new Date(row.deliveredAt))}</>
    },
    {
      name: t("TotalTime"),
      selector: "deliveredAt",
      sortable: true,
      maxWidth: "170px",
      cell: row => <>{dateToJpWithDuration(new Date(row.deliveredAt), new Date(row.createdAt))}</>
    },
  ]


  return (
    <DataTable
      title={t("OrderTimes")}
      columns={columns}
      data={orders}
      onRowClicked={toggleModal}
      progressPending={propsLoading}
      pointerOnHover
      progressComponent={<CustomLoader />}
      onSort={handleSort}
      sortFunction={customSort}
      pagination
      paginationServer
      paginationPerPage={30}
      paginationRowsPerPageOptions={[10, 20, 30, 40, 50, 100]}
      paginationTotalRows={orderCount}
      onChangeRowsPerPage={handlePerRowsChange}
      onChangePage={handlePageChange}
      paginationComponentOptions={paginationComponentOptions}
      noDataComponent={""}
      defaultSortField="deliveredAt"
      defaultSortAsc={false}
    />
  )
}
export default withTranslation()(SalesBoardOrderTimes)
