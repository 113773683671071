import React, { useState } from "react"
import {
  Card,
  Container,
  Row,
  Modal,
} from "reactstrap"
import OrderComponent from "../../components/Order/Order"
import Orders from "./Orders"
import Stores from "./Stores"
import Header from "../../components/Headers/Header"
import { calcBusinessEndTime, calcBusinessStartTime, getBusinessDate, getFirstDayOfMonth, } from "../../utils/calcBusinessTime"
import {
  filteredByRider,
  filteredByStatus,
  retrieveRiders,
  transformOrdersToStores,
  Rider,
} from "./lib"
import { calcDateDifference } from "../../utils/date"
import { CompletedOrdersQueryResponse, useCompletedOrdersQuery } from "../../domain/graphql/query/useCompletedOrdersQuery"
import Graphs from "./Graphs"
import FormItems from "./FormItems"
import Numbers from "./Numbers"
import { useOrderDetailQuery } from "../../domain/graphql/query/useOrderDetailQuery"
import OrderTimes from "./OrderTimes"

const SalesBoard: React.FC = () => {

  const [mode, setMode] = useState<"order" | "orderTimes" | "store" >("order")
  const [showGraphs, setShowGraphs] = useState(false)

  const [selectedOrderId, setSelectedOrderId] = useState<string | null>(null)
  const [selectedRider, setSelectedRider] = useState<Rider | null>(null)
  const [selectedStatus, setSelectedStatus] = useState<"DELIVERED" | "CANCELLED" | "CANCELLED_BY_USER" | null>(null)
  const [showModal, setShowModal] = useState(false)
  const toggleModal = (order: CompletedOrdersQueryResponse) => {
    if (order) setSelectedOrderId(order._id)
    setShowModal(prev => !prev)
  }

  const businessToday = getBusinessDate(new Date())
  const [startDate, setStartDate] = useState(getFirstDayOfMonth(businessToday))
  const [endDate, setEndDate] = useState(businessToday)

  const businessStartTime = calcBusinessStartTime(startDate).getTime()
  const businessEndTime = calcBusinessEndTime(endDate).getTime()

  const { loading, error, data, subscribeToMore } = useCompletedOrdersQuery(
    businessStartTime,
    businessEndTime,
  )
  if (error) {
    console.log(error.message)
  }

  const { error: orderDetailError, data: orderDetailData } = useOrderDetailQuery(selectedOrderId)
  if (orderDetailError) {
    console.log(orderDetailError.message)
  }


  const riders = retrieveRiders(data)
  const filteredOrders = filteredByStatus(filteredByRider(data, selectedRider), selectedStatus) as CompletedOrdersQueryResponse[]
  const dateDiff = calcDateDifference(startDate, endDate)
  const filteredStores = mode === "store" ? transformOrdersToStores(filteredOrders, dateDiff) : []

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <FormItems
          mode={mode}
          setMode={setMode}
          filteredOrders={filteredOrders}
          filteredStores={filteredStores}
          showGraphs={showGraphs}
          setShowGraphs={setShowGraphs}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          riders={riders}
          selectedRider={selectedRider}
          setSelectedRider={setSelectedRider}
          selectedStatus={selectedStatus}
          setSelectedStatus={setSelectedStatus}
        />

        <Numbers
          riders={riders}
          selectedRider={selectedRider}
          selectedStatus={selectedStatus}
          dateDiff={dateDiff}
          businessStartTime={businessStartTime}
          businessEndTime={businessEndTime}
        />

        {showGraphs && <Graphs
          riders={riders}
          selectedRider={selectedRider}
          selectedStatus={selectedStatus}
          businessStartTime={businessStartTime}
          businessEndTime={businessEndTime}
        />}

        <Row>
          <div className="col">
            <Card className="shadow">
              {mode === "order"
                ? <Orders
                  allOrders={filteredOrders}
                  orderCount={filteredOrders.length}
                  loading={loading}
                  subscribeToMore={subscribeToMore}
                  toggleModal={toggleModal}
                />
                : mode === "orderTimes"
                  ? <OrderTimes
                    allOrders={filteredOrders}
                    orderCount={filteredOrders.length}
                    loading={loading}
                    subscribeToMore={subscribeToMore}
                    toggleModal={toggleModal}
                  />
                  : mode === "store"
                    ? <Stores
                      allStores={filteredStores}
                      orderCount={filteredOrders.length}
                      loading={loading}
                      subscribeToMore={subscribeToMore}
                      toggleModal={toggleModal}
                    />
                    : null
              }
            </Card>
          </div>
        </Row>

        <Modal
          className="modal-dialog-centered"
          size="lg"
          isOpen={showModal}
          toggle={() => toggleModal(null)}
        >
          <OrderComponent order={orderDetailData?.order} />
        </Modal>
      </Container>
    </>
  )
}

export default SalesBoard