/* eslint-disable react/display-name */
import React, { useState, useEffect } from 'react'
import { withTranslation } from 'react-i18next'
// reactstrap components
import { Badge, Card, Container, Row, Modal } from 'reactstrap'
import Header from '../components/Headers/Header'
import ZoneComponent from '../components/Zone/Zone'
import CustomLoader from '../components/Loader/CustomLoader'
import { getZones, deleteZone } from '../apollo/server'
import DataTable from 'react-data-table-component'
import orderBy from 'lodash/orderBy'
import Loader from 'react-loader-spinner'
import { paginationComponentOptions } from '../variables/table'
import { useQuery, useMutation, gql, } from "@apollo/client"
import styles from './styles'

const GET_ZONES = gql`
  ${getZones}
`
const DELETE_ZONE = gql`
  ${deleteZone}
`

const Zones = props => {
  const { t } = props
  const [editModal, setEditModal] = useState(false)
  const [zone, setZone] = useState(null)
  const toggleModal = zone => {
    setEditModal(!editModal)
    setZone(zone)
  }

  useEffect(() => {
    localStorage.removeItem('restaurant_id')
  }, [])

  const onCompleted = data => {}
  const onError = error => {
    console.log(error)
  }

  const customSort = (rows, field, direction) => {
    const handleField = row => {
      if (row[field]) {
        return row[field].toLowerCase()
      }

      return row[field]
    }

    return orderBy(rows, handleField, direction)
  }

  const handleSort = (column, sortDirection) =>
    console.log(column.selector, sortDirection)

  const columns = [
    {
      name: t('Title'),
      sortable: true,
      selector: 'title'
    },
    {
      name: t('Description'),
      sortable: true,
      selector: 'description'
    },
    {
      name: t('Actions'),
      cell: row => <ActionButtons row={row} />
    }
  ]
  const ActionButtons = props => {
    const { row } = props
    const [deleteVendor, { loading: zoneLoading }] = useMutation(DELETE_ZONE, {
      onCompleted,
      onError,
      refetchQueries: [
        { query: GET_ZONES, },
      ],
    })
    if (zoneLoading) {
      return (
        <Loader
          type="ThreeDots"
          color="#BB2124"
          height={20}
          width={40}
          visible={zoneLoading}
        />
      )
    }
    return (
      <>
        <Badge
          style={styles.badgeStyle}
          href="#pablo"
          onClick={e => {
            e.preventDefault()
            toggleModal(row)
          }}
          color="primary">
          {t('Edit')}
        </Badge>
        {" "}
        <Badge
          style={styles.badgeStyle}
          href="#pablo"
          color="danger"
          onClick={e => {
            e.preventDefault()
            if (window.confirm('削除してもよろしいですか？')) {
              deleteVendor({ variables: { id: row._id } })
            }
          }}>
          {t('Delete')}
        </Badge>
      </>
    )
  }

  const { loading, error, data } = useQuery(GET_ZONES, {
    onCompleted: data => {},
    onError: error => console.log(error),
  })
  if (error) {
    return (
      <span>
        {`${t('Error')}! ${error.message}`}
      </span>
    )
  }
  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <ZoneComponent />
        {/* Table */}
        <Row className="mt-5">
          <div className="col">
            <Card className="shadow">
              <DataTable
                title={t('Zone')}
                columns={columns}
                data={data && data.zones ? data.zones : []}
                pagination
                progressPending={loading}
                progressComponent={<CustomLoader />}
                onSort={handleSort}
                sortFunction={customSort}
                defaultSortField="title"
                paginationComponentOptions={paginationComponentOptions}
                noDataComponent={t('NoData')}
              />
            </Card>
          </div>
        </Row>
        <Modal
          className="modal-dialog-centered"
          size="lg"
          isOpen={editModal}
          toggle={() => toggleModal() }>
          <ZoneComponent zone={zone} closeModal={setEditModal} />
        </Modal>
      </Container>
    </>
  )
}

export default withTranslation()(Zones)
