import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import {
  Row,
  Col,
  Card,
  CardHeader,
  Button
} from "reactstrap"
import { saveStoreClosedConfiguration } from "../../apollo/server"
import { useMutation, gql, } from "@apollo/client"
import { Alert } from "reactstrap/lib"


interface IProps {
  storeClosed: boolean
}
const StoreClosed: React.FC<IProps> = props => {
  const { t } = useTranslation()
  const [storeClosed, setStoreClosed] = useState(props.storeClosed)
  const [successMessage, setSuccessMessage] = useState("")
  const [errorMessage, setErrorMessage] = useState("")

  const [save, { loading, error }] = useMutation(gql`${saveStoreClosedConfiguration}`, {
    onCompleted: result => {
      if (result.saveStoreClosedConfiguration.storeClosed) setSuccessMessage(t("StoreClosedSuccessMessage"))
      else setSuccessMessage(t("StoreOpenSuccessMessage"))
    },
    onError: e => {
      setErrorMessage(t("ErrorMessage"))
      console.log(e)
    },
  })
  if (error) return <p>{t("Error")}</p>

  function submit(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.preventDefault()
    if (loading) return

    if (storeClosed && !window.confirm(t("StoreClosedConfirmation")))
      return

    if (!storeClosed && !window.confirm(t("StoreOpenConfirmation")))
      return

    save({ variables: { storeClosed } })
  }

  const onDismiss = () => {
    setSuccessMessage("")
    setErrorMessage("")
  }

  return (
    <Row className="mt-3">
      <div className="col">
        <Card className="shadow">
          <CardHeader className="border-0">
            <h3 className="mb-0">{t("StoreClosed")}</h3>
          </CardHeader>

          <Row className="ml-3 mr-3" style={{ display: "flex", alignItems: "center" }}>
            <Col md="10">
              <label className="custom-toggle">
                <input
                  name="input-enable"
                  checked={storeClosed}
                  type="checkbox"
                  onChange={e => setStoreClosed(e.target.checked)}
                />
                <span className="custom-toggle-slider rounded-circle" />
              </label>
            </Col>
            <Col md="2">
              <Button
                className="btn-block mb-2"
                type="button"
                color="primary"
                onClick={submit}
                size="lg">
                {loading ? t("Saving") : t("Save")}
              </Button>
            </Col>
          </Row>
          <Row className="mx-3" style={{ display: "flex", justifyContent: "flex-end" }}>
            <Col md="6">
              <Alert
                color="success"
                isOpen={!!successMessage}
                toggle={onDismiss}>
                <span className="alert-inner--icon">
                  <i className="ni ni-like-2" />
                </span>{" "}
                <span className="alert-inner--text" style={{ whiteSpace: "pre-wrap" }}>
                  <strong>{t("Success")}! </strong>{successMessage}
                </span>
              </Alert>
              <Alert
                color="danger"
                isOpen={!!errorMessage}
                toggle={onDismiss}>
                <span className="alert-inner--icon">
                  <i className="ni ni-like-2" />
                </span>{" "}
                <span className="alert-inner--text">
                  <strong>{t("Danger")}!</strong> {errorMessage}
                </span>
              </Alert>
            </Col>
          </Row>
        </Card>
      </div>
    </Row>
  )
}
export default StoreClosed
