type OrderStatus = "PENDING"
  | "ACCEPTED"
  | "READY_TO_PICK"
  | "PICKED"
  | "DELIVERED"
  | "CANCELLED"
  | "CANCELLED_BY_USER"

export const convertOrderStatus = (status: OrderStatus) => {
  switch (status) {
    case "PENDING":
      return "受付待ち"
    case "ACCEPTED":
      return "準備中"
    case "READY_TO_PICK":
      return "引渡可能"
    case "PICKED":
      return "配達中"
    case "DELIVERED":
      return "配達済み"
    case "CANCELLED":
      return "キャンセル"
    case "CANCELLED_BY_USER":
      return "キャンセル（客不出）"
    default:
      return ""
  }
}
