/* eslint-disable react/display-name */
import React, { useState } from "react"
import { withTranslation } from "react-i18next"
import DataTable from "react-data-table-component"
import orderBy from "lodash/orderBy"
import CustomLoader from "../../components/Loader/CustomLoader"
import { paginationComponentOptions } from "../../variables/table"
import { paging } from "../../utils/pagination"
import { convertOrderStatus } from "../../utils/convertOrderStatus"
import { dateToJapanese } from "../../utils/date"

const SalesBoardOrders = props => {
  console.log("SalesBoardOrders")
  const {
    allOrders,
    orderCount,
    toggleModal,
    loading: propsLoading,
    t,
  } = props

  const [page, setPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(30)
  const orders = paging(allOrders, page, rowsPerPage)

  const propExists = (obj, path) => {
    return path.split(".").reduce((obj, prop) => {
      return obj && obj[prop] ? obj[prop] : ""
    }, obj)
  }

  const customSort = (_rows, field, direction) => {
    const handleField = row => {
      if (field && isNaN(propExists(row, field))) {
        if (field === "rider") {
          return row.rider.name
        }
        return propExists(row, field).toLowerCase()
      }

      return row[field]
    }

    return paging(orderBy(allOrders, handleField, direction), page, rowsPerPage)
  }

  const handleSort = (column, sortDirection) => console.log(column.selector, sortDirection)

  const handlePerRowsChange = async (perPage, page) => {
    setPage(page)
    setRowsPerPage(perPage)
  }

  const handlePageChange = async page => {
    setPage(page)
  }

  const columns = [
    {
      selector: "deliveredAt",
      sortable: true,
      maxWidth: "200px",
      name: t("DeliveryDate"),
      cell: row => <>{dateToJapanese(new Date(row.deliveredAt))}</>
    },
    {
      name: t("OrderStatus"),
      selector: "orderStatus",
      sortable: true,
      width: "170px",
      cell: row => <>{convertOrderStatus(row.orderStatus)}</>
    },
    {
      name: t("OrderInfo"),
      maxWidth: "300px",
      cell: row => (
        <div>
          <p className="mb-0 text-sm">{row.orderId}</p>
          <p className="mb-0 text-sm">{row.deliveryAddress.deliveryAddress}</p>
        </div>
      )
    },
    {
      name: t("Orderer"),
      sortable: true,
      maxWidth: "150px",
      cell: row => <>{row.user.name}</>
    },
    {
      name: t("PaymentMethod"),
      sortable: true,
      maxWidth: "150px",
      cell: row => <>{row.paymentMethod}</>
    },
    {
      name: t("Restaurant"),
      maxWidth: "150px",
      cell: row => <>{row.restaurant.name ?? ""}</>
    },
    {
      name: t("AssignedRider"),
      sortable: true,
      selector: "rider",
      maxWidth: "150px",
      cell: row => <>{row.rider ? row.rider.name : ""}</>
    },
    {
      name: t("Sales"),
      maxWidth: "100px",
      cell: row => {
        if (row.orderStatus === "DELIVERED") {
          return <>{(row.orderAmount - row.storeProfit).toLocaleString()}</>
        } else if (row.orderStatus === "CANCELLED_BY_USER") {
          return <>{(-row.storeProfit).toLocaleString()}</>
        } else if (row.orderStatus === "CANCELLED") {
          return <>{0}</>
        }
      }
    },
  ]

  return (
    <DataTable
      title={t("Order")}
      columns={columns}
      data={orders}
      onRowClicked={toggleModal}
      progressPending={propsLoading}
      pointerOnHover
      progressComponent={<CustomLoader />}
      onSort={handleSort}
      sortFunction={customSort}
      pagination
      paginationServer
      paginationPerPage={30}
      paginationRowsPerPageOptions={[10, 20, 30, 40, 50, 100]}
      paginationTotalRows={orderCount}
      onChangeRowsPerPage={handlePerRowsChange}
      onChangePage={handlePageChange}
      paginationComponentOptions={paginationComponentOptions}
      noDataComponent={""}
      defaultSortField="deliveredAt"
      defaultSortAsc={false}
    />
  )
}
export default withTranslation()(SalesBoardOrders)
