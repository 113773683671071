import { useQuery } from "@apollo/client"
import gql from "graphql-tag"

const orderDetailQuery = gql`query ($id: String!) {
  order(id: $id) {
    _id
    orderId
    paidAmount
    orderAmount
    taxInSubtotal
    customerCharge
    chargeLimit
    noChargeLimit
    storeProfit
    storeCommission
    storeCommissionRate
    orderStatus
    status
    paymentStatus
    reason
    isActive
    deliveryCharges
    memo
    createdAt
    updatedAt
    startPreparationAt
    finishPreparationAt
    startDeliveryAt
    deliveredAt
    deliveryAddress {
      deliveryAddress
      details
      label
      phone
      location {
        coordinates
      }
    }
    restaurant {
      _id
      name
      chargeLimit
      commission
    }
    user {
      _id
      name
      phone
      email
    }
    rider {
      _id
      name
      phone
    }
    items {
      _id
      title
      quantity
      createdAt
      variation {
        _id
        title
        price
        taxInPrice
        storeProfit discounted
      }
      addons {
        _id
        title
        options {
          _id
          title
          price
          taxInPrice
          storeProfit
        }
      }
    }
    coupon {
      discount
    }
  }
}`

export type OrderDetailQueryResponse = {
  _id: string
  orderId: string
  paidAmount: number
  orderAmount: number
  taxInSubtotal: number
  customerCharge: number
  chargeLimit: number
  storeProfit: number
  storeCommission: number
  storeCommissionRate: number
  orderStatus: string
  status: string
  paymentStatus: string
  reason: string
  isActive: boolean
  deliveryCharges: number
  noChargeLimit: boolean
  memo: string
  createdAt: string
  updatedAt: string
  startPreparationAt?: string
  finishPreparationAt?: string
  startDeliveryAt?: string
  deliveredAt: string
  deliveryAddress: {
    deliveryAddress: string
    details: string
    label: string
    phone: string
    location: {
      coordinates: number[]
    }
  }
  restaurant: {
    _id: string
    name: string
    chargeLimit: number
    commission: number
  }
  user: {
    _id: string
    name: string
    phone: string
    email: string
  }
  rider: {
    _id: string
    name: string
    phone: string
  }
  items: {
    _id: string
    title: string
    quantity: number
    createdAt: string
    variation: {
      _id: string
      title: string
      price: number
      taxInPrice: number
      storeProfit: number
      discounted: number
    }
    addons: {
      _id: string
      title: string
      options: {
        _id: string
        title: string
        price: number
        taxInPrice: number
        storeProfit: number
      }[]
    }[]
  }[]
  coupon: {
    discount: number
  }
}

type Response = {
  order: OrderDetailQueryResponse
}
export function useOrderDetailQuery(id?: string) {
  return useQuery<Response>(orderDetailQuery, { 
    variables: { id } ,
    skip: !id 
  })
}