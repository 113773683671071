export const getFirstDayOfMonth: (today: Date) => Date =
  today => {
    const copied = new Date(today)
    copied.setDate(1)
    copied.setHours(2)
    copied.setMinutes(0)
    copied.setSeconds(0)
    return copied
  }
export const getBusinessDate: (today: Date) => Date =
  today => {
    if (today.getHours() >= 11) {
      return today
    } else {
      today.setDate(today.getDate() - 1)
      return today
    }
  }

export const calcBusinessStartTime: (today: Date) => Date =
  start => {
    const copied = new Date(start)
    copied.setHours(2)
    copied.setMinutes(0)
    copied.setSeconds(0)
    return copied
  }

export const calcBusinessEndTime: (today: Date) => Date =
  end => {
    const copied = new Date(end)
    copied.setDate(copied.getDate() + 1)
    copied.setHours(2)
    copied.setMinutes(0)
    copied.setSeconds(0)
    return copied
  }
