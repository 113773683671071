import React from "react"
import {
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
} from "reactstrap"
import { retrieveRiders, Rider, } from "./lib"
import { useTranslation } from "react-i18next"
import { useNewUsersCountQuery } from "../../domain/graphql/query/useNewUsersCountQuery"
import { useNewRestaurantsCountQuery } from "../../domain/graphql/query/useNewRestaurantsCountQuery"
import { useSalesboardStatistics } from "../../domain/graphql/query/useSalesboardStatistics"


interface Props {
  riders: ReturnType<typeof retrieveRiders>
  selectedRider: Rider | null
  selectedStatus: "DELIVERED" | "CANCELLED" | "CANCELLED_BY_USER" | null
  dateDiff: number
  businessStartTime: number
  businessEndTime: number
}
const Numbers: React.FC<Props> = props => {
  const { t } = useTranslation()

  const { error, data } = useSalesboardStatistics(
    props.businessStartTime,
    props.businessEndTime,
    props.dateDiff,
    props.selectedStatus,
    props.selectedRider?._id,
  )
  const { error: userCountError, data: userCountData } = useNewUsersCountQuery(
    props.businessStartTime,
    props.businessEndTime,
  )
  const { error: restaurantCountError, data: restaurantCountData } = useNewRestaurantsCountQuery(
    props.businessStartTime,
    props.businessEndTime,
  )

  if (error) {
    console.log(error)
  }
  if (userCountError) {
    console.log(userCountError.message)
  }
  if (restaurantCountError) {
    console.log(restaurantCountError.message)
  }

  return (
    <>
      <Row>
        <Cell size={2} title={t("OrderCount")} value={data?.salesboardStatistics?.count ?? 0} />
        <Cell size={3} title={t("ReceiptPrice")} value={data?.salesboardStatistics?.receipt.toLocaleString() ?? 0} />
        <Cell size={3} title={t("Pay")} value={data?.salesboardStatistics?.pay.toLocaleString() ?? 0} />
        <Cell size={2} title={t("Sales")} value={data?.salesboardStatistics?.sales.toLocaleString() ?? 0} />
        <Cell size={2} title={t("NewUsersCount")} value={userCountData?.newUsersCount ?? 0} />
      </Row>

      <Row>
        <Cell size={2} title={t("AverageOrderCount")} value={data?.salesboardStatistics?.averageCount ?? 0} />
        <Cell size={3} title={t("AverageReceiptPrice")} value={data?.salesboardStatistics?.averageReceipt.toLocaleString() ?? 0} />
        <Cell size={3} title={t("AveragePay")} value={data?.salesboardStatistics?.averagePay.toLocaleString() ?? 0} />
        <Cell size={2} title={t("AverageSales")} value={data?.salesboardStatistics?.averageSales.toLocaleString() ?? 0} />
        <Cell size={2} title={t("NewRestaurantsCount")} value={restaurantCountData?.newRestaurantsCount ?? 0} />
      </Row>
    </>
  )
}

export default Numbers

interface CellProps {
  size: number
  title: string
  value: string | number
}
const Cell: React.FC<CellProps> = props => (
  <Col className="mb-lg-3 mb-sm-3" xl={props.size}>
    <Card className="card-stats mb-4 mb-lg-0" >
      <CardBody>
        <Row>
          <div className="col">
            <CardTitle className="text-uppercase text-muted mb-0">
              {props.title}
            </CardTitle>
            <span className="h2 font-weight-bold mb-0">
              {props.value}
            </span>
          </div>
        </Row>
      </CardBody>
    </Card>
  </Col >
)