import { useQuery } from "@apollo/client"
import gql from "graphql-tag"

const newRestaurantsCountQuery = gql`query ($start: Float, $end: Float) {
  newRestaurantsCount(start: $start, end: $end)
}`


type Variables = {
  start: number
  end: number
}
type Response = {
  newRestaurantsCount?: number
}

export function useNewRestaurantsCountQuery(start: number, end: number) {
  return useQuery<Response, Variables>(newRestaurantsCountQuery, { variables: { start, end } })
}